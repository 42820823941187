import React, { Component, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

class SessionExpiryPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        // this.open=this.props.open;
    }


    onLoginClick = () => {
        localStorage.clear();
        window.history.pushState({}, "Login", "/#/");
        window.history.go(0)
    }
    render() {
        return (
            <div>
                <Dialog
                    fullScreen
                    open={this.props.open}
                    TransitionComponent={Transition}
                    PaperProps={{
                        style: {
                            backgroundColor: "#000000ba",
                        },
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            alignSelf: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            margin: "auto",
                            textAlign: "center"
                        }}
                    >
                        <div style={{ fontSize: "2rem", color: "white" }}>Your session has timed out</div>
                        <div style={{ marginTop: "20px", display: "inline-block", fontSize: "1.3rem", color: "white" }}>
                            <div style={{ display: "inline-block", color: "#609beb", cursor: "pointer" }}
                                onClick={this.onLoginClick}>
                                Click here</div> to log in again</div>
                    </div>
                </Dialog>
            </div>
        );
    }
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
export default SessionExpiryPopup;