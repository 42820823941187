import React, { PureComponent } from "react";
import "./VentilatorScreen.css";
import img1 from "./Images/PlayButton.svg";
import img2 from "./Images/PauseButton.svg";
import CanvasGauge from "./canvas-gauge";
import Popup from "reactjs-popup";
import VentilatorSettingsPopup from "./Popupscreen";
// import AlarmPillGreen from "./Images/alarm_icon.svg";
import Home_hover_icon from "./Images/home_hover_icon.png";
import Settings from "./Images/alarmsettingicon.svg";
import Ventmode from "./Ventmode";
import Monitorscreen from "./Monitorscreen";
import SettingScreen from "./SettingScreen";
import WarningMsg from "./Keepalive";
import { DEVICEALARM, SOCKET_DEVICESETTING } from "../../api-route";
import {
  httpVentilatorSettingsAuditlog,
  httpVentilatorAlmSettingsAuditlog,
} from "../../services/http";
import { client, RBTMQConnect, RBTMQReconnect } from "../../mqttConnections";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import BackupVentilationBox from "./BackupVnetilationComp";
import AlarmList from "./dialog/alarm-list";
import { formatAlmData } from "./dialog/alarm-list/formatAlmData";
//--------------------------
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SessionExpiryDialog from "./dialog/sessionExpiryDialog";

function BoxComponent(props) {
  // const [enable, setEnable] = useState(props.enable);
  // const [prevValue, setPrevValue] = useState(props.value);
  return (
    <div className="BoxComp-mainDiv">
      <p className="boxComp-CaptionStyle"> {props.caption}</p>
      <h1 className="boxComp-ValueStyle glow-circlular">{props.value}</h1>
      <p className="boxComp-UnitStyle">{props.unit}</p>
    </div>
  );
}
class Homescreen extends PureComponent {
  constructor() {
    super();
    this.state = {
      sessionExpired: false,
      //------------------navigation---------
      showAlarmHistory: false,
      navigation: {
        alarmSetting: false,
        monitoring: false,
      },
      //------------------
      openBackdrop: false,
      //----------------------
      playButton: false,
      time: 0,
      date: 0,
      setting: false,
      ventmode: false,
      monitor: false,
      alarmsetting: false,
      status: false,
      updatedAlarm: {
        id: "",
        Severity: 0,
        c_alarm: "",
        deviceId: "Device-Id",
      },
      mqttConnections: {},
      settings: {
        BR: "12",
        ExpTrig: "0",
        InspP: "15",
        InspT: "1.5",
        InspTrig: "0",
        PEEP: "2",
        PSUP: "5",
        RiseT: "1",
        TV: "300",
        VBrthOpt: "0",
        VMode: "0",
      },
      mainMonitoringValues: {
        BR: 0,
        FIO2: "--",
        IER: "0:0",
        IP: 0,
        IT: 0,
        MV: 0,
        PP: 0,
        SBR: 0,
        SPO2: "--/--",
        TV: 0,
      },
      alarmSettings: {
        APN: "60",
        APNBR: "4",
        APN_EN: "0",
        BR_EN: "1",
        BR_H: "30",
        BR_L: "7",
        CKTD: "60",
        CKTD_EN: "1",
        FIO2_EN: "0",
        FIO2_H: "85",
        FIO2_L: "25",
        MV_EN: "1",
        MV_H: "10",
        MV_L: "2",
        PEEP_EN: "1",
        PEEP_H: "20",
        PEEP_L: "1",
        P_EN: "1",
        P_H: "25",
        P_L: "5",
        SPO2_EN: "0",
        SPO2_H: "99",
        SPO2_L: "70",
        TV_EN: "1",
        TV_H: "700",
        TV_L: "200",
      },
      comStatus: false,
      ventilator: {
        BedID: "",
        RoomID: "",
        Severity: "1",
        c_alarm: "No Alarm",
        deviceId: "_DEVICEID",
        company_id: 100,
        plant_id: "P5@100",
      },
      allAlarms: [],
      toggleMenu: false,
      deviceInfo: {
        FwVersion: "",
        UiVersion: "",
        HMR: "",
        LMDate: ""
      }
    };
    this.openVentmodePopup = this.openVentmodePopup.bind(this);
    this.alarmSettingOld = null;
    this.openSettingScreenPopup = this.openSettingScreenPopup.bind(this);
    this.closePopup = this.closePopup.bind(this);
  }

  componentWillReceiveProps(newProps) {
    // console.log(
    //   `Vntilator:${this.state.ventilator.deviceId} found new props-->`,
    //   newProps
    // );
    if (this.props.ventilator == newProps.ventilator) {
      //console.log("this.props.ventilator == newProps.ventilator", true);
    } else {
      this.replaceComoponent(newProps.ventilator);
      //console.log("this.props.ventilator == newProps.ventilator", false);
    }
  }

  replaceComoponent = (newVentilator) => {
    //console.log("replaceComoponent called for", this.props.ventilator.deviceId);
    this.runWillUnMount();
    this.setState({ ventilator: newVentilator }, () => {
      this.getHistoricalDataOnLoad();
    });
  };

  /* -----------------------------------

  SetValue --> this method is to publish ventilator settings as  key:value
                on topic 'ION/deviceId/SVENTSETTINGS' 
  ------------------------------------*/
  setValue = (toWhichKey, value) => {
    //this.state.ventilator.deviceId
    console.info(
      "[HomeScreen]SetValue:- toWhichKey",
      toWhichKey,
      "value",
      value
    );
    if ("ventilator" in this.state && "deviceId" in this.state.ventilator) {
      let mqttTopic = `ION/${this.state.ventilator.deviceId}/SVENTSETTINGS`;

      if (toWhichKey === "MODE_PVC") {
        client.publish(mqttTopic, `VBrthOpt:${value.VBrthOpt}`);
        client.publish(mqttTopic, `VMode:${value.VMode}`);
        client.publish(mqttTopic, `CktType:${value.CktType}`);
        client.publish(mqttTopic, `PType:${value.PType}`);
      } else {
        client.publish(mqttTopic, `${toWhichKey}:${value}`, () => {
          httpVentilatorSettingsAuditlog({
            key: toWhichKey,
            value: value,
            ventId: this.state.ventilator.deviceId,
          })
            .then((res) => {
              console.log("httpVentilatorSettingsAuditlog", res);
            })
            .catch((err) => {
              console.log("httpVentilatorSettingsAuditlog", err);
            });
        });
      }
    }
  };

  /* -----------------------------------

   playPauseToggle --> this method is to publish ventilator's PLAYBUTTON AS TRUE /FASLE
                  --> mqtt topics comes from db
                on topic 'ION/deviceId/SSTARTSTOP' 
  ------------------------------------*/
  playPauseToggle = () => {
    if ("SSTARTSTOP" in this.state.mqttConnections) {
      if (this.state.mqttConnections.SSTARTSTOP) {
        if (this.state.playButton) {
          // console.log("VSTATE:STOP");
          client.publish(
            this.state.mqttConnections.SSTARTSTOP,
            "VSTATE:STOP",
            (response) => {
              // console.log("Callback value", response);
              this.setState({ playButton: false });
            }
          );
        } else {
          let json = `VSTATE:START`;
          // console.log(`${this.state.mqttConnections.SSTARTSTOP} data `, json);
          // console.log("VSTATE:START");
          client.publish(
            this.state.mqttConnections.SSTARTSTOP,
            json,
            (response) => {
              // console.log("Callback value", response);
              this.setState({ playButton: true });
            }
          );
        }
      }
    }
  };
  componentDidMount() {
    this.runDidMount();
  }

  runDidMount = () => {
    console.log("componentDidMount:Vent:", this.props.ventilator.deviceId);
    this.setState({
      navigation: {
        monitoring: this.props.isCurveMonitoringOpen,
      },
    })
    this.setState({ ventilator: this.props.ventilator }, () => {
      this.getHistoricalDataOnLoad();
    });
    client.subscribe(`ION/${this.props.ventilator.deviceId}/CURRENTALARM`, () => {
      // console.log(`Client subscribed`);
    });
    client.on("message", (topic, msg) => {
      if (topic == `ION/${this.props.ventilator.deviceId}/CURRENTALARM`) {
        // console.log(`CURRENTALARM message:`, JSON.parse(msg.toString()));
        let data = JSON.parse(msg.toString());
        if (data && data.c_alarm && data.Severity && this.state.updatedAlarm.c_alarm !== data.c_alarm) {
          // console.log("Alarm updated to:", data.c_alarm,"UPdated alm:",this.state.updatedAlarm);
          this.setState({
            updatedAlarm: {
              ...this.state.updatedAlarm,
              ...data
            }
          })
        }
      }
    });
  };

  runWillUnMount = () => {
    if (this.disconnectSubscription) {
      this.disconnectSubscription.unsubscribe();
    }
    this.props.socket.off(
      `ION/${this.state.ventilator.deviceId}/ALLALARM`,
      this.sailsAllAlarms
    );
    // this.props.socket.off(
    //   `ION/${this.state.ventilator.deviceId}/HOMEMONITORING`,
    //   this.sailsHomemonitoringValue
    // );
    this.props.socket.off(
      `ION/${this.state.ventilator.deviceId}/DEVICEINFO`,
      this.sailsDeviceInfo
    );
    this.props.socket.off(
      `machine-alarm-settings${this.state.ventilator.deviceId}`,
      this.sailsSubscrieAlarmSetting
    );
    this.props.socket.off(
      `machine-settings${this.state.ventilator.deviceId}`,
      this.sailsSubscrieVentSetting
    );
    this.props.socket.off(
      `update-device-screen-alarm${this.state.ventilator.deviceId}`,
      this.updateDeviceScreenAlarmHandler
    );
    this.props.socket.off(
      `ION/${this.state.ventilator.deviceId}/STARTSTOP`,
      this.sailsStartStopValue
    );
    this.unSubscribeMqtt();
  };

  componentWillUnmount() {
    //clearInterval(this.interval_id);
    client.unsubscribe(`ION/${this.props.ventilator.deviceId}/CURRENTALARM`);
    client.unsubscribe(`ION/${this.state.ventilator.deviceId}/CURVEMONITORING`);
    if (this.reConnectSubscription) {
      this.reConnectSubscription.unsubscribe();
    }
    if (this.connectSubscription) {
      this.connectSubscription.unsubscribe();
    }
    this.runWillUnMount();
  }
  unSubscribeMqtt = () => {
    let { mqttConnections } = this.state;
    let tpoics = Object.values(mqttConnections);
    if (tpoics.length) {
      client.unsubscribe(tpoics);
    }
  };

  processMsg = (topic, msg) => {
    if (topic === `ION/${this.state.ventilator.deviceId}/HOMEMONITORING`) {
      this.sailsHomemonitoringValue(JSON.parse(msg));
    }
  };

  openPopup = () => {
    // console.log("open popup", this.state.setting);

    this.setState({ setting: true });
  };
  closePopup() {
    // console.log("close popup", this.state.setting);
    this.setState({
      setting: false,
      ventmode: false,
      monitor: false,
      alarmsetting: false,
    });
  }

  openVentmodePopup() {
    //console.log("open popup", this.state.ventmode);

    this.setState({ ventmode: true });
  }
  toggleMonitoringCurve = () => {
    this.props.handleToggleingOfCurveMonitoring(this.props.ventilator.deviceId, !this.state.navigation.monitoring)
    this.setState({
      navigation: {
        alarmSetting: false,
        monitoring: !this.state.navigation.monitoring,
      },
    });
  };

  openSettingScreenPopup() {
    //console.log("opne setting screen", this.state.alarmsetting);

    this.alarmSettingOld = { ...this.state.alarmSettings };
    this.setState({ alarmsetting: true });
  }
  publishAlarmSettingsToDevice = (value) => {
    if ("ventilator" in this.state && "deviceId" in this.state.ventilator) {
      let mqttTopic = `ION/${this.state.ventilator.deviceId}/SALARMSSETTING`;

      let objectToSent = {};

      for (let key in value) {
        objectToSent[key] = parseInt(value[key]);
      }
      // console.info("VentilatorAlarmSettingclient.published", objectToSent);
      // console.info("VentilatorAlarmSettingclient.old", this.alarmSettingOld);
      httpVentilatorAlmSettingsAuditlog({
        oldSett: { ...this.alarmSettingOld },
        newSett: { ...objectToSent },
        ventId: this.state.ventilator.deviceId,
      })
        .then((res) => {
          console.log("httpVentilatorSettingsAuditlog", res);
        })
        .catch((err) => {
          console.log("httpVentilatorSettingsAuditlog", err);
        });
      let stringJson = JSON.stringify(objectToSent);
      console.log("Published settings", objectToSent);
      client.publish(mqttTopic, stringJson);
    }

    //    console.log("alarm checcking values", value);
  };
  onSelectVentAtion = (event) => {
    if (event.target.value == "remvovefromwatchlist") {
      this.props.handleClickOnVentId(this.state.ventilator);
      // this.props.removeVentCompletelyById(this.state.ventilator.deviceId);
    }
  };
  onPopUpClose = () => {
    // console.log("ONpopup close ");
    this.setState({
      alarmsetting: false,
      setting: false,
      ventmode: false,
    });
  };
  onToggleClicked = () => {
    this.setState({ toggleMenu: !this.state.toggleMenu })
  }

  render() {
    //console.log(`Render:Vntilator:`, this.props.ventilator.deviceId);
    const {
      setting,
      settings,
      ventilator,
      comStatus,
      mainMonitoringValues,
      alarmSettings,
      playButton,
      updatedAlarm,
      navigation,
      showAlarmHistory,
      allAlarms,
    } = this.state;

    //--------------------------
    let SeverityClass = "linear-gradient(182deg,#55b5590d, #6ac36e0f)";
    if (updatedAlarm) {
      if (updatedAlarm.Severity == 1) {
        SeverityClass = "linear-gradient(182deg,#ba567270, #D61A3C)";
        //console.log("updatedAlarm.Severity", 1);
      } else if (updatedAlarm.Severity == 2) {
        SeverityClass = "linear-gradient(182deg,#ff9e0f6e, #ff9e0f)";
        //console.log("updatedAlarm.Severity", 2);
      } else if (updatedAlarm.Severity == 3) {
        SeverityClass = "linear-gradient(182deg, #a72abd73,#a72abd)";
        //console.log("updatedAlarm.Severity", 3);
      } else if (updatedAlarm.Severity == 4) {
        SeverityClass = "linear-gradient(360deg, #55b5598a, #64c168)";
        //console.log("updatedAlarm.Severity", 4);
      }
    }

    const { ventmode } = this.state;
    const { alarmsetting } = this.state;
    let Mode = null;
    if (settings.VBrthOpt == "AC" && settings.VMode == 3) {
      Mode = "PCV";
      if (settings.InspTrig > 0) {
        //console.log("PCV-----------settings.InspT>0",settings.InspT)
        Mode = "PCV(A)";
      }
    } else if (settings.VBrthOpt == "AC" && settings.VMode == 4) {
      Mode = "VCV";
      if (settings.InspTrig > 0) {
        // console.log("VCV-------------settings.InspTrig>0",settings.InspTrig)
        Mode = "VCV(A)";
      }
    } else if (settings.VBrthOpt == "SU") {
      Mode = "CPAP";
      if (settings.InspTrig > 0) {
        // console.log("VCV-------------settings.InspTrig>0",settings.InspTrig)
        Mode = "PSV";
      }
    } else if (settings.VBrthOpt == "I" && settings.VMode == 3) {
      Mode = "PC-SIMV(PS)";
      if (settings.PSUP == 0) {
        Mode = "PC-SIMV";
      }
    } else if (settings.VBrthOpt == "I" && settings.VMode == 4) {
      Mode = "VC-SIMV(PS)";
      if (settings.PSUP == 0) {
        Mode = "VC-SIMV";
      }
    } else if (settings.VBrthOpt == "PR") {
      Mode = "PVRC";
    }
    let isBackUp = null;
    if (
      "VBrthOpt" in settings &&
      settings.VBrthOpt === "SU" &&
      settings.VMode == 3
    ) {
      isBackUp = true;
    }

    let ventilatorSettingsButtonStyle = {
      container: {
        height: "100%", //8rem
        width: "100%", // 11re,
      },
      modePVC: {
        background: "#919191",
        opacity: "2",
        display: "inline-block",
      },
      InspP: {
        background: "#919191",
        opacity: "2",
        display: "inline-block",
      },
      PEEP: {
        background: "#919191",
        opacity: "2",
        display: "inline-block",
      },
      BR: {
        background: "#919191",
        opacity: "2",
        display: "inline-block",
      },
      InspT: {
        background: "#919191",
        opacity: "2",
        display: "inline-block",
      },
    };
    // render Control InspP or Tidal Vol Based
    // VentilatorMode
    //  for prassure -->Insp.p
    // for Volume TidalVol
    let renderInpPOrTidalVol = (
      <div className="col-md-2" style={{ padding: "0px 5px 2px 5px" }}>
        <button
          style={{
            ...ventilatorSettingsButtonStyle.container,
            ...ventilatorSettingsButtonStyle.InspP,
          }}
          onClick={this.openPopup}
        >
          <span className="buttonCaption">
            {isBackUp ? "BackUp " : " "}Insp. P
          </span>
          <br />
          <span className="buttonValue">
            {"InspP" in settings ? settings.InspP : 0}
          </span>
          <br />
          <span className="buttonUnit">cmH2O</span>
        </button>
      </div>
    );

    if (settings.VMode == 4) {
      // console.log(
      //   "[VnetScreen]-->ventMode4TidalVol has to be enabel",
      //   settings.VMode
      // );
      renderInpPOrTidalVol = (
        <div
          className="col-md-2 disabled"
          style={{ padding: "0px 5px 2px 5px" }}
        >
          <button
            style={{
              ...ventilatorSettingsButtonStyle.container,
              ...ventilatorSettingsButtonStyle.InspP,
            }}
            onClick={this.openPopup}
          >
            <span className="buttonCaption">TidalVol</span>
            <br />
            <span className="buttonValue">
              {"TV" in settings ? settings.TV : 0}
            </span>
            <br />
            <span className="buttonUnit">mL</span>
          </button>
        </div>
      );
    }

    let renderNavbar = (
      <div
        className="row custome-nav homescreen-cus-navbar-style"
        style={{ background: "black" }}
      >
        <div className="col-lg-1 col-xl-1 col-md-1 col-xm-1 p-0">
          <a
            href="#/alpha-vents"
            className="btn btn-lg firstRowButtons"
            role="button"
          >
            <img src={Home_hover_icon} />
          </a>
        </div>
        <div className="col-lg-2 col-xl-2 col-md-2 col-xm-2 text-center p-0 ">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <button
              type="button "
              className="btn btn-lg p-0"
              /*  onClick={() => {
                this.props.handleClickOnVentId(ventilator);
              }} */
              style={{ width: "100%", height: "85%" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  fontSize: "10px",
                }}
              >
                <strong style={{ color: "white", fontSize: "14px" }}>
                  {ventilator.deviceId}
                </strong>
                <div className="row">
                  <div
                    className="col-md-6"
                    style={{ color: "white", fontSize: "12px" }}
                  >
                    R-ID{" "}
                    {updatedAlarm
                      ? "RoomID" in updatedAlarm
                        ? updatedAlarm.RoomID
                        : null
                      : null}
                  </div>

                  <div
                    className="col-md-6"
                    style={{ color: "white", fontSize: "12px" }}
                  >
                    B-ID{" "}
                    {updatedAlarm
                      ? "BedID" in updatedAlarm
                        ? updatedAlarm.BedID
                        : null
                      : null}
                  </div>
                </div>
                <FormControl
                  style={{ marginLeft: "30px", marginRight: "30px" }}
                >
                  <Select
                    value=""
                    /*  displayEmpty */
                    style={{
                      height: "15px",
                      marginLeft: "30px",
                      marginRight: "30px",
                      color: "white",
                      fontSize: "12px",
                    }}
                    onClick={this.onSelectVentAtion}
                  >
                    {/*   <MenuItem value="" disabled>
                      Action
                    </MenuItem> */}
                    <MenuItem value={"remvovefromwatchlist"}>
                      Remove from watchlist
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </button>
          </div>
        </div>
        <div
          className="col-lg-6 col-xl-6 col-md-6 col-xm-5 p-0"
          style={{
            background: SeverityClass,
          }}
        >
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <button
              type="button"
              className="btn btn-lg alarm-btn-device-screen custfontSize"
              style={{
                whiteSpace: "pre-wrap",
                overflow: "hidden",
                background: "transparent",
                boxShadow: "none",
                display: "contents",
              }}
              onClick={() => {
                this.setState({
                  showAlarmHistory: !this.state.showAlarmHistory,
                });
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "40px" }}>
                  {showAlarmHistory ? "⬆" : "⬇"}
                </div>
                <div>{updatedAlarm.c_alarm}</div>
              </div>
            </button>
          </div>

          <AlarmList
            showAlarmHistory={showAlarmHistory}
            alarmList={allAlarms}
          />
        </div>
        <div className="col-lg-1 col-xl-1 col-md-1 col-xm-2 p-0">
          <button
            type="button"
            className="btn btn-lg firstRowButtons"
            onClick={this.openSettingScreenPopup}
          >
            <img src={Settings} style={{ height: "2rem", width: "2rem" }} />
          </button>
        </div>
        <div className="col-lg-2 col-xl-2 col-md-2 col-xm-2 p-0">
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              justifyItems: "center",
              flexWrap: "wrap",
              alignItems: "center",
              flexDirection: "column",
              cursor: "pointer",
            }}
            onClick={this.toggleMonitoringCurve}
          >
            <button
              type="button"
              className="btn btn-lg custfontSize"
              style={{ padding: "10%", display: "contents" }}
            >
              <strong style={{ whiteSpace: "break-spaces" }}>
                {/*ventilator.deviceId*/}{" "}
                {navigation.monitoring ? "Main Screen" : "Curves Screen"}
              </strong>
            </button>
          </div>
        </div>
      </div>
    );
    let renderMonitoringValues = (
      <div
        className="row homescreen-middle-row"
        style={{
          background: "black",
        }}
      >
        <div id="navbarLeft">
          <div className="main-div12 m-0">
            <div className="menu w-100 h-100" className={!this.state.toggleMenu ? 'd-none' : 'd-block bgtransparent h-100'}>
              <div className="row m-0" style={{
                fontSize: "16px",
                color: "white",
                padding: "15px",
              }}>
                <div className="col-12" style={{
                  fontSize: "20px",
                }}>
                  Device information:
                </div>

                <div className="col-7 mt-3">Firmware version MCB / Stepper</div>
                <div className="col-5 mt-3"> {this.state.deviceInfo.FwVersion ?
                  this.state.deviceInfo.FwVersion : '---'}</div>

                <div className="col-7 mt-1">Software version</div>
                <div className="col-5 mt-1">{this.state.deviceInfo.UiVersion ?
                  this.state.deviceInfo.UiVersion : "---"}</div>

                <div className="col-7 mt-1">Hour Meter Reading</div>
                <div className="col-5 mt-1">{this.state.deviceInfo.HMR ?
                  this.state.deviceInfo.HMR : "---"}</div>

                <div className="col-7 mt-1">Last Maintainance Date</div>
                <div className="col-5 mt-1">{this.state.deviceInfo.LMDate ?
                  this.state.deviceInfo.LMDate : "---"}</div>



              </div>
            </div>
            <div onClick={() => this.onToggleClicked()} className="menubar-toggle bg-white">
              {this.state.toggleMenu ?
                <ChevronLeftIcon />
                : <ChevronRightIcon />
              }
            </div>
          </div>
        </div>

        <div className="container-fluid VentSecRowMarginTop VentSecRowPadding">
          <div className="row">
            <div className="col-md-9 align-self-center pr-0 secRowColpadleft">
              {/* FirstRow row in 9 */}
              <div className="row">
                <div className="col-md-3 md3-padding ">
                  {/*-----PP Box------*/}
                  <BoxComponent
                    enable={true}
                    caption={"Peak P."}
                    unit={"cmH2O"}
                    default="0"
                    value={mainMonitoringValues.PP}
                  />
                </div>
                <div className="col-md-3 md3-padding colmaxWidthBR">
                  <BoxComponent
                    enable={true}
                    caption={"Breath Rate"}
                    unit={"BPM"}
                    default="0"
                    value={Math.round(Number(mainMonitoringValues.BR))}
                  />
                </div>
                <div className="col-md-3 md3-padding">
                  <BoxComponent
                    enable={true}
                    caption={"Spont BR"}
                    unit={"%"}
                    default="0"
                    value={mainMonitoringValues.SBR}
                  />
                </div>
                <div className="col-md-3 md3-padding colmaxWidthTV">
                  <BoxComponent
                    enable={true}
                    caption={"TV(i/e)"}
                    unit={"mL"}
                    default="0"
                    value={mainMonitoringValues.TV}
                  />
                </div>
              </div>

              {/* Second row in 9 */}
              <div className="row">
                <div className="col-md-3 md3-padding">
                  {/*-----PP Box------*/}
                  <BoxComponent
                    enable={true}
                    caption={"FiO2"}
                    unit={"%"}
                    default="0"
                    value={
                      mainMonitoringValues.FIO2
                        ? mainMonitoringValues.FIO2
                        : "--"
                    }
                  />
                </div>
                <div className="col-md-3 md3-padding colmaxWidthIE">
                  <BoxComponent
                    caption={"I:E Ratio"}
                    unit={" "}
                    default="0"
                    enable={true}
                    // value={
                    //   "IER" in mainMonitoringValues
                    //     ? parseFloat(mainMonitoringValues.IER).toFixed(2)
                    //     : 0
                    // }
                    value={
                      mainMonitoringValues.IER
                        ? mainMonitoringValues.IER
                        : "0:0"
                    }
                  />
                </div>
                <div className="col-md-3 md3-padding">
                  <BoxComponent
                    caption={"SpO2/HR"}
                    unit={"%"}
                    enable={true}
                    default="--/--"
                    value={
                      mainMonitoringValues.SPO2
                        ? mainMonitoringValues.SPO2
                        : "--/--"
                    }
                  />
                </div>
                <div className="col-md-3 md3-padding colmaxWidthMV">
                  <BoxComponent
                    enable={true}
                    caption={"Minute Vol"}
                    unit={"L"}
                    default="0"
                    value={mainMonitoringValues.MV}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 pr-0">
              <div className="canvasDivStyle">
                <CanvasGauge
                  ip={mainMonitoringValues.IP}
                  InspP={settings.InspP ? settings.InspP : null}
                  PEEP={settings.PEEP ? settings.PEEP : null}
                  P_H={alarmSettings.P_H}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    let renderBackupVentilationBox = null;
    if (isBackUp && this.isApnea) {
      // renderBackupVentilationBox = (
      //   <BackupVentilationBox alarm={updatedAlarm.c_alarm} />
      // );
      renderBackupVentilationBox = <BackupVentilationBox alarm={"APNEA"} />;
    }
    let renderControls = (
      <div
        className="row"
        style={{
          background: "black",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-2" style={{ padding: "0px 5px 2px 5px" }}>
              <button
                style={{
                  ...ventilatorSettingsButtonStyle.container,
                  ...ventilatorSettingsButtonStyle.modePVC,
                }}
                onClick={this.openVentmodePopup}
              >
                <span className="FstbuttonCaption">Mode</span>
                <br />
                <span className="FstbuttonValue">{Mode}</span>
              </button>
            </div>
            {renderInpPOrTidalVol}
            <div className="col-md-2" style={{ padding: "0px 5px 2px 5px" }}>
              <button
                style={{
                  ...ventilatorSettingsButtonStyle.container,
                  ...ventilatorSettingsButtonStyle.PEEP,
                }}
                onClick={this.openPopup}
              >
                <span className="buttonCaption">PEEP</span>
                <br />
                <span className="buttonValue">{settings.PEEP}</span>
                <br />
                <span className="buttonUnit">cmH2O</span>
              </button>
            </div>
            <div className="col-md-2" style={{ padding: "0px 5px 2px 5px" }}>
              <button
                style={{
                  ...ventilatorSettingsButtonStyle.container,
                  ...ventilatorSettingsButtonStyle.BR,
                }}
                onClick={this.openPopup}
              >
                <span className="buttonCaption">B.Rate</span>
                <br />
                <span className="buttonValue">{settings.BR}</span>
                <br />
                <span className="buttonUnit">BPM</span>
              </button>
            </div>
            <div className="col-md-2" style={{ padding: "0px 5px 2px 5px" }}>
              <button
                style={{
                  ...ventilatorSettingsButtonStyle.container,
                  ...ventilatorSettingsButtonStyle.InspT,
                }}
                onClick={this.openPopup}
              >
                <span className="buttonCaption">Insp. Time</span>
                <br />
                <span className="buttonValue">{settings.InspT}</span>
                <br />
                <span className="buttonUnit">sec</span>
              </button>
            </div>

            <div
              className="col-md-2"
              style={{
                padding: "0px 5px 2px 5px",
                opacity: "0.4",
                cursor: "not-allowed",
              }}
            >
              <img
                style={{ height: "100%", width: "100%" }}
                src={playButton ? img2 : img1}
              //onClick={(event) => this.playPauseToggle()}
              />
            </div>
          </div>
        </div>
      </div>
    );

    let renderMonitoringCurve = null;
    /* console.log("navigation.monitoring", navigation.monitoring); */
    if (navigation.monitoring) {
      renderControls = null;
      renderMonitoringValues = null;
      renderMonitoringCurve = (
        <div
          className="row homescreen-middle-row"
          style={{
            background: "black",
          }}
        >
          <div
            className="container-fluid  VentSecRowPadding responsiveTopMargin"
            style={{ overflow: "hidden" }}
          >
            {/* FirstRow row in 9 */}

            <Monitorscreen
              socket={this.props.socket}
              mood={Mode}
              mainMonitoringValues={mainMonitoringValues}
              deviceId={ventilator.deviceId}
              settings={settings}
              openSettingPopup={this.openPopup}
            />
          </div>
        </div>
      );
    } else {
    }
    return (
      <React.Fragment>
        <SessionExpiryDialog
          open={this.state.sessionExpired}
        ></SessionExpiryDialog>
        <div
          className="card"
          style={{
            background: "black",
            overflow: "auto",
            margin: "2px 0px",

            borderRadius: "0px",
          }}
        >
          <div
            className="card-body homescreen-card-body-hidden-overflow remove-card-body-padding"
            style={{ paddingTop: "0px" }}
          >
            {/* ------ Navbar -----*/}
            {renderNavbar}
            {/* ------ Middle info  -----*/}
            {/*  */}

            {renderMonitoringValues}
            {/*---- control buttons  plus play buttons  ---*/}
            {renderBackupVentilationBox}
            {renderControls}
            {renderMonitoringCurve}
            {/*---- here navbar,middleinfo,bottomContolles ended now below all popup are there ---*/}
            {/*---- popups start here  ---*/}
            <WarningMsg
              deviceId={ventilator.deviceId}
              ventilator={ventilator}
              handleClickOnVentId={this.props.handleClickOnVentId}
            />
            <div>
              <Popup
                contentStyle={{
                  backgroundColor: "black",
                  height: "600px",
                  width: "1000px",
                  paddingTop: "2px",
                  // marginLeft: "15px",
                }}
                open={ventmode}
                closeOnDocumentClick={false}
                onClose={this.onPopUpClose}
              >
                <Ventmode
                  ventMode={this.state.mode}
                  setValue={this.setValue}
                  ventilator={this.state.ventilator}
                  closePopup={this.closePopup}
                  settings={settings}
                />
              </Popup>
            </div>

            <div>
              <Popup
                contentStyle={{
                  backgroundColor: "black",
                  opacity: "1",
                  width: "90%",
                  paddingTop: "2px",
                }}
                open={setting}
                closeOnDocumentClick={false}
                onClose={this.onPopUpClose}
              >
                <VentilatorSettingsPopup
                  setValue={this.setValue}
                  ventModeStatus={Mode}
                  settings={settings}
                  confirmSettings={this.postVentilatorSettings}
                  closePopup={this.closePopup}
                />
              </Popup>
            </div>
            <div>
              <Popup
                contentStyle={{
                  backgroundColor: "black",
                  width: "98%",
                  paddingTop: "40px",
                  margin: "auto",
                }}
                open={alarmsetting}
                closeOnDocumentClick={false}
                closeOnEscape={true}
                onClose={this.onPopUpClose}
              >
                <SettingScreen
                  closePopup={this.closePopup}
                  alarmSettings={alarmSettings}
                  peakhighlowvalue={this.publishAlarmSettingsToDevice}
                />
              </Popup>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  handleBackdropClose = (reason, event) => {
    console.log("handleBackdropClose event", event, "reson", reason);
  };
  sailsSubscrieVentSetting = (data) => {
    this.setState({ settings: data });
  };
  sailsAllAlarms = (data) => {
    // console.log("sailsAllAlarms data:", data);
    if (data && data.ALLALARM && Array.isArray(data.ALLALARM)) {
      this.setState({
        allAlarms: formatAlmData(
          data.ALLALARM,
          () => {
            this.isApnea = true;
          },
          () => {
            this.isApnea = false;
          }
        ),
      });
    }
  };
  sailsSubscrieAlarmSetting = (data) => {
    this.setState({ alarmSettings: data });
  };
  sailsDeviceInfo = (data) => {
    // console.log("sailsDeviceInfo Data:", data);
    this.setState({ deviceInfo: data })
  }
  machineSocketMachineHandler = (data) => {
    if ("settings" in data) {
      console.log("Socket:DeviceScreen-settings", data);
      if ("deviceId" in this.state.ventilator) {
        console.log("deviceId in this.state.ventilator->", true);
        if (data.settings.deviceId == this.state.ventilator.deviceId) {
          console.log(
            "data.settings.deviceId == this.state.ventilator.deviceId->",
            true
          );

          if ("json" in data.settings) {
            console.log("settings: data.json->", data.settings);
            this.setState({ settings: data.settings.json });
          } else {
            console.log("settings: not found data.json->", data.settings);
            console.log(
              "settings: not found data.json->",
              Object.keys(data.settings)
            );
          }
        } else {
          console.log(
            "data.settings.deviceId == this.state.ventilator.deviceId->",
            false
          );
        }
      }

      //this.setState({ settings: data.settings });
    }
    if ("alarmSettings" in data) {
      if ("deviceId" in this.state.ventilator) {
        // console.log("Socket:DeviceScreen-settings", data);
        if (data.alarmSettings.deviceId == this.state.ventilator.deviceId) {
          this.setState({ alarmSettings: data.alarmSettings.json });
          // console.log("Socket:machine-alarmSettings");
        }
      }
    }
  };

  rbMQTT_SubscribeDeviceSpecificTopics = () => {
    window.mqtt.subscribe(
      `ION/${this.state.ventilator.deviceId}/HOMEMONITORING`
    );
  };
  sailsHomemonitoringValue = (data) => {
    this.setState({ mainMonitoringValues: data, comStatus: true });
  };
  sailsStartStopValue = (data) => {
    // console.log("STARTSTOP value:", data)
    if (data && "VSTATE" in data) {
      if (data.VSTATE === "false") this.setState({ playButton: false });
      if (data.VSTATE === "true") this.setState({ playButton: true });
    }
  };
  initMQTT = () => {
    if (this.reConnectSubscription) {
      this.reConnectSubscription.unsubscribe();
    }
    if (this.connectSubscription) {
      this.connectSubscription.unsubscribe();
    }
    this.rbMQTT_SubscribeDeviceSpecificTopics();
    this.connectSubscription = RBTMQConnect.subscribe((obserer) => {
      this.rbMQTT_SubscribeDeviceSpecificTopics();
    });
    this.reConnectSubscription = RBTMQReconnect.subscribe((obserer) => {
      this.unSubscribeMqtt();
    });

    window.mqtt.on("message", this.processMsg);
    window.mqtt.publish(
      `ION/${this.state.ventilator.deviceId}/SINITSYNC`,
      "SYNCVENTSETTINGS"
    );

    //sails server
    // this.props.socket.on("machine", this.machineSocketMachineHandler); // used for vent setting from sails need to correct
    // this.props.socket.on("update-device-screen-alarm",this.updateDeviceScreenAlarmHandler);
    window.socket.on(
      `ION/${this.state.ventilator.deviceId}/ALLALARM`,
      this.sailsAllAlarms
    );
    // window.socket.on(
    //   `ION/${this.state.ventilator.deviceId}/HOMEMONITORING`,
    //   this.sailsHomemonitoringValue
    // );
    window.socket.on(`ION/${this.state.ventilator.deviceId}/DEVICEINFO`, this.sailsDeviceInfo);
    window.socket.on(
      `machine-alarm-settings${this.state.ventilator.deviceId}`,
      this.sailsSubscrieAlarmSetting
    );
    window.socket.on(
      `machine-settings${this.state.ventilator.deviceId}`,
      this.sailsSubscrieVentSetting
    );
    window.socket.on(
      `update-device-screen-alarm${this.state.ventilator.deviceId}`,
      this.updateDeviceScreenAlarmHandler
    );

    window.socket.on(
      `ION/${this.state.ventilator.deviceId}/STARTSTOP`,
      this.sailsStartStopValue
    );
  };

  getHistoricalDataOnLoad = () => {
    let ventilator = this.state.ventilator;
    //console.log("getHistoricalDataOnLoad, ", ventilator);
    if (ventilator && Object.keys(ventilator).length > 1) {
      //console.info("Device-Screen: loclStg", ventilator);
      let token = localStorage.getItem("abmUsertoken");
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json", Authorization: token },
        // body: JSON.stringify({ name:plant.plant  })
      };

      fetch(`${DEVICEALARM}${ventilator.deviceId}`, requestOptions)
        .then((res) => {
          if (res.status === 401) {
            this.setState({ sessionExpired: true });
          }
          return res.json();
        })
        .then((data) => {
          let newSate = {};
          // if ("deviceInfo" in data) {
          //   // console.info("HistoricalData :DeviceScreen-mainMonitoringValues");
          //   newSate["deviceInfo"] = data.deviceInfo;
          // }
          if ("mainMonitoringValues" in data) {
            // console.info("HistoricalData :DeviceScreen-mainMonitoringValues");
            newSate["mainMonitoringValues"] = data.mainMonitoringValues;
            //this.setState({ mainMonitoringValues: data.mainMonitoringValues });
          }
          if ("settings" in data) {
            //console.info("HistoricalData :DeviceScreen-settings");
            newSate["settings"] = data.settings;
            // this.setState({ settings: data.settings });
          }
          if ("mqttConnections" in data) {
            // console.info("mqttConnections :DeviceScreen-settings");
            newSate["mqttConnections"] = data.mqttConnections;
            // this.setState({ settings: data.settings });
          }
          if ("alarmSettings" in data) {
            //console.info("alarmSettings :DeviceScreen-alarmSettings");
            newSate["alarmSettings"] = data.alarmSettings;
            // this.setState({ settings: data.settings });
          }
          if ("alarm" in data) {
            // console.info("alarm :DeviceScreen-alarm");
            newSate["updatedAlarm"] = data.alarm;
            newSate["playButton"] = data.alarm.VSTATE == 'true' ? true : false;
            // this.setState({ settings: data.settings });
          }
          if ("ALLALARM" in data) {
            //  console.info("allAlarms of VentilatorScreen",data.ALLALARM);

            newSate["allAlarms"] = formatAlmData(
              data.ALLALARM,
              () => {
                this.isApnea = true;
              },
              () => {
                this.isApnea = false;
              }
            );
          } else {
            let updatedAlarm = {
              c_alarm: ventilator.c_alarm,
              Severity: ventilator.Severity,
              RoomID: ventilator
                ? "RoomID" in ventilator
                  ? ventilator.RoomID
                  : ""
                : "",
              BedID: ventilator
                ? "BedID" in ventilator
                  ? ventilator.BedID
                  : ""
                : "",
            };
            newSate["updatedAlarm"] = updatedAlarm;
          }
          // console.log("Historical Data", data);
          this.setState(newSate, this.initMQTT);
        })
        .catch((error) => {
          // console.log("data-error", ventilator.deviceId);
          console.log("Error in fetch ", error);
        });
    }
  };
  updateDeviceScreenAlarmHandler = (update) => {
    if (update && "Severity" in update && "c_alarm" in update) {
      this.setState({ updatedAlarm: update });
    }
  };
}
export default Homescreen;
