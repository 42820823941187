
import React from "react";
import { Router,Switch,HashRouter, Route, Link, Redirect } from "react-router-dom";
import error from "./Screens/Images/4042.png"

const PrivateRoute = () => {
  

  return (
   <div style={{position:"relative",top:"150px",display:"flex",justifyContent:"center",margin:"auto"}}>
    <div style={{}}> <img style={{height:"150px",width:"150px"}} src={error} alt="Logo" /></div>
     <div style={{marginLeft:"70px",marginTop:"20px"}}><p style={{fontSize:"30px",fontWeight:"bold",marginLeft:"0px"}}>404 Page Not Found</p>
     <span>We can't find page your looking for.</span></div>
   </div>
  )
}

export default PrivateRoute;
