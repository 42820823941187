import React from "react";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
const useStyles = makeStyles((theme) => ({
  root: {
    "&:hover": {
      background: "#efefef",
      cursor: "hand"
    },
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: "100%",
      height: theme.spacing(6),
    },
  },
}));

export const VentsCard = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper elevation={2}
        onClick={() => {
          let query = QueryString.stringify(data);
          history.push(`/device-screen?${query}`);
        }}
        style={{ cursor: "hand", height: 'auto' }}>
        <div className="row" style={{ padding: '4px 0px' }}>
          <div className='col-md-3 col-6 d-flex justify-content-center ' style={{ padding: '5px', flexFlow: 'column' }}>
            <p style={{ margin: '0px', fontSize: "20px", paddingLeft: '15px', textAlign: 'center' }}>{data.deviceId}
            </p>
          </div>
          <div className='col-md-3 col-6 d-flex justify-content-center ' style={{ padding: '5px', flexFlow: 'column' }}>
            <p style={{ margin: '0px', textAlign: 'center' }}>
              <span>Bed ID: </span>
              <span>{data.BedID}</span>
            </p>
          </div>
          <div className='col-md-3 col-6 d-flex justify-content-center ' style={{ padding: '5px', flexFlow: 'column' }}>
            <p style={{ margin: '0px', textAlign: 'center' }}> <span>Room ID: </span>
              <span>{data.RoomID}
              </span>
            </p>
          </div>
          <div className='col-md-3 col-6 d-flex justify-content-center ' style={{ padding: '5px', flexFlow: 'column' }}>
            <p style={{ margin: '0px', textAlign: 'center' }}>
              <span>{data.VSTATE=='true' ? `Running` : `Standby`}</span>
            </p>
          </div>
        </div>
      </Paper>
    </div>
  );
}