import React, { useEffect, useState } from "react";
import { fetchPalylist } from "../../../services/http";
import ReactPlayer from "react-player";

function App({ user }) {
  const [playlist, setPlaylist] = useState([]);
  useEffect(() => {
    fetchPalylist({ token: user.token })
      .then(async (res) => {
        let list = await res.json();
        console.log("Playlist-res", list);
        setPlaylist([list[0].stream]);
      })
      .catch((res) => {
        console.log("Playlist-error", res);
      });
  }, []);
  if (playlist.length) {
    return (
      // <ReactPlayer
      //   style={{ borderLeft: "1px solid #3f3c3c" }}
      //   width={"100%"}
      //   height={"100%"}
      //   url={playlist}
      // />
      <iframe src={playlist} width="100%" height="100%" title="Live Ventilator screen"></iframe>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      }}
    >
      <h4 style={{ color: "white", textAlign: "center" }}>No Streams found</h4>
      <h6 style={{ color: "white", textAlign: "center" }}>
        Please check with admin
      </h6>
    </div>
  );
}

export default App;
