import mqtt from "mqtt";
import * as Constants from "./components/constants";
import { fromEvent } from "rxjs";
const options = {
  username: Constants.RABBIT_USERNAME,
  password: Constants.RABBIT_PASSWORD,
};
client = mqtt.connect(Constants.WS_URL, { ...options, reconnectPeriod: 1000,
  clientId:'ui-'+new Date().getMilliseconds().toString()+Math.random().toString(16).substr(2, 8)}); //unique random String as clientId
export const RBTMQConnect = fromEvent(client, "connect");
export const RBTMQDisconnect = fromEvent(client, "disconnect");
export const RBTMQError = fromEvent(client, "error");
export const RBTMQReconnect = fromEvent(client, "reconnect");
export const RBTMQReconnecting = fromEvent(client, "reconnecting");
export var client;
window.mqtt=client
