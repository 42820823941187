import React, { useState, useEffect } from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import { Link } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";

import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
// import MoreVertIcon from "@material-ui/icons/MoreVert";
import QueryString from "query-string";
export default (props) => {
  const history = useHistory();
  const [watch, setWatch] = React.useState(Boolean(props.quickView));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOnMenuItem = (e) => {
    if (!e) var e = window.event;
    e.cancelBubble = true;
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    // console.log("Click occured at Watch/Unwatch");
    props.onTap({ ...props.ventilator, name: props.ventilator.deviceId });
    //setWatch(!watch);
    setAnchorEl(null);
  };
  useEffect(() => {
    if (props.quickView) {
      setWatch(true);
    } else {
      setWatch(false);
    }
  });

  const MenuJsx = (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHorizIcon
          style={{
            color: "white",
          }}
        ></MoreHorizIcon>
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClickOnMenuItem}>
          {watch ? (
            <span style={{ display: "inherit" }}>
              <VisibilityOffIcon />
              <span style={{ paddingLeft: "5px" }}> Unwatch</span>
            </span>
          ) : (
            <span style={{ display: "inherit" }}>
              <VisibilityIcon />
              <span style={{ paddingLeft: "5px" }}> Watch</span>
            </span>
          )}
        </MenuItem>
      </Menu>

      <span style={{ paddingTop: "13px", paddingLeft: "11px" }}>
        {watch ? <VisibilityIcon style={{ fontSize: "28px" }} /> : null}
      </span>
    </div>
  );

  return (
    <div
      className="row"
      onClick={() => {
        // console.log("Navigating to vent screen");
        // window.history.pushState(
        //   { data: "ok" },
        //   "device screen",
        //   "#/device-screen"
        // );
        // this.setState({ alarm: {alarm} });
        // this.setState({ directToDevice: true });
        let query = QueryString.stringify(props.ventilator);
        history.push(`/device-screen?${query}`);
      }}
    >
      <div
        className="col-2"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          //  minWidth: "3rem",
          //   maxWidth: "10rem"
        }}
      >
        <NotificationsIcon
          style={{
            color: "white",
            fontSize: "43px",
          }}
        />
      </div>
      <div className="col-8" style={{ paddingLeft: "0px" }}>
        <div style={{}}>
          <h4> Vent ID:{props.ventilator.deviceId}</h4>
          <p style={{ marginBottom: "5px" }}>{props.ventilator.c_alarm} </p>
          <p style={{ margin: "0px" }}>
            Room Number:{props.ventilator.RoomID}
            {<br />} Bed ID:
            {props.ventilator.BedID}
          </p>
        </div>
      </div>
      <div className="col-2" style={{ display: "flex", justifyContent: "center" }}>
        {MenuJsx}
      </div>
    </div>
  );
};
