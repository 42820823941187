import React, { useRef, useEffect } from 'react'

function Canvas(props) {
    const canvasRef = useRef(null)
    useEffect(() => {
        let peeptick = props.peep ? Number(props.peep) : 0;
        let peektick = props.inspp && props.peep ? Number(props.inspp) + Number(props.peep) : 30;
        let limittick = props.highpressurelimit ? Number(props.highpressurelimit) : 50;
        // peeptick = 0
        // peektick = 30;
        // limittick = 45;
        // console.clear();
        // console.log("props.highpressurelimit:", props.highpressurelimit," props.peep:",props.peep, " props.inspp:",props.inspp)
        const canvas = canvasRef.current
        const ctx = canvas.getContext('2d')
        let topBottomOffset = 40;                      // Difference between total height of canvas and total height of actual rectatngular gauge
        let bottomOffset = 20;                         //Gap between bottom of canvas and bottom of actual rectatngular gauge
        let captionDiff = 5;
        let addDiff = 0;
        let ticksGap = (canvas.height - topBottomOffset) / 60;
        // console.log("ticksGap is:", ticksGap)
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.lineWidth = "2";
        ctx.strokeStyle = "yellow";
        ctx.fillStyle = "#ffffffc9";
        ctx.font = "14px Arial";
        if (peeptick >= 0 && peeptick <= 60) {
            ctx.beginPath();
            ctx.moveTo(80, (canvas.height - bottomOffset) - (ticksGap * peeptick));
            ctx.lineTo(117, (canvas.height - bottomOffset) - (ticksGap * peeptick));
            ctx.stroke();
            ctx.fillText("PEEP", 120, (canvas.height - bottomOffset) - (ticksGap * peeptick) + captionDiff);
        }


        if (peektick >= 0 && peektick <= 60) {
            ctx.beginPath();
            ctx.moveTo(80, (canvas.height - bottomOffset) - (ticksGap * peektick));
            ctx.lineTo(117, (canvas.height - bottomOffset) - (ticksGap * peektick));
            ctx.stroke();
            ctx.fillText("Ppeak", 120, (canvas.height - bottomOffset) - (ticksGap * peektick) + captionDiff);
        }

        if (limittick >= 0 && limittick <= 60) {
            if (peektick === limittick) { //condition to avoid overlaping of peektick and limittick
                addDiff = 0.2;
                captionDiff = -8;
            }
            if (peektick === limittick + 1) { //condition to avoid overlaping of peektick and limittick
                captionDiff = 8;
            }
            if (peektick === limittick - 1) {//condition to avoid overlaping of peektick and limittick
                captionDiff = -5;
            }
            ctx.strokeStyle = "#FF0000";
            ctx.beginPath();
            ctx.moveTo(80, (canvas.height - bottomOffset) - (ticksGap * (limittick + addDiff)));
            ctx.lineTo(117, (canvas.height - bottomOffset) - (ticksGap * (limittick + addDiff)));
            ctx.stroke();
            ctx.fillText("Plimit", 120, (canvas.height - bottomOffset) - (ticksGap * (limittick + addDiff)) + captionDiff);
        }

    }, [props])

    return <canvas width={160}
        height={400} ref={canvasRef} {...props} />
}

export default Canvas