import React from "react";
import CustCanvasGauge from "./CustCanvasGauge";
import { LinearGauge } from "react-canvas-gauges";
export default function Gauge({PEEP,InspP,P_H,ip}) {
  // console.log("Settings:", props.settings, "Alarm Settings:", props.alarmSettings, "PEEP:", props.settings.PEEP, " InspPressure:", props.settings.InspP);
  // let peepVal =
  //   props.settings && props.settings.PEEP ? props.settings.PEEP : null;
  // let inspPVal =
  //   props.settings && props.settings.InspP ? props.settings.InspP : null;
  // let highPressureLimitVal =
  //   props.settings && props.alarmSettings.P_H ? props.alarmSettings.P_H : null;
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "auto",
          left: "auto",
          zIndex: "90",
        }}
      >
        <CustCanvasGauge
          // peep={peepVal}
          // inspp={inspPVal}
          // highpressurelimit={highPressureLimitVal}
          peep={PEEP}
          inspp={InspP}
          highpressurelimit={P_H}
        />
      </div>
      <div>
        <LinearGauge
          width={160}
          height={400}
          /* colorNeedle={"yellow"} */
          colorUnits="black"
          minValue={0}
          maxValue={60}
          majorTicks={["0", "15", "30", "45", "60"]}
          minorTicks={2}
          strokeTicks={true}
          highlights={false}
          colorPlate={"black"}
          borderShadowWidth={0}
          borders={false}
          /*  needleType={"line"} */
          /* needleWidth={2} */
          animationDuration={1500}
          animationRule={"linear"}
          tickSide={"left"}
          numberSide={"left"}
          needle={false}
          /* needleSide={"left"} */
          barStrokeWidth={-10}
          barBeginCircle={0}
          value={ip} ///hard code value
          colorBarProgress="#09F9F9"
          colorValueText="red"
          colorBar="#ffffff00"
          colorBarEnd="#ffffff30"
          colorMajorTicks="#ffffff22"
          colorNumbers="white"
          valueBox={false}
          barProgress={true}
        ></LinearGauge>
      </div>{" "}
    </div>
  );
}
