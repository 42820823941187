import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";

// *******************************************************
// RAIL
// *******************************************************
const railOuterStyle = {
  position: "absolute",
  height: "100%",
  width: 42,
  transform: "translate(-50%, 0%)",
  borderRadius: 7,
  cursor: "pointer",
  // border: '1px solid white',
};

const railInnerStyle = {
  position: "absolute",
  height: "100%",
  width: 24,
  transform: "translate(-50%, 0%)",
  borderRadius: 7,
  pointerEvents: "none",
  backgroundColor: "rgb(155,155,155)",
};

export function SliderRail({ getRailProps }) {
  return (
    <Fragment>
      <div style={railOuterStyle} {...getRailProps()} />
      <div style={railInnerStyle} />
    </Fragment>
  );
}

SliderRail.propTypes = {
  getRailProps: PropTypes.func.isRequired,
};

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
export class Handle extends Component {
  state = {
    high: true,
  };
  handleChange = (data) => {
    this.props.onchange(data);
  };
  componentWillReceiveProps(props) {
    this.setState({ high: props.findHigh });
  }
  render() {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      index,
      getHandleProps,
    } = this.props;

    let customHandleStyle = null;
    if (this.props.readOnly) {
      customHandleStyle = {
        top: `${percent}%`,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        borderRadius: "50%",
        width: 34,
        height: 34,
        backgroundColor: "white",
      };
    } else {
      customHandleStyle = {
        top: `${percent}%`,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        borderRadius: "50%",
        width: 34,
        height: 34,
        //border: "6px solid white", // main circle color
        boxShadow:
          index === 0 && this.state.high
            ? "1px 1px 10px 10px red"
            : index === 1 && !this.state.high
            ? "1px 1px 10px 10px red"
            : "1px 1px 1px 1px yellow",
        backgroundColor: "white",
      };
    }

    return (
      <Fragment>
        <div
          style={{
            top: `${percent}%`,
            position: "absolute",
            transform: "translate(-50%, -50%)",
            WebkitTapHighlightColor: "rgba(0,0,0,0)",
            zIndex: 5,
            width: 24,
            height: 24,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "none",
          }}
          className="first-div"
          {...getHandleProps(id, {
            onClick: () => {
              // console.log("xsujan ID is",id)
              if (id === "$$-0") {
                this.handleChange(true);
              } else if (id === "$$-1") {
                this.handleChange(false);
              }
            },
          })}
        >
          <span
            className="para"
            style={{
              borderRadius: "2px",
              fontSize: "24px",
              color: index == 0 ? "red" : "rgb(49,56,138)",
            }}
          >
            {index == 0 ? "H" : "L"}
          </span>
          <div
            className={"handel-rectangle"}
            style={{
              fontFamily: "Roboto",
              fontSize: 11,
              position: "absolute",
              left: "-44px",
              left: index == 0 ? "-53px" : "44px",
            }}
          >
            <span
              style={{
                backgroundColor: "white",
                boxShadow: " 0 4px 8px 0 rgba(0,0,0,0.2)",
                display: "flex",
                height: "1.8rem",
                width: "2rem",
                fontWeight: "bolder",
                fontSize: "medium",
                alignItems: " center",
                justifyContent: "center",
                borderRadius: "6PX",
              }}
            >
              {parseInt(value)}
            </span>
          </div>
        </div>
        <div
          className="second-div"
          role="slider"
          aria-valuemin={min}
          aria-valuemax={max}
          aria-valuenow={value}
          style={customHandleStyle}
        ></div>
      </Fragment>
    );
  }
}

Handle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
};

// *******************************************************
// KEYBOARD HANDLE COMPONENT
// Uses button to allow keyboard events
// *******************************************************
export function KeyboardHandle({
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
}) {
  return (
    <button
      role="slider"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      style={{
        top: `${percent}%`,
        position: "absolute",
        transform: "translate(-50%, -50%)",
        width: 24,
        height: 24,
        zIndex: 5,
        cursor: "pointer",
        border: 0,
        borderRadius: "50%",
        boxShadow: "1px 1px 1px 1px rgba(0, 0, 0, 0.3)",
        backgroundColor: "#ffc400",
      }}
      {...getHandleProps(id)}
    />
  );
}

KeyboardHandle.propTypes = {
  domain: PropTypes.array.isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
};

// *******************************************************
// TRACK COMPONENT
// *******************************************************
export function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        zIndex: 1,
        backgroundColor: "#20bc2a",
        borderRadius: 7,
        cursor: "pointer",
        width: 23,
        transform: "translate(-50%, 0%)",
        top: `${source.percent}%`,
        height: `${target.percent - source.percent}%`,
      }}
      {...getTrackProps()}
    />
  );
}

Track.propTypes = {
  source: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  target: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
};

// *******************************************************
// TICK COMPONENT
// *******************************************************
export function Tick({ tick, format }) {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          marginTop: -0.5,
          marginLeft: 10,
          height: 1,
          width: 6,
          backgroundColor: "rgb(200,200,200)",
          top: `${tick.percent}%`,
        }}
      />
      <div
        style={{
          position: "absolute",
          marginTop: -5,
          marginLeft: 20,
          fontSize: 10,
          top: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired,
  }).isRequired,
  format: PropTypes.func.isRequired,
};

Tick.defaultProps = {
  format: (d) => d,
};
