import React from "react";
import "./App.css";
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap";
import Avatar from "@material-ui/core/Avatar";
import Badge from "@material-ui/core/Badge";
// import { FormatListBulleted } from "@material-ui/icons";
class CanvasDraw extends React.PureComponent {
  constructor(props) {
    super(props);
    this.xCoordinate = 0;
    this.ventiltr = {
      volume: 0,
      inspFlow: 0,
      manualInspTrig: 0,
      inspTrigUser: 0,
      inspPressure: 0,
    };
    this.defaultGraph = {
      currPressVal: 1,
      dist: 1,
      xCoordinate: 10,
      prevPressVal: 0,
      speed: 1,
      currFlowVal: 0,
      prevFlowVal: 0,
      prevVolVal: 0,
      currVolVal: 0,
    };
    this.canvas = React.createRef();
    this.MeterGraph = React.createRef();
    this.xAxisCanvas = React.createRef();
    this.MeterGraph1 = React.createRef();
    this.canvasLayer0 = React.createRef();
    this.MeterGraphLayer0 = React.createRef();
    window.mqtt.subscribe(`ION/${this.props.deviceId}/CURVEMONITORING`);
    this.default = true;
    this.oneTimeZoom = false;
    this.twoTimeZoom = false;
    this.renderTicks = false;
    this.renderGraph = false;
    this.zoomLevel = 0;
    this.state = {
      dist: 1,
      xAxisValue: 32
    }
  }
  componentWillReceiveProps(comingProps) {
    if (this.state.xAxisValue !== comingProps.xAxisValue) {
      this.setState({
        xAxisValue: comingProps.xAxisValue,
        // dist: comingProps.xAxisValue == 32 ? 1 : 2
      }, () => {
        this.drawXAxis(comingProps.xAxisValue == 32 ? 1 : 2)
      })
    }
  }

  sailsCurveMonitoringHandler = (json) => {
    // console.log('Testing code extecuted IN JSON',json);
    if (
      json &&
      "IP" in json &&
      "FL" in json &&
      "VL" in json &&
      "ITF" in json &&
      "ITR" in json
    ) {
      this.ventiltr["inspFlow"] = Number(json.FL);
      this.ventiltr["inspPressure"] = Number(json.IP);
      this.ventiltr["volume"] = Number(json.VL);
      this.ventiltr["manualInspTrig"] = json.ITF === "1";
      this.ventiltr["inspTrigUser"] = Number(json.ITR);
      // console.log('Testing code extecuted IN JSON ITF:', json.ITF, " ITR:", json.ITR);

      if (
        this.MeterGraph &&
        this.MeterGraph.current &&
        this.defaultGraph.xCoordinate == 10
      ) {
        var line = this.MeterGraph.current.getContext("2d");
        line.clearRect(
          0,
          0,
          this.MeterGraph.current.width,
          this.MeterGraph.current.height
        );
        this.drawLabel(this.MeterGraph.current);
      }
      if (this.MeterGraph && this.MeterGraph.current && this.renderTicks) {
        // console.log("Im clearing Main Canavas Ticks")
        this.renderTicks = false;
        var line = this.MeterGraph.current.getContext("2d");
        line.clearRect(
          0,
          0,
          this.MeterGraph.current.width,
          this.MeterGraph.current.height
        );
        this.drawLabel(this.MeterGraph.current);
      }
      if (this.ventiltr && this.canvas.current && this.renderGraph) {
        this.renderGraph = false;
        var line = this.canvas.current.getContext("2d");
        line.clearRect(
          0,
          0,
          this.canvas.current.width,
          this.canvas.current.height
        );
        this.draw(this.canvas.current);
      }
      if (this.ventiltr && this.canvas.current && !this.renderGraph) {
        this.draw(this.canvas.current);
      }
    }
    // if (Math.floor(this.defaultGraph.xCoordinate) == 11 && this.canvasLayer0 && this.canvasLayer0.current) {
    //   this.drawGrids(this.canvasLayer0.current)
    // }
  };
  drawGrids = () => {
    if (this.canvasLayer0 && this.canvasLayer0.current) {
      let canvas = this.canvasLayer0.current;
      if (canvas != null) {
        var line = canvas.getContext("2d");
      } else {
        console.log("Canvas not found");
        return;
      }
      let equalSpace = ((canvas.width - 13) / 16);
      line.lineWidth = 0.4;
      line.strokeStyle = "#ffffff";
      // for (let i = 0; i <= 8; i++) {
      //   let currentXpoint = equalSpace * i
      //   line.clearRect(currentXpoint + 9, 0, 2, canvas.height);
      // }
      // console.log("Canvas im doing drawGrids", canvas.width);
      line.beginPath();
      for (let i = 0; i <= 16; i++) {
        let currentXpoint = equalSpace * i
        line.setLineDash([5, 3])
        line.moveTo(currentXpoint + 10, 0); //+10 because there is 10 gap we have to leave at the begining
        line.lineTo(currentXpoint + 10, canvas.height);
      }
      line.closePath()
      line.stroke();
      line.setLineDash([])
    }
  }
  drawLabel = (canvas) => {
    if (canvas != null) {
      var line = canvas.getContext("2d");
    } else {
      console.log("Canvas not found");
      return;
    }
    var line = canvas.getContext("2d");
    line.lineWidth = "2";
    line.strokeStyle = "#ffffffa6";
    line.beginPath();
    line.moveTo(4, 0);
    line.lineTo(4, canvas.height);
    line.stroke();

    // pressure cm labels
    // 1st group bars
    line.lineWidth = "1.5";
    line.strokeStyle = "#80ff00cf";
    line.beginPath();
    line.moveTo(0, 1);
    line.lineTo(13, 1);
    line.stroke();
    line.font = "12px Arial";
    line.fillStyle = "#ffffffa6";
    if (this.default) {
      this.defaultZoomGraph(canvas);
    } else if (this.oneTimeZoom) {
      this.oneTimeZoomGraph(canvas);
    } else if (this.twoTimeZoom) {
      this.twoTimeZoomGraph(canvas);
    }

    // flow lpm  labels

    // volume lm  labels
  };

  defaultZoomGraph(canvas) {
    var line = canvas.getContext("2d");
    let equalSpace = canvas.height / 5.8;

    let pressZeroPos = equalSpace; //Zero'th position of Pressure
    let preesureGap = equalSpace / 4;

    let flowZeroPos = canvas.height / 2; //Zero'th position of Flow
    let flowGap = (flowZeroPos - pressZeroPos) / 5;

    let volumeZeroPos = canvas.height - 1; //Zero'th position of Volume
    let volumeGap = equalSpace / 4;
    line.fillText("60", 14, 9);
    line.strokeStyle = "gray"; // green

    line.beginPath();
    line.moveTo(5, preesureGap);
    line.lineTo(13, preesureGap);
    line.stroke();

    line.beginPath();
    line.moveTo(5, preesureGap * 2);
    line.lineTo(13, preesureGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, preesureGap * 3);
    line.lineTo(13, preesureGap * 3);
    line.stroke();
    line.fillText("15", 14, preesureGap * 3 + 4);
    line.strokeStyle = "#80ff00cf";
    line.beginPath();
    line.moveTo(0, pressZeroPos);
    line.lineTo(13, pressZeroPos);
    line.stroke();
    line.fillText("0", 14, pressZeroPos + 4);
    //---------------------------------------------------------------------------
    line.strokeStyle = "#ffff00cf"; // yellow
    line.beginPath();
    line.moveTo(0, flowZeroPos - flowGap * 4);
    line.lineTo(13, flowZeroPos - flowGap * 4);
    line.stroke();
    line.fillText("160", 14, flowZeroPos - flowGap * 4 + 4);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap * 3);
    line.lineTo(13, flowZeroPos - flowGap * 3);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap * 2);
    line.lineTo(13, flowZeroPos - flowGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap);
    line.lineTo(13, flowZeroPos - flowGap);
    line.stroke();
    line.fillText("40", 14, flowZeroPos - flowGap + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos);
    line.lineTo(13, flowZeroPos);
    line.stroke();
    line.fillText("0", 14, flowZeroPos + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap);
    line.lineTo(13, flowZeroPos + flowGap);
    line.stroke();
    line.fillText("-40", 14, flowZeroPos + flowGap + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap * 2);
    line.lineTo(13, flowZeroPos + flowGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap * 3);
    line.lineTo(13, flowZeroPos + flowGap * 3);
    line.stroke();
    line.strokeStyle = "#ffff00cf"; // yellow
    line.beginPath();
    line.moveTo(0, flowZeroPos + flowGap * 4);
    line.lineTo(13, flowZeroPos + flowGap * 4);
    line.stroke();
    line.fillText("-160", 14, flowZeroPos + flowGap * 4 + 4);

    //--------------------------------------------------------------------------
    line.strokeStyle = "#ff0000cf"; // red
    line.beginPath();
    line.moveTo(0, volumeZeroPos - volumeGap * 4);
    line.lineTo(13, volumeZeroPos - volumeGap * 4);
    line.stroke();
    line.fillText("2000", 14, volumeZeroPos - volumeGap * 4 + 4);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap * 3);
    line.lineTo(13, volumeZeroPos - volumeGap * 3);
    line.stroke();

    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap * 2);
    line.lineTo(13, volumeZeroPos - volumeGap * 2);
    line.stroke();
    line.fillText("1000", 14, volumeZeroPos - volumeGap * 2 + 4);

    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap);
    line.lineTo(13, volumeZeroPos - volumeGap);
    line.stroke();
    line.strokeStyle = "#ff0000cf"; // red
    line.beginPath();
    line.moveTo(0, volumeZeroPos);
    line.lineTo(13, volumeZeroPos);
    line.stroke();
    line.fillText("0", 14, volumeZeroPos + 1);
  }
  oneTimeZoomGraph(canvas) {
    var line = canvas.getContext("2d");
    let equalSpace = canvas.height / 5.8;

    let pressZeroPos = equalSpace; //Zero'th position of Pressure
    let preesureGap = equalSpace / 4;

    let flowZeroPos = canvas.height / 2; //Zero'th position of Flow
    let flowGap = (flowZeroPos - pressZeroPos) / 5;

    let volumeZeroPos = canvas.height - 1; //Zero'th position of Volume
    let volumeGap = equalSpace / 4;
    line.fillText("40", 14, 9);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, preesureGap);
    line.lineTo(13, preesureGap);
    line.stroke();

    line.beginPath();
    line.moveTo(5, preesureGap * 2);
    line.lineTo(13, preesureGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, preesureGap * 3);
    line.lineTo(13, preesureGap * 3);
    line.stroke();
    line.fillText("10", 14, preesureGap * 3 + 4);

    line.beginPath();
    line.strokeStyle = "#80ff00cf"; //green

    line.moveTo(0, pressZeroPos);
    line.lineTo(13, pressZeroPos);
    line.stroke();
    line.fillText("0", 14, pressZeroPos + 4);

    //-------------------------------------------------------------------------
    line.strokeStyle = "#ffff00cf"; // yellow
    line.beginPath();
    line.moveTo(0, flowZeroPos - flowGap * 4);
    line.lineTo(13, flowZeroPos - flowGap * 4);
    line.stroke();
    line.fillText("120", 14, flowZeroPos - flowGap * 4 + 4);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap * 3);
    line.lineTo(13, flowZeroPos - flowGap * 3);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap * 2);
    line.lineTo(13, flowZeroPos - flowGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap);
    line.lineTo(13, flowZeroPos - flowGap);
    line.stroke();
    line.fillText("30", 14, flowZeroPos - flowGap + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos);
    line.lineTo(13, flowZeroPos);
    line.stroke();
    line.fillText("0", 14, flowZeroPos + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap);
    line.lineTo(13, flowZeroPos + flowGap);
    line.stroke();
    line.fillText("-30", 14, flowZeroPos + flowGap + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap * 2);
    line.lineTo(13, flowZeroPos + flowGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap * 3);
    line.lineTo(13, flowZeroPos + flowGap * 3);
    line.stroke();
    line.strokeStyle = "#ffff00cf"; // yellow
    line.beginPath();
    line.moveTo(0, flowZeroPos + flowGap * 4);
    line.lineTo(13, flowZeroPos + flowGap * 4);
    line.stroke();
    line.fillText("-120", 14, flowZeroPos + flowGap * 4 + 4);

    //--------------------------------------------------------------------------
    line.strokeStyle = "#ff0000cf"; // red
    line.beginPath();
    line.moveTo(0, volumeZeroPos - volumeGap * 4);
    line.lineTo(13, volumeZeroPos - volumeGap * 4);
    line.stroke();
    line.fillText("1000", 14, volumeZeroPos - volumeGap * 4 + 4);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap * 3);
    line.lineTo(13, volumeZeroPos - volumeGap * 3);
    line.stroke();

    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap * 2);
    line.lineTo(13, volumeZeroPos - volumeGap * 2);
    line.stroke();
    line.fillText("500", 14, volumeZeroPos - volumeGap * 2 + 4);

    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap);
    line.lineTo(13, volumeZeroPos - volumeGap);
    line.stroke();
    line.strokeStyle = "#ff0000cf"; // red
    line.beginPath();
    line.moveTo(0, volumeZeroPos);
    line.lineTo(13, volumeZeroPos);
    line.stroke();
    line.fillText("0", 14, volumeZeroPos + 1);
  }
  twoTimeZoomGraph(canvas) {
    var line = canvas.getContext("2d");
    let equalSpace = canvas.height / 5.8;

    let pressZeroPos = equalSpace; //Zero'th position of Pressure
    let preesureGap = equalSpace / 4;

    let flowZeroPos = canvas.height / 2; //Zero'th position of Flow
    let flowGap = (flowZeroPos - pressZeroPos) / 5;

    let volumeZeroPos = canvas.height - 1; //Zero'th position of Volume
    let volumeGap = equalSpace / 4;
    line.fillText("20", 10, 9);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, preesureGap);
    line.lineTo(13, preesureGap);
    line.stroke();

    line.beginPath();
    line.moveTo(5, preesureGap * 2);
    line.lineTo(13, preesureGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, preesureGap * 3);
    line.lineTo(13, preesureGap * 3);
    line.stroke();
    line.fillText("5", 14, preesureGap * 3 + 4);
    line.strokeStyle = "#80ff00cf";

    line.beginPath();
    line.moveTo(0, pressZeroPos);
    line.lineTo(13, pressZeroPos);
    line.stroke();
    line.fillText("0", 13, pressZeroPos + 4);

    //-------------------------------------------------------------------------
    line.strokeStyle = "#ffff00cf"; // yellow
    line.beginPath();
    line.moveTo(0, flowZeroPos - flowGap * 4);
    line.lineTo(13, flowZeroPos - flowGap * 4);
    line.stroke();
    line.fillText("80", 14, flowZeroPos - flowGap * 4 + 4);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap * 3);
    line.lineTo(13, flowZeroPos - flowGap * 3);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap * 2);
    line.lineTo(13, flowZeroPos - flowGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos - flowGap);
    line.lineTo(13, flowZeroPos - flowGap);
    line.stroke();
    line.fillText("20", 14, flowZeroPos - flowGap + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos);
    line.lineTo(13, flowZeroPos);
    line.stroke();
    line.fillText("0", 14, flowZeroPos + 4);
    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap);
    line.lineTo(13, flowZeroPos + flowGap);
    line.stroke();
    line.fillText("-20", 14, flowZeroPos + flowGap + 4);

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap * 2);
    line.lineTo(13, flowZeroPos + flowGap * 2);
    line.stroke();

    line.beginPath();
    line.moveTo(5, flowZeroPos + flowGap * 3);
    line.lineTo(13, flowZeroPos + flowGap * 3);
    line.stroke();
    line.strokeStyle = "#ffff00cf"; // yellow
    line.beginPath();
    line.moveTo(0, flowZeroPos + flowGap * 4);
    line.lineTo(13, flowZeroPos + flowGap * 4);
    line.stroke();
    line.fillText("-80", 14, flowZeroPos + flowGap * 4 + 4);

    //--------------------------------------------------------------------------
    line.strokeStyle = "#ff0000cf"; // red
    line.beginPath();
    line.moveTo(0, volumeZeroPos - volumeGap * 4);
    line.lineTo(13, volumeZeroPos - volumeGap * 4);
    line.stroke();
    line.fillText("500", 14, volumeZeroPos - volumeGap * 4 + 4);
    line.strokeStyle = "gray";
    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap * 3);
    line.lineTo(13, volumeZeroPos - volumeGap * 3);
    line.stroke();

    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap * 2);
    line.lineTo(13, volumeZeroPos - volumeGap * 2);
    line.stroke();
    line.fillText("250", 14, volumeZeroPos - volumeGap * 2 + 4);

    line.beginPath();
    line.moveTo(5, volumeZeroPos - volumeGap);
    line.lineTo(13, volumeZeroPos - volumeGap);
    line.stroke();
    line.strokeStyle = "#ff0000cf"; // red
    line.beginPath();
    line.moveTo(0, volumeZeroPos);
    line.lineTo(13, volumeZeroPos);
    line.stroke();
    line.fillText("0", 14, volumeZeroPos + 1);
  }
  draw = (canvas) => {
    if (canvas != null) {
      var line = canvas.getContext("2d");
    } else {
      console.log("Canvas not found");
      return;
    }
    var line = canvas.getContext("2d");
    var dataPointA, dataPointB;
    this.defaultGraph.xCoordinate += this.state.dist;
    let equalSpace = canvas.height / 5.8;
    if (this.defaultGraph.xCoordinate >= canvas.width) {
      this.defaultGraph.xCoordinate = this.state.dist + 10;
      line.clearRect(9, 0, 10 / this.defaultGraph.speed, canvas.height); //to clear small dots that appear at the start of graphs after one cycle
    }

    //Horizonal thin white lines
    line.lineWidth = "0.15";
    line.strokeStyle = "#404040";

    line.beginPath();
    line.moveTo(10, equalSpace);
    line.lineTo(canvas.width, equalSpace);
    line.stroke();
    line.lineWidth = "0.03";
    line.beginPath();
    line.moveTo(10, canvas.height / 2);
    line.lineTo(canvas.width, canvas.height / 2);
    line.stroke();

    line.lineWidth = "0.15";
    line.beginPath();
    line.moveTo(10, canvas.height);
    line.lineTo(canvas.width, canvas.height);
    line.stroke();

    //Pressure Curve

    this.defaultGraph.prevPressVal = this.defaultGraph.currPressVal;
    this.defaultGraph.currPressVal = this.ventiltr.inspPressure;

    if (this.default) {
      if (this.defaultGraph.prevPressVal >= 60) {
        dataPointA = equalSpace;
      } else {
        dataPointA = this.defaultGraph.prevPressVal * (equalSpace / 60);
      }

      if (this.defaultGraph.currPressVal >= 60) {
        dataPointB = equalSpace;
      } else {
        dataPointB = this.defaultGraph.currPressVal * (equalSpace / 60);
      }
    } else if (this.oneTimeZoom) {
      if (this.defaultGraph.prevPressVal >= 40) {
        dataPointA = equalSpace;
      } else {
        dataPointA = this.defaultGraph.prevPressVal * (equalSpace / 40);
      }

      if (this.defaultGraph.currPressVal >= 40) {
        dataPointB = equalSpace;
      } else {
        dataPointB = this.defaultGraph.currPressVal * (equalSpace / 40);
      }
    } else if (this.twoTimeZoom) {
      if (this.defaultGraph.prevPressVal >= 20) {
        dataPointA = equalSpace;
      } else {
        dataPointA = this.defaultGraph.prevPressVal * (equalSpace / 20);
      }

      if (this.defaultGraph.currPressVal >= 20) {
        dataPointB = equalSpace;
      } else {
        dataPointB = this.defaultGraph.currPressVal * (equalSpace / 20);
      }
    }

    if (
      this.ventiltr.inspTrigUser > 0 &&
      this.ventiltr.manualInspTrig === true
    ) {
      // console.log("Im eneterd Red Pressure line code");
      line.strokeStyle = "red";
      line.lineWidth = "1";
      line.beginPath();
      if (this.default) {
        line.moveTo(
          this.defaultGraph.xCoordinate,
          -(60 * (equalSpace / 60)) + equalSpace
        );
        line.lineTo(
          this.defaultGraph.xCoordinate,
          -(15 * (equalSpace / 60)) + equalSpace
        );
      } else if (this.oneTimeZoom) {
        line.moveTo(
          this.defaultGraph.xCoordinate,
          -(40 * (equalSpace / 40)) + equalSpace
        );
        line.lineTo(
          this.defaultGraph.xCoordinate,
          -(10 * (equalSpace / 40)) + equalSpace
        );
      } else if (this.twoTimeZoom) {
        line.moveTo(
          this.defaultGraph.xCoordinate,
          -(20 * (equalSpace / 20)) + equalSpace
        );
        line.lineTo(
          this.defaultGraph.xCoordinate,
          -(5 * (equalSpace / 20)) + equalSpace
        );
      }
      line.stroke();
    }

    line.lineWidth = "2";
    line.strokeStyle = "#80FF00";
    line.beginPath();
    line.moveTo(
      this.defaultGraph.xCoordinate - this.state.dist,
      -dataPointA + equalSpace
    );
    line.lineTo(this.defaultGraph.xCoordinate, -dataPointB + equalSpace);
    line.stroke();
    // line.clearRect(
    //   this.defaultGraph.xCoordinate + this.state.dist,
    //   0,
    //   10 / this.defaultGraph.speed,
    //   canvas.height
    // );

    //Flow Curve
    line.strokeStyle = "#FFFF00";
    this.defaultGraph.prevFlowVal = this.defaultGraph.currFlowVal;
    this.defaultGraph.currFlowVal = this.ventiltr.inspFlow;

    // Conditions for Positive flow values starts

    let flowInBetweenTicks = (canvas.height / 2 - equalSpace) / 200; //(canvas.height / 2) - equalSpace) ==> to get space between pressure and flow's zeroth positions
    let flowHighest = 190;
    if (this.default) {
      flowInBetweenTicks = (canvas.height / 2 - equalSpace) / 200; //(canvas.height / 2) - equalSpace) ==> to get space between pressure and flow's zeroth positions
      flowHighest = 190;
    } else if (this.oneTimeZoom) {
      flowInBetweenTicks = (canvas.height / 2 - equalSpace) / 150;
      flowHighest = 120;
    } else if (this.twoTimeZoom) {
      flowInBetweenTicks = (canvas.height / 2 - equalSpace) / 100;
      flowHighest = 80;
    }

    if (this.defaultGraph.prevFlowVal >= flowHighest) {
      dataPointA = flowHighest * flowInBetweenTicks;
    } else if (this.defaultGraph.prevFlowVal >= 0) {
      dataPointA = this.defaultGraph.prevFlowVal * flowInBetweenTicks;
    }

    if (this.defaultGraph.currFlowVal >= flowHighest) {
      dataPointB = flowHighest * flowInBetweenTicks;
    } else if (this.defaultGraph.currFlowVal >= 0) {
      dataPointB = this.defaultGraph.currFlowVal * flowInBetweenTicks;
    }
    // Conditions for Positive flow values ends

    //Conditions for negative flow values starts
    if (this.defaultGraph.prevFlowVal <= -flowHighest) {
      dataPointA = -flowHighest * flowInBetweenTicks;
    } else if (this.defaultGraph.prevFlowVal < 0) {
      dataPointA = this.defaultGraph.prevFlowVal * flowInBetweenTicks;
    }
    if (this.defaultGraph.currFlowVal <= -flowHighest) {
      dataPointB = -flowHighest * flowInBetweenTicks;
    } else if (this.defaultGraph.currFlowVal < 0) {
      dataPointB = this.defaultGraph.currFlowVal * flowInBetweenTicks;
    }
    //Conditions for negative flow values ends

    line.beginPath();
    line.moveTo(
      this.defaultGraph.xCoordinate - this.state.dist,
      -dataPointA + canvas.height / 2
    );
    line.lineTo(this.defaultGraph.xCoordinate, -dataPointB + canvas.height / 2);
    line.stroke();
    // line.clearRect(
    //   this.defaultGraph.xCoordinate + this.state.dist,
    //   0,
    //   10 / this.defaultGraph.speed,
    //   canvas.height
    // );

    //Volume Curve
    line.strokeStyle = "#FF0000";
    this.defaultGraph.prevVolVal = this.defaultGraph.currVolVal;
    this.defaultGraph.currVolVal = this.ventiltr.volume / 20;

    if (this.default) {
      if (this.defaultGraph.prevVolVal >= 100) {
        dataPointA = equalSpace;
      } else {
        dataPointA = this.defaultGraph.prevVolVal * (equalSpace / 100);
      }

      if (this.defaultGraph.currVolVal >= 100) {
        dataPointB = equalSpace;
      } else {
        dataPointB = this.defaultGraph.currVolVal * (equalSpace / 100);
      }
    } else if (this.oneTimeZoom) {
      if (this.defaultGraph.prevVolVal >= 50) {
        dataPointA = equalSpace;
      } else {
        dataPointA = this.defaultGraph.prevVolVal * (equalSpace / 50);
      }

      if (this.defaultGraph.currVolVal >= 50) {
        dataPointB = equalSpace;
      } else {
        dataPointB = this.defaultGraph.currVolVal * (equalSpace / 50);
      }
    } else if (this.twoTimeZoom) {
      if (this.defaultGraph.prevVolVal >= 25) {
        dataPointA = equalSpace;
      } else {
        dataPointA = this.defaultGraph.prevVolVal * (equalSpace / 25);
      }

      if (this.defaultGraph.currVolVal >= 25) {
        dataPointB = equalSpace;
      } else {
        dataPointB = this.defaultGraph.currVolVal * (equalSpace / 25);
      }
    }

    line.beginPath();
    line.moveTo(
      this.defaultGraph.xCoordinate - this.state.dist,
      -dataPointA + canvas.height - 1
    );
    line.lineTo(this.defaultGraph.xCoordinate, -dataPointB + canvas.height - 1);
    line.stroke();
    line.clearRect(
      this.defaultGraph.xCoordinate + this.state.dist,
      0,
      10 / this.defaultGraph.speed,
      canvas.height
    );
    // if (!(Math.floor((Math.floor(this.defaultGraph.xCoordinate + this.state.dist) - 15) % 150/* (Math.floor((canvas.width - 5) / 16)) */))) {
    // this.clearGrid(this.canvas.current);
    // this.drawGrids(this.canvas.current);
    // }
  };

  resized = () => {
    if (this.canvas) {
      this.canvas.current.width = this.canvas.current.clientWidth;
      this.canvas.current.height = this.canvas.current.clientHeight;
      if (this.canvasLayer0) {
        this.canvasLayer0.current.width = this.canvas.current.clientWidth;
        this.canvasLayer0.current.height = this.canvas.current.clientHeight;
      }
    }
  };
  meterresized = () => {
    if (this.MeterGraph) {
      this.MeterGraph.current.width = this.MeterGraph.current.clientWidth;
      this.MeterGraph.current.height = this.MeterGraph.current.clientHeight;
      if (this.MeterGraphLayer0) {
        this.MeterGraphLayer0.current.width = this.MeterGraph.current.clientWidth;
        this.MeterGraphLayer0.current.height = this.MeterGraph.current.clientHeight;
      }
    }
  };

  defaultClick() {
    this.defaultGraph.xCoordinate = 10;
    this.renderGraph = true;
    this.renderTicks = true;
    this.default = true;
    this.oneTimeZoom = false;
    this.twoTimeZoom = false;
  }
  oneTimeClick() {
    this.defaultGraph.xCoordinate = 10;
    this.renderGraph = true;
    this.renderTicks = true;
    this.default = false;
    this.oneTimeZoom = true;
    this.twoTimeZoom = false;
  }
  twoTimeClick() {
    this.defaultGraph.xCoordinate = 10;
    this.renderGraph = true;
    this.renderTicks = true;
    this.default = false;
    this.oneTimeZoom = false;
    this.twoTimeZoom = true;
  }

  zoomOnClick = () => {
    if (this.default) {
      this.oneTimeClick();
      this.zoomLevel = 1;
    } else if (this.oneTimeZoom) {
      this.twoTimeClick();
      this.zoomLevel = 2;
    } else if (this.twoTimeZoom) {
      this.defaultClick();
      this.zoomLevel = 0;
    }
    return this.zoomLevel;
  };

  render() {
    // console.log("[CanvasRender]-xAxisValue", this.state.xAxisValue)
    return (
      <div className="row" style={{ width: "100%", height: "100%" }}>
        <div
          className="col-1 CanvasYaxis"
          style={{ padding: "0px", height: "90%" }}
        >
          <div style={{ height: "100%", marginLeft: "30px" }}>
            <div className="rotateObj">
              <h2 className="active-pressure">
                <span>Pressure (cmH2O)</span>
              </h2>
            </div>
            <div className="rotateObj">
              <h2 className="active-flow">
                <span>Flow (LPM)</span>
              </h2>
            </div>
            <div className="rotateObj">
              <h2 className="active-volume">
                <span> Volume (mL)</span>
              </h2>
            </div>
          </div>
        </div>
        <div
          id="canvasContainer"
          className="col-11"
          style={{ width: "100%", height: "100%", paddingRight: "0px", position: 'relative' }}
        >
          <div className="ZoomCanvasIconPosition">
            <ZoomCanvas
              zoomLevel={this.zoomLevel}
              zoomOnClick={this.zoomOnClick}
            />
          </div>
          <canvas
            id={this.props.deviceId + "meter"}
            ref={this.MeterGraph}
            className="meterGraph"
          />
          <canvas
            id={this.props.deviceId}
            ref={this.canvas}
            className="Graph"
          />
          <div style={{
            position: 'absolute', left: '0px', top: '0px',
            paddingLeft: '15px', height: '100%', width: '100%'
          }}>                                                    {/* we used this to draw verticle grid lines on the background */}
            <canvas id={this.props.deviceId + "meterLayer0"}
              ref={this.MeterGraphLayer0}
              className="meterGraph"
            />
            <canvas
              id={this.props.deviceId + 'Layer0'}
              ref={this.canvasLayer0}
              className="Graph"
            />
          </div>
          <div style={{ height: "100%", width: "100%" }}>
            <canvas
              id={this.props.deviceId + "meter1"}
              ref={this.MeterGraph1}
              className="meterGraph"
              style={{
                // width: "10%",
                height: "40px"
              }}
            />
            <canvas
              id={this.props.deviceId + "xaxis"}
              ref={this.xAxisCanvas}
              // className="xAxisGraph"
              className="Graph"
              style={{
                // width: "90%",
                height: "40px"
              }}
            />
          </div>
        </div>
        <div className="watchlistcurve time-text">
            Time(sec)
          </div>
      </div>
    );
  }
  drawXAxis = (xAxisVal = 1) => {
    if (
      this.xAxisCanvas &&
      this.xAxisCanvas.current) {
      let canvas = this.xAxisCanvas.current;
      if (canvas != null) {
        var line = canvas.getContext("2d");
      } else {
        console.log("Canvas not found");
        return;
      }
      this.defaultGraph.xCoordinate = 10
      if (this.canvas && this.canvas.current) {
        var line1 = this.canvas.current.getContext("2d");
        line1.clearRect(0, 0, this.canvas.current.width, this.canvas.current.height)
      };
      this.xAxisCanvas.current.width = this.xAxisCanvas.current.clientWidth;
      this.xAxisCanvas.current.height = this.xAxisCanvas.current.clientHeight;

      let _dist = (this.xAxisCanvas.current.clientWidth / 1050) * xAxisVal;
      this.setState({ dist: _dist })
      // console.log("im in drawXAxis", canvas.width)
      var line = canvas.getContext("2d");
      line.clearRect(0, 0, canvas.width, canvas.height);
      line.lineWidth = "1";
      line.strokeStyle = "#ffffffbf";
      line.beginPath();
      line.moveTo(10, 5);
      line.lineTo(canvas.width, 5);
      line.stroke();

      // line.beginPath();
      line.lineWidth = 0.8;
      let filtextVal = this.state.xAxisValue == 32 ? 2 : 1;
      let equalSpace = ((canvas.width - 10) / 16);

      line.font = "12px Arial";
      line.fillStyle = "white";
      for (let i = 0; i <= 16; i++) {
        let currentXpoint = equalSpace * i
        line.moveTo(currentXpoint + 10, 5); //+10 because there is 10 gap we have to leave at the begining
        line.lineTo(currentXpoint + 10, 15);
        let xpoint = (currentXpoint) - 8
        if (i == 16) {
          xpoint = (currentXpoint) - 15;  // to prevent hiding of last text(16/32)
        }
        if (i == 0) {
          xpoint = (currentXpoint)
        }
        if (i != 0 && (i * filtextVal) < 10) {
          xpoint = (currentXpoint) - 4
        }
        line.fillText((i * filtextVal), xpoint + 10, 28);
      }
      line.stroke();
    }
  }
  componentDidMount() {
    this.drawXAxis()
    this.resized();
    this.meterresized();
    this.drawGrids();
    window.mqtt.on("message", async (topic, message) => {
      if (topic === `ION/${this.props.deviceId}/CURVEMONITORING`) {
        this.sailsCurveMonitoringHandler(JSON.parse(message.toString()));
      }
    });
    // this.interval = setInterval(() => {
    //     this.testCode();
    // }, 100);
  }
  async componentWillUnmount() {
    await window.mqtt.unsubscribe(`ION/${this.props.deviceId}/CURVEMONITORING`);
    // this.clearTestCode();
  }
  testCode = () => {
    let ventiltr = {
      inspPressure: function () {
        let data = [
          60,
          0,
          0,
          120,
          0,
          55,
          55,
          15,
          30,
          45,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ];
        let index = Math.floor(Math.random() * 18);
        // console.log("inspPressure", data[index]);
        return data[index];
        // return 0;
      },
      inspFlow: function () {
        let data = [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          40,
          80,
          120,
          160,
          -40,
          -80,
          -120,
          -160,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ];
        let index = Math.floor(Math.random() * 22);
        return data[index];
      },
      volume: function () {
        let data = [
          2000,
          1000,
          1000,
          1500,
          500,
          40,
          125,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ];
        let index = Math.floor(Math.random() * 16);
        // console.log("volume:", data[index]);
        // return 0;
        return data[index];
      },
      manualInspTrig: function () {
        let data = [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
        ];
        let index = Math.floor(Math.random() * 33);
        if (data[index] === 1) {
          return "1";
        } else return "0";
      },
      inspTrigUser: () => {
        return "1";
      },
    };
    this.sailsCurveMonitoringHandler({
      IP: ventiltr.inspPressure(),
      FL: ventiltr.inspFlow(),
      VL: ventiltr.volume(),
      ITF: ventiltr.manualInspTrig(),
      ITR: ventiltr.inspTrigUser(),
    });
  };
  clearTestCode = () => {
    clearInterval(this.interval);
  };
}

export default CanvasDraw;

function ZoomCanvas({ zoomLevel, zoomOnClick }) {
  const [level, setLevel] = React.useState(zoomLevel);
  // const callBackZoomLevel=
  return (
    <Badge
      style={{ marginRight: "14px", color: "#ffffffb6", zIndex: "999" }}
      badgeContent={level}
      color="default"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Avatar variant="rounded" style={{ background: "transparent" }}>
        <ZoomOutMapIcon
          style={{ color: "#ffffffb6", fontSize: "28px", cursor: "pointer" }}
          onClick={() => {
            setLevel(zoomOnClick());
          }}
        />
      </Avatar>
    </Badge>
  );
}
