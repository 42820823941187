import React, { useEffect, useState, Component, PureComponent } from "react";
//import UserDetails from "../../Screens/user_details.json";
// import { HashRouter, Route, Redirect } from "react-router-dom";
// import CompanyDetails from "../companypage/index";
import {
  TOTAL_COUNT,
  COMPANYLIST,
  PGET,
  COMPANY_DEL,
  PLANT_DEL,
  USER_DEL,
  DEVICE_DEL,
} from "../../../api-route";
// import VentDetails from "./vent-details.json";
// import UserDetails from "./user_details.json";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import PropTypes from "prop-types";
import VentDetailsDialog from "../../Screens/dialog/VentDetailsDialog";
import PlantDetailsDialog from "../../Screens/dialog/PlantDetailsDialog";
import UserDetailsDialog from "../../Screens/dialog/UserDetailsDialog";
import AddNewVent from "../../Screens/dialog/AddNewVent";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import AddNewPlant from "../../Screens/dialog/AddNewPlant";
import AddNewUser from "../../Screens/dialog/AddNewUser";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddNewCompany from "../../Screens/dialog/AddNewCompany";
import CompanyDetailsDialog from "../../Screens/dialog/CompanyDetailsDialog";
import {
  fetchplant,
  fetchuser,
  fetchcompany,
  fetchmachine,
  fetchplantByCid
} from "../../../services/http";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PowerButton from "@material-ui/icons/PowerSettingsNew";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import UserIcon from "@material-ui/icons/AccountCircle";
import HomeIcon from "@material-ui/icons/Home";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import PropTypes, { any } from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import SessionExpiryDialog from "../../Screens/dialog/sessionExpiryDialog";
import BranchDeleteDialog from "../../Screens/dialog/BranchDeleteDialog";
import CompanyDeleteDialog from "../../Screens/dialog/CompanyDeleteDialog";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';
import clsx from "clsx";

import Drawer from "@material-ui/core/Drawer";
// import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Warning from "../../Screens/Images/Warning.svg";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
// import PowerButton from "@material-ui/icons/PowerSettingsNew";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import newLogo from "../../../img/abm-new-logo.png";

function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />;
}
const drawerWidth = 240;
const styles = theme => ({

  root: {
    display: "flex",
  },
  show: {
    dispaly: "block",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    // marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    zIndex: "1300",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    // width: theme.spacing(7) + 1,
    // [theme.breakpoints.up("sm")]: {
    // width: doNotThisComponentIfHardwareScreen ? "0px" : theme.spacing(9) + 1,
    width: "0px",
    // },
  },
  toolbar: {
    width: drawerWidth,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  }
});
class SuperAdminConsole extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentCompany: {},
      openAddNewCompanyDialog: false,
      openCompanyDetailsDialog: false,
      openDeleteDialog: false,
      tabValue: 0,
      currentVent: {},
      currentUser: {},
      currentPlant: {},
      currentCompany: {},
      openDialog: false,
      openAddNewVentDialog: false,
      openAddNewPlantDialog: false,
      openAddNewUserDialog: false,
      openUserDetailsDialog: false,
      openPlantDetailsDialog: false,
      openQrCodeDialogs: false,
      currentDel: "",
      delUser: null,
      delCompany: null,
      delDevice: null,
      delPlant: null,
      serverBranch: [],
      Plants: [],
      Users: [],
      Devices: [],
      Companys: [],
      CompanyList: [],

      limit: 10,
      pageUser: 0,
      pageDevice: 0,
      pagePlant: 0,
      pageCompany: 0,

      totalUser: 0,
      totalDevice: 0,
      totalCompany: 0,
      totalPlant: 0,

      userOffset: 0,
      companyOffset: 0,
      plantOffset: 0,
      deviceOffset: 0,
      sessionExpired: false,
      currentUserFrDel: "",
      deviceOrderBy: 'createdAt',
      userOrderBy: 'createdAt',
      branchOrderBy: 'createdAt',
      hospitalOrderBy: 'createdAt',
      order: 'desc',
      anchorEl: null,

      branchFilterByCompany: 'all',
      userFilterByCompany: 'all',
      userFilterByBranch: 'all',
      deviceFilterByCompany: 'all',
      deviceFilterByBranch: 'all',
      branchListForUserFilter: [],
      branchListForDeviceFilter: [],

      searchAnchorEl: null,
      searchKeyHospital: '',
      searchKeyBranch: '',
      searchKeyUser: '',
      searchKeyDevice: '',
    };
    this.openPopover = null;
    this.idPopover = null;
    this.idSearchPopover = null;
    this.openSearchPopover = null;
    this.sidebarOpen = false
  }
  toggleDrawer = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen });
  };

  handleChangePage = (event, newPage, offset) => {
    if (this.state.tabValue === 0) {
      this.setState({ companyOffset: offset });
      this.fetchCompany(offset);
      this.setState({ pageCompany: newPage });
    } else if (this.state.tabValue === 1) {
      this.setState({ plantOffset: offset });
      this.fetchPlant(offset);
      this.setState({ pagePlant: newPage });
    } else if (this.state.tabValue === 2) {
      this.setState({ userOffset: offset });
      this.fetchUsers(offset);
      this.setState({ pageUser: newPage });
    } else if (this.state.tabValue === 3) {
      this.setState({ deviceOffset: offset });
      this.fetchMachine(offset);
      this.setState({ pageDevice: newPage });
    }
  };

  openAddNewCompanyDialog = () => {
    // <Redirect to="/companydetails" />
    this.setState({ openAddNewCompanyDialog: true });
  };
  closeAddNewCompanyDialog = () => {
    this.setState({ openAddNewCompanyDialog: false });
  };

  openCompanyDetailsDialog = (company) => {
    this.setState({ currentCompany: company });
    this.setState({ openCompanyDetailsDialog: true });
  };
  closeCompanyDetailsDialog = () => {
    this.setState({ openCompanyDetailsDialog: false });
  };
  openQrCodeDialog = () => {
    this.setState({ openQrCodeDialogs: false });
  };
  closeQrCodeDialog = () => {
    this.setState({ openQrCodeDialogs: false });
  };
  openDeleteDialog = () => {
    this.setState({ openDeleteDialog: true });
  };
  closeDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };
  openVentDialog = (vent) => {
    this.setState({ currentVent: vent });
    this.setState({ openDialog: true });
  };
  closeVentDialog = () => {
    this.setState({ openDialog: false });
  };

  openUserDetailsDialog = (user) => {
    // console.info("curreneuser -user", user);
    this.setState({ currentUser: user, openUserDetailsDialog: true });
  };
  closeUserDetailsDialog = () => {
    this.setState({ openUserDetailsDialog: false });
  };

  openPlantDetailsDialog = (plant) => {
    this.setState({ currentPlant: plant });
    this.setState({ openPlantDetailsDialog: true });
  };
  closePlantDetailsDialog = () => {
    this.setState({ openPlantDetailsDialog: false });
  };

  openAddNewVentDialog = () => {
    this.setState({ openAddNewVentDialog: true });
  };
  closeAddNewVentDialog = () => {
    this.setState({ openAddNewVentDialog: false });
  };

  openAddNewPlantDialog = () => {
    this.setState({ openAddNewPlantDialog: true });
  };
  closeAddNewPlantDialog = () => {
    this.setState({ openAddNewPlantDialog: false });
  };

  openAddNewUserDialog = () => {
    this.setState({ openAddNewUserDialog: true });
  };
  closeAddNewUserDialog = () => {
    this.setState({ openAddNewUserDialog: false });
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };

  componentDidMount() {
    this.getTotalcount();
    this.fetchCompany(0);
    this.fetchPlant(0);
    this.fetchUsers(0);
    this.fetchMachine(0);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.totalCompany !== this.state.totalCompany) {
      this.fetchCompanyList();
    }
  }

  handleChangeUser = (e) => {
    if (e) {
      if (e === 'reset-filter') {
        this.setState({ userOrderBy: 'createdAt', order: 'desc', })
      }
      // this.setState({ pageUser: Math.ceil(this.state.totalUser / this.state.limit) - 1 })
      this.getTotalcount();
      this.fetchUsers(0);
    }
  };

  handleChangeUserDetails = (e) => {
    if (e) {
      this.fetchUsers(this.state.userOffset);
    }
  };
  handleChangeVent = (e) => {
    if (e) {
      if (e === 'reset-filter') {
        this.setState({ deviceOrderBy: 'createdAt', order: 'desc', })
      }
      // this.setState({ pageDevice: Math.ceil(this.state.totalDevice / this.state.limit) - 1 })
      this.getTotalcount();
      this.fetchMachine(0);
    }
  };
  handleChangeVentDetails = (e) => {
    if (e) {
      this.fetchMachine(this.state.deviceOffset);
    }
  };

  handleChangePlant = (e) => {
    // console.log("IM inside reset-filter", e)
    if (e) {
      if (e === 'reset-filter') {
        // console.log("IM inside reset-filter", e)
        this.setState({ branchOrderBy: 'createdAt', order: 'desc', })
      }
      // this.setState({ pagePlant: Math.ceil(this.state.totalPlant / this.state.limit) - 1 })
      this.getTotalcount();
      this.fetchPlant(0);
    }
  };

  handleChangePlantDetails = (e) => {
    if (e) {
      this.fetchUsers(this.state.userOffset);
      this.fetchPlant(this.state.plantOffset);
      this.fetchMachine(this.state.deviceOffset);
    }
  };
  handleChangecompany = (e) => {
    if (e) {
      if (e === 'reset-filter') {
        this.setState({ hospitalOrderBy: 'createdAt', order: 'desc', })
      }
      // this.setState({ pageCompany: Math.ceil(this.state.totalCompany / this.state.limit) - 1 })
      this.getTotalcount();
      this.fetchCompany(0);
      this.fetchUsers(0);
    }
  };
  handleChangecompanyDetails = (e) => {
    if (e) {
      this.fetchCompany(this.state.companyOffset);
      this.fetchUsers(this.state.userOffset);
      this.fetchCompanyList();
    }
  };

  getTotalcount = () => {
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    fetch(TOTAL_COUNT, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          this.setState({
            totalDevice: result.TotDevice ? result.TotDevice : 0,
          });
          this.setState({ totalPlant: result.TotPlant ? result.TotPlant : 0 });
          this.setState({ totalUser: result.TotUser ? result.TotUser : 0 });
          this.setState({
            totalCompany: result.TotCompany ? result.TotCompany : 0,
          });
        }
      });
  };

  fetchCompany = (offsetArg) => {
    let token = localStorage.getItem("abmUsertoken");
    let limit = this.state.limit ? this.state.limit : 10;
    let offset = offsetArg ? offsetArg : 0;
    let serachKey = this.state.searchKeyHospital ? this.state.searchKeyHospital : '' //Hospital Name
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    fetch(`${COMPANYLIST}?limit=${limit}&offset=${offset}&search=${serachKey}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          this.setState({ sessionExpired: true });
        }
        return res.json();
      })
      .then(
        (result) => {
          if (result && Array.isArray(result)) {
            // console.log("xsujan Compay List,result", result);
            this.setState({ Companys: result });
          } else {
            this.setState({ Companys: [] });
          }
        },
        (error) => {
          console.log("Compay List,result", error);
        }
      );
    // console.log("xsujan Compay List:", this.state.Companys);
  };

  fetchCompanyList = () => {
    let token = localStorage.getItem("abmUsertoken");
    let limit = this.state.totalCompany;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    fetch(`${COMPANYLIST}?limit=${limit}&offset=${0}`, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          if (result && Array.isArray(result)) {
            // console.log("xsujan Compay List:", result);
            this.setState({ CompanyList: result });
            this.setState({ totalCompany: result.length });
          } else {
            this.setState({ CompanyList: [] });
          }
        },
        (error) => {
          console.log("Compay List,Error:", error);
        }
      );
    // console.log("xsujan Compay List:", this.state.Companys);
  };

  fetchPlant = async (offsetArg) => {
    // console.log("IM FETCHPLANT:: IM GETTING CALLED11")
    let token = localStorage.getItem("abmUsertoken");
    let limit = this.state.limit ? this.state.limit : 10;
    let offset = offsetArg ? offsetArg : 0;
    let branchFilterByCompany = this.state.branchFilterByCompany &&
      this.state.branchFilterByCompany !== 'all' ? this.state.branchFilterByCompany : ''
    // console.log("IM FETCHPLANT:: IM GETTING CALLED22", branchFilterByCompany)
    let serachKey = this.state.searchKeyBranch ? this.state.searchKeyBranch : '' //Branch Name

    let res = await fetchplant({ token, limit, offset, branchFilterByCompany, serachKey });
    if (res.status === 401) {
      this.setState({ sessionExpired: true });
      return;
    }
    let data = await res.json();
    // console.log("xsujan PLANTS in FETCH:", data.data);
    if (data && data.data && data.data.length > 0) {
      this.setState({
        Plants: data.data,
      });
    } else {
      this.setState({
        Plants: [],
      });
    }
  };

  fetchUsers = async (offsetArg) => {
    let token = localStorage.getItem("abmUsertoken");
    let limit = this.state.limit ? this.state.limit : 10;
    let offset = offsetArg ? offsetArg : 0;
    let userFilterByCompany = this.state.userFilterByCompany &&
      this.state.userFilterByCompany !== 'all' ? this.state.userFilterByCompany : ''

    let userFilterByBranch = this.state.userFilterByBranch &&
      this.state.userFilterByBranch !== 'all' ? this.state.userFilterByBranch : ''

    let searchKey = this.state.searchKeyUser ? this.state.searchKeyUser : '' //User Name
    // console.log("searchKey::::", searchKey);
    const res = await fetchuser({ token, limit, offset, userFilterByCompany, userFilterByBranch, searchKey });
    if (res.status === 401) {
      this.setState({ sessionExpired: true });
      return;
    }
    let response = await res.json();
    if (response && response.data && response.data.length > 0) {
      this.setState({ Users: response.data });
    } else {
      this.setState({ Users: [] });
    }
    // console.log("xsujan USERS in FETCH:", this.state.Users);
  };

  fetchMachine = async (offsetArg) => {
    let token = localStorage.getItem("abmUsertoken");
    let limit = this.state.limit ? this.state.limit : 10;
    let offset = offsetArg ? offsetArg : 0;
    let deviceFilterByCompany = this.state.deviceFilterByCompany &&
      this.state.deviceFilterByCompany !== 'all' ? this.state.deviceFilterByCompany : ''

    let deviceFilterByBranch = this.state.deviceFilterByBranch &&
      this.state.deviceFilterByBranch !== 'all' ? this.state.deviceFilterByBranch : ''

    let serachKey = this.state.searchKeyDevice ? this.state.searchKeyDevice : '' //Device Id

    const res = await fetchmachine({ token, limit, offset, deviceFilterByCompany, deviceFilterByBranch, serachKey });
    if (res.status === 401) {
      this.setState({ sessionExpired: true });
      return;
    }
    let data = await res.json();
    if (data && data.length > 0) {
      console.log("xsujan Machine in FETCH:", data);
      this.setState({ Devices: data });
    } else {
      this.setState({ Devices: [] });
    }
    // console.log("xsujan Machine in FETCH:", this.state.Devices);
  };


  descendingComparator(a, b, orderBy, currentType) {
    // if (currentType === 'branch') {
    //   console.log("OrderBy:", orderBy, " b :", b, " a :", a)
    // }
    if ((currentType === 'device' || currentType === 'branch' || currentType === 'user')
      && 'company_id' in a && 'company_id' in b && a.company_id && b.company_id && 'name' in a.company_id && 'name' in b.company_id) {
      a = { ...a, 'company_id': a.company_id.name }
      b = { ...b, 'company_id': b.company_id.name }
    }
    if ((currentType === 'device' || currentType === 'user') && 'plant_id' in a && 'plant_id' in b
      && 'plant_id' in a && 'plant_id' in b && a.plant_id && b.plant_id && 'name' in a.plant_id && 'name' in b.plant_id
    ) {
      a = { ...a, 'plant_id': a.plant_id.name }
      b = { ...b, 'plant_id': b.plant_id.name }
    }

    if (orderBy && b[orderBy] && a[orderBy]) {
      if ((typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') || (
        b[orderBy] instanceof String && a[orderBy] instanceof String)) {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
          return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
          return 1;
        }
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
      }
    }
    return 0;
  }

  getComparator(order, orderBy, currentType) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy, currentType)
      : (a, b) => -this.descendingComparator(a, b, orderBy, currentType);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }


  handleDeviceRequestSort = (event, property) => {
    const isAsc = this.state.deviceOrderBy === property && this.state.order === 'asc';
    this.setState({ deviceOrderBy: property, order: isAsc ? 'desc' : 'asc' });
  };
  handleUserRequestSort = (event, property) => {
    const isAsc = this.state.userOrderBy === property && this.state.order === 'asc';
    this.setState({ userOrderBy: property, order: isAsc ? 'desc' : 'asc' });
  };

  handleBranchRequestSort = (event, property) => {
    const isAsc = this.state.branchOrderBy === property && this.state.order === 'asc';
    this.setState({ branchOrderBy: property, order: isAsc ? 'desc' : 'asc' });
  };
  handleHospitalRequestSort = (event, property) => {
    const isAsc = this.state.hospitalOrderBy === property && this.state.order === 'asc';
    this.setState({ hospitalOrderBy: property, order: isAsc ? 'desc' : 'asc' });
  };

  handleClickPopover = (event) => {
    // console.log("handleClickPopover:", event.currentTarget)
    this.setState({ anchorEl: event.currentTarget })
  };
  handleClosePopover = () => {
    this.setState({ anchorEl: null })
  };
  onFilterClick = (currentType, companyid, branchid) => {
    // console.log("FilterID is:", currentType, companyid, branchid);
    if (currentType === 'branch') {
      this.setState({ branchFilterByCompany: companyid }, () => this.fetchPlant(0));
    }
    if (currentType === 'user') {
      this.setState({ userFilterByCompany: companyid, userFilterByBranch: branchid ? branchid : '' },
        () => this.fetchUsers(0));
    }
    if (currentType === 'device') {
      this.setState({ deviceFilterByCompany: companyid, deviceFilterByBranch: branchid ? branchid : '' },
        () => this.fetchMachine(0));
    }
    // this.setState({ branchFilterByCompany: filterid }, () => this.fetchPlant(0));
    this.handleClosePopover();
  }
  setPlantListForUserFilter = (list) => {
    this.setState({ branchListForUserFilter: list })
  }
  setPlantListForDeviceFilter = (list) => {
    this.setState({ branchListForDeviceFilter: list })
  }

  handleClickSearchPopover = (event) => {
    this.setState({ searchAnchorEl: event.currentTarget })
  };
  handleCloseSearchPopover = () => {
    this.setState({ searchAnchorEl: null })
  };


  onSearchClick = (currentType, serachKey) => {
    // console.log("serachKey is:", currentType, serachKey);
    // return;
    if (currentType === 'hospital') {
      this.setState({ searchKeyHospital: serachKey }, () => this.fetchCompany(0));
    }

    if (currentType === 'branch') {
      this.setState({ searchKeyBranch: serachKey }, () => this.fetchPlant(0));
    }
    if (currentType === 'user') {
      this.setState({ searchKeyUser: serachKey }, () => this.fetchUsers(0));
    }
    if (currentType === 'device') {
      this.setState({ searchKeyDevice: serachKey }, () => this.fetchMachine(0));
    }
    // this.setState({ branchFilterByCompany: filterid }, () => this.fetchPlant(0));
    this.handleCloseSearchPopover();
  }

  render() {
    this.openPopover = Boolean(this.state.anchorEl);
    this.idPopover = this.openPopover ? 'simple-popover' : undefined;

    this.openSearchPopover = Boolean(this.state.searchAnchorEl);
    this.idSearchPopover = this.openSearchPopover ? 'simple-search-popover' : undefined;

    function createdAtDateFormat(params) {
      if (params) {
        var timestamp = params
        var crdate = new Date(timestamp);
        var crhours = crdate.getHours();
        var crminutes = crdate.getMinutes();
        var crampm = crhours >= 12 ? 'pm' : 'am';
        crhours = crhours % 12;
        crhours = crhours ? crhours : 12; // the hour '0' should be '12'
        crminutes = ('0' + crminutes).slice(-2);
        var strTime = crhours + ':' + crminutes + ' ' + crampm;
        return crdate.getDate() +
          "/" + (crdate.getMonth() + 1) +
          "/" + crdate.getFullYear() +
          " " + strTime
      } else return ''
    }

    return (
      <div className="content">
        {this.idPopover ?
          <FilterPopover
            openPopover={this.openPopover}
            idPopover={this.idPopover}
            CompanyList={this.state.CompanyList}
            handleClosePopover={this.handleClosePopover}
            onFilterClick={this.onFilterClick}
            anchorEl={this.state.anchorEl}
            tabValue={this.state.tabValue}
            branchFilterByCompany={this.state.branchFilterByCompany}
            userFilterByCompany={this.state.userFilterByCompany}
            userFilterByBranch={this.state.userFilterByBranch}
            deviceFilterByCompany={this.state.deviceFilterByCompany}
            deviceFilterByBranch={this.state.deviceFilterByBranch}
            setPlantListForDeviceFilter={this.setPlantListForDeviceFilter}
            setPlantListForUserFilter={this.setPlantListForUserFilter}
            branchListForUserFilter={this.state.branchListForUserFilter}
            branchListForDeviceFilter={this.state.branchListForDeviceFilter}
          />
          : null}

        {this.idSearchPopover ?
          <SearchPopover
            openPopover={this.openSearchPopover}
            idPopover={this.idSearchPopover}
            CompanyList={this.state.CompanyList}
            handleClosePopover={this.handleCloseSearchPopover}
            onSearchClick={this.onSearchClick}
            anchorEl={this.state.searchAnchorEl}
            tabValue={this.state.tabValue}
            searchKeyDevice={this.state.searchKeyDevice}
            searchKeyUser={this.state.searchKeyUser}
            searchKeyBranch={this.state.searchKeyBranch}
            searchKeyHospital={this.state.searchKeyHospital}
          />
          : null}

        <SessionExpiryDialog
          open={this.state.sessionExpired}
        ></SessionExpiryDialog>
        <AddNewCompany
          onChangedcompany={this.handleChangecompany}
          onChangePlant={this.handleChangePlant}
          onChangeUser={this.handleChangeUser}
          open={this.state.openAddNewCompanyDialog}
          setClose={this.closeAddNewCompanyDialog}
        ></AddNewCompany>
        {this.state.openCompanyDetailsDialog ?
          <CompanyDetailsDialog
            onChanged={this.handleChangecompanyDetails}
            currentCompany={this.state.currentCompany}
            open={this.state.openCompanyDetailsDialog}
            setClose={this.closeCompanyDetailsDialog}
          ></CompanyDetailsDialog>
          : null}
        <AddNewVent
          onChanged={this.handleChangeVent}
          companyList={this.state.CompanyList}
          open={this.state.openAddNewVentDialog}
          setClose={this.closeAddNewVentDialog}
        ></AddNewVent>

        <VentDetailsDialog
          onChanged={this.handleChangeVentDetails}
          currentVent={this.state.currentVent}
          open={this.state.openDialog}
          setClose={this.closeVentDialog}
        ></VentDetailsDialog>

        <AddNewPlant
          onChange={this.handleChangePlant}
          companyList={this.state.CompanyList}
          open={this.state.openAddNewPlantDialog}
          setClose={this.closeAddNewPlantDialog}
        ></AddNewPlant>

        <AddNewUser
          onChange={this.handleChangeUser}
          companyList={this.state.CompanyList}
          open={this.state.openAddNewUserDialog}
          setClose={this.closeAddNewUserDialog}
        ></AddNewUser>

        <UserDetailsDialog
          onChange={this.handleChangeUserDetails}
          currentUser={this.state.currentUser}
          open={this.state.openUserDetailsDialog}
          setClose={this.closeUserDetailsDialog}
        ></UserDetailsDialog>
        <PlantDetailsDialog
          onChange={this.handleChangePlantDetails}
          currentPlant={this.state.currentPlant}
          open={this.state.openPlantDetailsDialog}
          setClose={this.closePlantDetailsDialog}
        ></PlantDetailsDialog>

        <DeleteDialog
          open={this.state.openDeleteDialog}
          setClose={this.closeDeleteDialog}
          currentDel={this.state.currentDel}
          delUser={this.state.delUser}
          delCompany={this.state.delCompany}
          delDevice={this.state.delDevice}
          delPlant={this.state.delPlant}
          onChangeVent={this.handleChangeVentDetails}
          onChangePlant={this.handleChangePlantDetails}
          onChangedUser={this.handleChangeUserDetails}
          onChangedCompany={this.handleChangecompanyDetails}
          currentUserFrDel={this.state.currentUserFrDel}
        ></DeleteDialog>
        <AppBar
          position="fixed"
          style={{ background: "#b7b7b7", paddingRight: '0px' }}
          className={clsx(this.props.classes.appBar, {
            [this.props.classes.appBarShift]: this.state.sidebarOpen,
          })}
        >
          <Toolbar style={{ paddingRight: '0px', height: '64px' }} >

            <div className="row" style={{ width: '100%' }}>
              <div className="">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={this.toggleDrawer}
                  edge="start"
                  className={clsx(this.props.classes.menuButton, {
                    [this.props.classes.hide]: this.state.sidebarOpen,
                  })}
                  style={{ position: 'absolute', left: '15px', top: '6px', zIndex: '999' }}
                >
                  <MenuIcon />
                </IconButton>

              </div>
              {/* <div className=" d-none d-sm-flex col-sm-12 justify-content-start justify-content-lg-center" style={{
                display: 'flex', alignItems: 'center', overflow: 'hidden', paddingLeft: '40px'
              }}>
                <div
                  title="This portal is only a secondry monitoring mechanism for the
                ventilator and does not replace the primary mehanism of
                physically attending at all time"
                >
                  <Typography variant="h6" noWrap style={{ width: "100%" }}>
                    <img src={Warning} width="33px" style={{ padding: '2px' }} />
                    <span style={{ fontSize: "15px", textTransform: "none" }}>
                      Covid-19 use only. It does not replace the need to monitor the
                      Ventilator completely.
              </span>
                  </Typography>
                </div>
              </div> */}
              <div className="" style={{
                display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
                padding: '0px', position: 'absolute', right: '0px', top: "8px"
              }}>
                <img src={newLogo} alt="Logo" height="50px" width="120px" style={{ padding: "0px 15px" }} />

              </div>
            </div>
            {/* {renderHeaderSearchBar} */}
          </Toolbar>
        </AppBar>
        <Drawer open={this.state.sidebarOpen} onClose={this.toggleDrawer}>
          {/* <div className={this.props.classes.toolbar}> */}
          <CardHeader
            style={{ width: '240px' }}
            avatar={
              <Avatar aria-label="recipe" >
                <UserIcon />
              </Avatar>
            }
            action={
              // <IconButton aria-label="settings">
              <IconButton onClick={this.toggleDrawer}>
                {this.props.theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
              // </IconButton>
            }
            title={this.props.user.userName}
            subheader={this.props.user.role}
          />
          {/* </div> */}
          <Divider />
          <div className="sidebarCustPadding">
            <List>
              <ListItemLink
                button
                key={"home"}
                to={{
                  pathname: "/home",
                }}
                onClick={() => {
                  this.setState({ sidebarOpen: false })
                }}
              >
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary={"Home"} />
              </ListItemLink>
              <ListItemLink
                className="nav-link"
                to="/"
                onClick={() => this.props.logOut()}
              >
                <ListItemIcon>
                  <PowerButton />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemLink>
            </List>
          </div>
        </Drawer>
        <div className="container-fluid " style={{ marginTop: '64px', padding: '0px' }}>
          <AppBar position="static">
            <Tabs
              TabIndicatorProps={{ style: { background: "gray" } }}
              value={this.state.tabValue}
              onChange={this.handleTabChange}
            >
              <Tab label="Hospital" {...a11yProps(0)} />
              <Tab label="Branch" {...a11yProps(1)} />
              <Tab label="User" {...a11yProps(2)} />
              <Tab label="Device" {...a11yProps(3)} />
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.tabValue} index={0}>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{ padding: "5px 20px 2px" }}
                  >
                    <div style={{ float: "right", }}>
                      <div style={{ display: 'inline-block', margin: '5px 7px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot" invisible={!this.state.searchKeyHospital}>
                          <SearchIcon
                            onClick={this.handleClickSearchPopover}
                            style={{ cursor: 'hand' }}
                          />
                        </Badge>
                      </div>
                      <AddBoxIcon
                        style={{ margin: '5px', cursor: "hand" }}
                        onClick={() => {
                          this.openAddNewCompanyDialog();
                        }}
                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "2px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">
                          <EnhancedTableHead
                            // classes={classes}
                            // numSelected={selected.length}
                            order={this.state.order}
                            orderBy={this.state.hospitalOrderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={this.handleHospitalRequestSort}
                            // rowCount={rows.length}
                            currentType="hospital"
                          />
                          <TableBody>
                            {this.stableSort((this.state.Companys),
                              this.getComparator(this.state.order, this.state.hospitalOrderBy, 'hospital')).map((Company) => (
                                <TableRow
                                  hover={true}
                                  key={Company.id}
                                  onClick={() => {
                                    // localStorage.setItem(
                                    //   "selectedCompany",
                                    //   Company.id
                                    // );
                                    // console.log("Company Details:", Company)
                                    this.openCompanyDetailsDialog(Company);
                                  }}
                                >
                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {Company.name}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Company.max_user}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Company.email}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Company.phone}
                                    {/* {Company.phone && !Company.phone.includes('+')
                                      ? "+91 " + Company.phone : Company.phone} */}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {createdAtDateFormat(Company.createdAt)}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    <DeleteIcon
                                      onMouseDown={() => {
                                        this.setState({
                                          currentDel: "company",
                                          delCompany: Company.id,
                                        });
                                        this.openDeleteDialog();
                                      }}
                                      style={{ fontSize: "18px", cursor: "hand" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}

                            {/* {this.emptyRows > 0 && (
                            <TableRow style={{ height: 53 * this.emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                          </TableBody>
                        </Table>
                        <TablePaginationNew
                          count={this.state.totalCompany}
                          offset={this.state.companyOffset}
                          page={this.state.pageCompany}
                          onChangePage={this.handleChangePage}
                        />
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{ padding: "5px 20px 2px" }}
                  >
                    <div style={{ float: "right" }}>
                      <div style={{ display: 'inline-block', margin: '5px 7px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot" invisible={!this.state.searchKeyBranch}>
                          <SearchIcon
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickSearchPopover}
                          />
                        </Badge>
                      </div>
                      <div style={{ display: 'inline-block', margin: '5px 10px 5px 4px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={this.state.branchFilterByCompany === 'all' || !this.state.branchFilterByCompany}>
                          <FilterListIcon
                            aria-describedby={this.idPopover}
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickPopover}
                          />
                        </Badge>
                      </div>


                      <AddBoxIcon
                        style={{ margin: '5px', cursor: "hand" }}
                        onClick={() => {
                          this.openAddNewPlantDialog();
                        }}
                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "2px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">
                          <EnhancedTableHead
                            // classes={classes}
                            // numSelected={selected.length}
                            order={this.state.order}
                            orderBy={this.state.branchOrderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={this.handleBranchRequestSort}
                            // rowCount={rows.length}
                            currentType="branch"
                          />
                          <TableBody>
                            {/* {(this.state.rowsPerPagePlant > 0
                              ? this.state.Plants.slice(this.state.pagePlant * this.state.rowsPerPagePlant, this.state.pagePlant * this.state.rowsPerPagePlant + this.state.rowsPerPagePlant)
                              : */}

                            {this.stableSort(this.state.Plants,
                              this.getComparator(this.state.order, this.state.branchOrderBy, 'branch')).map((Plant) => (
                                <TableRow
                                  hover={true}
                                  key={Plant.id}
                                  onClick={() => {
                                    this.openPlantDetailsDialog({
                                      ...Plant,
                                      companyId: Plant.company_id
                                        ? Plant.company_id.id
                                        : "",
                                      companyName: Plant.company_id
                                        ? Plant.company_id.name
                                        : "",
                                    });
                                  }}
                                >

                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {Plant.company_id
                                      ? Plant.company_id.name
                                      : ""}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {Plant.name}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {createdAtDateFormat(Plant.createdAt)}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    <DeleteIcon
                                      onMouseDown={() => {
                                        this.setState({
                                          currentDel: "plant",
                                          delPlant: Plant.id,
                                        });
                                        this.openDeleteDialog();
                                      }}
                                      style={{ fontSize: "18px", cursor: "hand" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}

                            {/* {this.emptyRows > 0 && (
                            <TableRow style={{ height: 53 * this.emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                          </TableBody>
                        </Table>
                        <TablePaginationNew
                          count={this.state.totalPlant}
                          offset={this.state.plantOffset}
                          page={this.state.pagePlant}
                          onChangePage={this.handleChangePage}
                        />
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={2}>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{ padding: "5px 20px 2px" }}
                  >
                    <div style={{ float: "right", }}>


                      <div style={{ display: 'inline-block', margin: '5px 7px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={!this.state.searchKeyUser}>
                          <SearchIcon
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickSearchPopover}

                          />
                        </Badge>
                      </div>
                      <div style={{ display: 'inline-block', margin: '5px 10px 5px 4px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={this.state.userFilterByCompany === 'all' || !this.state.userFilterByCompany}>
                          <FilterListIcon
                            aria-describedby={this.idPopover}
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickPopover}
                          />
                        </Badge>
                      </div>

                      <AddBoxIcon
                        style={{ margin: '5px', cursor: "hand" }}
                        onClick={() => {
                          this.openAddNewUserDialog();
                        }}
                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "2px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">
                          <EnhancedTableHead
                            // classes={classes}
                            // numSelected={selected.length}
                            order={this.state.order}
                            orderBy={this.state.userOrderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={this.handleUserRequestSort}
                            // rowCount={rows.length}
                            currentType="user"
                          />

                          <TableBody>
                            {this.stableSort(this.state.Users,
                              this.getComparator(this.state.order, this.state.userOrderBy, 'user')).map((User) => {
                                if (User.role && User.role !== "superadmin") {
                                  return (
                                    <TableRow
                                      hover={true}
                                      key={User.id}
                                      onClick={() => {
                                        this.openUserDetailsDialog({
                                          ...User,
                                          companyId: User.company_id
                                            ? User.company_id.id
                                            : "",
                                          companyName: User.company_id
                                            ? User.company_id.name
                                            : "",
                                          plantId: User.plant_id
                                            ? User.plant_id.id
                                            : "",
                                          plantName: User.plant_id
                                            ? User.plant_id.name
                                            : "",
                                        });
                                      }}
                                    ><TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                        {User.company_id ? User.company_id.name : ""}
                                      </TableCell>
                                      <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                        {User.plant_id ? User.plant_id.name : ""}
                                      </TableCell>
                                      <TableCell
                                        style={{ padding: "12px", textTransform: 'capitalize' }}
                                        scope="row"
                                      >
                                        {User.userName}
                                      </TableCell>
                                      <TableCell style={{ padding: "12px" }}>
                                        {User.email}
                                      </TableCell>
                                      <TableCell style={{ padding: "12px" }}>
                                        {User.role}
                                      </TableCell>
                                      <TableCell style={{ padding: "12px" }}>
                                        {createdAtDateFormat(User.createdAt)}
                                      </TableCell>
                                      <TableCell style={{ padding: "12px" }}>
                                        {User.role && User.role !== "superadmin" ?
                                          <DeleteIcon
                                            onMouseDown={() => {
                                              this.setState({
                                                currentDel: "user",
                                                delUser: User.id,
                                                currentUserFrDel: User.email,
                                              });
                                              this.openDeleteDialog();
                                            }}
                                            style={{
                                              fontSize: "18px",
                                              cursor: "hand",
                                            }}
                                          /> : null}
                                      </TableCell>
                                    </TableRow>)
                                } else return null
                              })}

                            {/* {this.emptyRows > 0 && (
                            <TableRow style={{ height: 53 * this.emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                          </TableBody>
                        </Table>
                        <TablePaginationNew
                          count={this.state.totalUser}
                          offset={this.state.userOffset}
                          page={this.state.pageUser}
                          onChangePage={this.handleChangePage}
                        />
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={3}>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{ padding: "5px 20px 2px" }}
                  >
                    <div style={{ float: "right", }}>


                      <div style={{ display: 'inline-block', margin: '5px 7px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={!this.state.searchKeyDevice}>
                          <SearchIcon
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickSearchPopover}

                          />
                        </Badge>
                      </div>
                      <div style={{ display: 'inline-block', margin: '5px 10px 5px 4px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={this.state.deviceFilterByCompany === 'all' || !this.state.deviceFilterByCompany}>
                          <FilterListIcon
                            aria-describedby={this.idPopover}
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickPopover}
                          />
                        </Badge>
                      </div>
                      <AddBoxIcon
                        style={{ margin: '5px', cursor: "hand" }}
                        onClick={async () => {
                          await this.openAddNewVentDialog();
                        }}
                      />
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "2px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">



                          <EnhancedTableHead
                            // classes={classes}
                            // numSelected={selected.length}
                            order={this.state.order}
                            orderBy={this.state.deviceOrderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={this.handleDeviceRequestSort}
                            // rowCount={rows.length}\
                            currentType="device"

                          />

                          <TableBody>
                            {/* {(this.state.rowsPerPageDevice > 0
                              ? this.state.Devices.slice(this.state.pageDevice * this.state.rowsPerPageDevice, this.state.pageDevice * this.state.rowsPerPageDevice + this.state.rowsPerPageDevice)
                              : */}
                            {this.stableSort(this.state.Devices,
                              this.getComparator(this.state.order, this.state.deviceOrderBy, 'device')).map((Device) => (
                                <TableRow
                                  hover={true}
                                  onClick={() => {
                                    this.openVentDialog({
                                      ...Device,
                                      companyId: Device.company_id
                                        ? Device.company_id.id
                                        : "",
                                      companyName: Device.company_id
                                        ? Device.company_id.name
                                        : "",
                                      plantId: Device.plant_id
                                        ? Device.plant_id.id
                                        : "",
                                      plantName: Device.plant_id
                                        ? Device.plant_id.name
                                        : "",
                                    });
                                  }}
                                  key={Device.id}
                                >

                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {Device.company_id
                                      ? Device.company_id.name
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "12px", textTransform: 'capitalize' }}
                                    scope="row"
                                  >
                                    {Device.plant_id ? Device.plant_id.name : ""}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Device.name}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Device.alarm && Device.alarm.length && Device.alarm[0].RoomID ? Device.alarm[0].RoomID : ''}
                                    {/* {Device.roomNo} */}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Device.alarm && Device.alarm.length && Device.alarm[0].BedID ? Device.alarm[0].BedID : ''}
                                    {/* {Device.bedId} */}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {createdAtDateFormat(Device.createdAt)}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    <DeleteIcon
                                      onMouseDown={() => {
                                        this.setState({
                                          currentDel: "device",
                                          delDevice: Device.id,
                                        });
                                        this.openDeleteDialog();
                                      }}
                                      style={{ fontSize: "18px", cursor: "hand" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}

                            {/* {this.emptyRows > 0 && (
                            <TableRow style={{ height: 53 * this.emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                          </TableBody>
                        </Table>
                        <TablePaginationNew
                          count={this.state.totalDevice}
                          offset={this.state.deviceOffset}
                          page={this.state.pageDevice}
                          onChangePage={this.handleChangePage}
                        />
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(SuperAdminConsole);

function DeleteDialog(props) {
  const {
    open,
    setClose,
    delUser,
    delCompany,
    delDevice,
    delPlant,
    onChangePlant,
    onChangeVent,
    onChangedUser,
    onChangedCompany,
    currentDel,
    currentUserFrDel,
  } = props;
  const handleClose = () => {
    setClose(true);
  };
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  // const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState();
  const [branchDeleteOpen, setBranchDeleteOpen] = React.useState(false);
  const [companyDeleteOpen, setCompanyDeleteOpen] = React.useState(false);
  const [associatedData, setAssociatedData] = React.useState({});
  const [associatedCompanyData, setAssociatedCompanyData] = React.useState({});

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const handleDelete = async (e) => {
    // console.log( "handleDelete", e.target,delUser,delCompany, delDevice, delPlant);
    if (currentDel === "device" && delDevice) {
      deleteDevice();
    }
    if (currentDel === "plant" && delPlant) {
      deletePlant();
    }
    if (currentDel === "user" && delUser) {
      deleteUser();
    }
    if (currentDel === "company" && delCompany) {
      deleteCompany();
    }
  };
  const deleteCompany = async () => {
    if (props.delCompany) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          delCompany: props.delCompany,
        }),
      };
      const res = await fetch(`${COMPANY_DEL}/${props.delCompany}`, requestOptions);
      const msg = await res.json();
      // console.log("Delete hospital response is:", res, "Msg is:", msg);
      if (res.status === 200) {
        setSnackMsg("Hospital deleted successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        onChangedCompany(true);
      } else if (res.status === 406) {
        setAssociatedCompanyData(msg);
        setCompanyDeleteOpen(true);
      } else {
        setSnackMsg(msg.message);
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      setSnackMsg(`Sorry something went wrong `);
      setSnackSeverity("error");
      setOpenSnack(true);
    }
    handleClose();
  };

  const deleteUser = async () => {
    let msg = (<ul><li>This user has been registerd as Primary/Root user.</li>
      <li>Primary/Root user cannot be deleted.</li>
      <li> Primary user: First user of the hospital </li></ul>);

    if (props.delUser) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          delUser: props.delUser,
          delUserEmail: currentUserFrDel,
        }),
      };
      const res = await fetch(`${USER_DEL}`, requestOptions);
      const msgd = await res.text();
      // console.log("Delete User response is:", res, "Msg is:", msgd);
      if (res.status === 200) {
        setSnackMsg("User deleted successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        onChangedUser(true);
      } else if (res.status === 406) {
        setSnackMsg(msg);
        setSnackSeverity("warning");
        setOpenSnack(true);
      } else {
        setSnackMsg(`Sorry.. user deleted unsuccesfully beacause ${msgd}`);
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      setSnackMsg(`Sorry something went wrong `);
      setSnackSeverity("error");
      setOpenSnack(true);
    }
    handleClose();
  };

  const deletePlant = async () => {
    if (props.delPlant) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          delPlant: props.delPlant,
        }),
      };
      const res = await fetch(`${PLANT_DEL}/${props.delPlant}`, requestOptions);
      const msg = await res.json();
      if (res.status === 200) {
        setSnackMsg("Branch deleted successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        onChangePlant(true);
      } else if (res.status === 406) {
        setAssociatedData(msg);
        setBranchDeleteOpen(true);
      } else {
        setSnackMsg(msg.message);
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      // alert("Sorry something went wrong");
      setSnackMsg(`Sorry something went wrong`);
      setSnackSeverity("error");
      setOpenSnack(true);
    }
    handleClose();
  };

  const deleteDevice = async () => {
    if (props.delDevice) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          delDevice: props.delDevice,
        }),
      };
      const res = await fetch(`${DEVICE_DEL}`, requestOptions);
      const msg = await res.json();
      // console.log("Delete device response is:", res, "Msg is:", msg);
      if (res.status === 200) {
        //alert("device deleted Succesfully");
        setSnackMsg("Device deleted successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        onChangeVent(true);
      } else {
        setSnackMsg(`Sorry.. device deleted unsuccesfully beacause ${msg.message}`);
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      setSnackMsg("Sorry something went wrong");
      setSnackSeverity("error");
      setOpenSnack(true);
    }
    handleClose();
  };
  const closeBranchDeleteDialog = () => {
    setBranchDeleteOpen(false);
  };
  const closeCompanyDeleteDialog = () => {
    setCompanyDeleteOpen(false);
  };
  return (
    <React.Fragment>
      {branchDeleteOpen ? (
        <BranchDeleteDialog
          open={branchDeleteOpen}
          setClose={closeBranchDeleteDialog}
          associatedData={associatedData}
          onChangeVent={onChangeVent}
          onChangePlant={onChangePlant}
          onChangedUser={onChangedUser}
          setSnackMsg={setSnackMsg}
          setOpenSnack={setOpenSnack}
          setSnackSeverity={setSnackSeverity}
        ></BranchDeleteDialog>
      ) : null}
      {companyDeleteOpen ? (
        <CompanyDeleteDialog
          open={companyDeleteOpen}
          setClose={closeCompanyDeleteDialog}
          associatedData={associatedCompanyData}
          onChangeVent={onChangeVent}
          onChangePlant={onChangePlant}
          onChangedUser={onChangedUser}
          onChangedCompany={onChangedCompany}
          setSnackMsg={setSnackMsg}
          setOpenSnack={setOpenSnack}
          setSnackSeverity={setSnackSeverity}
        ></CompanyDeleteDialog>
      ) : null}

      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={{ vertical: "top", horizontal: "center", }}
        severity={snackSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "0.8rem" }}
        >
          DELETE
        </DialogTitle>
        <DialogContent>
          <h4 style={{ padding: "30px 0px 0px 35px", fontSize: "20px" }}>
            Are you sure you want to delete this?
          </h4>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDelete} color="primary">
            {" "}
            {/* onClick={deleteItem} */}
            Delete
          </Button>

          <Button onClick={handleClose} color="primary" autoFocus>
            Close{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ backgroundColor: "#ffffffe8", minHeight: "80vh" }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TablePaginationNew(props) {
  const theme = useTheme();
  let { count, page, offset, onChangePage } = props;
  let limit = 10;
  let currentOffset = offset;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1, currentOffset - limit);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1, currentOffset + limit);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(
      event,
      Math.max(0, Math.ceil(count / limit) - 1),
      (Math.ceil(count / limit) - 1) * limit
    );
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {/* {(count > 0) ?
        <div style={{ display: "inline-flex", alignItems: "center", padding: "15px" }}> {(page * limit) + 1} - {Math.min((page * limit) + limit, count)}  of  {count}
        </div> : null} */}
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / limit) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / limit) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

const deviceHeadCells = [
  { id: 'company_id', label: 'Hospital Name' },
  { id: 'plant_id', label: 'Branch Name' },
  { id: 'name', label: 'Device' },
  { id: 'roomNo', label: 'Room No' },
  { id: 'bedId', label: 'Bed Id' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'action', label: 'Action' }
];

const userHeadCells = [
  { id: 'company_id', label: 'Hospital Name' },
  { id: 'plant_id', label: 'Branch Name' },
  { id: 'userName', label: 'User Full Name' },
  { id: 'email', label: 'Email / UserID' },
  { id: 'role', label: 'Role' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'action', label: 'Action' }
];

const branchHeadCells = [
  { id: 'company_id', label: 'Hospital Name' },
  { id: 'name', label: 'Branch Name' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'action', label: 'Action' }
];

const hospitalHeadCells = [
  { id: 'name', label: 'Hospital Name' },
  { id: 'max_user', label: 'Maximum Number Of Users' },
  { id: 'email', label: 'Admin User Email' },
  { id: 'phone', label: 'Phone no' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'action', label: 'Action' }
];

function EnhancedTableHead(props) {
  let tableCellStyle = {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "15px",
    fontWeight: "600",
  }

  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    currentType

  } = props;
  let currentHead = [];
  switch (currentType) {
    case 'device': currentHead = deviceHeadCells;
      break;
    case 'user': currentHead = userHeadCells;
      break;
    case 'branch': currentHead = branchHeadCells;
      break;
    case 'hospital': currentHead = hospitalHeadCells;
      break;
  }

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell> */}
        {currentHead.map(headCell => {
          // console.log("orderBy === headCell.id== ", orderBy, headCell.id)
          if (headCell.id === 'action') {
            return <TableCell
              style={tableCellStyle}
            >
              {headCell.label}
            </TableCell>
          }
          return <TableCell
            key={currentType + headCell.id}
            // align={headCell.numeric ? 'right' : 'left'}
            // padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            style={tableCellStyle}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={{
                  border: 0,
                  clip: 'rect(0 0 0 0)',
                  height: 1,
                  margin: -1,
                  overflow: 'hidden',
                  padding: 0,
                  position: 'absolute',
                  top: 20,
                  width: 1
                }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        })}
      </TableRow>
    </TableHead>
  );
}


function FilterPopover(props) {
  const { idPopover, openPopover, anchorEl, handleClosePopover, CompanyList,
    onFilterClick, tabValue, deviceFilterByBranch, deviceFilterByCompany, userFilterByCompany,
    userFilterByBranch, branchFilterByCompany, setPlantListForDeviceFilter, setPlantListForUserFilter,
    branchListForUserFilter, branchListForDeviceFilter
  } = props;

  const [selectedHospitalFilter, setSelectedHospitalFilter] = React.useState('all');
  const [selectedBranch, setselectedBranch] = useState("all");
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    if (tabValue == 1) {
      setSelectedHospitalFilter(branchFilterByCompany)
    } else if (tabValue == 2) {
      setItems(branchListForUserFilter);
      setSelectedHospitalFilter(userFilterByCompany)
      setselectedBranch(userFilterByBranch)
    } else if (tabValue == 3) {
      setItems(branchListForDeviceFilter);
      setSelectedHospitalFilter(deviceFilterByCompany)
      setselectedBranch(deviceFilterByBranch)

    }
  }, [])


  const handleOnChangeFilterHospital = (e) => {
    // console.log("Hospital onchage:", e.target.value);
    setSelectedHospitalFilter(e.target.value);
    if (tabValue == 2 || tabValue == 3) {
      renderPlantTableData(e.target.value)
    }
  }

  const handleOnChangeFilterBranch = (e) => {
    setselectedBranch(e.target.value)
  }

  const renderPlantTableData = async (v) => {
    setItems([]);
    let token = localStorage.getItem("abmUsertoken");
    if (v !== 'all') {
      const data = await fetchplantByCid(token, v);
      // console.log("data===== plant =============", data);
      if (data && data.data && data.data.length > 0) {
        setselectedBranch('all')
        setItems(data.data);
        if (tabValue == 2) {
          setPlantListForUserFilter(data.data)
        } else if (tabValue == 3) {
          setPlantListForDeviceFilter(data.data)
        }
      }
    } else {
      setselectedBranch('all')
      if (tabValue == 2) {
        setPlantListForUserFilter([])
      } else if (tabValue == 3) {
        setPlantListForDeviceFilter([])
      }
    }
  };

  const onClickFilter = () => {
    if (tabValue == 1) {
      onFilterClick('branch', selectedHospitalFilter);
    } else if (tabValue == 2) {
      onFilterClick('user', selectedHospitalFilter, selectedBranch);
    } else if (tabValue == 3) {
      onFilterClick('device', selectedHospitalFilter, selectedBranch);
    }
    // console.log("Hospital Filter clicked")
  }
  return (
    <Popover
      id={idPopover}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ width: '400px', padding: "20px 15px 15px" }}>
        <div className="row">

          <div className="col-5">  Hospital Name </div>
          <div className="col-7">
            <FormControl
              style={{ width: "100%" }}
            >
              <Select
                name="role"
                defaultValue={"user"}
                value={selectedHospitalFilter}
                onChange={handleOnChangeFilterHospital}
                style={{ backgroundColor: "white", textTransform: 'capitalize' }}
              >
                {[{ id: 'all', name: "All" }, ...CompanyList].map((item, value) => (
                  <MenuItem key={value} value={item.id} style={{ textTransform: 'capitalize' }}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {tabValue == 2 || tabValue == 3 ?
          <div className="row">
            <div className="col-5">  Branch Name </div>
            <div className="col-7">
              <FormControl
                style={{ width: "100%" }}
              >
                <Select
                  name="role"
                  defaultValue={"user"}
                  value={selectedBranch}
                  onChange={handleOnChangeFilterBranch}
                  style={{ backgroundColor: "white", textTransform: 'capitalize' }}
                >
                  {/* {(items.length === 0) ? <MenuItem >
                    Branch Not Available
                                        </MenuItem> : */}
                  {[{ id: 'all', name: "All" }, ...items].map((item, value) => (
                    <MenuItem key={value} value={item.id} style={{ textTransform: 'capitalize' }}>
                      {item.name}
                    </MenuItem>
                  ))}
                  {/* } */}
                </Select>
              </FormControl>
            </div>
          </div>
          : null}


        <div className="d-flex justify-content-end mt-3">
          {/* <button
          type="submit"
          className="btn"
        >
          Filter</button> */}
          {/* <Button variant="contained" size="small" onClick={onClickFilter}>Filter </Button> */}

          <Button
            variant="contained"
            color="primary"
            startIcon={<FilterListIcon />}
            style={{
              marginRight: "10px",
              padding: "5px 15px"
            }}
            onClick={onClickFilter}
          >Filter </Button>
          <Button size="small" onClick={handleClosePopover}>Cancel</Button>
        </div>

      </div>

    </Popover>

  )
}

function SearchPopover(props) {
  const { idPopover, openPopover, anchorEl, handleClosePopover,
    onSearchClick, tabValue, searchKeyHospital, searchKeyDevice, searchKeyUser, searchKeyBranch
  } = props;
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [searchedKey, setSearchedKey] = useState('')

  useEffect(() => {
    // console.log("IM INSIDE useEffect");
    setSearchedKey('');
    if (tabValue === 0) {
      setSearchPlaceholder("Search Hospital Name");
      setSearchedKey(searchKeyHospital);
    } else if (tabValue === 1) {
      setSearchPlaceholder("Search Branch Name");
      setSearchedKey(searchKeyBranch);
    } else if (tabValue === 2) {
      setSearchPlaceholder("Search User Full Name");
      setSearchedKey(searchKeyUser);
    } else if (tabValue === 3) {
      setSearchPlaceholder("Search Device");
      setSearchedKey(searchKeyDevice);
    }
  }, [])

  const onClickSearch = () => {
    if (tabValue == 0) {
      onSearchClick('hospital', searchedKey);
    } else if (tabValue == 1) {
      onSearchClick('branch', searchedKey);
    } else if (tabValue == 2) {
      onSearchClick('user', searchedKey);
    } else if (tabValue == 3) {
      onSearchClick('device', searchedKey);
    }
  }
  const onChangeSearchInput = (e) => {
    // console.log("onChangeSearchInput:",e.target.value)
    setSearchedKey(e.target.value)
  }
  return (
    <Popover
      id={idPopover}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ width: '400px', padding: "25px 15px 15px" }}>


        <div className="input-group mb-3" style={{
          border: "1px solid #888",
          background: "none",
          borderRadius: "4px"
        }} >
          <div className="input-group-prepend" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "5px",
          }}>
            <SearchIcon />
          </div>
          <input type="text" className="form-control" placeholder={searchPlaceholder}
            onChange={onChangeSearchInput} value={searchedKey}
            aria-label="search" aria-describedby="basic-addon1" style={{
              paddingLeft: "10px",
              background: "none",
              // textTransform:(tabValue == 0 || tabValue == 1)?'lowercase':'none'
            }} />
        </div>
        <div className="d-flex justify-content-end mt-3">

          {/* <Button variant="contained" size="small" onClick={onClickSearch}>Search </Button> */}

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            style={{
              marginRight: "10px",
              padding: "5px 15px"
            }}
            onClick={onClickSearch}
          >Search </Button>

          <Button size="small" onClick={handleClosePopover}>Cancel</Button>
        </div>

      </div>


    </Popover>)
}