import React, { Component } from "react";
//import { Line } from "react-chartjs-2";
import Graphsettings from "./Graphsettings";
import Popup from "reactjs-popup";
// import Popupscreen from "./Popupscreen";
import WatchListCurve from "./WatchListCurve";
// import GraphMeter from "./GraphMeter";
import "./App.css";
class Monitorscreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      setting: false,
      graph: false,
      //canvasState: {},
      xAxisValue: 32
    };
    this.connectSubscription = null;
    this.reConnectSubscription = null;
    this.closePopup = this.closePopup.bind(this);
  }


  closePopup() {
    this.setState({ graph: false });
    this.setState({ setting: false });
  }
  closeMonitor() {
    this.setState({ monitor: false });
  }
  setXAxisValue = (value) => {
    // console.log("setXAxisValue setting in MOnitorscreen:", value);
    this.setState({ xAxisValue: value })
  }
  testCode = () => {
    let ventiltr = {
      values: [0],
      inspPressure: function () {
        let data = [60, 0, 0, 120, 0, 55, 55, 15, 30, 45, 0, 0, 0, 0, 0, 0, 0, 0];
        let index = Math.floor(Math.random() * 18);
        // console.log("inspPressure", data[index]);
        return data[index];
        // return 0;
      },
      inspFlow: function () {
        let data = [0, 0, 0, 0, 0, 0, 0, 40, 80, 120, 160, -40, -80, -120, -160, 0, 0, 0, 0, 0, 0, 0];
        let index = Math.floor(Math.random() * 22);
        return data[index];
      },
      volume: function () {
        let data = [2000, 1000, 1000, 1500, 500, 40, 120, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let index = Math.floor(Math.random() * 16);
        // console.log("volume:", data[index]);
        // return 0; 
        return data[index];
      },
      manualInspTrig: function () {
        let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        let index = Math.floor(Math.random() * 33);
        if (data[index] === 1) {
          return true;
        } else
          return false;
      },
      inspTrigUser: () => {
        return '1';
      },
    };
    this.interval = setInterval(() => {
      this.setState({
        canvasState: { ...ventiltr },
      });
    }, 20);
  };
  clearTestCode = () => {
    clearInterval(this.interval);
  };
  sailsCurveMonitoringHandler = (json) => {
    // console.log("this.props:", this);
    if (json && "IP" in json && "FL" in json && "VL" in json && 'ITF' in json && 'ITR' in json) {
      this.setState({
        canvasState: {
          inspPressure: function () {
            return json.IP;
          },
          inspFlow: function () {
            return json.FL;
          },
          volume: function () {
            return json.VL;
          },
          manualInspTrig: function () {
            return json.ITF === "1";
          },
          inspTrigUser: () => {
            return json.ITR
          },
        },
      });
    }
  };

  //Production method
  // componentDidMount() {
  //   if (process.env.NODE_ENV !== "production") {
  //     // this.testCode();
  //       console.log("--------------WINDOW.SOCKET",window.socket)
  //     window.socket.on(
  //       `ION/${this.props.deviceId}/CURVEMONITORING`,
  //       this.sailsCurveMonitoringHandler
  //     );
  //   } else {

  //     window.socket.on(
  //       `ION/${this.props.deviceId}/CURVEMONITORING`,
  //       this.sailsCurveMonitoringHandler
  //     );
  //   }
  // }


  // componentWillUnmount() {
  //   //this.props.socket
  //   window.socket.off(
  //     `ION/${this.props.deviceId}/CURVEMONITORING`,
  //     this.sailsCurveMonitoringHandler
  //   );
  //   if (process.env.NODE_ENV !== "production") {
  //     this.clearTestCode();
  //   }
  // }

  render() {
    const { graph, canvasState } = this.state;
    // const {closeMonitorscreeenPopup} =this.props;
    //  console.log("mainMonitoringValues:",this.props.mainMonitoringValues);
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-10 col-md-10 col-sm-12 col-12"
            style={{ padding: "10px 0px 0px 0px" }}
          >
            <div style={{
              height: "100%",
              width: "100%",
              border: "0px solid red",
              minHeight: '350px'
            }}
            >
              <WatchListCurve
                deviceId={this.props.deviceId}
                xAxisValue={this.state.xAxisValue}
              />
            </div>
          </div>

          <div className="col-lg-2 col-md-2 col-sm-12 col-12"
            style={{ paddingRight: "0px", paddingLeft: "0px" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <button className="button-3">
                <span className="value-header">Peak P.</span>
                <br />
                <span className="value">
                  {this.props.mainMonitoringValues.PP}
                </span>
                <br />
                <span className="values-unit">cmH2O</span>
              </button>
              <button className="button-3">
                <span className="value-header">Breath Rate</span>
                <br />
                <span className="value">
                  {Math.round(Number(this.props.mainMonitoringValues.BR))}
                </span>
                <br />
                <span className="values-unit">per min</span>
              </button>
              <button className="button-3">
                <span className="value-header">TV(i/e)</span>
                <br />
                <span className="value">
                  {this.props.mainMonitoringValues.TV}
                </span>
                <br />
                <span className="values-unit">mL</span>
              </button>
              <div style={{
                display: "flex",
                width: "100%",
                border: "0px solid pink",
                flexDirection: "row",
              }}
              >
                <button className="button-4" style={{ padding: "0px" }}>
                  <span className="value-header2">MV</span>
                  <br />
                  <span className="value2">
                    {this.props.mainMonitoringValues.MV}
                  </span>
                  <br />
                  <span className="values-unit2">L</span>
                </button>
                <button className="button-4" style={{ paddingLeft: "0px" }}>
                  <span className="value-header2">Insp.T</span>
                  <br />
                  <span className="value2">
                    {this.props.mainMonitoringValues.IT / 10}
                  </span>
                  <br />
                  <span className="values-unit2">sec</span>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  border: "0px solid pink",
                  flexDirection: "row",
                }}
              >
                <button className="button-4" style={{ padding: "0px" }}>
                  <span className="value-header2">I:E</span>
                  <br />
                  <span className="value2">
                    {this.props.mainMonitoringValues.IER
                      ? this.props.mainMonitoringValues.IER
                      : "1:1"}
                  </span>
                  <br />
                  <span />
                  <br />
                </button>
                <button className="button-4" style={{ paddingLeft: "0px" }}>
                  <span className="value-header2">Spont BR</span>
                  <br />
                  <span className="value2">
                    {this.props.mainMonitoringValues.SBR}
                  </span>
                  <br />
                  <span className="values-unit2">%</span>
                </button>

              </div>
              <div className="d-flex justify-content-center">
                <div style={{ color: "yellow", fontWeight: 600 }}>
                  <span style={{ color: 'white' }}> Mode : </span>  {this.props.mood}
                </div>
              </div>

              <div
                className="row responsiveTopMargin desktop-screen"
                style={{
                  alignItems: "center",
                  border: "0px solid green",
                  justifyContent: "center",
                }}
              >
                <button
                  className="settings"
                  onClick={this.props.openSettingPopup}
                  style={{}}
                >
                  <span style={{ color: "white", fontSize: "18px" }}>
                    Ventilator Settings
                  </span>
                </button>
                <button
                  className="settings"
                  onClick={() => {
                    // console.log("IM CLIKED GRAPHSETTINGS")
                    this.setState({ graph: true })
                  }}
                  style={{}}
                >
                  <span style={{ color: "white", fontSize: "18px" }}>
                    Graph Settings
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="hh d-flex justify-content-center align-items-center">
          <Popup
            contentStyle={{
              backgroundColor: "black",
              opacity: "0.9",
              height: "350px",
              width: "400px",
              paddingTop: "2px",
              // marginLeft: "15px",
            }}
            open={graph}
            closeOnDocumentClick={false}
          >
            <Graphsettings
              closePopup={this.closePopup}
              setXAxisValue={this.setXAxisValue}
              xAxisValue={this.state.xAxisValue}
            />
          </Popup>
        </div>
      </React.Fragment>
    );
  }

}

export default Monitorscreen;
