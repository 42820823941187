import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";

export default ({ children, tootltip, placement, onClick }) => {
  return (
    <Tooltip placement={placement} title={tootltip}>
      {children}
    </Tooltip>
  );
};
