import React from "react";
import { Link } from "react-router-dom";
export default () => (
  <footer className="footer">
    <div className="container-fluid">
      <nav className="float-left">
        <ul>
          <li>
            <Link to="#">ABM Team</Link>
          </li>
          <li>
            <Link to="#">About Us</Link>
          </li>
          <li>
            <Link to="#">Blog</Link>
          </li>
          <li>
            <Link to="#">Licenses</Link>
          </li>
        </ul>
      </nav>
      <div className="copyright float-right">
        &copy;
        {new Date().getFullYear()}{" "}
        <a href="https://www.abmrespiratorycare.com/" target="_blank">
           Advanced Bio Machines Pte Ltd
        </a>
      </div>
    </div>
  </footer>
);
