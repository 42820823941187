import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import { COMPANY_CREATE } from "../../../api-route";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import "../App.css";
// import GMap from "../GoogleMapContainer";
import InputField from "./validation/editfrom";
import MuiAlert from "@material-ui/lab/Alert";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function AddNewCompanyDialog(props) {
  const { open, setClose, onChangedcompany, onChangePlant, onChangeUser } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [phoneWithoutCode, setPhoneWithoutCode] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");


  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({ vertical: "top", horizontal: "center" });
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const [values, setValues] = React.useState({});
  const [selected, setSelected] = useState(5);
  const [sessionExpired, setSessionExpired] = React.useState(false);
  let password = values.password;
  const [state, setState] = React.useState({
    checkedAutoGenPass: true,
  });

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const handleInputChange = (name, value) => {
    setValues({ ...values, [name]: value, });
  };

  const submitForm = () => {
    // e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (phoneWithoutCode.length == 0) {
      setPhoneError("Phone number is required")
      isValid = false
    }
    if (!isValid) {
      return;
    }
    // e.preventDefault();
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    console.log("Hospital Details:", values, "MaxUsers", values.maxusers ? values.maxusers : selected)
    let userdata = {
      fName: values.fName,
      lName: values.lName ? values.lName : "",
      password: values.password ? values.password : null,
      autoGenPass: state.checkedAutoGenPass
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        name: values.name,
        email: values.email,
        phone: phone,
        userId: _user.email,
        branch: values.branch,
        maxusers: values.maxusers ? values.maxusers : selected,
        user: userdata
      }),
    };


    fetch(`${COMPANY_CREATE}`, requestOptions)
      .then((res) => {
        console.log("Response:", res)
        if (res.status === 401) {
          setSessionExpired(true);
          return;
        }
        if (res.status === 409) {
          setSnackMsg("Email or name is already in use")
          setSnackSeverity("warning")
        } else if (res.status === 400) {
          setSnackMsg("Something bad with input")
          setSnackSeverity("error")
        } else if (res.status === 200 || res.status === 201) {
          setSnackMsg("Hospital added successfully")
          setSnackSeverity("success")
          onChangedcompany("reset-filter");
          onChangePlant(true);
          onChangeUser(true)
          handleClose();
        } else {
          setSnackMsg("Encountered some issues, Try again later")
          setSnackSeverity("error")
        }
        setOpenSnack(true)
      })
      .catch((err) => { });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setClose(false);
  };
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const handleChangeDropdown = (e) => {
    if (e.target.value) {
      setSelected(e.target.value);
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "1rem" }}
        >
          ADD HOSPITAL

          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>

            <AppBar position="static">
              <Tabs
                TabIndicatorProps={{ style: { background: "gray" } }}
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="add-new-vent-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form noValidate>
                            {/* <input name="email" style={{ display: 'none' }} /> */}
                            {/* <input name="password"
                              type="password" style={{ display: 'none' }} /> */}
                            <fieldset className="border border-secondary p-3" style={{ marginBottom: '20px' }}>
                              <legend className="w-auto add-hospital-legend">Hospital Details</legend>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">Hospital Name</label>
                                    <InputField
                                      ref={inputRefs.current[0]}
                                      label="Hospital Name"
                                      name="name"
                                      onChange={handleInputChange}
                                      validation={"required|userpattern"}
                                      className="form-control"
                                    />
                                  </div>

                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      Branch Name
                                  </label>
                                    <InputField
                                      ref={inputRefs.current[1]}
                                      label="Branch"
                                      name="branch"
                                      onChange={handleInputChange}
                                      validation={"required|userpattern"}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                              </div>


                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">

                                      Maximum Number of Users
                                  </label>
                                    <FormControl
                                      style={{ width: "100%" }}
                                    >
                                      <Select
                                        // value={Device}
                                        // onChange={}
                                        name="maxusers"
                                        value={selected}
                                        onChange={handleChangeDropdown}
                                        style={{ backgroundColor: "white" }}
                                      >
                                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, value) => (
                                          <MenuItem key={value} value={item}>
                                            {item}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                </div>
                              </div>
                            </fieldset>

                            {/* <div className="separator">Hospital Admin User Details</div> */}
                            <fieldset className="border border-secondary p-3" style={{}}>
                              <legend className="w-auto add-hospital-legend">Hospital Admin User Details</legend>
                              <div className="row">

                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      User Email
                                  </label>
                                    <InputField
                                      ref={inputRefs.current[2]}
                                      label="Email"
                                      name="email"
                                      onChange={handleInputChange}
                                      validation={"required|emailpattern"}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      Phone number
                                     </label>
                                    {/* <InputField
                                      ref={inputRefs.current[3]}
                                      label="Phone number"
                                      name="phone"
                                      onChange={handleInputChange}
                                      validation={"required|onlynumber"}
                                      className="form-control"
                                    /> */}
                                    <PhoneInput
                                      enableSearch
                                      country={'in'}
                                      value={phone}
                                      onChange={(value, data, event, formattedValue) => {
                                        // console.log('formattedValue:', formattedValue);
                                        setPhoneError('');
                                        setPhone(formattedValue);
                                        setPhoneWithoutCode(value.slice(data.dialCode.length));
                                      }
                                      }
                                      inputProps={{
                                        name: 'phone',
                                        required: true,
                                      }}
                                    />
                                    {phoneError && (
                                      <p style={{ color: "red", fontSize: "12px", textAlign: "left" }}>
                                        {phoneError}
                                      </p>
                                    )}
                                  </div>

                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      First Name
                                     </label>
                                    <InputField
                                      ref={inputRefs.current[4]}
                                      label="First Name"
                                      name="fName"
                                      onChange={handleInputChange}
                                      validation={"required|userpattern"}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      Last Name
                                  </label>
                                    <InputField
                                      ref={inputRefs.current[5]}
                                      label="Last Name"
                                      name="lName"
                                      onChange={handleInputChange}
                                      //validation={"required|userpattern"}
                                      className="form-control"
                                    />
                                  </div>
                                </div>

                              </div>
                              <div className="row" style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <div className="col-md-12">
                                  <FormGroup row>
                                    <FormControlLabel
                                      control={<Checkbox checked={state.checkedAutoGenPass} onChange={handleChangeCheckbox} name="checkedAutoGenPass" />}
                                      label="Auto generate random password"
                                    />
                                  </FormGroup>
                                </div>
                              </div>


                              {state.checkedAutoGenPass ? null :
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="bmd-label-floating"> Password</label>
                                      <InputField
                                        ref={inputRefs.current[6]}
                                        label="Password"
                                        name="password"
                                        type="password"
                                        //  placeholder="Enter password"
                                        validation="required|min:6"
                                        onChange={handleInputChange}
                                        className="form-control"
                                        disabled={state.checkedAutoGenPass}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label className="bmd-label-floating">Confirm password</label>
                                      <InputField
                                        ref={inputRefs.current[7]}
                                        label="Confirm password"
                                        name="conf_password"
                                        type="password"
                                        validation="passwordmatch"
                                        //   placeholder="Confirm Password"
                                        onChange={handleInputChange}
                                        password={password}
                                        className="form-control"
                                        disabled={state.checkedAutoGenPass}
                                      />
                                    </div>
                                  </div>
                                </div>
                              }
                            </fieldset>
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: '60px' }}>
          <button type="button" className="btn" onClick={submitForm} >
            Submit
            </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}