import React, { useState, useEffect, Component } from "react";
import QrReader from "react-qr-reader";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import MapContainer from "../GoogleMapContainer";
import InputField from "./validation/editfrom";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import { fetchplantByCid } from "../../../services/http";
import "../App.css";
import { DEVICE_EDIT, GET_DEVICEINFO } from "../../../api-route";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function VentDetailsDialog(props) {
  const { open, setClose, currentVent, onChanged, fromAdmin } = props;
  // console.log("currentVent", currentVent)
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const [result, setResult] = useState("No result");
  //Form Validation
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [data, setData] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [selected1, setselected1] = useState("");
  const [hasError1, sethasError1] = useState(false);
  const [sessionExpired, setSessionExpired] = React.useState(false);
  const [deviceInfo, setDeviceInfo] = React.useState({});

  const handleInputChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangedrop1 = (name, value) => {
    setselected1(value);
    setData((prev) => ({ ...prev, [name]: value }));
    sethasError1(false);
  };

  useEffect(() => {
    if (currentVent.companyId) {
      renderPlantTableData(currentVent.companyId);
      fetchDeviceInfo();
    }
  }, [currentVent]);
  const renderPlantTableData = async (companyid) => {
    let token = localStorage.getItem("abmUsertoken");
    const data = await fetchplantByCid(token, companyid);

    if (data && data.data && data.data.length > 0) {
      setItems(data.data);
    }
  };

  const editDevice = async () => {
    if (
      "bedId" in data ||
      "roomNo" in data ||
      "company_id" in data ||
      "plant_id" in data
    ) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          deviceid: currentVent.id,
          edited: data,
        }),
      };

      const res = await fetch(`${DEVICE_EDIT}`, requestOptions);
      // const msg = await res.text();
      if (res.status === 401) {
        setSessionExpired(true);
        return;
      }
      if (res.status === 200) {
        setSnackMsg("Device edited successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        handleClose();
        onChanged(true);
      } else if (res.status === 400) {
        setSnackMsg("Required parameter(s) are missing");
        setSnackSeverity("error");
        setOpenSnack(true);
      } else {
        setSnackMsg("Encountered some issues, Try again later");
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      setSnackMsg("Nothing edited");
      setSnackSeverity("info");
      setOpenSnack(true);
    }
  };

  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }

    if (!isValid) {
      return;
    }
    sethasError1(false);
    if (!selected1 && !currentVent.plantId) {
      sethasError1(true);
      return;
    }

    editDevice();
  };
  const handleScan = (data) => {
    //data.preventDefault();
    if (data) {
      // console.log("data", data);

      setResult(data);
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const downloadQR = () => {
    var can = document.getElementById("123456");
    // var src = can.toDataURL("image/png");6
    html2canvas(can).then(function (canvas) {
      var link = document.createElement("a");
      document.body.appendChild(link);
      link.download = currentVent.name + "QR.png";
      link.href = canvas.toDataURL("image/png");
      link.target = "_blank";
      link.click();
    });
  };

  const syncDeviceInfo = () => {
    console.log("syncDeviceInfo:", currentVent.name)
    window.mqtt.publish(
      `ION/${currentVent.name}/SINITSYNC`,
      "SYNCVENTSETTINGS"
    );
    setTimeout(() => fetchDeviceInfo(), 3000)
  }

  const fetchDeviceInfo = () => {
    // console.log("fetchDeviceInfo invoked++++++++++++++++++")
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    fetch(`${GET_DEVICEINFO}/${currentVent.name}`, requestOptions)
      .then((res) => res.json())
      .then((data) => {
        // console.log("DeviceInfo", data)
        setDeviceInfo(data);
      })
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setData({});
    setClose(false);
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "18px" }}
        >
          DEVICE
          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                TabIndicatorProps={{ style: { background: "gray" } }}
                value={value}
                onChange={handleChange}
              >
                <Tab label="Details" {...a11yProps(0)} />
                {fromAdmin ? null : <Tab label="Edit" {...a11yProps(1)} />}
                <Tab label="Version Info" {...a11yProps(3)} />
                <Tab label="Generate QR Code" {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Device
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={currentVent.name}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Bed ID
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // value={currentVent.bedId}
                                    value={currentVent.alarm && currentVent.alarm.length && currentVent.alarm[0].BedID ? currentVent.alarm[0].BedID : ''}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Room No
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // value={currentVent.roomNo}
                                    value={currentVent.alarm && currentVent.alarm.length && currentVent.alarm[0].RoomID ? currentVent.alarm[0].RoomID : ''}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Hospital Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentVent.companyName}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Branch Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentVent.plantName}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Created At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentVent.createdAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Updated At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentVent.updatedAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            {fromAdmin ? null :
              <TabPanel value={value} index={1}>
                <div className="content">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="vent-details-dialog card card-plain">
                          <div className="card-body genrl-tab">
                            <form onSubmit={submitForm} noValidate>
                              {/* <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Bed Id
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[0]}
                                    name="bedId"
                                    label="Bed Id"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control"
                                    defaultValue={currentVent.bedId}
                                  />
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Room No
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[1]}
                                    label="Room No"
                                    name="roomNo"
                                    onChange={handleInputChange}
                                    validation={"required"}
                                    className="form-control"
                                    defaultValue={currentVent.roomNo}
                                  />
                                </div>
                              </div>
                            </div> */}
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      Hospital Name
                                    </label>
                                    <InputField
                                      disabled={true}
                                      ref={inputRefs.current[2]}
                                      label="Hospital"
                                      name="company_id"
                                      onChange={handleInputChange}
                                      validation={"required|userpattern"}
                                      className="form-control capitalize"
                                      defaultValue={currentVent.companyName}
                                    />
                                    {/* <input type="text" className="form-control" defaultValue={currentVent.company_id} /> */}
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  {/* <div className="form-group">
                                  <label className="bmd-label-floating">Plant Id</label>
                                  <InputField
                                       ref={inputRefs.current[3]}
                                       label="Plant Id"
                                          name="plantId"
                                          onChange={handleInputChange}
                                          validation={"required|userpattern"}
                                          className ="form-control"
                                          defaultValue={currentVent.plantName}
                                        />
                                </div>
                              */}
                                  <div className="form-group">
                                    <label className="bmd-label-floating">
                                      {" "}
                                      Branch Name{" "}
                                    </label>
                                    <FormControl style={{ width: "100%" }}>
                                      <Select
                                        // value={Device}
                                        // onChange={}
                                        name="plant_id"
                                        defaultValue={currentVent.plantId}
                                        onChange={(event) =>
                                          handleChangedrop1(
                                            event.target.name,
                                            event.target.value
                                          )
                                        }
                                        style={{ backgroundColor: "white", textTransform: 'capitalize' }}
                                      >
                                        {items.map((item, value) => (
                                          <MenuItem key={value} value={item.id} className="capitalize">
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      {hasError1 && (
                                        <p
                                          style={{
                                            color: "red",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {" "}
                                          Branch must be selected!
                                        </p>
                                      )}
                                    </FormControl>
                                  </div>
                                </div>
                              </div>

                              <button type="submit" className="btn"
                                style={{ float: 'right', margin: '30px 0px -15px 0px' }}>
                                save{" "}
                              </button>
                              <div className="clearfix"></div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TabPanel>
            }
            <TabPanel value={value} index={fromAdmin ? 2 : 3}>
              <div className="content">
                <div className="container-fluid">
                  <div className="vent-details-dialog card card-plain">
                    <div className="card-body genrl-tab">
                      <div className="row" style={{ minHeight: "250px" }}>
                        {/* <Map /> */}
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <div className="HpQrcode">
                            <QRCode
                              id="123456"
                              value={
                                "deviceId=" +
                                currentVent.name +
                                "&plant_id=" +
                                currentVent.plantId +
                                "&company_id=" +
                                currentVent.companyId
                              }
                              size={300}
                              level={"L"}
                              margin={7}
                              //scale={15}
                              // color={{
                              //   dark: "#010599FF",
                              //   light: "#FFBF60FF",
                              // }}
                              fgColor={"#010599FF"}
                              bgColor={"#FFBF60FF"}
                              includeMargin={true}
                              renderAs={"svg"}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                    <div className="row">
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        {" "}
                        <div className="d-flex justify-content-center">
                          <button onClick={downloadQR} className="btn">
                            Download QR </button>
                        </div>
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={fromAdmin ? 1 : 2}>
              <div className="content">
                <div className="container-fluid">
                  <div className="vent-details-dialog card card-plain">
                    <div className="card-body genrl-tab">

                      <div className="row" >
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="bmd-label-floating">
                              Firmware version MCB / Stepper
                            </label>
                            <input
                              type="text"
                              className="form-control capitalize"
                              value={deviceInfo.FwVersion ? deviceInfo.FwVersion : ''}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="bmd-label-floating">
                              Software Version
                            </label>
                            <input
                              type="text"
                              className="form-control capitalize"
                              value={deviceInfo.UiVersion ? deviceInfo.UiVersion : ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="bmd-label-floating">
                              Hour Meter Reading
                            </label>
                            <input
                              type="text"
                              className="form-control capitalize"
                              value={deviceInfo.HMR ? deviceInfo.HMR : ''}
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="bmd-label-floating">
                              Last Maintenance Date
                            </label>
                            <input
                              type="text"
                              className="form-control capitalize"
                              value={deviceInfo.LMDate ? deviceInfo.LMDate : ''}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="col-md-4">
                      {/* {" "} */}
                      <div className="d-flex">
                        <Tooltip title="Sync version info from device" placement="top">
                          <button onClick={syncDeviceInfo} className="btn">
                            Refresh </button>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}

function Map() {
  if (navigator.onLine) {
    return <MapContainer></MapContainer>;
  } else {
    return <div>Oops.. No Internet connection</div>;
  }
}
