import React, { PureComponent, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import UserDetailsDialog from "./dialog/UserDetailsDialogForAdmin";
import AddBoxIcon from "@material-ui/icons/AddBox";
import DeleteIcon from "@material-ui/icons/Delete";
import AddNewUser from "./dialog/AddNewUsersFromAdmin";
import { USER_DEL, TOTAL_COUNTFORADMIN } from "../../api-route";
import "./App.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { fetchuserByCid, fetchplantByC_id, fetchmachine } from "../../services/http";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import SessionExpiryDialog from "./dialog/sessionExpiryDialog";
import { CustomsSnackbar } from "../snakbar/snackbar";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import FilterListIcon from '@material-ui/icons/FilterList';
import Popover from '@material-ui/core/Popover';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import SearchIcon from '@material-ui/icons/Search';
import Badge from '@material-ui/core/Badge';

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import VentDetailsDialog from "./dialog/VentDetailsDialog";

class AdminConsole extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentUser: {},
      openAddNewUserDialog: false,
      openUserDetailsDialog: false,
      openDeleteDialog: false,
      delUser: null,
      Users: [],
      tabValue: 0,

      limit: 10,
      totalUser: 0,
      pageUser: 0,
      userOffset: 0,
      sessionExpired: false,
      currentUserFrDel: "",

      userOrderBy: 'createdAt',
      deviceOrderBy: 'createdAt',
      order: 'desc',
      anchorEl: null,

      userFilterByBranch: 'all',
      branchListForUserFilter: [],
      branchListForDeviceFilter: [],

      searchAnchorEl: null,
      searchKeyUser: '',
      branchList: [],

      openDialog: false,
      Devices: [],
      currentVent: {},

      pageDevice: 0,
      deviceOffset: 0,
      totalDevice: 0,

      deviceFilterByBranch: 'all',
      searchKeyDevice: '',
    };
  }

  handleChangePage = (event, newPage, offset) => {
    if (this.state.tabValue === 1) {
      this.setState({ deviceOffset: offset });
      this.fetchMachine(offset);
      this.setState({ pageDevice: newPage });
    } else if (this.state.tabValue === 0) {
      this.setState({ userOffset: offset });
      this.fetchUsersForAdmin(offset);
      this.setState({ pageUser: newPage });
    }
  };

  handleChangeUser = (e) => {
    if (e) {
      if (e === 'reset-filter') {
        this.setState({ userOrderBy: 'createdAt', order: 'desc', })
      }
      this.fetchUsersForAdmin(this.state.userOffset);
      this.getTotalcountForAdmin();
    }
  };

  openUserDetailsDialog = (user) => {
    this.setState({ currentUser: user, openUserDetailsDialog: true });
  };

  closeUserDetailsDialog = () => {
    this.setState({ openUserDetailsDialog: false });
  };

  openAddNewUserDialog = () => {
    this.setState({ openAddNewUserDialog: true });
  };

  closeAddNewUserDialog = () => {
    this.setState({ openAddNewUserDialog: false });
  };

  openDeleteDialog = () => {
    this.setState({ openDeleteDialog: true });
  };

  closeDeleteDialog = () => {
    this.setState({ openDeleteDialog: false });
  };

  componentDidMount() {
    this.fetchUsersForAdmin(0);
    this.getTotalcountForAdmin();
    this.renderPlantTableData();
    this.fetchMachine(0);
  }
  openVentDialog = (vent) => {
    this.setState({ currentVent: vent });
    this.setState({ openDialog: true });
  };
  fetchMachine = async (offsetArg) => {
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    // let cid = _user.company_id;
    let limit = this.state.limit ? this.state.limit : 10;
    let offset = offsetArg ? offsetArg : 0;
    let deviceFilterByCompany = _user.company_id;
    // let deviceFilterByCompany = this.state.deviceFilterByCompany &&
    //   this.state.deviceFilterByCompany !== 'all' ? this.state.deviceFilterByCompany : ''

    let deviceFilterByBranch = this.state.deviceFilterByBranch &&
      this.state.deviceFilterByBranch !== 'all' ? this.state.deviceFilterByBranch : ''

    let serachKey = this.state.searchKeyDevice ? this.state.searchKeyDevice : '' //Device Id

    const res = await fetchmachine({ token, limit, offset, deviceFilterByCompany, deviceFilterByBranch, serachKey });
    if (res.status === 401) {
      this.setState({ sessionExpired: true });
      return;
    }
    let data = await res.json();
    if (data && data.length > 0) {
      // console.log("Devices data in admin console:", data)
      this.setState({ Devices: data });
    } else {
      this.setState({ Devices: [] });
    }
    // console.log("xsujan Machine in FETCH:", this.state.Devices);
  };

  fetchUsersForAdmin = async (offsetArg) => {
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    let cid = _user.company_id;
    let limit = this.state.limit ? this.state.limit : 10;
    let offset = offsetArg ? offsetArg : 0;
    let userFilterByBranch = this.state.userFilterByBranch &&
      this.state.userFilterByBranch !== 'all' ? this.state.userFilterByBranch : ''

    let searchKey = this.state.searchKeyUser ? this.state.searchKeyUser : '' //User Name

    const res = await fetchuserByCid({ token, cid, limit, offset, userFilterByBranch, searchKey });
    if (res.status === 401) {
      this.setState({ sessionExpired: true });
    }
    const response = await res.json();
    // console.log("data===== =============", response.data);

    if (response && response.data.length > 0) {
      this.setState({ Users: response.data });
    } else {
      this.setState({ Users: [] });
    }
  };
  getTotalcountForAdmin = () => {
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    let cid = _user.company_id;
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    fetch(`${TOTAL_COUNTFORADMIN}?company_id=${cid}`, requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (result) {
          this.setState({
            totalUser: result.TotUser ? result.TotUser : 0,
            totalDevice: result.totDevice ? result.totDevice : 0,
          });
        }
      })

  }

  handleClickPopover = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  };

  handleClosePopover = () => {
    this.setState({ anchorEl: null })
  };

  handleClickSearchPopover = (event) => {
    this.setState({ searchAnchorEl: event.currentTarget })
  };

  handleCloseSearchPopover = () => {
    this.setState({ searchAnchorEl: null })
  };

  onFilterClick = (currentType, branchid) => {
    // this.setState({ userFilterByBranch: branchid ? branchid : '' },
    //   () => this.fetchUsersForAdmin(0));
    if (currentType === 'device') {
      this.setState({ deviceFilterByBranch: branchid ? branchid : '' },
        () => this.fetchMachine(0));
    }
    if (currentType === 'user') {
      this.setState({ userFilterByBranch: branchid ? branchid : '' },
        () => this.fetchUsersForAdmin(0));
    }
    this.handleClosePopover();
  }

  onSearchClick = (currentType, serachKey) => {
    if (currentType === 'user') {
      this.setState({ searchKeyUser: serachKey }, () => this.fetchUsersForAdmin(0));
    }
    if (currentType === 'device') {
      this.setState({ searchKeyDevice: serachKey }, () => this.fetchMachine(0));
    }
    this.handleCloseSearchPopover();
  }
  renderPlantTableData = async () => {
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    const data = await fetchplantByC_id(token, _user.company_id);
    if (data && data.data && data.data.length > 0) {
      // console.log("data.data::::", data.data)
      this.setState({ branchList: data.data })
    }
  }
  handleUserRequestSort = (event, property) => {
    const isAsc = this.state.userOrderBy === property && this.state.order === 'asc';
    this.setState({ userOrderBy: property, order: isAsc ? 'desc' : 'asc' });
  };
  handleDeviceRequestSort = (event, property) => {
    const isAsc = this.state.deviceOrderBy === property && this.state.order === 'asc';
    this.setState({ deviceOrderBy: property, order: isAsc ? 'desc' : 'asc' });
  };

  descendingComparator(a, b, orderBy, currentType) {
    // if (currentType === 'branch') {
    //   console.log("OrderBy:", orderBy, " b :", b, " a :", a)
    // }
    if ((currentType === 'user')
      && 'company_id' in a && 'company_id' in b && a.company_id && b.company_id && 'name' in a.company_id && 'name' in b.company_id) {
      a = { ...a, 'company_id': a.company_id.name }
      b = { ...b, 'company_id': b.company_id.name }
    }
    if ((currentType === 'user') && 'plant_id' in a && 'plant_id' in b
      && 'plant_id' in a && 'plant_id' in b && a.plant_id && b.plant_id && 'name' in a.plant_id && 'name' in b.plant_id
    ) {
      a = { ...a, 'plant_id': a.plant_id.name }
      b = { ...b, 'plant_id': b.plant_id.name }
    }

    if (orderBy && b[orderBy] && a[orderBy]) {
      if ((typeof b[orderBy] === 'string' && typeof a[orderBy] === 'string') || (
        b[orderBy] instanceof String && a[orderBy] instanceof String)) {
        if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
          return -1;
        }
        if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
          return 1;
        }
      } else {
        if (b[orderBy] < a[orderBy]) {
          return -1;
        }
        if (b[orderBy] > a[orderBy]) {
          return 1;
        }
      }
    }
    return 0;
  }

  getComparator(order, orderBy, currentType) {
    return order === 'desc'
      ? (a, b) => this.descendingComparator(a, b, orderBy, currentType)
      : (a, b) => -this.descendingComparator(a, b, orderBy, currentType);
  }

  stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
  }
  handleTabChange = (event, newValue) => {
    this.setState({ tabValue: newValue });
  };
  closeVentDialog = () => {
    this.setState({ openDialog: false });
  };
  render() {
    this.openPopover = Boolean(this.state.anchorEl);
    this.idPopover = this.openPopover ? 'simple-popover' : undefined;
    this.openSearchPopover = Boolean(this.state.searchAnchorEl);
    this.idSearchPopover = this.openSearchPopover ? 'simple-search-popover' : undefined;
    function createdAtDateFormat(params) {
      if (params) {
        var timestamp = params
        var crdate = new Date(timestamp);
        var crhours = crdate.getHours();
        var crminutes = crdate.getMinutes();
        var crampm = crhours >= 12 ? 'pm' : 'am';
        crhours = crhours % 12;
        crhours = crhours ? crhours : 12; // the hour '0' should be '12'
        crminutes = ('0' + crminutes).slice(-2);
        var strTime = crhours + ':' + crminutes + ' ' + crampm;
        return crdate.getDate() +
          "/" + (crdate.getMonth() + 1) +
          "/" + crdate.getFullYear() +
          " " + strTime
      } else return ''
    }
    return (
      <div className="content">
        {this.idPopover ?
          <FilterPopover
            openPopover={this.openPopover}
            idPopover={this.idPopover}
            tabValue={this.state.tabValue}
            CompanyList={this.state.Users.company_id}
            handleClosePopover={this.handleClosePopover}
            onFilterClick={this.onFilterClick}
            anchorEl={this.state.anchorEl}
            userFilterByBranch={this.state.userFilterByBranch}
            deviceFilterByBranch={this.state.deviceFilterByBranch}
            branchList={this.state.branchList}
          />
          : null}

        {this.idSearchPopover ?
          <SearchPopover
            openPopover={this.openSearchPopover}
            idPopover={this.idSearchPopover}
            CompanyList={this.state.CompanyList}
            tabValue={this.state.tabValue}
            handleClosePopover={this.handleCloseSearchPopover}
            onSearchClick={this.onSearchClick}
            anchorEl={this.state.searchAnchorEl}
            searchKeyUser={this.state.searchKeyUser}
            searchKeyDevice={this.state.searchKeyDevice}
          />
          : null}

        <SessionExpiryDialog open={this.state.sessionExpired}>
        </SessionExpiryDialog>
        <AddNewUser
          onChange={this.handleChangeUser}
          open={this.state.openAddNewUserDialog}
          setClose={this.closeAddNewUserDialog}
        ></AddNewUser>
        <UserDetailsDialog
          onChange={this.handleChangeUser}
          currentUser={this.state.currentUser}
          open={this.state.openUserDetailsDialog}
          setClose={this.closeUserDetailsDialog}
        ></UserDetailsDialog>

        <DeleteDialog
          onChange={this.handleChangeUser}
          open={this.state.openDeleteDialog}
          setClose={this.closeDeleteDialog}
          delUser={this.state.delUser}
          currentUserFrDel={this.state.currentUserFrDel}
        ></DeleteDialog>

        <VentDetailsDialog
          // onChanged={this.handleChangeVentDetails}
          currentVent={this.state.currentVent}
          open={this.state.openDialog}
          setClose={this.closeVentDialog}
          fromAdmin={true}
        ></VentDetailsDialog>

        <div className="container-fluid " style={{ marginTop: '64px', padding: '0px' }}>
          <AppBar position="static">
            <Tabs
              TabIndicatorProps={{ style: { background: "gray" } }}
              value={this.state.tabValue}
              onChange={this.handleTabChange}
            >
              {/* <Tab label="Hospital" {...a11yProps(0)} />
              <Tab label="Branch" {...a11yProps(1)} /> */}
              <Tab label="User" {...a11yProps(0)} />
              <Tab label="Device" {...a11yProps(1)} />
            </Tabs>
          </AppBar>
          {/* </div> */}
          <TabPanel value={this.state.tabValue} index={0}>
            {/* <div className="row"> */}

            {/* <div className="container-fluid "> */}
            <div className="row ">
              <div className="col-md-12">
                <div className="card" style={{ margin: "0px" }}>
                  <div className="card-body" style={{ paddingTop: "5px" }}>
                    <div style={{ float: "right", }}>

                      <div style={{ display: 'inline-block', margin: '5px 7px', }}>
                        <Badge color="secondary" badgeContent=" " variant="dot" invisible={!this.state.searchKeyUser}>
                          <SearchIcon
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickSearchPopover}
                          />
                        </Badge>
                      </div>
                      <div style={{ display: 'inline-block', margin: '5px 15px 5px 2px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={this.state.userFilterByBranch === 'all' || !this.state.userFilterByBranch}>
                          <FilterListIcon
                            aria-describedby={this.idPopover}
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickPopover}
                          />
                        </Badge>
                      </div>
                      <AddBoxIcon
                        style={{ cursor: "hand" }}
                        onClick={() => {
                          this.openAddNewUserDialog();
                        }}
                      ></AddBoxIcon>
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "2px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">
                          <EnhancedTableHead
                            order={this.state.order}
                            orderBy={this.state.userOrderBy}
                            onRequestSort={this.handleUserRequestSort}
                            currentType="user"
                          />
                          <TableBody>
                            {this.stableSort(this.state.Users,
                              this.getComparator(this.state.order, this.state.userOrderBy, 'user')).map((User) => (
                                <TableRow hover={true} key={User.id} onClick={() => {
                                  this.openUserDetailsDialog({
                                    ...User,
                                    companyId: User.company_id ? User.company_id.id : "",
                                    companyName: User.company_id ? User.company_id.name : "",
                                    plantId: User.plant_id ? User.plant_id.id : "",
                                    plantName: User.plant_id ? User.plant_id.name : "",
                                  });
                                }}>
                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {User.company_id ? User.company_id.name : ""}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {User.plant_id ? User.plant_id.name : ""}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }} scope="row">
                                    {User.userName}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {User.email}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }} >
                                    {User.role}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }} >
                                    {createdAtDateFormat(User.createdAt)}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    <DeleteIcon
                                      onMouseDown={() => {
                                        this.setState({
                                          delUser: User.id,
                                          currentUserFrDel: User.email,
                                        });
                                        this.openDeleteDialog();
                                      }}
                                      style={{
                                        fontSize: "18px",
                                        cursor: "hand",
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}

                            {/* {this.emptyRows > 0 && (
                            <TableRow style={{ height: 53 * this.emptyRows }}>
                              <TableCell colSpan={6} />
                            </TableRow>
                          )} */}
                          </TableBody>
                        </Table>
                        <TablePaginationNew
                          count={this.state.totalUser}
                          offset={this.state.userOffset}
                          page={this.state.pageUser}
                          onChangePage={this.handleChangePage}
                        />
                      </TableContainer>

                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <div className="row">
              <div className="col-md-12">
                <div className="card" style={{ margin: "0px" }}>
                  <div
                    className="card-body"
                    style={{ padding: "5px 20px 2px" }}
                  >
                    <div style={{ float: "right", }}>
                      <div style={{ display: 'inline-block', margin: '5px 7px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={!this.state.searchKeyDevice}>
                          <SearchIcon
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickSearchPopover}

                          />
                        </Badge>
                      </div>
                      <div style={{ display: 'inline-block', margin: '5px 10px 5px 4px' }}>
                        <Badge color="secondary" badgeContent=" " variant="dot"
                          invisible={this.state.deviceFilterByBranch === 'all' || !this.state.deviceFilterByBranch}>
                          <FilterListIcon
                            aria-describedby={this.idPopover}
                            style={{ cursor: "hand" }}
                            onClick={this.handleClickPopover}
                          />
                        </Badge>
                      </div>
                      {/* <AddBoxIcon
                        style={{ margin: '5px', cursor: "hand" }}
                        onClick={async () => {
                          await this.openAddNewVentDialog();
                        }}
                      /> */}
                    </div>
                    <div
                      className="table-responsive"
                      style={{ paddingTop: "2px" }}
                    >
                      <TableContainer component={Paper}>
                        <Table aria-label="custom pagination table">



                          <EnhancedTableHead
                            // classes={classes}
                            // numSelected={selected.length}
                            order={this.state.order}
                            orderBy={this.state.deviceOrderBy}
                            // onSelectAllClick={handleSelectAllClick}
                            onRequestSort={this.handleDeviceRequestSort}
                            // rowCount={rows.length}\
                            currentType="device"

                          />

                          <TableBody>
                            {/* {(this.state.rowsPerPageDevice > 0
                              ? this.state.Devices.slice(this.state.pageDevice * this.state.rowsPerPageDevice, this.state.pageDevice * this.state.rowsPerPageDevice + this.state.rowsPerPageDevice)
                              : */}
                            {this.stableSort(this.state.Devices,
                              this.getComparator(this.state.order, this.state.deviceOrderBy, 'device')).map((Device) => (
                                <TableRow
                                  hover={true}
                                  onClick={() => {
                                    this.openVentDialog({
                                      ...Device,
                                      companyId: Device.company_id
                                        ? Device.company_id.id
                                        : "",
                                      companyName: Device.company_id
                                        ? Device.company_id.name
                                        : "",
                                      plantId: Device.plant_id
                                        ? Device.plant_id.id
                                        : "",
                                      plantName: Device.plant_id
                                        ? Device.plant_id.name
                                        : "",
                                    });
                                  }}
                                  key={Device.id}
                                >

                                  <TableCell style={{ padding: "12px", textTransform: 'capitalize' }}>
                                    {Device.company_id
                                      ? Device.company_id.name
                                      : ""}
                                  </TableCell>
                                  <TableCell
                                    style={{ padding: "12px", textTransform: 'capitalize' }}
                                    scope="row"
                                  >
                                    {Device.plant_id ? Device.plant_id.name : ""}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {Device.name}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {/* {Device.roomNo} */}
                                    {Device.alarm && Device.alarm.length && Device.alarm[0].RoomID ? Device.alarm[0].RoomID : ''}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {/* {Device.bedId} */}
                                    {Device.alarm && Device.alarm.length && Device.alarm[0].BedID ? Device.alarm[0].BedID : ''}
                                  </TableCell>
                                  <TableCell style={{ padding: "12px" }}>
                                    {createdAtDateFormat(Device.createdAt)}
                                  </TableCell>
                                  {/* <TableCell style={{ padding: "12px" }}>
                                    <DeleteIcon
                                      onMouseDown={() => {
                                        this.setState({
                                          currentDel: "device",
                                          delDevice: Device.id,
                                        });
                                        this.openDeleteDialog();
                                      }}
                                      style={{ fontSize: "18px", cursor: "hand" }}
                                    />
                                  </TableCell> */}
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                        <TablePaginationNew
                          count={this.state.totalDevice}
                          offset={this.state.deviceOffset}
                          page={this.state.pageDevice}
                          onChangePage={this.handleChangePage}
                        />
                      </TableContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </TabPanel>
        </div>
      </div >
    );
  }
}
export default AdminConsole;

function DeleteDialog(props) {
  const { open, setClose, onChange, currentUserFrDel, delUser } = props;
  const handleClose = () => {
    setClose(true);
  };
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");

  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const deleteUser = async () => {
    let msg = (<ul><li>This user has been registerd as Primary/Root user.</li>
      <li>Primary/Root user cannot be deleted.</li>
      <li> Primary user: First user of the hospital </li></ul>);
    if (delUser) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          delUser: delUser,
          delUserEmail: currentUserFrDel,
        }),
      };
      const res = await fetch(`${USER_DEL}`, requestOptions);
      const msgd = await res.text();
      console.log("Delete User response is:", res, "Msg is:", msgd);
      if (res.status === 200) {
        setSnackMsg("User deleted successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        onChange(true);
      } else if (res.status === 406) {
        setSnackMsg(msg);
        setSnackSeverity("warning");
        setOpenSnack(true);
      } else {
        setSnackMsg(`Sorry.. user deleted unsuccesfully beacause ${msgd}`);
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      setSnackMsg(`Sorry something went wrong `);
      setSnackSeverity("error");
      setOpenSnack(true);
    }
    handleClose();
  };


  return (
    <React.Fragment>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        severity={snackSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "0.8rem" }}
        >
          DELETE
        </DialogTitle>
        <DialogContent>
          <h4 style={{ padding: "30px 0px 0px 35px", fontSize: "20px" }}>
            Are you sure you want to delete this?
          </h4>
        </DialogContent>
        <DialogActions>
          <Button onClick={deleteUser} color="primary">
            {" "}
            Delete
          </Button>

          <Button onClick={handleClose} color="primary" autoFocus>
            Close{" "}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}


function TablePaginationNew(props) {
  const theme = useTheme();
  let { count, page, offset, onChangePage } = props;
  let limit = 10;
  let currentOffset = offset;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1, currentOffset - limit);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1, currentOffset + limit);

  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / limit) - 1), (Math.ceil(count / limit) - 1) * limit);
  };

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      {/* {(count > 0) ?
        <div style={{ display: "inline-flex", alignItems: "center", padding: "15px" }}> {(page * limit) + 1} - {Math.min((page * limit) + limit, count)}  of  {count}
        </div> : null} */}
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / limit) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / limit) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}


function FilterPopover(props) {
  const { idPopover, openPopover, anchorEl, handleClosePopover,
    onFilterClick, userFilterByBranch, branchList, tabValue, deviceFilterByBranch
  } = props;

  const [selectedBranch, setselectedBranch] = useState("all");
  const [items, setItems] = React.useState([]);

  useEffect(() => {
    // setselectedBranch(userFilterByBranch);
    setItems(branchList);

    if (tabValue == 0) {
      setselectedBranch(userFilterByBranch);
      // setItems(branchList);
    } else if (tabValue == 1) {
      // setItems(branchListForDeviceFilter);
      // setSelectedHospitalFilter(deviceFilterByCompany)
      setselectedBranch(deviceFilterByBranch)

    }
  }, [])

  const handleOnChangeFilterBranch = (e) => {
    setselectedBranch(e.target.value)
  }

  const onClickFilter = () => {
    // onFilterClick(selectedBranch);
    if (tabValue == 0) {
      onFilterClick('user', selectedBranch);
    } else if (tabValue == 1) {
      onFilterClick('device', selectedBranch);
    }
  }
  return (
    <Popover
      id={idPopover}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ width: '400px', padding: "20px 15px 15px" }}>
        <div className="row">
          <div className="col-5">  Branch Name </div>
          <div className="col-7">
            <FormControl
              style={{ width: "100%" }}
            >
              <Select
                name="branch"
                defaultValue={"all"}
                value={selectedBranch}
                onChange={handleOnChangeFilterBranch}
                style={{ backgroundColor: "white", textTransform: 'capitalize' }}
              >
                {/* {(items.length === 0) ? <MenuItem >
                    Branch Not Available
                                        </MenuItem> : */}
                {[{ id: 'all', name: "All" }, ...items].map((item, value) => (
                  <MenuItem key={value} value={item.id} style={{ textTransform: 'capitalize' }}>
                    {item.name}
                  </MenuItem>
                ))}
                {/* } */}
              </Select>
            </FormControl>
          </div>
        </div>


        <div className="d-flex justify-content-end mt-3">
          {/* <Button variant="contained" size="small" onClick={onClickFilter}>Filter </Button> */}
          <Button
            variant="contained"
            color="primary"
            startIcon={<FilterListIcon />}
            style={{
              marginRight: "10px",
              padding: "5px 15px"
            }}
            onClick={onClickFilter}
          >Filter </Button>
          <Button size="small" onClick={handleClosePopover}>Cancel</Button>
        </div>
      </div>
    </Popover>
  )
}

function SearchPopover(props) {
  const { idPopover, openPopover, anchorEl, handleClosePopover,
    onSearchClick, searchKeyUser, tabValue, searchKeyDevice
  } = props;
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [searchedKey, setSearchedKey] = useState('')

  useEffect(() => {
    // setSearchPlaceholder("Search User Full Name");
    // setSearchedKey(searchKeyUser);
    if (tabValue === 0) {
      setSearchPlaceholder("Search User Full Name");
      setSearchedKey(searchKeyUser);
    } if (tabValue === 1) {
      setSearchPlaceholder("Search Device");
      setSearchedKey(searchKeyDevice);
    }
  }, [])

  const onClickSearch = () => {
    // onSearchClick(searchedKey);
    if (tabValue == 0) {
      onSearchClick('user', searchedKey);
    } else if (tabValue == 1) {
      onSearchClick('device', searchedKey);
    }
  }
  const onChangeSearchInput = (e) => {
    // console.log("onChangeSearchInput:",e.target.value)
    setSearchedKey(e.target.value)
  }
  return (
    <Popover
      id={idPopover}
      open={openPopover}
      anchorEl={anchorEl}
      onClose={handleClosePopover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div style={{ width: '400px', padding: "25px 15px 15px" }}>
        <div className="input-group mb-3" style={{
          border: "1px solid #888",
          background: "none",
          borderRadius: "4px"
        }} >
          <div className="input-group-prepend" style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "5px",
          }}>
            <SearchIcon />
          </div>
          <input type="text" className="form-control" placeholder={searchPlaceholder}
            onChange={onChangeSearchInput} value={searchedKey}
            aria-label="search" aria-describedby="basic-addon1" style={{
              paddingLeft: "10px",
              background: "none"
            }} />
        </div>
        <div className="d-flex justify-content-end mt-3">
          {/* <Button variant="contained" size="small" onClick={onClickSearch}>Search </Button> */}

          <Button
            variant="contained"
            color="primary"
            startIcon={<SearchIcon />}
            style={{
              marginRight: "10px",
              padding: "5px 15px"
            }}
            onClick={onClickSearch}
          >Search </Button>
          <Button size="small" onClick={handleClosePopover}>Cancel</Button>
        </div>
      </div>
    </Popover>)
}


const userHeadCells = [
  { id: 'company_id', label: 'Hospital Name' },
  { id: 'plant_id', label: 'Branch Name' },
  { id: 'userName', label: 'User Full Name' },
  { id: 'email', label: 'Email / UserID' },
  { id: 'role', label: 'Role' },
  { id: 'createdAt', label: 'Created At' },
  { id: 'action', label: 'Action' }
];

const deviceHeadCells = [
  { id: 'company_id', label: 'Hospital Name' },
  { id: 'plant_id', label: 'Branch Name' },
  { id: 'name', label: 'Device' },
  { id: 'roomNo', label: 'Room No' },
  { id: 'bedId', label: 'Bed Id' },
  { id: 'createdAt', label: 'Created At' },
  // { id: 'action', label: 'Action' }
];


function EnhancedTableHead(props) {
  let tableCellStyle = {
    paddingLeft: "12px",
    paddingRight: "12px",
    fontSize: "15px",
    fontWeight: "600",
  }

  const {
    order,
    orderBy,
    onRequestSort,
    currentType

  } = props;
  let currentHead = [];
  switch (currentType) {
    case 'user': currentHead = userHeadCells;
      break;
    case 'device': currentHead = deviceHeadCells;
      break;
  }

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {currentHead.map(headCell => {
          // console.log("orderBy === headCell.id== ", orderBy, headCell.id)
          if (headCell.id === 'action') {
            return <TableCell
              style={tableCellStyle}
            >
              {headCell.label}
            </TableCell>
          }
          return <TableCell
            key={currentType + headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
            style={tableCellStyle}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span style={{
                  border: 0,
                  clip: 'rect(0 0 0 0)',
                  height: 1,
                  margin: -1,
                  overflow: 'hidden',
                  padding: 0,
                  position: 'absolute',
                  top: 20,
                  width: 1
                }}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        })}
      </TableRow>
    </TableHead>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ backgroundColor: "#ffffffe8", minHeight: "80vh" }} p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}