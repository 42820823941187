import React, { Component } from "react";
import Select from "react-select";

const time = [
  { label: "32 secs", value: 32 },
  { label: "16 secs", value: 16 },
];

class Graphsettings extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.onCancelClick = this.onCancelClick.bind(this);
    this.currentXaxisVal = this.props.xAxisValue
  }

  onCancelClick() {
    this.props.closePopup();
  }
  confirmXaxis = () => {
    this.props.setXAxisValue(this.currentXaxisVal);
    this.props.closePopup();
  }
  render() {
    return (
      <div>
        <div className="row d-flex justify-content-center" style={{ paddingTop: "30px" }}>
          <span style={{ color: "yellow", fontSize: "30px" }}> X-Axis time in secs </span>
        </div>

        <div className="row" style={{ paddingTop: "30px" }}>
          <div className="col-12 d-flex justify-content-center">
            <Select
              className="dropdownvalues"
              options={time}
              placeholder={this.currentXaxisVal + ' secs'}
              onChange={(e) => {
                console.log("SELEct:", e.value)
                this.currentXaxisVal = e.value
              }}
              defaultValue={this.currentXaxisVal}
            ></Select>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="row d-flex justify-content-center " style={{ paddingTop: "80px" }}>
              {/* <div className="col-6 d-flex justify-content-center"> */}
              <button className="button-2" style={{ borderRadius: '8px', border: 'none', marginRight: '20px' }}
                onClick={() => this.confirmXaxis()}
              >
                <span style={{ color: "white", fontSize: "16px" }}>
                  CONFIRM
                </span>
              </button>
              {/* </div> */}
              {/* <div className="col-6 d-flex justify-content-center"> */}
              <button
                className="button-2" style={{ borderRadius: '8px', border: 'none' }}
                onClick={() => this.onCancelClick()}>
                <span style={{ color: "white", fontSize: "16px" }}>
                  CANCEL
                </span>
              </button>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Graphsettings;
