import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import "../components/Screens/App.css";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import "./Screens/App.css";
import { LOGIN } from "../../src/api-route";
import ReactLoading from "react-loading";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { lockScreenEvent } from "../rxjssignals/lock-signal";
import { lock, unlock, checkLock } from '../services/http';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from "@material-ui/core/IconButton";
class IdleLogoutDialog extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      setOpen: false,
    };

  }
  disablePopup = () => {
    this.setState({ setOpen: false });
  };

  componentDidMount() {
    lockScreenEvent.receive((data) => {
      if (
        data &&
        typeof data === "object" &&
        "verbs" in data &&
        data.verbs === "lockscreen"
      ) {
        this.setState({ setOpen: !this.state.setOpen });
      }
    });
  }

  render() {
    return (
      <div>
        <Dialogs
          disablePopup={this.disablePopup}
          setOpen={this.state.setOpen}
          user={this.props.user}
        />
      </div>
    );
  }
}

export default IdleLogoutDialog;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Dialogs(props) {
  const [password, setPassword] = useState("");
  const [failure, setFailure] = useState("");
  const [loading, setLoading] = useState(false);

  let setOpen = props.setOpen;

  async function makeLogin(login) {
    const token = await localStorage.getItem("abmUsertoken");
    fetch(LOGIN, {
      method: "post",
      body: JSON.stringify(login),
    }).then((res) => {
      if (res.status == 200) {
        setFailure("");
        setLoading(false);
        unlock({ token: token }).then((res) => {  //To make 'lockStatus' flag change in user table
          if (res.status === 200) {
            props.disablePopup();
            // send signal 
            lockScreenEvent.send({ verbs: "unlockscreen", action: false });
          }
        }).catch((err) => {
          setFailure("Something went wrong. Try again later")
        }
        );
      } else {
        setLoading(false);
        setFailure("The password is incorrect. Try again");
      }
    });
  }

  return (
    <div>
      <Dialog
        fullScreen
        open={setOpen}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#000000ba",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "auto",
          }}
        >
          <AccountCircleRoundedIcon
            style={{ color: "grey", fontSize: "175px" }}
          />
          <div style={{ color: "white", fontSize: "40px" }}>
            {props.user.email
              ? props.user.email
              : ''}
          </div>
          <div className="input-group" style={{ maxWidth: '300px' }}>
            <input
              autoComplete="off"
              type="password"
              className="form-control"
              placeholder="Password"
              style={{
                background: "white",
                width: "230px",
                marginTop: "20px",
                padding: "10px",
              }}
              name="password"
              onChange={(event) => {
                setFailure("");
                setPassword(event.target.value);
              }}
            />
            <span
              style={{
                marginTop: "20px",
                border: "1px solid gray",
                width: "40px",
              }}
            >
              <ArrowForwardIcon
                style={{ marginTop: "5px", marginLeft: "8px", color: "white" }}
                onClick={() => {
                  let login = {
                    username: props.user.email,
                    password: password,
                  };
                  setLoading(true);
                  setFailure("");
                  setTimeout(() => {
                    makeLogin(login);
                  }, 700);
                }}
              />
            </span>
          </div>
          {loading ? (
            <ReactLoading type={"cylon"} color="#fff" />
          ) : (
            <p style={{ color: "red", fontSize: "small", paddingTop: "13px" }}>
              {failure}
            </p>
          )}
        </div>
        <IconButton
          onClick={props.disablePopup}
          edge="start"
          style={{ position: "absolute", top: 60, right: 60 }}
        >
          <CancelIcon style={{ color: "white" }} />
        </IconButton>
      </Dialog>
    </div>
  );
}
