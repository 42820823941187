import React, { Component } from "react";
import Slider, { Rail, Handles, Tracks, Ticks } from "react-compound-slider";
//import ValueViewer from "docs/src/pages/ValueViewer"; // for examples only - displays the table above slider
import { SliderRail, Handle, Track, Tick } from "./components"; // example render components - source below
import { min } from "moment";

const sliderStyle = {
  position: "relative",
  height: "230px",
  marginLeft: "15%",
  width: 30,
  touchAction: "none",
};

class Example extends Component {
  state = {
    values: this.props.keyValues,
    update: this.props.keyValues,
    domain: this.props.domain,
    findHigh: false,
  };
  componentWillReceiveProps(props) {
    this.onUpdate(props.keyValues);
    this.onChange(props.keyValues);
  }
  onUpdate = (update) => {
    this.setState({ update });
  };
  onChange = (values) => {
    this.setState({ values });
  };
  handleToggle = (data) => {
    // this.props.onchange(data);
  };

  onchange = (data) => {
    this.handleToggle(data);
    // console.log("xsujan Form>", data);
    this.findOut(data);
  };

  findOut = (data) => {
    this.setState({ findHigh: data });
  };
  render() {
    const {
      state: { values, update },
    } = this;
    const { domain } = this.state;
    // console.log("Console,", update, values);
    return (
      <Slider
        vertical
        mode={2}
        reversed={true}
        // step={5}
        domain={domain}
        rootStyle={sliderStyle}
        values={values}
        warnOnChanges={true}
        disabled={true}
      >
        <Rail>
          {({ getRailProps }) => <SliderRail getRailProps={getRailProps} />}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle, index) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  index={index}
                  findHigh={this.state.findHigh}
                  onchange={(e) => {
                    this.onchange(e);
                  }}
                  readOnly={true}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        {/* <Ticks count={5}>
            {({ ticks }) => (
              <div className="slider-ticks">
                {ticks.map((tick) => (
                  <Tick key={tick.id} tick={tick} />
                ))}
              </div>
            )}
          </Ticks> */}
      </Slider>
    );
  }
}

export default Example;
