import React, { useEffect, useState } from "react";
import clsx from "clsx";
import "./ion-vents/ion-vents-style.css";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import PowerButton from "@material-ui/icons/PowerSettingsNew";
import SettingsIcon from "@material-ui/icons/Settings";
import Lock from "@material-ui/icons/Lock";
import MenuIcon from "@material-ui/icons/Menu";
import QrReader from "react-qr-reader";
import Menu from "@material-ui/core/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
// import logo from "./Screens/ABM Logo.png";
import newLogo from "../img/abm-new-logo.png";
import QrCodeDialog from "./Screens/dialog/QrCodeDialog";
import AlarmingAvatar from "./ui-components/avatar";
// import { Route, withRouter, matchPath } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Skeleton from "@material-ui/lab/Skeleton";
import HomeIcon from "@material-ui/icons/Home";
import { CustomsSnackbar } from "../components/snakbar/snackbar";
import { lockScreenEvent } from "../rxjssignals/lock-signal";
import scanner from "./Screens/qr-scan-512.png";
import SessionExpiryDialog from "./Screens/dialog/sessionExpiryDialog";
import LockComponent from "./lockbutton";
import Warning from "./Screens/Images/Warning.svg";
import { showAlmListService } from "../alarmService";
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import UserIcon from "@material-ui/icons/AccountCircle";
// import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from "@material-ui/core/Select";
import { SEARCH, MACHINELIST, SEARCH_IN_ALM } from "../api-route";
const drawerWidth = 240;
function ListItemLink(props) {
  return <ListItem button component={Link} {...props} />;
}

export default function MiniDrawer(props) {
  const [openQrCodeDialogs, setopenQrCodeDialogs] = React.useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
    },
    show: {
      dispaly: "block",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      // marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      zIndex: "1300",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      // width: theme.spacing(7) + 1,
      // [theme.breakpoints.up("sm")]: {
      // width: doNotThisComponentIfHardwareScreen ? "0px" : theme.spacing(9) + 1,
      width: "0px",
      // },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  let [sessionExpired, setSessionExpired] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  let [customeSnackbarMessage, setCustomeSnackbarMessage] = useState("");
  let [customeSnackbarFlag, setCustomeSnackbarFlag] = useState(false);
  let [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "left",
  });
  let [customeSnackbarSeverity, setCustomeSnackbarSeverity] = useState(
    "success"
  );
  let [deviceList, setDeviceList] = useState([]);
  let [resultData, setResultData] = React.useState([]);
  const menuId = "primary-search-account-menu";
  const [watchListGridSelected, setWatchListGridSelected] = React.useState('2:2');
  const [userSelectedGrid, setUserSelectedGrid] = React.useState('2:2');
  const [forceRender, setForceRender] = React.useState('00');

  useEffect(() => {
    getDeviceList();
    if (window.innerWidth >= 1900 && window.innerWidth < 2560) {
      setUserSelectedGrid('3:2');
      setWatchListGridSelected('3:2');
      props.currentGrid('3:2');
    }
    if (window.innerWidth >= 2560) {
      setUserSelectedGrid('4:2');
      setWatchListGridSelected('4:2');
      props.currentGrid('4:2');
    }
  }, []);

  useEffect(() => {
    // calcSize();
    window.addEventListener("resize", calcSize);
    return () => window.removeEventListener("resize", calcSize);
  }, [watchListGridSelected]);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const onCloseCustomeSnackbar = () => {
    setCustomeSnackbarFlag(false);
  };

  const findGap = (ventilators) => {
    let index = undefined;
    let indexs = [];
    if (Array.isArray(ventilators)) {
      ventilators.forEach((ele, index) => {
        if ("index" in ele) {
          indexs.push(index);
        }
      });
    }
    return indexs;
  };
  const add = (_data) => {
    // console.log("Im add"_data)
    let ifAvl = props.ventilators.filter(
      (ele) => ele.deviceId == _data.deviceId
    );
    if (ifAvl.length) {
      console.clear();
      // console.log("Exosts", ifAvl);
      alert("Device exists");
      return;
    } else {
    }

    // console.log("NewVentilator", _data);
    // if (props.ventilators.length == props.MAX_VENTS) {
    //   setCustomeSnackbarMessage(
    //     `Max ${props.MAX_VENTS} can be added to watchlist`
    //   );
    //   setCustomeSnackbarSeverity("warning");
    //   setCustomeSnackbarAnchor({ vertical: "top", horizontal: "left" });
    //   setCustomeSnackbarFlag(true);
    //   console.log(
    //     "props.ventilators.length == props.MAX_VENTS",
    //     props.ventilators.length == props.MAX_VENTS
    //   );
    // } else {
    //   console.log(
    //     "else->props.ventilators.length == props.MAX_VENTS",
    //     props.ventilators.length == props.MAX_VENTS
    //   );

    // console.log(
    //   `else->props.ventilators.length(${props.ventilators.length}) ==props.MAX_VENTS(${props.MAX_VENTS})`,
    //   props.ventilators.length == props.MAX_VENTS
    // );
    let allIndex = findGap(props.ventilators);
    console.log("All indexs", allIndex);
    if (allIndex.length) {

      let prevVents = [...props.ventilators];
      prevVents.splice(allIndex[0], 1, _data);
      // console.log("props.updateCurveMonitoringVisiblity", _data);
      props.updateCurveMonitoringVisiblity(_data);
      props.addVentToWatchList(prevVents);
    } else {
      // console.log("else allIndex.length", allIndex.length);
      let prevVents = [...props.ventilators];
      prevVents.push(_data);
      // console.log("props.updateCurveMonitoringVisiblity", _data);
      props.updateCurveMonitoringVisiblity(_data);
      props.addVentToWatchList(prevVents);
    }
    // }
    // }
  };
  const getDeviceList = () => {
    if (
      "company_id" in props.user &&
      "plant_id" in props.user &&
      "role" in props.user
    ) {
      let { company_id, plant_id } = props.user;
      // console.log("company_id:",company_id," plant_id:",plant_id)
      fetch(`${MACHINELIST}/${company_id}/${plant_id}?user=${props.user.role}`)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result && Array.isArray(result)) {
              // console.log("deviceList",result)
              let formatedAr = []
              for (let i = 0; i < result.length; i++) {
                formatedAr.push(result[i].name)
              }
              // console.log("formatedAr:", formatedAr)
              setDeviceList(formatedAr)
            }
          })
    }
  }

  const calcSize = () => {
    console.log("Userselected is:", userSelectedGrid)
    if (window.innerWidth < 1900 && watchListGridSelected != '2:2' &&
      (watchListGridSelected == '3:2' || watchListGridSelected == '4:2')) {
      setWatchListGridSelected('2:2');
      props.currentGrid('2:2');
    }
    if ((window.innerWidth < 2560 && window.innerWidth >= 1900) && watchListGridSelected != '3:2') {
      // if (userSelectedGrid != '2:2' && userSelectedGrid != '4:2') {
      setWatchListGridSelected('3:2');
      props.currentGrid('3:2');
      // }
    }
    if ((window.innerWidth >= 2560) && watchListGridSelected != '4:2') {
      // if (userSelectedGrid != '2:2' && userSelectedGrid != '3:2') {
      setWatchListGridSelected('4:2');
      props.currentGrid('4:2');
      // }
    }
    setForceRender('ui-' + new Date().getMilliseconds().toString()); // to make component rerender when resizes because we need to update grid dropdown
    // not a good way, need to think of some other logic  
  };

  const handleOnChnageWatchListGrid = (e) => {
    if (e.target.value == '3:2' && window.innerWidth < 1900) {
      setCustomeSnackbarMessage(`You're screen resolution doesn't support 3 column frame`);
      setCustomeSnackbarSeverity("warning");
      setCustomeSnackbarAnchor({ vertical: "top", horizontal: "left" });
      setCustomeSnackbarFlag(true);
      return;
    }
    if (e.target.value == '4:2' && window.innerWidth < 2560) {
      setCustomeSnackbarMessage(`You're screen resolution doesn't support 4 column frame`);
      setCustomeSnackbarSeverity("warning");
      setCustomeSnackbarAnchor({ vertical: "top", horizontal: "left" });
      setCustomeSnackbarFlag(true);
      return;
    } setUserSelectedGrid(e.target.value);
    setWatchListGridSelected(e.target.value);
    props.currentGrid(e.target.value);
  };
  const getData_old = (srchInput) => {
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    let data = [];
    fetch(`${SEARCH}${srchInput}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json();
      })
      .then((result) => {
        console.log("Search RESULT:", result)
        setResultData(result);
      });
  };

  const getData = (srchInput) => {
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        srchInput: srchInput,
        deviceList: deviceList
      })
    };
    let data = [];
    fetch(`${SEARCH_IN_ALM}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json();
      })
      .then((result) => {
        console.log("Search RESULT:", result)
        setResultData(result);
      });
  };

  const openQrCodeDialog = () => {
    setopenQrCodeDialogs(true);
  };
  const closeQrCodeDialog = () => {
    setopenQrCodeDialogs(false);
  };
  const renderSnackbar = (
    <CustomsSnackbar
      msg={customeSnackbarMessage}
      flag={customeSnackbarFlag}
      onClose={onCloseCustomeSnackbar}
      key={"cusSnackbar"}
      anchorOrigin={customeSnackbarAnchor}
      severity={customeSnackbarSeverity}
    />
  );

  const renderHeaderSearchBar = (
    <Menu
      // onMouseOut={() => {
      //   console.info("Drawer Out-------");
      // }}
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      style={{ top: "46px", left: "13px" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem> */}
      {/* {" "} */}
      {/* <Link className="nav-link" to="profile-page">
          Profile
        </Link> */}
      <div className="col-md-12">
        <div className="form-group" style={{ paddingBottom: "0px" }}>
          <form autoComplete="off" method="post" action="">
            <input
              autoComplete="false"
              name="hidden"
              type="text"
              style={{ display: "none" }}
            />
            <div
              className="input-group md-form form-sm form-2 pl-0"
              style={{
                background: "#f1f3f4",
                borderRadius: "8px",
              }}
            >
              <div className="input-group-append">
                <span
                  style={{
                    display: "inline-flex",
                    justifyContent: "center",
                    width: "40px",
                    alignItems: "center",
                    height: "46px",
                  }}
                >
                  <SearchIcon style={{ fontSize: "20px" }} />
                </span>
              </div>

              <input
                className="form-control my-0 py-1 "
                id="watchlistSearchInput"
                type="text"
                placeholder="Search vent ex: Vent001 "
                aria-label="Search"
                style={{
                  height: "46px",
                  background: "#f1f3f4",
                  borderRadius: "8px",
                  // maxWidth: "200px",
                }}
                onChange={(event) => {
                  let val = event.target.value.trim();
                  getData(val);
                }}
              />
              <img
                className="input-group-append"
                src={scanner}
                style={{ margin: "12px" }}
                alt="Scanner"
                height="20px"
                width="20px"
                onClick={() => {
                  openQrCodeDialog();
                }}
              />
            </div>
          </form>
        </div>
        {resultData.length ? (
          resultData.map((data, index) => {
            let returnedResult = props.ventilators.filter(
              (el) => el.deviceId == data.deviceId
            );
            if (returnedResult.length) {
              return <ListOfVents key={index} Data={{ ...data }} watch />;
            } else {
              return <ListOfVents key={index} Data={{ ...data }} add={add} />;
            }
          })
        ) : (
          <span>No match found</span>
        )}
      </div>
    </Menu>
  );

  const toggleDrawer = () => {
    setOpen(open ? false : true);
  };

  return (
    <div className={classes.root}>
      <SessionExpiryDialog open={sessionExpired}></SessionExpiryDialog>
      <QrCodeDialog
        open={openQrCodeDialogs}
        setClose={closeQrCodeDialog}
        onClose={handleMenuClose}
      ></QrCodeDialog>
      {renderSnackbar}
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ background: "gray", paddingRight: '0px' }}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar style={{ paddingRight: '0px', height: '64px' }} >

          <div className="row" style={{ width: '100%' }}>
            <div className="">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                className={clsx(classes.menuButton, {
                  [classes.hide]: open,
                })}
                style={{ position: 'absolute', left: '15px', top: '6px', zIndex: '999' }}
              >
                <MenuIcon />
              </IconButton>

            </div>
            <div className=" d-none d-sm-flex col-sm-12 justify-content-start justify-content-lg-center" style={{
              display: 'flex', alignItems: 'center', overflow: 'hidden', paddingLeft: '40px'
            }}>
              <div
                // className="d-none d-sm-block"
                // style={{ width: "90%" }}
                title="This portal is only a secondry monitoring mechanism for the
                ventilator and does not replace the primary mehanism of
                physically attending at all time"
              >
                <Typography variant="h6" noWrap style={{ width: "100%" }}>
                  <img src={Warning} width="33px" style={{ padding: '2px' }} />
                  <span style={{ fontSize: "15px", textTransform: "none" }}>
                    Alphatelecare is meant for secondary monitoring.
                    It does not replace the need to monitor the Ventilator completely.
                  </span>
                </Typography>
              </div>
            </div>
            <div className="" style={{
              display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
              padding: '0px', minWidth: '285px', position: 'absolute', right: '0px', background: 'gray', top: "8px"
            }}>
              <LockComponent />
              <AlarmingAvatar
                updateAlarmListStates={props.updateAlarmListStates}
              />
              {(props.isDeviceDashboard && window.innerWidth > 991) ? (<div>
                <Select
                  name="grid"
                  value={watchListGridSelected}
                  onChange={handleOnChnageWatchListGrid}
                  style={{
                    backgroundColor: "#e9ecef",
                    textTransform: 'capitalize',
                    padding: '2px 0px 4px 5px',
                    borderRadius: '5px',
                    marginRight: '8px'
                  }}
                >
                  <MenuItem value="2:2"
                    style={{ display: window.innerWidth > 991 ? 'block' : 'none' }}
                  >2 col</MenuItem>
                  <MenuItem value="3:2"
                    style={{ display: window.innerWidth >= 1900 ? 'block' : 'none' }}
                  >3 col</MenuItem>
                  <MenuItem value="4:2"
                    style={{ display: window.innerWidth >= 2560 ? 'block' : 'none' }}
                  >4 col</MenuItem>
                </Select>

              </div>) : null}
              {props.isDeviceDashboard ? (
                <Button
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  variant="contained"
                  color="default"
                  style={{ height: "40px", margin: "4px" }}
                >
                  Add to Watchlist
                </Button>
              ) : (
                <Button
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  style={{ height: "40px", margin: "4px" }}
                  onClick={() => {
                    history.push(`/watchlist`);
                  }}
                  variant="contained"
                  color="default"
                >
                  Watch List
                </Button>
              )}
            </div>
          </div>
          {renderHeaderSearchBar}
        </Toolbar>
      </AppBar>

      <Drawer open={open} onClose={toggleDrawer}>
        <div className={classes.toolbar}>
          <img src={newLogo} alt="Logo" height="26px" width="90px" style={{ padding: "0px 15px" }} />
          <span style={{ fontSize: "15px" }}>ABM ALPHA</span>
          <IconButton onClick={toggleDrawer}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <CardHeader
          style={{ paddingLeft: '20px' }}
          avatar={
            <Avatar aria-label="recipe" >
              <UserIcon />
            </Avatar>
          }
          title={props.user.userName}
          style={{ textTransform: 'capitalize' }}
          subheader={props.user.role}
        />
        <Divider />
        <div className="sidebarCustPadding">
          <List>
            {/* <ListItemLink button key={"Dashboard"} to="dashboard">
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItemLink> */}
            <ListItemLink
              button
              key={"home"}
              to={{
                pathname: "/alpha-vents",
                state: { prevPath: "oktt" },
              }}
              /*               to="/ion-vents"
               */ onClick={() => {
                props.updateAlarmListStates(null);
                showAlmListService.send(false);
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={"Home"} />
            </ListItemLink>

            <ListItemLink
              button
              style={{ display: props.user.role === "user" ? "none" : "flex" }}
              key={"admin console"}
              to="admin-console"
              onClick={() => {
                props.updateAlarmListStates(null);
                setOpen(false);
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary={"Configuration"} />
            </ListItemLink>

            <ListItemLink
              className="nav-link"
              to="/"
              onClick={() => {
                props.logOut();
              }}
            >
              <ListItemIcon>
                <PowerButton />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItemLink>
          </List>
        </div>
      </Drawer>
    </div >
  );
}

function ListOfVents(props) {
  const { loading = false, Data, watch } = props;
  let deviceID = Data.deviceId;
  let bedID = Data.BedID;
  let roomNO = Data.RoomID;

  return (
    <div
      onClick={() => {
        if ("add" in props) {
          props.add({ ...Data, name: Data.deviceId });
        }
      }}
      style={{
        border: "1px solid #dadce0",
        height: "65px",
        borderRadius: "8px",
        marginTop: "10px",
        // boxShadow:"2px 3px 2px 0px rgba(0,0,0,0.14)"
      }}
    >
      <div
        style={{ padding: "0px 10px 10px 10px", width: "100%", height: "100%" }}
      >
        <div
          style={{
            display: "inline-block",
            height: "100%",
            width: "90%",
            float: "left",
          }}
        >
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              style={{
                height: "30px",
                width: "55%",
                borderRadius: "7px",
                marginTop: "2px",
              }}
            />
          ) : (
            <div style={{ fontSize: "1.3rem" }}>{deviceID} </div>
          )}

          {loading ? (
            <span>
              <Skeleton
                animation="wave"
                variant="rect"
                style={{
                  height: "18px",
                  marginTop: "2px",
                  width: "95%",
                  borderRadius: "7px",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rect"
                style={{
                  height: "18px",
                  marginTop: "2px",
                  width: "95%",
                  borderRadius: "7px",
                }}
              />
            </span>
          ) : (
            <div style={{ fontSize: "12px" }}>
              <div>
                {" "}
                <span>Bed ID:</span>
                <span>{bedID}</span>
              </div>
              <div>
                <span style={{}}>Room ID:</span>
                <span>{roomNO}</span>{" "}
              </div>
            </div>
          )}
        </div>
        {loading ? (
          <Skeleton
            animation="wave"
            variant="rect"
            style={{ height: "72px", borderRadius: "7px" }}
          />
        ) : (
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              float: "left",
              height: "100%",
              width: "10%",
            }}
          >
            {watch ? <VisibilityIcon /> : null}
          </div>
        )}
      </div>
    </div>
  );
}
