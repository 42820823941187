import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { COMPANY_REGISTER, REGISTER_ME_TO_COMPNAY } from "../../../api-route";
import InputField from './inputField';

export default function SignUp(props) {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const [company, setCompany] = useState({});
  let query = useQuery();
  const inputRefs = React.useRef([
    React.createRef(), React.createRef(), React.createRef(), React.createRef()
  ]);
  const [data, setData] = React.useState({});
  let password = data.password;
  const handleChange = (name, value) => {
    setData(prev => ({ ...prev, [name]: value }))

  }

  useEffect(() => {
    console.log("query.get(ak)", query.get("ak"));

    if (query.get("ak")) {
      fetchCompany(query.get("ak"), (companyData) => {
        console.log("companyData", companyData);
        setCompany(companyData);
      });
    } else {
      console.log("AAK---", query.get("ak"));
    }
  }, []);

  return (

    <div className="container">
      <div
        style={{
          margin: "auto",
          display: "flex",
          alignSelf: "center",
          maxWidth: "600px",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <form className="well form-horizontal register" style={{ width: "100%" }}
        >
          <fieldset style={{ backgroundColor: "#ffffff66", paddingRight: "20px", marginTop: "20px" }}>
            <div style={{ marginTop: "10px" }}><center><h3><b>Register Company</b></h3></center></div><br />

            <div className="row form-group">
              <label className="col-md-4 labelstyle ">Company Name</label>
              <div className="col-md-8 ">
                <input
                  type="text"
                  readOnly
                  value={company ? company.name : ""}
                  className="form-control cust-input"
                  placeholder="Company Name"
                />
              </div>
            </div>


            <div className="row form-group">
              <label className="col-md-4 labelstyle">Registered Email</label>
              <div className="col-md-8">
                <input
                  type="email"
                  readOnly
                  value={company ? company.email : ""}
                  className="form-control cust-input"
                  placeholder="Registered Email"
                />
              </div>
            </div>




            <div className="row form-group">
              <label className="col-md-4 labelstyle" >Registered Phone</label>
              <div className="col-md-8 ">
                <input
                  type="text"
                  readOnly
                  value={company ? company.phone : ""}
                  className="form-control cust-input"
                  placeholder="Registered Phone"
                />
              </div>
            </div>



            <div className="row form-group">
              <label className="col-md-4 labelstyle">First Name</label>
              <div className="col-md-8">
                <InputField
                  ref={inputRefs.current[0]}
                  label="First name"
                  name="fName"
                  validation="required|firstName"
                  className="form-control cust-input1"
                  onChange={handleChange}
                  placeholder="Enter first name"

                /> </div> </div>




            <div className="row form-group">
              <label className="col-md-4 labelstyle" >Last Name</label>
              <div className="col-md-8">
                <InputField
                  label="Last name"
                  ref={inputRefs.current[1]}
                  name="lName"
                  type="text"
                  onChange={handleChange}
                  placeholder="Enter last name (Optional)"
                  className="form-control cust-input1"
                /></div></div>


            <div className="row form-group">
              <label className="col-md-4 labelstyle" >Password</label>
              <div className="col-md-8 ">
                <InputField
                  ref={inputRefs.current[2]}
                  label="Password"
                  name="password"
                  type="password"
                  placeholder="Enter password"
                  validation="required|min:6"
                  onChange={handleChange}
                  className="form-control cust-input1"
                />
              </div>
            </div>





            <div className="row form-group">
              <label className="col-md-4 labelstyle">Confirm Password</label>
              <div className="col-md-8">
                <InputField
                  ref={inputRefs.current[3]}
                  label="Confirm password"
                  name="cpwd"
                  type="password"
                  validation="passwordmatch"
                  placeholder="Confirm password"
                  onChange={handleChange}
                  password={password}
                  className="form-control cust-input1"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4"></div>
              {/* <div style={{width:"100%",display:"flex",justifyContent:"center"}}> */}
              <div className="col-md-8" style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="button"
                  className="btn btn-primary btn-block"
                  style={{ width: "320px", margin: "15px 25px 10px 20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    let isValid = true;
                    for (let i = 0; i < inputRefs.current.length; i++) {
                      const valid = inputRefs.current[i].current.validate()
                      console.log(valid)
                      if (!valid) {
                        isValid = false
                      }
                    }

                    if (!isValid) {
                      return
                    }
                    let user = {
                      fName: data.fName,
                      lName: data.lName,
                      password: data.password,
                      conf_password: data.cpwd,
                    };

                    let frmData = {
                      company: company,
                      user: user,
                    };
                    registerMyCompany(frmData, (response) => {
                      console.log("submittedData", frmData);
                      console.log("response is", response);
                      // if(response.status===409){
                      //   alert("Company already registerd")
                      // }
                    });
                  }}
                >
                  Register
          </button>
              </div></div>

            <div className="row">
              <div className="col-md-4"></div>
              <div className="col-md-8">
                <footer className="text-center">
                  <p className="forgot-password text-center">
                    Already registered <a href="#">sign in?</a>
                  </p>
                </footer></div></div>
          </fieldset>
        </form>
      </div>
    </div>

  );
}

const fetchCompany = async (ak, callback) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
  };

  const res = await fetch(
    `${COMPANY_REGISTER}?access_tkn=${ak}`,
    requestOptions
  );

  const data = await res.json();
  //console.log("coursesbody is:",data)
  callback(data);
};
const registerMyCompany = async (data2, callback) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(data2),
  };

  await fetch(`${REGISTER_ME_TO_COMPNAY}`, requestOptions).then((res) => {
    console.log(res)
    if (res.status === 409) {
      alert("Email or name have been used already");
    } else if (res.status === 400) {
      alert("Somthing bad with input");
    } else if (res.status === 200 || res.status === 201) {
      alert("Company created succesfully");

      window.history.pushState({}, "Login", "/");
      localStorage.clear();
      window.location.reload();
    } else {
      alert("Found some problem try later");
      ///alert(res.statusText + res.status);
    }

    //alert("Company Added Unsuccesfully Because " + result.message);
  })
    .catch((err) => { });
  //console.log("coursesbody is:",data)
  //callback(response);
};
