import React from 'react';
import { compose, withStateHandlers } from "recompose";
import { InfoWindow, withGoogleMap, withScriptjs, GoogleMap, Marker, StandaloneSearchBox } from 'react-google-maps';


export default class MapContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            latLng: null,
            cordVal: 12
        }
    }

    getCoordinate = (cord) => {
        console.log("New cord", cord);
    }
    // shouldComponentUpdate() {
    //     return false; // Will cause component to never re-render.
    // }
    render() {
        let latLng = null;
        const onMapClick = ({ isMarkerShown }) => {
            return (e) => {
                if (e && e.latLng) {
                    this.setState({ latLng: e.latLng })
                    console.log("e is long ", e.latLng.lng())
                    console.log("e is lat ", e.latLng.lat())
                }
                return ({
                    markerPosition: e.latLng,
                    isMarkerShown: true,

                })
            }
        }

        const Map = compose(
            withStateHandlers(() => ({
                isMarkerShown: false,
                markerPosition: null
            }), {
                onMapClick: onMapClick
            }),
            withScriptjs,
            withGoogleMap
        )(props =>
            <GoogleMap
                defaultZoom={8}
                defaultCenter={{ lat: 12.9716, lng: 77.5946 }}
                onClick={props.onMapClick}>
                {props.isMarkerShown && <Marker position={props.markerPosition} onClick={props.getCoordinate} />}
                {/* {    console.log("marker props",Object.keys(props.markerPosition?props.markerPosition:{}))} */}
                {console.log("marker props", props.markerPosition ? props.markerPosition.lng() : {})}
            </GoogleMap>
        )

        return (
            <div style={{ height: '100%' }}>
                <form>
                    <input type="text" />
                </form>
                <Map
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDY5NyNv4KbeBJk0rWc2uJ9Wbskjr4LHrk"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
                <p style={{ marginBottom: "0px", fontSize: "14px" }}>Latitude: {this.state.latLng ? this.state.latLng.lat() : null}</p>
                <p style={{ marginBottom: "0px", fontSize: "14px" }}>Longitude: {this.state.latLng ? this.state.latLng.lng() : null}</p>
            </div>
        )
    }
}
