import React, { forwardRef, useImperativeHandle } from "react";

const InputField = forwardRef((props, ref) => {

  const [value, setValue] = React.useState("");
  const [error, setError] = React.useState("");
  

  const handleChange = (event) => {
    
    setValue(event.target.value)
    setError("");
    props.onChange(event.target.name, event.target.value)
  }

  const validate = () => {
    //return true if is valid 
    //else return false

    if (props.validation) {
      const rules = props.validation.split("|");

      for (let i = 0; i < rules.length; i++) {
        const current = rules[i];

        if (current === "required") {
          if (!value) {
            setError(`${props.label} is required`);
            return false
          }
        }

        const pair = current.split(":")
        switch (pair[0]) {
          case "min":
            if (value.length < pair[1]) {
              setError(`This field must be at least ${pair[1]} charactesr long`);
              return false
            }
            break;
          case "max":
            if (value.length > pair[1]) {
              setError(`This ${value} must be no longer than ${pair[1]} charactesr long`);
              return false;
            }
            break;
            case "emailpattern":
            if (!/\S+@\S+\.\S+/.test(value)) {
                setError(`Enter valid ${props.label} `);
                return false;
              }
            break;
            case "firstName" :
              if (!/^[0-9a-zA-Z]+$/.test(value)) {
                setError(`Enter valid ${props.label} `);
                return false;
              }
            break;
            case "userpattern":
                if (!/^[0-9a-zA-Z]+$/.test(value)) {
                    setError(` valid ${props.label} `);
                    return false;
                  }
                break;
            case "numberpattern" :
              if(/^\(\d{3}\)\s*\d{3}(?:-|\s*)\d{4}$/.test(value)){
                setError(`Plz enter valid ${props.label}`);
                return false;
            } 
            break;
            case "passwordmatch":
                if(!value){
                    setError(`Re-enter password`);
                    return false;
                } 
                else if (value !== props.password) {
                    setError(` Password must match   `);
                    return false;
                  }
                break;
          default:
            break;
        }
      }
    }

    return true;
  }

  useImperativeHandle(ref, () => {
    return {
      validate: () => validate()
    }
  })

  return (
    <div className="input-wrapper">
      
      <input
        className = {props.className}
        placeholder={props.placeholder}
        name={props.name}
        onChange={(event) => handleChange(event)}
        type={props.type}
        readOnly = {props.readOnly ? true : false}
        value={props.value ? props.value : value}
        autoComplete={props.autoComplete}
        
      />
      {error && (
        <p className="error" style={{color:'red' , fontSize:'12px' ,textAlign:'left',marginBottom:"0px"}}>{error}</p>
      )}
    </div>
  )
})

InputField.defaultProps = {
  placeholder: "",
  name: "",
  type: "text",
  value: "",
  autoComplete: "off",
  validation: "",
  className : "text"
}


export default InputField;