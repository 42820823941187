import React, { useState } from "react";
import { PEDIT } from "../../../api-route";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import InputField from './validation/editfrom'
import "../App.css";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function UserDetailsDialog(props) {
  const { open, setClose, currentPlant, onChange } = props;
  // console.log("CUrrent plant:",currentPlant)
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({ vertical: "top", horizontal: "center" });

  const inputRefs = React.useRef([
    React.createRef(), React.createRef()
  ]);
  const [values, setValues] = React.useState({});
  const [data, setData] = React.useState({});
  const [sessionExpired, setSessionExpired] = React.useState(false);

  const handleInputChange = (name, value) => {
    setValues({ ...values, [name]: value });
  };
  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate()
      if (!valid) {
        isValid = false
      }
    }

    if (!isValid) {
      return
    }

    editPlantHttpCall();

  }


  const editPlantHttpCall = () => {
    let plantName = "";
    if (values.plant) {
      plantName = values.plant;
    } else {
      plantName = currentPlant.name;
    }
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', Authorization: token },
      body: JSON.stringify({ name: plantName, company_id: currentPlant.companyId, plant_id: currentPlant.id })
    };

    let data = [];
    fetch(`${PEDIT}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json()})
      .then((result) => {
        if (result.success) {
          setSnackMsg("Branch edited successfully")
          setSnackSeverity("success")
          setOpenSnack(true)
          onChange(true);
          handleClose();
        }
        else {
          setSnackMsg(`Branch editing failed because ${result.message}`)
          setSnackSeverity("error")
          setOpenSnack(true)
        }

      }).catch(err => {
        err.text().then(errorMessage => {

        })
      });
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setClose(false);
  };
  // { console.log("Current Plant is:", currentPlant) }
  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ background: "#808080", color: "white", fontSize: "18px" }}>BRANCH
        <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs TabIndicatorProps={{ style: { background: "gray" } }}
                value={value} onChange={handleChange}>
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Edit" {...a11yProps(1)} />

              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Branch Name</label>
                                  <input type="text" className="form-control capitalize" value={currentPlant.name} disabled />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Hospital Name</label>
                                  <input type="text" className="form-control capitalize" value={currentPlant.companyName} disabled />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Created At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentPlant.createdAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Updated At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentPlant.updatedAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                           
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form onSubmit={submitForm} noValidate>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Branch Name</label>
                                  <InputField
                                    ref={inputRefs.current[0]}
                                    label="Name"
                                    name="plant"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control capitalize"
                                    defaultValue={currentPlant.name}

                                  />
                                </div>
                              </div>
                           
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Hospital Name</label>
                                  <InputField
                                    disabled={true}
                                    ref={inputRefs.current[2]}
                                    label="Hospital"
                                    name="company"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control capitalize"
                                    defaultValue={currentPlant.companyName}
                                  />
                                </div>
                              </div>
                            </div>
                            <button type="submit" className="btn" style={{float:'right',margin:'30px 0px -15px 0px'}}>save </button>
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>

        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
            </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));