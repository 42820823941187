import React, { useEffect, useState, PureComponent } from "react";
import { Link } from "react-router-dom";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";

import Badge from "@material-ui/core/Badge";
import { red, orange } from "@material-ui/core/colors";
import { alarmSubscribers } from "../../alarmService";
import { withStyles } from "@material-ui/styles";
import * as Constants from "../constants";

const theme = createMuiTheme({
  spacing: 4,
});

const useStyles = () => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  high: {
    color: "#fff",
    backgroundColor: red[500],
  },
  low: {
    color: "#fff",
    backgroundColor: "#5e35b1",
  },
  medium: {
    color: "#fff",
    backgroundColor: orange[500],
  },
});

class VariantAvatars extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      priority: { h: 0, m: 0, l: 0 },
    };
    this.subscription = null;
  }
  updateSate = (data) => {
    if (data && Array.isArray(data)) {
      // console.log("header value--");
      let newProps = { h: 0, m: 0, l: 0 };
      data.forEach((data, index) => {
        if (data.Severity == Constants.HPALARM_SUB) {
          newProps.h++;
        } else if (data.Severity == Constants.LPALARM_SUB) {
          newProps.l++;
        } else if (data.Severity == Constants.MPALARM_SUB) {
          newProps.m++;
        }
      });
      this.setState((prvState) => {
        return { priority: newProps };
      });
    }
  };

  componentDidMount() {
    this.subscription = alarmSubscribers.subscribe((data) => {
      //console.log("Header-", data);
      this.updateSate(data);
    });
  }
  componentWillUnmount() {
    //console.log("[Avtar removed]----------did mont");
    this.subscription.unsubscribe();
  }
  render() {
    const { priority } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Badge
          style={{ marginRight: "14px" }}
          badgeContent={priority.h}
          color="secondary"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Link to="/alpha-vents">
            <Avatar
              variant="rounded"
              className={classes.high}
              onClick={() => {
                this.props.updateAlarmListStates({
                  ...{
                    Ctype: "danger",
                    type: "High",
                    description: "High Priority Alarming vents",
                    SelectedSeverity: 1,
                  },
                });

                console.log("High->", {
                  Ctype: "danger",
                  type: "High",
                  description: "High Priority Alarming vents",
                  SelectedSeverity: 1,
                });
              }}
            >
              H
            </Avatar>
          </Link>
        </Badge>

        <Badge
          style={{ marginRight: "14px" }}
          badgeContent={priority.m}
          color="error"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Link to="/alpha-vents">
            <Avatar
              variant="rounded"
              className={classes.medium}
              onClick={() => {
                this.props.updateAlarmListStates({
                  ...{
                    Ctype: "warning",
                    type: "Medium",
                    description: "Medium Priority Alarming vents",
                    SelectedSeverity: 2,
                  },
                });

                console.log("M->", {
                  Ctype: "warning",
                  type: "Medium",
                  description: "Medium Priority Alarming vents",
                  SelectedSeverity: 2,
                });
              }}
            >
              M
            </Avatar>
          </Link>
        </Badge>
      </div>
    );
  }
}

export default withStyles(useStyles)(VariantAvatars);
