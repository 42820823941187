import React, { useEffect, useState, Component, PureComponent } from "react";
import ProfilePic from "./blank-profile-picture.png"
class ProfilePage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="content">
                <div className="container-fluid">
                    <div className="row custPadding">
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header " style={{background:"gray"}}>
                                    <h4 className="card-title" style={{color:"white"}}>Profile</h4>
                                </div>
                                <div className="card-body">
                                    <form>
                                        <div className="row"> 
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="bmd-label-floating">Username</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="bmd-label-floating">Email address</label>
                                                    <input type="email" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="bmd-label-floating">Fist Name</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="bmd-label-floating">Last Name</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="bmd-label-floating">Role</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label className="bmd-label-floating">Plant</label>
                                                    <input type="text" className="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                        <button type="submit" className="btn pull-right">Update Profile</button>
                                        <div className="clearfix"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card card-profile" style={{paddingTop:"30px"}}>
                                <div className="card-avatar">
                                        <img className="img"  src={ProfilePic}  />  
                                </div>
                                <div className="card-body">
                                    <h6 className="card-category text-gray">Admin</h6>
                                    <h4 className="card-title">Sujan Shetty</h4>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}
export default ProfilePage;