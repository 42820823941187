import React from "react";
import ReactDOM from "react-dom";
import "./css/material-dashboard.css?v=2.1.0";
import LandingPage from "./components/LandingPage";
//import LandingPage from "./components/demoCode";

import * as serviceWorker from "./serviceWorker";
// import createBrowserHistory from "history/createBrowserHistory";
// const history = createBrowserHistory();
ReactDOM.render(
    <LandingPage />
  , document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
console.log("process.env.REACT_APP_RABBITMQ", process.env.REACT_APP_RABBITMQ);
console.log(
  "process.env.REACT_APP_SAILSSOCKET",
  process.env.REACT_APP_SAILSSOCKET
);
if (process.env.NODE_ENV !== "development") {
  console.log = () => { };
}
