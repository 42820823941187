import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import "../App.css";
import MapContainer from "../GoogleMapContainer";
import InputField from "./validation/editfrom";
import { MACHINE_CREATE, UPLOAD_DEV } from "../../../api-route";
// import Parser from "json2csv";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import {fetchplantByCid,} from "../../../services/http";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function AddNewPlantDialog(props) {
  const { open, setClose, onChanged, companyList } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [values, setValues] = React.useState({});
  const [selected2, setselected2] = useState("");
  const [hasError2, sethasError2] = useState(false);
  // const [company, setCompany] = React.useState([]);
  const [file, setFile] = React.useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  let company = companyList;
  //Form Validating
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [data, setData] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [selected1, setselected1] = useState("");
  const [hasError1, sethasError1] = useState(false);
  const [formatvalue, setFormatvalue] = React.useState("single");
  // const [visibledata, setVisibledata] = React.useState("true");
  const [sessionExpired, setSessionExpired] = React.useState(false);

  const handleInputChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };
  const handleFormatChange = (e) => {
    console.log(e.target.value);
    setFormatvalue(e.target.value);
    if (e.target.value === "bulk") {
    } else {
    }
  };
  const handleChangedrop1 = (e) => {
    if (e.target.value) {
      setselected1(e.target.value);
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
    sethasError1(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();

      if (!valid) {
        isValid = false;
      }
    }
    sethasError1(false);
    if (!selected1) {
      sethasError1(true);
      isValid = false;
    }

    sethasError2(false);
    if (!selected2) {
      sethasError2(true);
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        deviceId: data.deviceId,
        plant_id: values.plantId,
        roomNo: data.roomNo,
        bedId: data.bedId,
        userId: _user.email,
        company_id: values.company,
      }),
    };

    fetch(`${MACHINE_CREATE}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json();
      })
      .then((result) => {
        if (result.success) {
          setSnackMsg("Device added successfully");
          setSnackSeverity("success");
          onChanged("reset-filter");
          handleClose();
        } else {
          setSnackMsg(result.message);
          setSnackSeverity("error");
        }
        setOpenSnack(true);
      })
      .catch((err) => {
        console.log("Error while device adding:", err);
      });
  };
  const ConvertToCSV = (objArray, headerList) => {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";
    let row = "S.No,";

    for (let index in headerList) {
      row += headerList[index] + ",";
    }
    row = row.slice(0, -1);
    str += row + "\r\n";
    for (let i = 0; i < array.length; i++) {
      let line = i + 1 + "";
      for (let index in headerList) {
        let head = headerList[index];

        line += "," + array[i][head];
        // console.log("line", line);
      }
      str += line + "\r\n";
    }
    return str;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setClose(false);
  };
  const downloadFile = (data) => {
    // console.log("downlo");
    const fields = [
      "name",
      "roomNo",
      "bedId",
      "created",
      "reason",

      // {label:"",value:""},
    ];

    // const json2csvParser = new Parser({
    //   fields,
    //   unwind: ["data"],
    // });

    // const csv = json2csvParser.parse(data);
    var jsonObject = JSON.stringify(data);

    var csv = ConvertToCSV(jsonObject, fields);

    let blob = new Blob(["\ufeff" + csv], { type: "text/csv;charset=utf-8;" });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("Chrome") == -1;
    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);

    dwldLink.setAttribute(
      "download",
      "Responcedata" +
        new Date().toISOString() +
        "" +
        (Math.random() * 100 + 1) +
        ".csv"
    );

    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  };

  const convertToCSV = (objArray) => {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";

    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  };
  const handleChangedrop2 = (e) => {
    if (e.target.value) {
      setselected1("");
      setselected2(e.target.value);
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
      renderPlantTableData(e.target.value);
    }
    sethasError2(false);
  };

  const renderPlantTableData = async (v) => {
    setItems([]);
    let token = localStorage.getItem("abmUsertoken");
    const data = await fetchplantByCid(token, v);
    // console.log("data===== plant =============", data);
    if (data&&data.data && data.data.length > 0) {
      setItems(data.data);
    }
  };

  const onFormSubmit = (files) => {
    files.preventDefault(); // Stop form submit
    fileUpload(file);

    // csvJSON(files )
  };
  const onChangedfile = (e) => {
    setFile(e.target.files[0]);
  };

  const fileUpload = (file) => {
    let isValid = true;
    sethasError1(false);
    if (!selected1) {
      sethasError1(true);
      isValid = false;
    }

    sethasError2(false);
    if (!selected2) {
      sethasError2(true);
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    console.log(file.name);
    if (file) {
      var filenames = file.name.split(".")[1];
      if (filenames !== "csv") {
        setSnackMsg(`Accepts only csv file `);
        setSnackSeverity("error");
        setOpenSnack(true);
        return;
      }
    }

    const url = "http://example.com/file-upload";
    const formData = new FormData();
    let token = localStorage.getItem("abmUsertoken");
    console.log(file);
    const data = new FormData();
    data.append("file", file);
    data.append("company_id", values.company);
    data.append("plant_id", values.plantId);

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: data,
    };
    fetch(`${UPLOAD_DEV}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json();
      })
      .then((result) => {
        // console.log("result", result.success);
        if (result.success) {
          setSnackMsg("Device added successfully");
          downloadFile(result.createddata);
          setSnackSeverity("success");
          setOpenSnack(true);
          onChanged('reset-filter');
          // onChanged(true)
          //handleClose();
        } else {
          setSnackMsg(result.message);
          setSnackSeverity("error");
          setOpenSnack(true);
        }
      })
      .catch((err) => {
        console.log("errrr", err);
      });
    // console.log("Machine submitted successfully");
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}></SessionExpiryDialog>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "1rem" }}
        >
          ADD VENTILATOR
          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="add-new-vent-dialog card card-plain">
                      <div className="card-body genrl-tab">
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {/* Select upload format */}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="uplaodformat"
                            name="uplaodformat"
                            value={formatvalue}
                            onChange={handleFormatChange}
                          >
                            <FormControlLabel
                              value="single"
                              control={<Radio />}
                              label="Single upload"
                            />
                            <FormControlLabel
                              value="bulk"
                              control={<Radio />}
                              label="Bulk upload"
                            />
                          </RadioGroup>
                        </FormControl>
                        <div
                          style={{
                            display:
                              formatvalue === "single" ? "block" : "none",
                          }}
                        >
                          <form onSubmit={submitForm} noValidate>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Device Id
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[0]}
                                    label="Device Id"
                                    name="deviceId"
                                    placeholder="Device id once registered is not editable"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Bed Id
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[1]}
                                    label="Bed Id"
                                    name="bedId"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control"
                                  />
                                </div>
                              </div> */}
                            </div>
                            <div className="row">
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Room No
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[2]}
                                    label="Room No"
                                    name="roomNo"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control"
                                  />
                                </div>
                              </div> */}

                              {/*                              
                            </div>

                            <div className="row"> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Hospital Name{" "}
                                  </label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    error={hasError2}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="company"
                                      value={selected2}
                                      onChange={handleChangedrop2}
                                      style={{ backgroundColor: "white",textTransform:'capitalize' }}
                                    >
                                      {company.map((item, value) => (
                                        <MenuItem key={value} value={item.id} style={{textTransform:'capitalize' }}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {hasError2 && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        please select a hospital.
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  display: selected2 ? "block" : "none",
                                }}
                              >
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Branch Name{" "}
                                  </label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    error={hasError1}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="plantId"
                                      value={selected1}
                                      onChange={handleChangedrop1}
                                      style={{ backgroundColor: "white",textTransform:'capitalize' }}                                    >
                                      {items.length === 0 ? (
                                        <MenuItem>
                                          Branch Not Available
                                        </MenuItem>
                                      ) : (
                                        items.map((item, value) => (
                                          <MenuItem key={value} value={item.id} style={{textTransform:'capitalize' }}>
                                            {item.name}
                                          </MenuItem>
                                        ))
                                      )}
                                    </Select>
                                    {hasError1 && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Branch must be selected!
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                            </div>

                            <button type="submit" className="btn" style={{float:'right',marginRight:"20px"}}>
                             Submit
                            </button>
                            <div className="clearfix"></div>
                          </form>
                        </div>

                        <div
                          style={{
                            display: formatvalue === "bulk" ? "block" : "none",
                          }}
                        >
                          <form
                            onSubmit={onFormSubmit}
                            encType="multipart/form-data"
                            method="post"
                          >
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Hospital Name{" "}
                                  </label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    error={hasError2}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="company"
                                      value={selected2}
                                      onChange={handleChangedrop2}
                                      style={{ backgroundColor: "white",textTransform:'capitalize' }}
                                    >
                                      {company.map((item, value) => (
                                        <MenuItem key={value} value={item.id} style={{textTransform:'capitalize' }}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {hasError2 && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        please select a hospital.
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  display: selected2 ? "block" : "none",
                                }}
                              >
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Branch Name{" "}
                                  </label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    error={hasError1}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="plantId"
                                      value={selected1}
                                      onChange={handleChangedrop1}
                                      style={{ backgroundColor: "white" }}
                                    >
                                      {items.length === 0 ? (
                                        <MenuItem>
                                          Branch Not Available
                                        </MenuItem>
                                      ) : (
                                        items.map((item, value) => (
                                          <MenuItem key={value} value={item.id} style={{textTransform:'capitalize' }}>
                                            {item.name}
                                          </MenuItem>
                                        ))
                                      )}
                                    </Select>
                                    {hasError1 && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Branch must be selected!
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                            <br></br>
                            <input
                              required={true}
                              type="file"
                              name="file"
                              accept=".csv"
                              onChange={onChangedfile}
                            />
                            <br></br>
                            <br></br>

                            <button className="btn" type="submit" style={{float:'right',marginRight:"20px"}}>
                              Upload
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <TabPanel value={value} index={1}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12" style={{ minHeight: "300px" }}>
                      <form
                        onSubmit={onFormSubmit}
                        enctype="multipart/form-data"
                        method="post"
                      >
                        <input
                          required="true"
                          type="file"
                          name="file"
                          accept=".csv"
                          onChange={onChangedfile}
                        />
                        <button type="submit" style={{float:'right',marginRight:"20px"}} >Upload</button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12" style={{ minHeight: "300px" }}>
                      <Map />
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

function Map() {
  if (navigator.onLine) {
    return <MapContainer></MapContainer>;
  } else {
    return <div>Oops.. No Internet connection</div>;
  }
}
