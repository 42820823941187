export const PORT = 1337;
//export const BASE_URL = `http://localhost:${PORT}`; // local
var base_url = "";
if (process.env.NODE_ENV !== "production") {
  //developement
  base_url = process.env.REACT_APP_SAILSSOCKET;
} else {
  // production
  base_url = process.env.REACT_APP_SAILSSOCKET;
  //base_url = `http://localhost:${PORT}`;
  //base_url = `https://${window.location.hostname}`;
}
export const BASE_URL = base_url;

export const LOGIN = BASE_URL + "/api/User/login";
export const LOGOUT = BASE_URL + "/api/User/logout";
export const ALARM = BASE_URL + "/api/Alarm";
export const PCREATE = BASE_URL + "/api/Plant";
export const PGET = BASE_URL + "/api/PlantList";
export const PEDIT = BASE_URL + "/api/editPlantById";

export const USERINVITE = BASE_URL + "/api/User/invite";
export const USERCREATE = BASE_URL + "/api/User/create";
export const ADMINEDIT = BASE_URL + "/api/User/edit";

export const USERLIST = BASE_URL + "/api/User";
export const TOTALVENTS = BASE_URL + "/api/Machine/Plant/";
export const DEVICEALARM = BASE_URL + "/api/Machine/company_id/plant_id/";
export const SOCKET_DEVICESETTING = "/api/Machine/settings";
export const ALL_DEVICE = "/api/Machine/100/P5@100/machinelist";
export const LOG = "/api/ventilator/log";
export const UPLOAD_DEV = BASE_URL + "/api/uploaddevice";
export const MACHINE_CREATE = BASE_URL + "/api/Machine/create";
export const GET_MACHINE = BASE_URL + "/api/Machine";
export const COMPANY_CREATE = BASE_URL + "/api/Company/create";
export const COMPANY_REGISTER = BASE_URL + "/api/register/company";
export const PLANT_REGISTER = BASE_URL + "/api/register/plant";
export const DEVICEALRAM_ID = BASE_URL + "/api/Alarm/DeviceById";
export const REGISTER_ME_TO_COMPNAY = BASE_URL + "/api/register/company/me";
export const PLANT_DEL = BASE_URL + "/api/Plant/delete";
export const USER_DEL = BASE_URL + "/api/User/delete";
export const DEVICE_DEL = BASE_URL + "/api/Machine/delete";

//export const COMPANY_LIST = BASE_URL + "/api/Company/list";

export const DEVICE_EDIT = BASE_URL + "/api/Machine/edit";
export const USER_EDIT = BASE_URL + "/api/User/edit";
// super admi route
export const COMPANYLIST = BASE_URL + "/api/Company/list";
export const COMPANYLIST_CID = BASE_URL + "/api/Company/listBasedOnId";
export const USERLIST_CID = BASE_URL + "/api/User/listBasedOnId";
export const PLANTLIST_CID = BASE_URL + "/api/Plant/listBasedOnId";

export const COMPANY_DEL = BASE_URL + "/api/Company/delete";
export const COMPANY_EDIT = BASE_URL + "/api/Company/edit";

export const TOTAL_COUNT = BASE_URL + "/api/totalCount";
export const TOTAL_COUNTFORADMIN = BASE_URL + "/api/totalCountForAdmin";
export const EXPIRYTIME = BASE_URL + "/api/User/ExpiryTime";
export const GETTOKEN = BASE_URL + "/api/User/GetToken";

export const ULOCK = BASE_URL + "/api/User/lock";
export const UUNLOCK = BASE_URL + "/api/User/unlock";
export const CHLOCK = BASE_URL + "/api/User/chlock";
export const FETCH_PLAYLIST = BASE_URL + "/api/User/Playlist";

export const RESETPASSWORD_MAIL = BASE_URL + "/api/User/resetPasswordMail";
export const RESETPASSWORD = BASE_URL + "/api/User/resetPassword";
export const RESETPASSWORD_DECRYPT = BASE_URL + "/api/User/resetPassword_decrypt";

export const MACHINELIST = BASE_URL + "/api/Machine/getMachineList";
export const ALARMLIST_BY_MACHINE_ID = BASE_URL + "/api/Alarm/getAlmListByMachineId";
export const GET_DEVICEINFO = BASE_URL + "/api/DeviceInfo/getDeviceInfo";

export const SEARCH = BASE_URL + "/api/ventsearch?search="
export const SEARCH_IN_ALM = BASE_URL + "/api/ventSearchInAlm"