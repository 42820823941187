import React from "react";

class BackupVentilationBox extends React.PureComponent {
  render() {
    if (this.props.alarm) {
      let _alarmStr = this.props.alarm;
      // console.log("this.props.alarm", _alarmStr.toUpperCase());
      if (_alarmStr.toUpperCase() === "APNEA") {
        return (
          <div
            style={{
              margin: "auto",
              width: "50%",
              border: "2px solid yellow",
              fontSize: "28px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "yellow",
              fontWeight: "600",
              textAlign: "center",
              borderRadius: "7px",
              padding: "4px",
              marginBottom: "2px",
              minWidth: "250px",
            }}
          >
            Backup Mode Activated
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
}

export default BackupVentilationBox;
