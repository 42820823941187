import React, { useState, useEffect } from "react";
import md5 from "md5";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { USERINVITE, USERCREATE } from "../../../api-route";
import { fetchcompany, fetchplantByC_id } from "../../../services/http";
import "../App.css";
// import CMap from "../GoogleMapContainer";
import InputField from './validation/editfrom';
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function AddNewUserDialog(props) {

  const { open, setClose, onChange } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const Plant = [{ name: "Plant1", id: "0" }, { name: "Plant2", id: "1" }, { name: "Plant3", id: "2" }];
  const Role = [{ name: "User", id: "1", value: "user" }];
  //DropdownValidation
  const [selected, setselected] = useState("");
  const [hasError, sethasError] = useState(false);
  const [items, setItems] = React.useState([]);
  const [company, setCompany] = React.useState([]);
  const [selected1, setselected1] = useState("");
  const [hasError1, sethasError1] = useState(false);
  const [selected2, setselected2] = useState("");
  const [hasError2, sethasError2] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({ vertical: "top", horizontal: "center" });

  //Form Validation
  const inputRefs = React.useRef([
    React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()
  ]);

  const [values, setValues] = React.useState({});
  const [sessionExpired, setSessionExpired] = React.useState(false);
  let password = values.password;
  const [state, setState] = React.useState({
    checkedAutoGenPass: true,
  });

  const handleChangeCheckbox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handleChangedrop = e => {
    setselected(e.target.value);
    setValues({ ...values, [e.target.name]: e.target.value });
    sethasError(false);
  }
  const handleChangedrop1 = (e) => {
    if (e.target.value) {
      setselected1(e.target.value);
      setValues({ ...values, [e.target.name]: e.target.value });
    }
    sethasError1(false);
  }

  const handleChangedrop2 = (e) => {
    if (e.target.value) {
      setselected2(e.target.value);
      setValues({ ...values, [e.target.name]: e.target.value });
    }
    sethasError2(false);
  }

  useEffect(() => {
    renderPlantTableData();
    // renderCompanyTableData();
  }, []);

  const renderPlantTableData = async () => {
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    const data = await fetchplantByC_id(token, _user.company_id);
    // console.log("data===== plant =============", data)
    if (data&&data.data && data.data.length > 0) {
      setItems(data.data)
    }
  }

  const renderCompanyTableData = async () => {
    let token = localStorage.getItem("abmUsertoken");
    const data = await fetchcompany(token);
    // console.log("data===== company =============", data)
    if (data && data.length > 0) {
      setCompany(data)
    }
  }


  // let password = values.password;
  const handleInputChange = (name, value) => {
    //const {name , value} = e.target;
    // if(name==="email"){
    //   let validation_code = Math.floor(Math.random() * 1000000);
    // //console.log("validation code by random::" + validation_code);
    //  values["validation_code"]= validation_code
    // }
    setValues({ ...values, [name]: value });
  };

  const updateEmail = (e) => {
    let validation_code = Math.floor(Math.random() * 1000000);
    //console.log("validation code by random::" + validation_code);
    this.setState(
      {
        email: e.target.value,
        validation_code: validation_code
      },
      () => {
        this.validateEmail();
      }
    );
  }

  const submitForm = () => {
    // e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate()
      if (!valid) {
        isValid = false
      }
    }
    //  sethasError2(false);
    //      if (!selected2) {
    //       sethasError2(true);
    //       isValid = false
    //     }
    //     sethasError(false);
    //     if (!selected) {
    //       sethasError(true);
    //       isValid = false
    //     }

    sethasError1(false);
    if (!selected1) {
      sethasError1(true);
      isValid = false
    }
    if (!isValid) {
      return
    }
    // console.log("submit successfully");
    handleInvite();

  }
  const handleInvite = () => {
    console.log("values=====", values)
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    let userdata = {
      fName: values.fName,
      lName: values.lName ? values.lName : "",
      userName: values.fName + ' ' + values.lName ? values.lName : "",
      email: values.email,
      role: "user",
      company_id: _user.company_id,
      companyName: _user.companyName,
      plant_id: values.plant,
      password: values.password ? values.password : null,
      autoGenPass: state.checkedAutoGenPass
    }
    // console.log("userdata++++++:",userdata)
    fetch(USERCREATE, {
      headers: { "Content-Type": "application/json", Authorization: token },
      method: "POST",
      body: JSON.stringify({
        //username:values.username,
        //email: values.email,
        //company_id: _user.company_id,
        //companyName:_user.companyName,
        user: userdata,
        //companyName:Cu
        //plant: values.plant,
        // phone:values.phone,
        //validation_code: values.validation_code,
        //role: values.role
      })
    })
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json()
      })
      .then((result) => {
        // setResultData(result);
        // console.log("result", result)
        if (result.success) {
          setSnackMsg("User added successfully")
          setSnackSeverity("success")
          setOpenSnack(true)
          onChange("reset-filter");
          handleClose();
        }
        else {
          setSnackMsg(`Adding user failed because ${result.message}`)
          setSnackSeverity("error")
          setOpenSnack(true)
        }

      }).catch(err => {
        console.log(err)
      });


  }


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = (e) => {
    setClose(false);

  };
  const handleSnackbar = () => {
    setOpenSnack(false);
  };



  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      < CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "1rem" }}>
          ADD USER
          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
          </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs TabIndicatorProps={{ style: { background: "gray" } }}
                value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Details" {...a11yProps(0)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="add-new-vent-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form noValidate>
                            <div className="row">

                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">User Email</label>
                                  <InputField
                                    ref={inputRefs.current[1]}
                                    label="Email"
                                    name="email"
                                    type="email"
                                    validation="required|emailpattern"
                                    onChange={handleInputChange}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">First Name</label>
                                  <InputField
                                    ref={inputRefs.current[3]}
                                    label="First name"
                                    name="fName"
                                    onChange={handleInputChange}
                                    className="form-control"
                                    validation={"required|userpattern"}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating"> Last Name</label>
                                  <InputField
                                    ref={inputRefs.current[4]}
                                    label="Last name"
                                    name="lName"
                                    onChange={handleInputChange}
                                    className="form-control"
                                  // validation={"required|userpattern"}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating"> Branch </label>
                                  <FormControl style={{ width: "100%" }} error={hasError1} >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="plant"
                                      value={selected1}
                                      onChange={handleChangedrop1}
                                      style={{ backgroundColor: "white",textTransform:'capitalize' }}                                      
                                      >
                                      {items.map((item, value) => (

                                        <MenuItem key={value} value={item.id} style={{textTransform:'capitalize' }}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {hasError1 && <p style={{ color: 'red', fontSize: '12px' }}> Branch must be selected!</p>}
                                  </FormControl>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Role</label>
                                  <FormControl style={{ width: "100%" }}  >
                                    <Select name="role"
                                      // value={Device}
                                      // onChange={}
                                      defaultValue={"user"}
                                      //value={selected}
                                      disabled={true}
                                      //  onChange={handleChangedrop}
                                      style={{ backgroundColor: "white",textTransform:'capitalize' }}                                      
                                      >
                                      {Role.map((item, index) => (
                                        <MenuItem key={index} value={item.value}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {/* {hasError && <p style={{color:'red' , fontSize:'12px'}}> Role must be selected!</p>} */}
                                  </FormControl>
                                </div>
                              </div>

                            </div>

                            <div className="row" style={{ marginTop: '15px', marginBottom: '15px' }}>
                              <div className="col-md-12">
                                <FormGroup row> 
                                  <FormControlLabel
                                    control={<Checkbox checked={state.checkedAutoGenPass} onChange={handleChangeCheckbox} name="checkedAutoGenPass" />}
                                    label="Auto generate random password"
                                  />
                                </FormGroup>
                              </div>
                            </div>

                            {state.checkedAutoGenPass ? null :
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating">Password</label>
                                    <InputField
                                      ref={inputRefs.current[5]}
                                      name="password"
                                      type="password"
                                      label="Password"
                                      validation="required|min:6"
                                      onChange={handleInputChange}
                                      className="form-control"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label className="bmd-label-floating"> Confirm Password </label>
                                    <InputField
                                      ref={inputRefs.current[6]}
                                      name="conf_password"
                                      type="password"
                                      validation="passwordmatch"
                                      onChange={handleInputChange}
                                      className="form-control"
                                      password={password}
                                    />
                                  </div>
                                </div>
                              </div>
                            }
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>

          </div>
        </DialogContent>
        <DialogActions style={{ paddingRight: '20px' }}>
          <button type="button" className="btn" onClick={submitForm} >
           Submit
            </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
