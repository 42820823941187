import React from "react";
import {
  Router,
  Switch,
  HashRouter,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import Mainpanel from "./mainpanel";
import Login from "./login/login-comp";
import Cookies from "js-cookie";
import "../dataService";
import { RBTMQConnect } from "../mqttConnections";
import { httpLogout } from "../services/http";
// import RegMyCompany from "./registerations/company/index";
// import RegMyUser from "./registerations/user/index";
import SuperadminHome from "./superadminPages/homepage/index";
// import QueryString from "query-string";
import NotFound from "./404NotFound";
import ForgotPasswordPage from "./forgotPassword";

//import {RBTMQConnect} from "./mqttConnections"
// import createBrowserHistory from 'history/createBrowserHistory';
// const history = createBrowserHistory();
export default class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      redirect: false,
      removeSideBar: false,
      user: null,
      isLoggedERROR: false,
      isLoggedERRORR: false,
      loading: true,
      // user: {
      //   fName: "user1",
      //   email: "user1@abm.com",
      //   plant_id: "P5@100",
      //   role: "superadmin",
      //   userName: "user1",
      //   company_id: 100,
      // },
      openCacheSnack: true,
    };
  }
  isLoggedTrue(userData) {
    let { user } = userData;
    // this.setState({ isLoggedERRORR: true });
    localStorage.setItem("abmUser", JSON.stringify(user));
    this.setState({
      isLoggedERRORR: true,
      isLogged: true,
      redirect: true,
      user: user,
    });
  }

  sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, 1000));
  };

  logOut() {
    this.setState({ isLoggedERRORR: false });
    let token = localStorage.getItem("abmUsertoken");
    httpLogout({ user: this.state.user, token: token })
      .then((res) => {
        if (res.status == 200 || res.status == 201) {
          window.history.pushState({}, "Login", "/#/");
          // window.location.reload();
          this.setState({ isLogged: false, redirect: false, user: null });
          //var allCookies = document.cookie.split(';');
          //  console.log("Cookies.get==logout before", Cookies.get("token"));
          Cookies.remove("token");
          //console.log("Cookies.get==logout after", Cookies.get("token"));

          // The "expire" attribute of every cookie is
          // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
          // for (var i = 0; i < allCookies.length; i++)
          //     document.cookie = allCookies[i] + "=;expires="
          //     + new Date(0).toUTCString();
          localStorage.clear();
        } else if (res.status >= 500 || res.status <= 599) {
          console.log("Server error------------logout>", res);
          window.alert("internet disconnected ");
        } else {
          console.log("Response------------logout>", res);
        }
      })
      .catch((err) => {
        console.log("error in logout", err);
      });
  }

  handleClick = () => {
    // setOpen(true);
    this.setState({ openCacheSnack: true })
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ openCacheSnack: false })
  };
  render() {
    const { isLoggedERRORR, isLoggedERROR, isLogged, user } = this.state;
    let role = user ? user.role : "";

    if (this.state.loading) {
      return null; //app is not ready (fake request is in process)
    }

    let publicRoute = [
      <Switch>
        {/* <Route
          key={"1"}
          exact={true}
          strict
          path="/about"
          render={() => <h1>About</h1>}
        /> */}
        {/* <Route
          key={"3"}
          path="/register"
          render={() => (
            <Route
              key={"31"}
              path="/register/company"
              render={() => <RegMyCompany />}
            />
          )}
        /> */}
        {/* <Route
          key={"4"}
          path="/register"
          render={() => (
            <Route
              key={"41"}
              path="/register/user"
              render={() => <RegMyUser />}
            />
          )}
        /> */}
        <Route
          key={"5"}
          path="/"
          exact
          render={() => (
            <Login key={"lgin"} isLoggedTrue={this.isLoggedTrue.bind(this)} />
          )}
        />
        {/* <Route
          key={"6"}
          path="/companydetails"
          render={() => (
            <CompanyDetails
              key={"lgin"}
              isLoggedTrue={this.isLoggedTrue.bind(this)}
            />
          )}
        /> */}

        <Route
          key={"7"}
          path="/resetpassword/:id/:token"
          render={() => <ForgotPasswordPage />}
        />

        <Route key={"8"} path="" render={() => <NotFound />} />
      </Switch>,
    ];
    let renderDefaultRouteorRole = [];
    if (role == "admin" || role == "user") {
      renderDefaultRouteorRole.push(
        <Mainpanel
          key={"ion-dashboard"}
          user={user}
          islogg={isLoggedERROR}
          logOut={this.logOut.bind(this)}
        />
      );
      //localhost:3000/
      if (user.email && user.email === process.env.REACT_APP_GUEST_ID) {
        //user.playlist[0].route
        console.log("user.playlist", user.playlist[0].route);
        renderDefaultRouteorRole.push(
          <Redirect key={"guest-screen"} to={user.playlist[0].route} />
        );
      } else {
        renderDefaultRouteorRole.push(
          <Redirect key={"alpha-vents-dashboard-key"} to="/alpha-vents" />
        );
      }
    } else if (role == "superadmin") {
      renderDefaultRouteorRole = [
        // <SuperadminHome logOut={this.logOut.bind(this)} user={user} />,
        // isLoggedERROR ?
        <Switch>
          <Route
            key={"2"}
            path="/home"
            render={() => (
              <SuperadminHome logOut={this.logOut.bind(this)} user={user} />
            )}
          />
          <Route
            key={"8"}
            path=""
            render={() => (isLoggedERRORR == true ? <NotFound /> : null)}
          />
          <Route
            key={"5"}
            path="/"
            exact
            render={() => (
              <Login key={"lgin"} isLoggedTrue={this.isLoggedTrue.bind(this)} />
            )}
          />
        </Switch>,
        <Redirect to="/home" />,
      ];
    }

    return (
      <React.Fragment>
        <HashRouter>
          {/* {console.log("This.props.location", window.location.href)} */}
          {isLogged ? renderDefaultRouteorRole : publicRoute}
        </HashRouter> </React.Fragment>
    );
  }
  componentDidMount() {
    //  setTimeout( () => this.setState({isLoggedERROR:true}), 2000);
    //---------------------------------------------------------------------------progrss bar removal

    this.sleep().then(() => {
      // Pace.stop()
      const el = document.querySelector(".loader-container");
      const el1 = document.querySelector(".loader-container1");
      if (el && el1) {
        el.remove(); // removing the spinner element
        el1.remove(); // removing the spinner element
        this.setState({ loading: false }); // showing the app
      }

      // if (el1) {
      //   el1.remove();  // removing the spinner element
      // }
    });

    //---------------------------------------------------------------------------------------------

    this.subscription = RBTMQConnect.subscribe((observer) => {
      console.log("Rabit mqtt conneted", observer);
    });
    let user = localStorage.getItem("abmUser");
    // console.log("localUser", user);
    try {
      let _user = JSON.parse(user);
      if (_user) {
        this.isLoggedTrue({ user: _user });
      } else {
        console.log("logout is called");
        // this.logOut();
        //window.history.pushState({}, "Login", "/");
      }
    } catch (err) {
      console.log("expection in localUser", err);
      this.logOut();
    }
  }
  componentWillUnmount() {
    this.subscription.unsubscribe();
  }
}
