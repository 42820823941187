import {
  LOG,
  LOGOUT,
  PGET,
  USERLIST,
  GET_MACHINE,
  COMPANYLIST,
  PLANTLIST_CID,
  USERLIST_CID,
  COMPANYLIST_CID,
  ULOCK,
  UUNLOCK,
  CHLOCK,
  FETCH_PLAYLIST,
} from "../api-route";

const httpLogout = ({ user, token }) => {
  if (!user) {
    throw "httpLogout 'expected user: found undefined";
  }
  return fetch(LOGOUT, {
    method: "post",
    body: JSON.stringify(user),
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    withCredentials: true,
  }).then((res) => res);
};
const httpVentilatorSettingsAuditlog = ({ key, value, ventId }) => {
  console.log("{ key, value, ventId, _uid }", { key, value, ventId });
  if (!key || !value || !ventId) {
    throw "httpVentilatorSettingsAuditlog 'expected { key, value, ventId, _uid }: found undefined";
  }
  key = key.trim();
  let event_desc = null;
  //  let  settings= {
  //     BR: 0,
  //     ExpTrig: 0,
  //     InspP: 0,
  //     InspT: 0,
  //     InspTrig: 0,
  //     PEEP: 0,
  //     PSUP: 0,
  //     RiseT: 0,
  //     TV: 0,
  //     VBrthOpt: 0,
  //     VMode: 0,
  //   },
  switch (key) {
    case "BR":
      event_desc = `[${ventId}] Breath Rate settins changed to ${value} BPM`;
      break;
    case "ExpTrig":
      event_desc = `[${ventId}] Exp.Trig settins changed to ${value}`;
      break;
    case "InspP":
      event_desc = `[${ventId}] Insp.Press settins changed to ${value} cm `;
      break;
    case "InspT":
      event_desc = `[${ventId}] Insp.Time settins changed to ${value}`;
      break;
    case "InspTrig":
      event_desc = `[${ventId}] Insp.Trigg settins changed to ${value}`;
      break;
    case "PEEP":
      event_desc = `[${ventId}] PEEP settins changed to ${value} cm `;
      break;
    case "PSUP":
      event_desc = `[${ventId}] Press.Sup settins changed to ${value} cm`;
      break;
    case "RiseT":
      event_desc = `[${ventId}] RiseT settins changed to ${value}`;
      break;
    case "TV":
      event_desc = `[${ventId}] Tidal Vol settins changed to ${value} ml`;
      break;
    case "VBrthOpt":
      event_desc = `[${ventId}] VBrthOpt settins changed to ${value}`;
      break;
    case "VMode":
      event_desc = `[${ventId}] VMode settins changed to ${value}`;
      break;
    default:
      event_desc = null;
      break;
  }

  return fetch(LOG, {
    method: "post",
    body: JSON.stringify({ event_desc: event_desc }),
  }).then((res) => res);
};

const httpVentilatorAlmSettingsAuditlog = ({ oldSett, newSett, ventId }) => {
  console.log("{ oldSett, newSett, ventId, _uid }", {
    oldSett,
    newSett,
    ventId,
  });
  if (!oldSett || !newSett || !ventId) {
    throw "httpVentilatorSettingsAuditlog 'expected { key, value, ventId, _uid }: found undefined";
  }

  let changedKy = [];
  for (let key in oldSett) {
    if (oldSett[key] != newSett[key]) {
      let newKey = {};
      newKey[key] = newSett[key];
      changedKy.push(newKey);
    } else {
      // console.log(
      //   `oldSett[${key}:${oldSett[key]}] != newSett[${key}:${newSett[key]}]`,
      //   oldSett[key] != newSett[key]
      // );
    }
  }
  console.log("Changed keys", changedKy);
  let event_descs = changedKy.map((ele) => {
    let keys = Object.keys(ele);
    let key = keys[0].trim();
    let values = Object.values(ele);
    let value = values[0];
    let event_desc = null;
    switch (key) {
      case "APN":
        event_desc = `[${ventId}] Apnea alarm changed to ${value} BPM`;
        break;
      case "BR_H":
        event_desc = `[${ventId}] Breath R high alarm changed to ${value}`;
        break;
      case "BR_L":
        event_desc = `[${ventId}] Breath R low alarm changed to ${value} cm `;
        break;
      case "CKTD":
        event_desc = `[${ventId}] Circuit Disc high alarm changed to ${value}`;
        break;
      case "FIO2_H":
        event_desc = `[${ventId}] Insp.Trigg alarm changed to ${value}`;
        break;
      case "FIO2_L":
        event_desc = `[${ventId}] PEEP alarm changed to ${value} cm `;
        break;
      case "MV_H":
        event_desc = `[${ventId}] Minute V high alarm changed to ${value} cm`;
        break;
      case "MV_L":
        event_desc = `[${ventId}] Minute V low alarm changed to ${value}`;
        break;
      case "PEEP_H":
        event_desc = `[${ventId}] Peep high alarm changed to ${value} ml`;
        break;
      case "PEEP_L":
        event_desc = `[${ventId}] Peep low alarm changed to ${value}`;
        break;
      case "P_H":
        event_desc = `[${ventId}] Peak P high alarm changed to ${value}`;
        break;
      case "P_L":
        event_desc = `[${ventId}] Peak P low alarm changed to ${value}`;
        break;
      case "SPO2_H":
        event_desc = `[${ventId}] SPO2 high alarm changed to ${value}`;
        break;
      case "SPO2_L":
        event_desc = `[${ventId}] SPO2 low  alarm changed to ${value}`;
        break;
      case "TV_H":
        event_desc = `[${ventId}] Tidal Vol high alarm changed to ${value}`;
        break;
      case "TV_L":
        event_desc = `[${ventId}] Tidal Vol low  alarm changed to ${value}`;
        break;
      default:
        event_desc = null;
        break;
    }
    return event_desc;
  });

  return fetch(LOG + "?alarm=1", {
    method: "post",
    body: JSON.stringify({ event_descs: event_descs }),
  }).then((res) => res);
};

const fetchplant = async ({ token, limit, offset, branchFilterByCompany, serachKey }) => {
  // let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    // body: JSON.stringify({ name:plant.plant  })
  };
  const res = await fetch(
    `${PGET}?limit=${limit}&offset=${offset}&company_id=${branchFilterByCompany}&search=${serachKey}`,
    requestOptions
  );
  // const data = await res.json();
  //console.log("coursesbody is:",data)
  return res;
};

const fetchplantByC_id = async (token, cid) => {
  // let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    // body: JSON.stringify({ name:plant.plant  })
  };
  const res = await fetch(`${PLANTLIST_CID}?company_id=${cid}`, requestOptions);
  const data = await res.json();
  //console.log("coursesbody is:",data)
  return data;
};

const fetchplantByCid = async (token, cid) => {
  // let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    // body: JSON.stringify({ name:plant.plant  })
  };
  const res = await fetch(`${PGET}?company_id=${cid}`, requestOptions);
  const data = await res.json();
  //console.log("coursesbody is:",data)
  return data;
};

const fetchmachine = async ({ token, limit, offset, deviceFilterByCompany, deviceFilterByBranch, serachKey }) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    // body: JSON.stringify({ name:plant.plant  })
  };
  const res = await fetch(
    `${GET_MACHINE}?limit=${limit}&offset=${offset}&company_id=${deviceFilterByCompany}&plant_id=${deviceFilterByBranch}&search=${serachKey}`,
    requestOptions
  );
  // const data = await res.json();
  // console.log("coursesbody is:", data)
  return res;
};

const fetchcompany = async ({ token, limit, offset }) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
    // body: JSON.stringify({ name:plant.plant  })
  };
  const res = await fetch(`${COMPANYLIST}`, requestOptions);
  const data = await res.json();
  // console.log("coursesbody is:", data)
  return data;
};

const fetchuser = async ({ token, limit, offset, userFilterByCompany, userFilterByBranch, searchKey }) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const res = await fetch(
    `${USERLIST}?limit=${limit}&offset=${offset}&company_id=${userFilterByCompany}&plant_id=${userFilterByBranch}&search=${searchKey}`,
    requestOptions
  );
  // const data = await res.json();
  //console.log("coursesbody is:",data)
  return res;
};

const fetchuserByCid = async ({ token, cid, limit, offset,userFilterByBranch,searchKey }) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  const res = await fetch(
    `${USERLIST_CID}?company_id=${cid}&limit=${limit}&offset=${offset}&plant_id=${userFilterByBranch}&search=${searchKey}`,
    requestOptions
  );

  // const data = await res.json();
  //console.log("coursesbody is:",data)
  return res;
};
const lock = async ({ token }) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  return fetch(`${ULOCK}/me`, requestOptions);

  // const data = await res.json();
  //console.log("coursesbody is:",data)
  // return res;
};
const unlock = async ({ token }) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  return fetch(`${UUNLOCK}/me`, requestOptions);

  // const data = await res.json();
  //console.log("coursesbody is:",data)
  // return res;
};
const checkLock = async ({ token }) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  return fetch(`${CHLOCK}/me`, requestOptions);

  // const data = await res.json();
  //console.log("coursesbody is:",data)
  // return res;
};

const fetchPalylist = async ({ token }) => {
  //let token=localStorage.getItem("abmUsertoken");
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token },
  };
  return fetch(`${FETCH_PLAYLIST}`, requestOptions);

  // const data = await res.json();
  //console.log("coursesbody is:",data)
  // return res;
};

export {
  httpLogout,
  fetchcompany,
  httpVentilatorSettingsAuditlog,
  httpVentilatorAlmSettingsAuditlog,
  fetchplant,
  fetchuser,
  fetchmachine,
  fetchplantByCid,
  fetchuserByCid,
  fetchplantByC_id,
  lock,
  unlock,
  checkLock,
  fetchPalylist,
};
//httpLogout({ user: "name,rahul" });

// alarmSettings: {

//   PEEP_H: 0,
//   PEEP_L: 0,
//   P_H: 0,
//   P_L: 0,
//   SPO2_H: 0,
//   SPO2_L: 0,
//   TV_H: 0,
//   TV_L: 0,
// },
