import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InputField from "./validation/editfrom";
import "../App.css";
import { COMPANY_EDIT } from "../../../api-route";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function CompanyDetailsDialog(props) {
  const { open, setClose, currentCompany, onChanged } = props;
  // console.log("CUrrent company:",currentCompany)
  let maxuser = currentCompany.max_user ? currentCompany.max_user : 5;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [selected, setSelected] = useState(maxuser);
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const [phoneWithoutCode, setPhoneWithoutCode] = React.useState(currentCompany.phone);
  // console.log("Phone has county code?", currentCompany.phone.includes("+"))
  const [phone, setPhone] = React.useState(!currentCompany.phone.includes("+") ? "+91 " + currentCompany.phone : currentCompany.phone);
  const [phoneError, setPhoneError] = React.useState("");
  //Form Validation
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [data, setData] = React.useState({});
  const [sessionExpired, setSessionExpired] = React.useState(false);
  const handleInputChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const editCompany = async () => {
    if ("name" in data || "email" in data || "phone" in data || 'max_user' in data ||!currentCompany.phone.includes('+')) {
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          id: currentCompany.id,
          userId: _user.email,
          edited: {...data,phone:phone},
        }),
      };

      const res = await fetch(`${COMPANY_EDIT}`, requestOptions);
      if (res.status === 401) {
        setSessionExpired(true);
        return;
      }
      // console.log("Response After editing:", res)
      const msg = await res.text();
      if (res.status === 200) {
        // console.log("Response After editing res.status:", res.status)
        setSnackMsg("Hospital edited successfully");
        setSnackSeverity("success");
        setOpenSnack(true);
        onChanged(true);
        handleClose();
      } else {
        setSnackMsg(`Hospital editing failed because ${msg}`);
        setSnackSeverity("error");
        setOpenSnack(true);
      }
    } else {
      setSnackMsg("Nothing edited");
      setSnackSeverity("info");
      setOpenSnack(true);
    }
  };
  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    if (phoneWithoutCode.length == 0) {
      setPhoneError("Phone number is required")
      isValid = false
    }

    if (!isValid) {
      return;
    }
    editCompany();
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setData({});
    setClose(false);
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const handleChangeDropdown = (e) => {
    if (e.target.value) {
      setSelected(e.target.value);
      setData({
        ...data,
        [e.target.name]: e.target.value,
      });
    }
  };
  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      {openSnack ?
        <CustomsSnackbar
          msg={snackMsg}
          flag={openSnack}
          onClose={handleSnackbar}
          key={"cusSnackbar"}
          anchorOrigin={customeSnackbarAnchor}
          severity={snackSeverity}
        /> : null}
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "18px" }}
        >
          HOSPITAL
          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                TabIndicatorProps={{ style: { background: "gray" } }}
                value={value}
                onChange={handleChange}
              >
                <Tab label="Details" {...a11yProps(0)} />
                <Tab label="Edit" {...a11yProps(1)} />
                {/* <Tab label="Devices" {...a11yProps(2)} /> */}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Hospital Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentCompany.name}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Maximum Number of Users
                                  </label>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={currentCompany.max_user}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Admin User Email
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={currentCompany.email}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Phone Number
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    // value={currentCompany.phone && !currentCompany.phone.includes('+')
                                    //   ? "+91 " + currentCompany.phone : currentCompany.phone}
                                    value={currentCompany.phone}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Created At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentCompany.createdAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Updated At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentCompany.updatedAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form onSubmit={submitForm} noValidate>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Hospital Name
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[0]}
                                    label="Name"
                                    name="name"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control capitalize"
                                    defaultValue={currentCompany.name}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Maximum Number of Users
                                  </label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="max_user"
                                      value={selected}
                                      onChange={handleChangeDropdown}
                                      style={{ backgroundColor: "white" }}
                                    >
                                      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, value) => (
                                        <MenuItem key={value} value={item}>
                                          {item}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Admin User Email
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[1]}
                                    disabled={true}
                                    label="Email"
                                    name="email"
                                    onChange={handleInputChange}
                                    validation={"required|emailpattern"}
                                    className="form-control"
                                    defaultValue={currentCompany.email}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Phone number
                                  </label>
                                  {/* <InputField
                                    ref={inputRefs.current[2]}
                                    disabled={true}
                                    label="Phone number"
                                    name="phone"
                                    onChange={handleInputChange}
                                    // validation={"required|onlynumber"}
                                    className="form-control"
                                    defaultValue={currentCompany.phone}
                                  /> */}
                                  <PhoneInput
                                    enableSearch
                                    // country={'in'}
                                    value={phone}
                                    onChange={(value, data, event, formattedValue) => {
                                      // console.log('formattedValue:', formattedValue);
                                      setPhoneError('');
                                      setPhone(formattedValue);
                                      setPhoneWithoutCode(value.slice(data.dialCode.length));
                                      handleInputChange('phone', formattedValue);
                                    }
                                    }
                                    inputProps={{
                                      name: 'phone',
                                      required: true,
                                    }}
                                  />
                                  {phoneError && (
                                    <p style={{ color: "red", fontSize: "12px", textAlign: "left" }}>
                                      {phoneError}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <button type="submit" className="btn" style={{ float: 'right', margin: '30px 0px -15px 0px' }}>
                              save{" "}
                            </button>
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
