import { BehaviorSubject } from "rxjs";
//------------------------------
const lockScreenSubject = new BehaviorSubject(0);
const lockScreenEvent = {
  send: (msg) => {
    lockScreenSubject.next(msg);
  },
  receive: (callback) => {
    lockScreenSubject.subscribe((data) => {
      callback(data);
    });
  },
};

export { lockScreenEvent };
