import React from "react";
import { withRouter } from "react-router-dom";
import "./ion-vents-style.css";
import { TOTALVENTS, ALARM, MACHINELIST, ALARMLIST_BY_MACHINE_ID } from "../../api-route";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import Alarm from "./alarm";
import HomeIcon from "@material-ui/icons/Home";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import SearchIcon from "@material-ui/icons/Search";
import { alarmSubscribers, alarmService, showAlmListSubscribers } from "../../alarmService";
import { makeStyles } from "@material-ui/core/styles";
import { VentsCard } from "../ion-vents/searchCard";
import QrCodeDialog from "../Screens/dialog/QrCodeDialog";
import scanner from "../Screens/qr-scan-512.png";
import impexp from "../../img/baseline_import_export_black_18dp.png";
import SessionExpiryDialog from "../Screens/dialog/sessionExpiryDialog";
import Tooltip from "./TooltipButton";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const AlarmType = {
  error: "error",
  info: "info",
  success: "success",
  warning: "warning",
};

class IONVents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showList: false,
      showAlarmList: this.props.AlarmListStates ? true : false,
      AlarmListStates: this.props.AlarmListStates
        ? this.props.AlarmListStates
        : {},
      hpalarm: 0,
      mpalarm: 0,
      lpalarm: 0,
      noalarm: 0,
      data: [],
      openSnackbar: false,
      snackbarData: {
        severity: "success",
        message: "test message",
      },
      searchInput: "",

      //-----------------------
      totalVents: localStorage.getItem("totalVents"),
      liveVents: 0,
      standBy: 0,
      openQrCodeDialogs: false,
      sessionExpired: false,
      deviceList: null
    };

    this.Constants = {
      HPALARM_SUB: 1,
      MPALARM_SUB: 2,
      LPALARM_SUB: 3,
      NOALARM_SUB: 4,
    };
    this.intervalTimer = setInterval(
      // this.pingLiveVents,
      this.getLiveVents,
      process.env.REACT_APP_PULLING_MS
    );
    this.alarmData = null;
    //-------------------------
    this.alarmServices = null;
    this.alarmServiceSource = null;
    this.alarmServiceSubscription = null;
    this.showPriorityAlm = null;
    this.subsAlarm = null;
    //---------------------------
  }
  openQrCodeDialog = () => {
    this.setState({
      openQrCodeDialogs: true,
    });
    //setopenQrCodeDialogs(true);
  };
  closeQrCodeDialog = () => {
    this.setState({
      openQrCodeDialogs: false,
    });
  };
  showAlarmList = (dataToBeSet) => {
    if (dataToBeSet) {
      if (Object.keys(dataToBeSet).length) {
        this.setState({
          showAlarmList: true,
          AlarmListStates: dataToBeSet,
        });
      } else {
        this.setState({
          showAlarmList: false,
        });
      }
    } else {
      this.setState({
        showAlarmList: false,
      });
    }
  };

  action = (data) => {
    let returenResult = this.props.ventilators.filter(
      (el) => el.deviceId == data.deviceId
    );
    if (returenResult.length) {
      this.props.removeVentCompletelyById(data.deviceId);
    } else {
      this.props.addNewVentToWatchList(data);
    }
    //console.log("OnAction", data);
  };
  //ionVentNavigationProps
  AlarmListComponent = () => {
    let { data, AlarmListStates, searchInput } = this.state;
    console.log("AlarmListComponent", searchInput);

    return [
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"Snackbar"}
        open={this.state.openSnackbar}
        autoHideDuration={6000}
        onClose={this.handleSnackbar}
      >
        <Alert
          onClose={this.handleSnackbar}
          severity={this.state.snackbarData.severity}
        >
          {this.state.snackbarData.message}
        </Alert>
      </Snackbar>,

      <HomeIcon
        className="homeIconMarginLeft"
        style={{ cursor: "hand" }}
        fontSize={"large"}
        titleAccess={"Alpha Vents"}
        onClick={() => {
          this.setState({ showAlarmList: false });
        }}
      />,
      <div className="card">
        <div className="card-body">
          <div className="row custPadding">
            {/* Search bar code starts */}
            <div className="col-md-12">
              <div className="form-group" style={{ marginTop: "10px" }}>
                <form autoComplete="off" method="post" action="">
                  <input
                    autoComplete="false"
                    name="hidden"
                    type="text"
                    style={{ display: "none" }}
                  />
                  <div
                    className="input-group md-form form-sm form-2 pl-0"
                    style={{ border: "1px solid #8080809e" }}
                  >
                    <input
                      className="form-control my-0 py-1 "
                      id="searchInputAlm"
                      type="text"
                      placeholder="Search vent ex: Vent001 "
                      aria-label="Search"
                      style={{
                        paddingLeft: "10px",
                        background: "none",
                      }}
                      onChange={(event) => {
                        let val = event.target.value.toUpperCase().trim();
                        //console.log("Printing");
                        this.setState({
                          searchInput: val,
                        });
                      }}
                    />
                    <div className="input-group-append">
                      <span
                        style={{
                          display: "inline-flex",
                          justifyContent: "center",
                          width: "60px",
                          alignItems: "center",
                          height: "36px",
                        }}
                      >
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {/* Search bar code ends */}

            <div className="col-md-12">
              <h4 className="card-title">{AlarmListStates.description}</h4>

              {data.map((alarm, index) => {
                if (AlarmListStates.SelectedSeverity == alarm.Severity) {
                  let enableWatch = this.props.ventilators.filter(
                    (ele) => ele.deviceId == alarm.deviceId
                  );
                  enableWatch = enableWatch.length ? true : false;

                  if (searchInput.length == 0) {
                    return (
                      <div
                        className={`alert alert-${AlarmListStates.Ctype}`}
                        style={{ cursor: "hand" }}
                        key={"ui-" +
                          new Date().getMilliseconds().toString() +
                          Math.random().toString(16).substr(2, 8)}
                      >
                        <Alarm
                          onTap={this.action}
                          quickView={enableWatch}
                          key={index}
                          type={AlarmListStates.Ctype}
                          ventilator={alarm}
                        />
                      </div>
                    );
                  } else {
                    let string = "";
                    if (alarm.deviceId) {
                      string += alarm.deviceId;
                    }
                    if (alarm.BedID) {
                      string += alarm.BedID;
                    }
                    if (alarm.RoomID) {
                      string += alarm.RoomID;
                    }
                    if (string.toUpperCase().indexOf(searchInput) > -1) {
                      return (
                        <div
                          className={`alert alert-${AlarmListStates.Ctype}`}
                          style={{ cursor: "hand" }}
                          key={index}
                          onClick={() => {
                            this.action(alarm);
                          }}
                        >
                          <Alarm
                            onTap={this.action}
                            quickView={enableWatch}
                            key={index}
                            type={AlarmListStates.Ctype}
                            ventilator={alarm}
                          />
                        </div>
                      );
                    }
                  }
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        </div>
      </div>,
    ];
  };
  handleSnackbar = () => {
    this.setState({ openSnackbar: false });
  };
  openSnackbarWith = (msg) => {
    let prv = this.state.snackbarData;
    prv.severity = msg.Severity;
    prv.message = msg.message;
    this.setState({ openSnackbar: true, snackbarData: prv });
  };
  handleMenuClose = () => { };
  disableAlarmListPage = (state) => {
    this.setState({ showAlarmList: state });
  };
  componentWillReceiveProps(newProps) {
    // console.log(
    //   "[ionVent]  this.props.AlarmListStates == newProps.AlarmListStates:",
    //   this.props.AlarmListStates != newProps.AlarmListStates
    // );

    if (this.props.AlarmListStates != newProps.AlarmListStates) {
      // call same method which u r calling on local showAlarm list

      this.showAlarmList(newProps.AlarmListStates);
    }
  }
  render() {
    const { totalVents, liveVents, standBy, showAlarmList } = this.state;
    const { lpalarm, mpalarm, noalarm, hpalarm } = this.state;
    // const classes = useStyles();
    if (showAlarmList) {
      return this.AlarmListComponent();
    }
    return (
      <React.Fragment>
        <SessionExpiryDialog
          open={this.state.sessionExpired}
        ></SessionExpiryDialog>
        <div className="row custPadding">
          <div className="col-lg-3 col-md-1 col-sm-12"></div>
          <div className="col-lg-6 col-md-10 col-sm-12">
            <div
              className="card card-stats"
              style={{
                marginBottom: "0px",
                marginTop: "15px",
                paddingTop: "3.5%",
              }}
            >
              <div className={"row"}>
                <div className="col-xs-4 col-lg-4 col-sm-4 col-md-4">
                  <div className="card-header  card-header-icon">
                    <div
                      className="card-icon"
                      title={"Scan device QR "}
                      onClick={() => {
                        this.openQrCodeDialog();
                      }}
                      style={{
                        backgroundColor: "#9e9e9e45",
                        //  backgroundColor: "green",
                        marginTop: "-23px",
                        //border: "1px solid red",
                        cursor: "pointer",
                      }}
                    >
                      <img
                        className="input-group-append"
                        src={scanner}
                        style={{ margin: "8px" }}
                        alt="Scanner"
                        height="60px"
                        width="60px"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-8 col-lg-8 col-sm-8 col-md-8">
                  <div className="card-body">
                    <h4 className="card-title">Alpha Vents</h4>
                    <div className="row">
                      <div className="col-md-12">
                        <h3
                          className="card-title"
                          style={{ fontSize: "initial", marginBottom: "10px" }}
                        >
                          Total Ventilators : {totalVents}
                        </h3>
                        <h3
                          className="card-title"
                          style={{ fontSize: "initial", marginBottom: "10px" }}
                        >
                          Ventilator Online - Therapy running :{" "}
                          {liveVents - standBy}
                        </h3>
                        <h3
                          className="card-title"
                          style={{ fontSize: "initial", marginBottom: "10px" }}
                        >
                          Ventilator Online - Standby : {standBy}
                        </h3>

                        <h3
                          className="card-title"
                          style={{ fontSize: "initial", marginBottom: "10px" }}
                        >
                          Ventilator Disconnected : {totalVents - liveVents}
                        </h3>
                      </div>

                      {/* <div className="col-md-3">
                        <div style={{ height: 200 }}>
                        <VectorMap
                          map={"world_mill"}
                          backgroundColor="#3b96ce"
                          ref="map"
                          containerStyle={{
                            width: "100%",
                            height: "100%",
                            marginTop: "0px",
                          }}
                          containerClassName="map"
                        />
                      </div> 
                    </div>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-1 col-sm-12"></div>
        </div>
        {/* alarm counts */}

        {/* Search bar code starts */}

        <div className="row custPadding">
          <div className="col-lg-3 col-md-1 col-sm-12"></div>
          <div className="col-lg-6 col-md-10 col-sm-12">
            <div
              className="card"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <div className="card-body" style={{ padding: "5px 15px" }}>
                <div className="row ">
                  <div className="col-md-12">
                    <div className="form-group" style={{ marginTop: "10px" }}>
                      <form autoComplete="off" method="post" action="">
                        <input
                          autoComplete="false"
                          name="hidden"
                          type="text"
                          style={{ display: "none" }}
                        />
                        <div
                          className="input-group md-form form-sm form-2 pl-0"
                          style={{ border: "1px solid #8080809e" }}
                        >
                          <input
                            className="form-control my-0 py-1 "
                            id="searchInput"
                            type="text"
                            placeholder="Search vent eg: Vent001 "
                            aria-label="Search"
                            style={{
                              paddingLeft: "10px",
                              background: "none",
                            }}
                            onChange={(event) => {
                              let val = event.target.value.toUpperCase().trim();
                              // console.log("Printing-2", val);
                              this.setState({
                                searchInput: val,
                              });
                            }}
                          />
                          <div className="input-group-append">
                            {/* <span
                              style={{
                                display: "inline-flex",
                                justifyContent: "center",
                                width: "60px",
                                alignItems: "center",
                                height: "36px",
                              }}
                            >
                              <SearchIcon />
                            </span> */}
                            {this.state.openQrCodeDialogs ? <QrCodeDialog
                              open={this.state.openQrCodeDialogs}
                              setClose={this.closeQrCodeDialog}
                              onClose={this.handleMenuClose}
                              deviceList={this.state.deviceList}
                            ></QrCodeDialog> : null}
                            <Tooltip
                              tootltip={this.state.showList ? "Hide" : "Show"}
                              placement={"bottom"}
                            >
                              <img
                                className="input-group-append"
                                src={impexp}
                                style={{ margin: "8px", cursor: "pointer" }}
                                alt="Scanner"
                                height="20px"
                                width="20px"
                                onClick={() => {
                                  this.setState({
                                    showList: !this.state.showList,
                                  });
                                }}
                              />
                            </Tooltip>
                            <Tooltip tootltip={"Scan QR"} placement={"bottom"}>
                              <img
                                className="input-group-append"
                                src={scanner}
                                style={{ margin: "8px", cursor: "pointer" }}
                                alt="Scanner"
                                height="20px"
                                width="20px"
                                onClick={() => {
                                  this.openQrCodeDialog();
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </form>
                    </div>
                    {this.state.data.map((alarm, index) => {
                      if (
                        this.state.showList &&
                        this.state.searchInput.length < 1
                      ) {
                        ///^\s+$/.test(this.state.searchInput)
                        return <VentsCard data={alarm} key={index} />;
                      }
                      if (this.state.searchInput.length > 0) {
                        let string = "";
                        if (alarm.deviceId) {
                          string += alarm.deviceId;
                        }
                        if (alarm.BedID) {
                          string += alarm.BedID;
                        }
                        if (alarm.RoomID) {
                          string += alarm.RoomID;
                        }
                        // console.log("string", string);
                        if (
                          string.toUpperCase().indexOf(this.state.searchInput) >
                          -1
                        ) {
                          return <VentsCard data={alarm} key={index} />;
                        }
                      } else {
                        return null;
                      }
                    })}
                    {/*search result area end*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-1 col-sm-12"></div>
        </div>

        {/* Search bar code ends */}
        {/* search  result area */}
        <div>
          <div className="row custPadding">
            <div className="col-12 col-lg-1 col-md-1"></div>
            <div className="col">
              <div className="card card-stats cardMargin">
                <div
                  className="card-header card-header-danger card-header-icon"
                  onClick={() => {
                    let prvValue = {};
                    prvValue.Ctype = "danger";
                    prvValue.type = "High";
                    prvValue.description = "High Priority Alarming vents";
                    prvValue.SelectedSeverity = this.Constants.HPALARM_SUB;
                    this.showAlarmList(prvValue);
                  }}
                >
                  <div className="card-icon">
                    <PriorityHighIcon fontSize="large" />
                  </div>
                  <p className="card-category card-cat-custMinHeight">
                    High Priority Alarming vents
                  </p>
                  <h3 className="card-title glow">{hpalarm}</h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                    <LocalOfferIcon style={{ fontSize: "20px" }} /> Tracked from
                    device
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-1 col-md-1 "></div>
            <div className="col">
              <div className="card card-stats cardMargin">
                <div
                  className="card-header card-header-warning card-header-icon"
                  onClick={() => {
                    let prvValue = {};
                    prvValue.Ctype = "warning";
                    prvValue.type = "Medium";
                    prvValue.description = "Medium Priority Alarming vents";
                    //  prvValue.state.localstg = this.Constants.MPALARM_SUB;
                    prvValue.SelectedSeverity = this.Constants.MPALARM_SUB;
                    this.showAlarmList(prvValue);
                  }}
                >
                  <div className="card-icon">
                    <PriorityHighIcon fontSize="large" />
                  </div>
                  <p className=" card-category card-cat-custMinHeight">
                    Medium Priority Alarming vents
                  </p>
                  <h3 className="card-title">{mpalarm}</h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                    <LocalOfferIcon style={{ fontSize: "20px" }} /> Tracked from
                    device
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-1 col-md-1"></div>
          </div>
          <div className="row custPadding ">
            <div className="col-12 col-lg-1 col-md-1"></div>
            <div className="col">
              <div className="card card-stats cardMargin">
                <div
                  className="card-header card-header-warning card-header-icon"
                  onClick={() => {
                    let prvValue = {};
                    prvValue.Ctype = "primary";
                    prvValue.type = "Low";
                    prvValue.description = "Low Priority Alarming vents";

                    prvValue.SelectedSeverity = this.Constants.LPALARM_SUB;

                    this.showAlarmList(prvValue);
                  }}
                >
                  <div className="card-icon cust-icon-low-icon">
                    <PriorityHighIcon fontSize="large" />
                  </div>
                  <p className="card-category card-cat-custMinHeight">
                    Low Priority Alarming vents
                  </p>
                  <h3 className="card-title">{lpalarm}</h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                    <LocalOfferIcon style={{ fontSize: "20px" }} /> Tracked from
                    device
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-1 col-md-1"></div>
            <div className="col">
              <div className="card card-stats cardMargin">
                <div
                  className="card-header card-header-success card-header-icon"
                  onClick={() => {
                    let prvValue = {};
                    prvValue.Ctype = "success";
                    prvValue.type = "Running";
                    prvValue.description = "Running with no Alarms";
                    prvValue.SelectedSeverity = this.Constants.NOALARM_SUB;
                    // prvValue.localstg = this.Constants.NOALARM_SUB;
                    this.showAlarmList(prvValue);
                  }}
                >
                  <div className="card-icon">
                    <PriorityHighIcon fontSize="large" />
                  </div>
                  <p className="card-category card-cat-custMinHeight">
                    No Alarm vents
                  </p>
                  <h3 className="card-title">{noalarm}</h3>
                </div>
                <div className="card-footer">
                  <div className="stats">
                    <LocalOfferIcon style={{ fontSize: "20px" }} /> Tracked from
                    device
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-1 col-md-1"></div>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={this.state.openSnackbar}
          autoHideDuration={6000}
          onClose={this.handleSnackbar}
        >
          <Alert
            onClose={this.handleSnackbar}
            severity={this.state.snackbarData.severity}
          >
            {this.state.snackbarData.message}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
  getDeviceList() {
    if (
      "company_id" in this.props.user &&
      "plant_id" in this.props.user &&
      "role" in this.props.user
    ) {
      let { company_id, plant_id } = this.props.user;
      // console.log("company_id:",company_id," plant_id:",plant_id)
      fetch(`${MACHINELIST}/${company_id}/${plant_id}?user=${this.props.user.role}`)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result && Array.isArray(result)) {
              // console.log("deviceList",result)
              let formatedAr = []
              for (let i = 0; i < result.length; i++) {
                formatedAr.push(result[i].name)
              }
              this.setState({ deviceList: formatedAr }, this.getLiveVents(formatedAr))
            }
          })
    }
  }
  componentDidMount() {
    // db call
    this.initComponent();
    // old line
    //this.subsAlarm = alarmSubscribers.subscribe(this.alarmHandler);
    this.subsAlarm = alarmSubscribers.subscribe(this.updateStateData);
    this.showPriorityAlm = showAlmListSubscribers.subscribe(this.disableAlarmListPage);
    // this.pingLiveVents();
    this.getDeviceList();
    // this.getLiveVents();
  }
  updateStateData = (dbDtata) => {
    if (dbDtata) {
      let high = 0;
      let low = 0;
      let mid = 0;
      let no = 0;
      try {
        dbDtata.forEach((data, index) => {
          if (data.Severity == this.Constants.HPALARM_SUB) {
            high++;
          } else if (data.Severity == this.Constants.LPALARM_SUB) {
            low++;
          } else if (data.Severity == this.Constants.MPALARM_SUB) {
            mid++;
          } else if (data.Severity == this.Constants.NOALARM_SUB) {
            no++;
          }
        });
        this.setState(
          {
            data: dbDtata,
            hpalarm: high,
            mpalarm: mid,
            lpalarm: low,
            noalarm: no,
          },
          () => {
            //console.log("dbData", this.state);
          }
        );
      } catch (error) { }
    }
  };


  initComponent = () => {
    if (process.env.NODE_ENV !== "production") {
    }
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", Authorization: token },
    };
    // console.log("initComponent:IonVent>>>", this.props.user.token);

    fetch(TOTALVENTS + this.props.user.plant_id, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          this.setState({ sessionExpired: true });
        }
        return res.json();
      })
      .then(
        (result) => {
          // console.log("xsujan Machines", result);
          if (result) {
            this.setState({
              totalVents: result.totalVents,
            });
            localStorage.setItem("totalVents", result.totalVents);
            // console.log("initComponent Result", result.alarm);
            // alarmService.send(result.alarm);
          }
        },
        (error) => {
          //localStorage.clear();
          console.log("Machines error", error);
        }
      );
  };
  componentWillUnmount() {
    this.subsAlarm.unsubscribe();
    this.showPriorityAlm.unsubscribe();
    this.alarmData = null;
    clearInterval(this.intervalTimer);
  }
  pingLiveVents = () => {
    //console.log("pingingLiveVents>>>")
    if (
      "company_id" in this.props.user &&
      "plant_id" in this.props.user &&
      "role" in this.props.user
    ) {
      //console.log("ping-> user details", this.props.user);
      let { company_id, plant_id } = this.props.user;
      fetch(`${ALARM}/${company_id}/${plant_id}?user=${this.props.user.role}`)
        .then((res) => res.json())
        .then(
          (result) => {
            if (result && Array.isArray(result)) {
              // console.log("ALM RESULT:", result);
              this._standBy = 0;
              alarmService.send(result);
              if (this.state.liveVents != result.length
              ) {
                this.setState({ liveVents: result.length }, () => {
                  this.initComponent();
                });
              }
              result.forEach((el) => {
                if (!el.VSTATE) {
                  this._standBy++;
                }
              });
              if (this.state.standBy !== this._standBy) {
                this.setState({ standBy: this._standBy });
              }
            }
          },
          (error) => {
            //localStorage.clear();
            console.log("PingResult error", error);
          }
        );
    } else {
      // console.log("ping error user props", this.props.user);
    }
  };

  getLiveVents = (result) => {
    if (result || this.state.deviceList) {
      let token = localStorage.getItem("abmUsertoken");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          deviceList: this.state.deviceList ? this.state.deviceList : result,
          role: this.props.user.role
        }),
      };
      // console.log("getLiveVents>>>requestOptions", requestOptions)
      if (
        "company_id" in this.props.user &&
        "plant_id" in this.props.user &&
        "role" in this.props.user
      ) {
        fetch(`${ALARMLIST_BY_MACHINE_ID}`, requestOptions)
          .then((res) => res.json())
          .then(
            (result) => {
              if (result && Array.isArray(result)) {
                this._standBy = 0;
                alarmService.send(result);
                if (this.state.liveVents != result.length
                ) {
                  this.setState({ liveVents: result.length }, () => {
                    this.initComponent();
                  });
                }
                result.forEach((el) => {
                  if (el.VSTATE==='false') {
                    this._standBy++;
                  }
                });
                if (this.state.standBy !== this._standBy) {
                  this.setState({ standBy: this._standBy });
                }
              }
            },
            (error) => {
              console.log("PingResult error", error);
            }
          );
      }
    }
  }
}

export default withRouter(IONVents);
