import React, { useState, useEffect } from "react";
import { LOGIN, RESETPASSWORD_MAIL } from "../../api-route";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { CustomsSnackbar } from "../snakbar/snackbar";
import "./login.css";
import logo from "../../img/abm-new-logo.png";
import userpic from "../../img/username-icon.png";
import passwordpic from "../../img/password-icon.png";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ReactLoading from "react-loading";

export default (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [Logindata, setLogindata] = useState({});
  const [toggleEyeIcon, setToggleEyeIcon] = useState(true);
  const [userError, setUserError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({ vertical: "top", horizontal: "center" });
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  const userOnChange = (event) => {
    setUserError("");
    setUsername(event.target.value);
  };
  const passwordOnChange = (event) => {
    setPasswordError("");
    setPassword(event.target.value);
  };
  useEffect(() => { });
  const toggleShow = () => {
    setToggleEyeIcon(!toggleEyeIcon);
  };
  const validate = () => {
    if(!isResetClicked){
    if (!username || (!password)) {
      if (!username) {
        setUserError("Email required");
      }
      if (!password) {
        setPasswordError("Password required");
      }
      return false;
    } else {
      return true;
    }}
    else{
      if (!username) {
        setUserError("Email required");
        return false;
      }else {
        return true;
      }
    }
  };


  const onLogin = () => {
    if (!validate()) {
      return;
    }
    if (isResetClicked) {
      setLoading(true)
      fetch(RESETPASSWORD_MAIL, {
        method: "POST",
        body: JSON.stringify({ email: username }),
      })
        .then((res) => res.json())
        .then((result) => {
          // console.log("Login result:",result);
          if (result.status) {
            setLoading(false);
            if (result.status === 200) {
              setOpenSnack(true);
              setSnackMsg("A mail has been sent to your email address");
              setSnackSeverity("success")
              // setIsResetClicked(false)
            } else {
              setOpenSnack(true);
              setSnackMsg(result.message ? result.message : "Something went wrong.Try again later");
              setSnackSeverity("error");
            }
          }
        }).catch((error) => {
          console.log(error);
          setLoading(false);
          setOpenSnack(true);
          setSnackMsg("Something went wrong.Try again later");
          setSnackSeverity("error");
        })
    } else {
      let login = {
        username: username,
        password: password,
      };
      setLogindata(login);
      setOpen(true);
    }
  }


  return (
    <React.Fragment>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <div className="login">
        <section className="own-code-animation own-code-login">
          <div className="own-content">
            <div className="own-header">
              <span className="own-logo">
                <img src={logo} alt="Logo" className="img-fluid logo-resize" />
              </span>
            </div>
            <div className="own-form">
              <form className="m-0">
                {isResetClicked ? <div>
                  <p style={{ color: '#000', display: 'flex', justifyContent: 'center', fontSize: '26px', marginBottom: '40px' }}>
                    Forgot your password?</p>
                  {/* <p style={{ color: '#000000bf', display: 'flex', justifyContent: 'center', fontSize: '14px', textAlign: 'center' }}>
                    Enter you're e-mail address we'll send you a link to reset your password</p> */}
                </div> : null}
                <div className="form-group">
                  <div style={{ marginBottom: "-40px" }}>
                    <img
                      className="input-img-dim"
                      alt="User Icon"
                      src={userpic}
                    ></img>
                  </div>
                  <div className="own-transformY-50 own-transition-delay-1">
                    <input
                      type="text"
                      id="Username"
                      className="form-control own-input-cs"
                      placeholder="Email"
                      required="required"
                      style={{ paddingLeft: "50px" }}
                      name="username"
                      onChange={userOnChange}
                    />
                    {userError && <p className="error-pgrph">{userError}</p>}
                  </div>
                </div>

                {isResetClicked ? null :
                  <div className="form-group m-0">
                    <div
                      className="input-group md-form form-sm form-2 pl-0"
                    // style={{ border: "1px solid #8080809e" }}
                    >
                      <div className="input-group-append">
                        <div
                          style={{
                            borderBottom: "3px solid #000",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            className="input-img-dim"
                            alt="Lock Icon"
                            src={passwordpic}
                          ></img>
                        </div>
                      </div>

                      <input
                        id="password"
                        type={toggleEyeIcon ? "password" : "text"}
                        className="form-control own-input-cs1"
                        placeholder="Password"
                        required="required"
                        autoComplete="true"
                        style={{ paddingLeft: "20px" }}
                        name="password"
                        onChange={passwordOnChange}
                      />
                      <div
                        className="input-group-append"
                        style={{
                          borderBottom: "3px solid #000",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingRight: "15px",
                        }}
                      >
                        {toggleEyeIcon ? (
                          <VisibilityIcon
                            style={{ fontSize: "1.2rem", cursor: "pointer" }}
                            onClick={toggleShow}
                          />
                        ) : (
                          <VisibilityOffIcon
                            style={{ fontSize: "1.2rem", cursor: "pointer" }}
                            onClick={toggleShow}
                          />
                        )}
                      </div>
                    </div>
                    {passwordError && (
                      <p className="error-pgrph">{passwordError}</p>
                    )}
                  </div>
                }
                <div className="form-group">
                  <div className="own-transformY-50 own-transition-delay-4">
                    <button
                      type="button"
                      disabled={loading}
                      className="own-btn-black"
                      onClick={() => { onLogin() }
                      }
                    >
                      {isResetClicked ? "Submit" : "Log in"}

                    </button>
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {loading ? (
                    <ReactLoading type={"cylon"} color="#000
                                " />
                  ) : null}
                </div>
              </form>
            </div>
            <div className="own-style-line">
              <div className="own-transformY-50 own-transition-delay-5">
                <h3 style={{ marginTop: "0px", cursor: 'pointer' }}><a onClick={() => { setIsResetClicked(!isResetClicked) }}>{isResetClicked ? "Login" : "Forgot Password?"} </a></h3>
                <div><h3 style={{ marginTop: "0px" }}>
                  <a href="">
                    ver{" "}
                    <span
                      style={{
                        fontFamily: "Roboto sans-serif",
                        fontSize: "16px",
                      }}
                    >
                      2.4.0
                    </span>{" "}
                  </a>
                </h3>  </div>            </div>
            </div>
            <div className="own-footer">
              <div className="own-transformY-50 own-transition-delay-9">
                <p>
                  Copyright &#169;
                  <span className="switcher-text2">
                    2021, ABM respiratory Care
                  </span>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>

      <TermsDialog
        open={open}
        props={props}
        Logindata={Logindata}
        setOpen={setOpen}
        proceed={proceed}
        setProceed={setProceed}
      />
    </React.Fragment>
  );
};

function makeLogin(props, login, openSnack, msgSnack, closePopup) {
  // console.log("sujan props makelogin is", props);
  fetch(LOGIN, {
    method: "post",
    body: JSON.stringify(login),
  })
    .then((res) => res.json())
    .then(
      (result) => {
        let userData = {};
        if (result.success) {
          userData = result.data;
          //document.cookie = "token="+userData.user.token;
          localStorage.setItem("abmUsertoken", userData.user.token);
          props.isLoggedTrue(userData);
        } else {
          msgSnack("Your email or password is incorrect");
          openSnack(true);
          closePopup();
        }
      },
      (error) => {
        msgSnack("Sorry.. Something Went Wrong");
        openSnack(true);
        closePopup();
        console.log(`responseerr:`, error);
      }
    );
}

function TermsDialog(props) {
  // console.log("sujan props",props);
  const { open, setOpen } = props;
  const { proceed, setProceed } = props;

  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("error");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleProceed = () => {
    setProceed(true);
    console.log("Change procced", proceed);
    // if (proceed) {
    makeLogin(
      props.props,
      props.Logindata,
      setOpenSnack,
      setSnackMsg,
      handleClose
    );
    // console.log("Change procced", props.Logindata);
    // }
  };

  return (
    <div>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
      >
        <div style={{}}>
          <div
            style={{
              background: "white",
              padding: "30px 0px",
              margin: "0px 20px",
              borderRadius: "5px",
            }}
          >
            <span
              style={{
                marginLeft: "30px",
                marginTop: "30px",
                fontSize: "x-large",
                color: "black",
                textTransform: "uppercase",
                fontWeight: "bold",
                marginTop: "30px",
              }}
            >
              {" "}
              Caution
            </span>
          </div>

          <DialogContent>
            <div>
              <p
                style={{
                  fontSize: "15px",
                  marginTop: "10px",
                  margin: "0px 20px",
                  textAlign: "left",
                  lineHeight: "31px",
                }}
              >
                This portal provides a secondry mechanism to provide remote
                access to ventilator for covid-19 pandemic and does not replace
                the need to have a primary physical person attend to the
                ventilator at all the time. The use of this site is as per{" "}
                <span style={{ fontWeight: "bold" }}>terms and conditions</span>{" "}
                and I have read and understood the same.
              </p>

              {/* <div  style={{marginLeft:"40px",marginTop:"40px",fontSize:"15px"}}>
                     <input style={{height:"14px",width:"30px"}} type="checkbox"  id="agreeCheck" required value="agreed"/>
                     <span style={{ }}for="agreeCheck">I agree</span>
                 </div> */}
            </div>
          </DialogContent>
          <DialogActions>
            <button
              autoFocus
              className="btn btn-outline btn-md"
              onClick={handleClose}
              style={{
                color: "#1e88e5",
                border: "1px #1e88e5 solid",
                marginRight: "30px",
                marginBottom: "20px",
              }}
            >
              Decline
            </button>
            <button
              autoFocus
              className="btn btn-md"
              onClick={handleProceed}
              style={{
                background: "#1e88e5",
                marginRight: "30px",
                marginBottom: "20px",
              }}
            >
              Accept
            </button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}
