import { BehaviorSubject } from "rxjs";
//------------------------------
const alarmSubscribers = new BehaviorSubject(0);
const alarmService = {
  send: (msg) => {
    alarmSubscribers.next(msg);
  },
};
const showAlmListSubscribers = new BehaviorSubject(false);
const showAlmListService = {
  send: (msg) => {
    showAlmListSubscribers.next(msg);
  },
};
export { alarmService, alarmSubscribers,showAlmListSubscribers,showAlmListService };
