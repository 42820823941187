import React, { Component } from "react";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Insppresspopup from "./Insppresspopup";
import Popup from "reactjs-popup";
import { checkLock } from "../../services/http";
import { CustomsSnackbar } from "../../components/snakbar/snackbar";

const enablePressureSupport = ({ ventModeStatus }) => {
  switch (ventModeStatus) {
    case "PCV(A)":
      return false;
    case "PCV":
      return false;
    default:
      return true;
  }
};
const enableMinuteVol = ({ ventModeStatus }) => {
  if (ventModeStatus === "PVRC") {
    return true;
  } else {
    return ventModeStatus ? ventModeStatus.startsWith("VC") : false;
  }
};

class Popupscreen extends Component {
  constructor(props) {
    super(props);
    this.token = localStorage.getItem("abmUsertoken");
    this.state = {
      ventilatorChildPopup: false,
      childPopupValue: 0,
      title: "",
      contentStyle: {},
      range: { min: 2, max: 10 }, //default
      step: 1, //default
      float: false,
      //settings: "settings" in this.props ? { ...this.props.settings } : {},
      openSnack: false,
    };
    this.InspPressRef = React.createRef();
    this.InspTRef = React.createRef();

    this.BRRef = React.createRef();
    this.ExpTrigRef = React.createRef();

    this.InspTrigRef = React.createRef();
    this.PEEPRef = React.createRef();
    this.PSUPRef = React.createRef();
    this.RiseTRef = React.createRef();
    this.TVRef = React.createRef();

    this.onCancelClick = this.onCancelClick.bind(this);
  }

  onCancelClick() {
    this.props.closePopup();
  }
  toggleChildPopup = () => {
    this.setState({ ventilatorChildPopup: !this.state.ventilatorChildPopup });
  };

  onPressVentilatorSettings = (ref) => {
    checkLock({ token: this.token })
      .then((res) => {
        if (res.status === 200) {
          //------------------------------------
          let title = ref.current.title;
          let content =
            ref.current && ref.current.textContent
              ? ref.current.textContent
              : "";
          if ("InspP" == title) {
            let range = { min: 5, max: 60 - Number(this.props.settings.PEEP) };
            let step = 1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              marginLeft: "11%",
              marginTop: "10%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("InspT" == title) {
            let inspTSettingsMax;
            if (this.props.settings.BR != 0) {
              var inspmaxcal = 60 / Number(this.props.settings.BR) - 0.3;
              if (inspmaxcal > 5) {
                inspTSettingsMax = 5;
              } else {
                inspTSettingsMax = inspmaxcal.toFixed(1);
              }
            } else {
              inspTSettingsMax = 0;
            }
            //console.info("InspT Max------->", inspTSettingsMax);
            let range = { min: 0.3, max: inspTSettingsMax };
            let step = 0.1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              // paddingTop: "5%",
              marginLeft: "26%",
              marginTop: "10%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: true,
              });
            }
          }
          if ("BR" == title) {
            let breathRateSettingsMax = 25;
            let breathmaxcal = 60 / (Number(this.props.settings.InspT) + 0.3);
            if (Math.floor(breathmaxcal) > 60) {
              breathRateSettingsMax = 60;
            } else {
              breathRateSettingsMax = Math.floor(breathmaxcal);
            }
            let range = { min: 0, max: breathRateSettingsMax };
            let step = 1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              marginLeft: "44%",
              marginTop: "10%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("ExpTrig" == title) {
            let range = { min: 0, max: 90 };
            let step = 10;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              //paddingTop: "5%",
              marginLeft: "11%",
              marginTop: "20%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("PEEP" == title) {
            // change peepMax in ventilator settings
            let peepSettingsMax = 40;
            if (60 - Number(this.props.settings.InspP) > 30) {
              peepSettingsMax = 30;
            } else {
              peepSettingsMax = 60 - Number(this.props.settings.InspP);
            }

            // else if (
            //   60 - this.props.settings.InspP <=
            //   60 - this.props.settings.PSUP
            // ) {
            //   peepSettingsMax = 60 - this.props.settings.InspP;
            // }

            let range = { min: 2, max: peepSettingsMax };
            let step = 1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              //paddingTop: "5%",
              marginLeft: "26%",
              marginTop: "10%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("RiseT" == title) {
            let range = { min: 1, max: 5 };
            let step = 1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              //paddingTop: "5%",
              marginLeft: "11%",
              marginTop: "20%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("TV" == title) {
            let range = { min: 100, max: 2000 };
            let step = 10;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              //paddingTop: "5%",
              marginLeft: "44%",
              marginTop: "10%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("InspTrig" == title) {
            let range = { min: 0, max: 9 };
            let step = 1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              //paddingTop: "5%",
              marginLeft: "26%",
              marginTop: "20%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          if ("PSUP" == title) {
            let pressureSupportSettingsMax =
              60 - Number(this.props.settings.PSUP) > 40
                ? 40
                : 60 - Number(this.props.settings.PSUP);
            // if (60 - this.props.settings.PEEP > 40) {
            //   pressureSupportSettingsMax = 40;
            // } else {
            //   pressureSupportSettingsMax = 60 - this.props.settings.PEEP;
            // }
            let range = { min: 0, max: pressureSupportSettingsMax };
            let step = 1;
            let contentStyle = {
              backgroundColor: "black",
              opacity: "0.9",
              height: "285px",
              width: "330px",
              //paddingTop: "5%",
              marginLeft: "11%",
              marginTop: "10%",
            };
            if (content) {
              this.setState({
                childPopupValue: content,
                title: title,
                contentStyle: contentStyle,
                range: range,
                step: step,
                float: false,
              });
            }
          }
          this.toggleChildPopup();
        }
        // console.log("title", title);
        // console.log("InspPressRef", ref);
        // this.toggleChildPopup();
        //----------------------------------
        else {
          //console.log("LOCK-Else")
          this.cusSnackbar.open();
        }
      })
      .catch((res) => {
        console.log("LOCK-catch");
        console.error(res);
      });
  };
  setValue = (whomToSet, value) => {
    if ("settings" in this.props) {
      this.props.setValue(whomToSet, value);
      console.log("[PopupScreen]:SetValue toWhome", whomToSet, "value", value);
    }
  };
  cusSnackbar = {
    title: "popup-lock-snackbar",
    message: (
      <span>
        Can't allow in monitor mode.
        <br />
        Unlock to access control mode
      </span>
    ),
    anchor: { vertical: "bottom", horizontal: "right" },
    severity: "warning",
    close: () => {
      this.setState({ openSnack: false });
    },
    open: () => {
      // console.log("---OPen called, this", this)
      this.setState({ openSnack: true });
    },
  };

  render() {
    //let { settings } = this.state;
    let { settings, locked, ventModeStatus } = this.props;
    let { ventilatorChildPopup, title } = this.state;
    let TVVal = Number(settings.TV) ? Number(settings.TV) : 0;
    let BRVal = Number(settings.BR) ? Number(settings.BR) : 0;
    let isBackUp = null;
    if (
      "VBrthOpt" in settings &&
      settings.VBrthOpt === "SU" &&
      settings.VMode == 3
    ) {
      isBackUp = true;
    }

    return (
      <div>
        <div
          style={{
            marginTop: "20px",
            // height: "50px",
            color: "yellow",
            fontSize: "48px",
            textAlign: "center",
            paddingTop: "10px",
          }}
        >
          {/*   {this.props.ventModeStatus} */}
        </div>
        <CustomsSnackbar
          msg={this.cusSnackbar.message}
          flag={this.state.openSnack}
          onClose={this.cusSnackbar.close}
          key={this.cusSnackbar.title}
          anchorOrigin={this.cusSnackbar.anchor}
          severity={this.cusSnackbar.severity}
        />
        <Carousel showThumbs={false}>
          <div
            className="custPopupOverflow"
            style={{
              marginTop: "2%",
              // height: "55vh",
              width: "95%",
              marginLeft: "30px",
            }}
          >
            <div className="row">
              <div className="col-md-3 col-sm-3 col-6" id="InspP_id">
                <div>
                  <span
                    style={{
                      color: settings.VMode == 3 ? "#09F9F9" : "#4a5f5f",
                      fontSize: "20px",
                    }}
                  >
                    {isBackUp ? "BackUp " : null} Insp.Press
                  </span>
                </div>

                <div
                  title={"InspP"}
                  ref={this.InspPressRef}
                  className="CustMargTopForPVC"
                  onClick={() => {
                    if (settings.VMode == 3) {
                      this.onPressVentilatorSettings(this.InspPressRef);
                    }
                  }}
                >
                  <span
                    className={settings.VMode == 3 ? "glow-circlular" : ""}
                    style={{
                      color: settings.VMode == 3 ? "white" : "#ffffff33 ",
                      fontSize: "80px",
                    }}
                  >
                    {settings ? settings.InspP : ""}
                  </span>
                </div>

                <div>
                  <span
                    style={{
                      color: settings.VMode == 3 ? "white" : "#ffffff33 ",
                      fontSize: "15px",
                    }}
                  >
                    cmH2O
                  </span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6" id="InspT_id">
                <div>
                  <span style={{ color: "#09F9F9", fontSize: "20px" }}>
                    Insp.Time
                  </span>
                </div>

                <div
                  title={"InspT"}
                  ref={this.InspTRef}
                  className="CustMargTopForPVC"
                  onClick={() => {
                    this.onPressVentilatorSettings(this.InspTRef);
                  }}
                >
                  <span
                    className="glow-circlular"
                    style={{ color: "white", fontSize: "80px" }}
                  >
                    {settings ? settings.InspT : ""}
                  </span>
                  <br />
                </div>

                <div>
                  <span style={{ color: "white", fontSize: "15px" }}>sec</span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span style={{ color: "#09F9F9", fontSize: "20px" }}>
                    {isBackUp ? "Backup " : "Breath "}Rate
                  </span>
                </div>

                <div
                  className="CustMargTopForPVC"
                  title={"BR"}
                  ref={this.BRRef}
                  onClick={() => {
                    this.onPressVentilatorSettings(this.BRRef);
                  }}
                >
                  <span
                    className="glow-circlular"
                    style={{ color: "white", fontSize: "80px" }}
                  >
                    {settings ? settings.BR : ""}
                  </span>
                  <br />
                </div>

                <div>
                  <span style={{ color: "white", fontSize: "15px" }}>BPM</span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span style={{ color: "#4a5f5f", fontSize: "20px" }}>
                    I:E
                  </span>
                </div>

                <div className="CustMargTopForPVC">
                  <span style={{ color: "#ffffff33", fontSize: "80px" }}>
                    {settings ? setIEratio(settings) : "1:2:3"}
                  </span>
                  <br />
                </div>
              </div>
            </div>

            <div className="row" style={{ marginTop: "4%" }}>
              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span
                    style={{
                      color: settings.VMode == 3 ? "#09F9F9" : "#4a5f5f",
                      fontSize: "20px",
                    }}
                  >
                    Rise.Time
                  </span>
                </div>

                <div
                  className="CustMargTopForPVC"
                  title={"RiseT"}
                  ref={this.RiseTRef}
                  onClick={() => {
                    if (settings.VMode == 3) {
                      this.onPressVentilatorSettings(this.RiseTRef);
                    }
                  }}
                >
                  <span
                    className={settings.VMode == 3 ? "glow-circlular" : ""}
                    style={{
                      color: settings.VMode == 3 ? "white" : "#ffffff33 ",
                      fontSize: "80px",
                    }}
                  >
                    {settings ? settings.RiseT : ""}
                  </span>
                  <br />
                </div>

                <div>
                  <span
                    style={{
                      color: settings.VMode == 3 ? "white" : "#ffffff33 ",
                      fontSize: "15px",
                    }}
                  ></span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span style={{ color: "#09F9F9", fontSize: "20px" }}>
                    Insp.Trigg
                  </span>
                </div>

                <div
                  className="CustMargTopForPVC"
                  title={"InspTrig"}
                  ref={this.InspTrigRef}
                  onClick={() => {
                    this.onPressVentilatorSettings(this.InspTrigRef);
                  }}
                >
                  <span
                    className="glow-circlular"
                    style={{ color: "white", fontSize: "80px" }}
                  >
                    {settings ? settings.InspTrig : ""}
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      color: "white",
                      fontSize: "13px",
                    }}
                  >
                    Trigger Sensitivity Level
                  </span>
                </div>
              </div>
              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span
                    style={{
                      color: settings.VMode == 4 ? "#09F9F9" : "#4a5f5f",
                      fontSize: "20px",
                    }}
                  >
                    Tidal Vol
                  </span>
                </div>

                <div
                  title={"TV"}
                  ref={this.TVRef}
                  className="CustMargTopForPVC"
                  onClick={() => {
                    if (settings.VMode == 4) {
                      this.onPressVentilatorSettings(this.TVRef);
                    }
                  }}
                >
                  <span
                    className={settings.VMode == 4 ? "glow-circlular" : ""}
                    style={{
                      color: settings.VMode == 4 ? "white" : "#ffffff33 ",
                      fontSize: "80px",
                    }}
                  >
                    {settings ? settings.TV : ""}
                  </span>
                  <br />
                </div>

                <div>
                  <span
                    style={{
                      color: settings.VMode == 4 ? "white" : "#ffffff33 ",
                      fontSize: "15px",
                    }}
                  >
                    mL
                  </span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span
                    style={{
                      color: enableMinuteVol({ ventModeStatus })
                        ? "#09F9F9"
                        : "#4a5f5f",
                      fontSize: "20px",
                    }}
                  >
                    Minute Vol
                  </span>
                </div>
                <div>
                  <div
                    className="CustMargTopForPVC"
                    title={"MinVol"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      className={
                        enableMinuteVol({ ventModeStatus })
                          ? "glow-circlular"
                          : ""
                      }
                      style={{
                        color: enableMinuteVol({ ventModeStatus })
                          ? "white"
                          : "#ffffff33",
                        fontSize: "80px",
                      }}
                    >
                      {((TVVal / 1000) * BRVal).toFixed(1)}
                    </span>
                    <br />
                  </div>
                  <div>
                    <span
                      style={{
                        color: enableMinuteVol({ ventModeStatus })
                          ? "white"
                          : "#ffffff33",
                        fontSize: "15px",
                      }}
                    >
                      L
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="custPopupOverflow"
            style={{
              marginTop: "2%",
              height: "50vh",
              width: "95%",
              marginLeft: "30px",
            }}
          >
            {/* <div className="row" style={{paddingTop:"10px", marginLeft:"50px"}}>
                        <text style={{color:"yellow", fontSize:"50px"}}> PVC-AC </text>
                    </div> */}

            <div className="row">
              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span style={{ color: "#09F9F9", fontSize: "20px" }}>
                    PEEP
                  </span>
                </div>

                <div
                  className="CustMargTopForPVC"
                  title={"PEEP"}
                  ref={this.PEEPRef}
                  onClick={() => {
                    this.onPressVentilatorSettings(this.PEEPRef);
                  }}
                >
                  <span
                    className="glow-circlular"
                    style={{ color: "white", fontSize: "80px" }}
                  >
                    {settings ? settings.PEEP : ""}
                  </span>
                  <br />
                </div>

                <div>
                  <span style={{ color: "white", fontSize: "15px" }}>
                    cmH2O
                  </span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span
                    style={{
                      color: enablePressureSupport({ ventModeStatus })
                        ? "#09F9F9"
                        : "#ffffff33",
                      fontSize: "20px",
                    }}
                  >
                    Press.Sup.
                  </span>
                </div>

                <div
                  className="CustMargTopForPVC"
                  title={"PSUP"}
                  ref={this.PSUPRef}
                  onClick={() => {
                    if (enablePressureSupport({ ventModeStatus })) {
                      this.onPressVentilatorSettings(this.PSUPRef);
                    }
                  }}
                >
                  <span
                    className={
                      enablePressureSupport({ ventModeStatus })
                        ? "glow-circlular"
                        : ""
                    }
                    style={{
                      color: enablePressureSupport({ ventModeStatus })
                        ? "white"
                        : "#ffffff33",
                      fontSize: "80px",
                    }}
                  >
                    {settings ? settings.PSUP : ""}
                  </span>
                  <br />
                </div>
                <div>
                  <span
                    style={{
                      color: enablePressureSupport({ ventModeStatus })
                        ? "white"
                        : "#ffffff33",
                      fontSize: "15px",
                    }}
                  >
                    cmH2O
                  </span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6"></div>
            </div>
            <div className="row" style={{ marginTop: "4%" }}>
              <div className="col-md-3 col-sm-3 col-6">
                <div>
                  <span style={{ color: "#09F9F9", fontSize: "20px" }}>
                    Exp. Trig
                  </span>
                </div>

                <div
                  className="CustMargTopForPVC"
                  title={"ExpTrig"}
                  ref={this.ExpTrigRef}
                  onClick={() => {
                    this.onPressVentilatorSettings(this.ExpTrigRef);
                  }}
                >
                  <span
                    className="glow-circlular"
                    style={{ color: "white", fontSize: "80px" }}
                  >
                    {settings ? settings.ExpTrig : ""}
                  </span>
                  <br />
                </div>

                <div>
                  <span style={{ color: "white", fontSize: "15px" }}>%</span>
                </div>
              </div>

              <div className="col-md-3 col-sm-3 col-6"></div>

              <div className="col-md-3 col-sm-3 col-6"></div>
            </div>
          </div>
        </Carousel>
        <div className="row" style={{ marginTop: "1%" }}>
          {/* <div className="col-md-6">
            <button
              className="btn"
              style={{
                backgroundColor: "green",

                width: "150px",
                //  marginTop:"100px",
                marginLeft: "250px",
              }}
              // onClick={() => {
              //   this.props.confirmSettings(settings);
              //   this.props.closePopup();
              // }}
            >
              <span style={{ fontSize: "20px", color: "white" }}>
                {" "}
                Confirm{" "}
              </span>
            </button>
          </div> */}

          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn"
              style={{
                backgroundColor: "gray",
                width: "150px",
              }}
              onClick={this.onCancelClick}
            >
              <span style={{ fontSize: "20px", color: "White" }}> Close </span>
            </button>
          </div>
        </div>

        <Popup
          id="5th-popup"
          contentStyle={this.state.contentStyle}
          open={ventilatorChildPopup}
          closeOnDocumentClick={true}
        >
          <Insppresspopup
            range={this.state.range}
            step={this.state.step}
            title={title}
            float={this.state.float}
            setValue={this.setValue}
            value={this.state.childPopupValue}
            style={{ marginRoot: 12 }}
            toggleChildPopup={this.toggleChildPopup}
          />
        </Popup>
      </div>
    );
  }
}

export default Popupscreen;

function setIEratio(ventiltr) {
  var ieratiomsg = "0:0";
  var istr;
  var estr;

  ventiltr.ieRatioUser = () => {
    if (ventiltr.BR) {
      //qDebug()<<"setieRatioUser";
      let m_ieRatioUser = 60 / ventiltr.BR - ventiltr.InspT;
      // console.log("ieRatioUser:m_ieRatioUser", m_ieRatioUser);
      return m_ieRatioUser;
    }
  };
  ventiltr.inspTimeUser = () => {
    // console.log("iTime:ventiltr.InspT", ventiltr.InspT);
    return ventiltr.InspT;
  };

  //ventiltr.ieRatioUser() is used as eTime

  // console.log("eTime:" + ventiltr.ieRatioUser());
  // console.log("iTime:" + ventiltr.inspTimeUser());

  if (
    ventiltr.ieRatioUser() < ventiltr.inspTimeUser() &&
    ventiltr.ieRatioUser() > 0
  ) {
    //
    istr = ventiltr.inspTimeUser() / ventiltr.ieRatioUser();
    // console.log("i:" + istr);

    ieratiomsg = istr.toFixed(1) + ":1";
  } else if (
    ventiltr.inspTimeUser() < ventiltr.ieRatioUser() &&
    ventiltr.inspTimeUser() > 0
  ) {
    estr = ventiltr.ieRatioUser() / ventiltr.inspTimeUser();
    // console.log("e:" + estr);
    ieratiomsg = "1:" + estr.toFixed(1);
  } else if (ventiltr.ieRatioUser() === ventiltr.inspTimeUser()) {
    ieratiomsg = "1:1";
  } else {
    ieratiomsg = "0:0";
  }
  return ieratiomsg;
}
