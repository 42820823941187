import { fromEvent } from "rxjs";
import SocketClientIO from "socket.io-client";
import SailsIO from "sails.io.js";
let scoket = SailsIO(SocketClientIO);
scoket.sails.autoConnect = false;
scoket.sails.reconnection = true;
scoket.sails.reconnectionAttempts = 10;
scoket.sails.reconnectionDelay = 1000;
let socket = null;
if (process.env.NODE_ENV !== "production") {
  socket = scoket.sails.connect(process.env.REACT_APP_SAILSSOCKET);
} else {
//  socket = scoket.sails.connect(process.env.REACT_APP_SAILSSOCKET);
socket = scoket.sails.connect("/");
}

export var SailsSocket = socket;
window.socket = socket;
export const Connect = fromEvent(SailsSocket, "connect");
export const Disconnect = fromEvent(SailsSocket, "disconnect");
export const Error = fromEvent(SailsSocket, "error");
export const Reconnect = fromEvent(SailsSocket, "reconnect");
export const Reconnecting = fromEvent(SailsSocket, "reconnecting");
//--------------------------------------------------------------------------
//export const Machine = fromEvent(SailsSocket, "machine");
