import React from "react";
import Footer from "./footer";
import TopNavbar from "./topnavbar";
import {
  Switch,
  Redirect,
  Route,
  withRouter,
  matchPath,
  useHistory,
} from "react-router-dom";
import IonVents from "./ion-vents/ion-vents";
import AdminConsole from "./Screens/AdminConsole";
import RegisterCompany from "./registerations/company/index";
// import RegisterUser from "./registerations/user/index";
import SingleDeviceScreen from "./Screens/SingleDeviceScreen";
import SingleDeviceScreenGuest from "./Screens/SingleDeviceScreenGuest";
import Dashboard from "./Screens/Dashboard";
import Sidebar from "./sidebar";
import { SailsSocket } from "../dataService";
import { fromEvent } from "rxjs";
import { alarmService } from "../alarmService";
import ProfilePage from "./ProfilePage";
import IdleLogoutDialog from "./IdleLogoutDialog";
import "./navbar.css";
import NotFound from "./404NotFound";

var socket = SailsSocket;
class MainPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MAX_VENTS: 12,
      ventilators: [], // watchList array
      AlarmListStates: null,
      user: null,
      islog: true,
      isCurveMonitoringOpen: {},
      currentGrid: '2:2'
    };
    this.rxjs_alarm = null;
    this.rxjs_alarm_subscription = null;
  }

  updateAlarmListStates = (newProps) => {
    console.log("[mainpanel] called updateAlarmListStates()");
    this.setState({ AlarmListStates: newProps });
  };
  clearAlarmListStates = () => {
    this.setState({ AlarmListStates: null });
  };

  removeVent = (vent) => {
    console.log("removeVent ventId", vent);

    let index = this.state.ventilators.findIndex(
      (x) => x.deviceId == vent.deviceId
    );
    let prevVents = [...this.state.ventilators];
    prevVents.splice(index, 1);
    this.setState({ ventilators: prevVents }, () => {
      console.log("Vent removed form index", index);
    });
  };
  updateCurveMonitoringVisiblity = (newVent) => {
    // console.log("Enterd=====updateCurveMonitoringVisiblity");
    if (!Object.keys(this.state.isCurveMonitoringOpen).includes(newVent.deviceId)) {
      // console.log("Not there in isCurveMonitoringOpen");
      this.setState({
        isCurveMonitoringOpen: {
          ...this.state.isCurveMonitoringOpen,
          [newVent.deviceId]: false
        }
      })
    }
  }
  addOrReplaceVentilator = (newList) => {
    // console.log("NEW LIST:", newList);
    this.setState({ ventilators: newList }, () => {
      console.log("TotalDevices-aded to dashboard", this.state.ventilators);
    });
  };

  currentGrid = (selectedGridSystem) => {
    this.setState({ currentGrid: selectedGridSystem }, () => {
      // console.log("currentGrid(MainPanel):", this.state.currentGrid);
    });
  }
  handleToggleingOfCurveMonitoring = (deviceId, status) => {
    // console.log("handleToggleingOfCurveMonitoring", deviceId, status)
    if (Object.keys(this.state.isCurveMonitoringOpen).includes(deviceId)) {
      this.setState({ isCurveMonitoringOpen: { ...this.state.isCurveMonitoringOpen, [deviceId]: status } })
    }
  }
  addOrReplaceVentilatorOld = (newVentilator, existingDevice) => {
    let _device = {
      BedID: "",
      RoomID: "",
      Severity: "1",
      c_alarm: "...",
      company_id: "",
      deviceId: newVentilator.name,
      plant_id: "",
    };
    if (this.state.ventilators.length == 4) {
      let index = this.state.ventilators.findIndex(
        (x) => x.deviceId == existingDevice.deviceId
      );
      let prevVents = [...this.state.ventilators];
      prevVents.splice(index, 1, _device);
      this.setState({ ventilators: prevVents }, () => { });
    } else if (this.state.ventilators.length < 4) {
      //console.log("New Vetilator found mainPanel", newVentilator);

      this.setState(
        { ventilators: [...this.state.ventilators, _device] },
        () => {
          //  console.log("New Device added", this.state.ventilators);
        }
      );
    }
  };
  removeVentCompletelyById = (ventId) => {
    let newArray = this.state.ventilators.filter((el) => el.deviceId != ventId);
    this.setState({ ventilators: newArray });
  };
  setVentilators = (ventilators) => {
    this.setState({ ventilators: ventilators }, () => {
      // console.log("This.context.history");
    });
  };

  addNewVentToWatchList = (vent) => {
    // if (this.state.MAX_VENTS == this.state.ventilators.length) {
    //   alert("Watchlist full maxvents" + this.state.MAX_VENTS);
    // } else {
      this.setState({ ventilators: [...this.state.ventilators, vent] });
    // }
  };
  render() {
    const { islog } = this.state;
    let doNotThisComponentIfHardwareScreen = false;
    let isDeviceScreen = matchPath(this.props.location.pathname, {
      path: "/device-screen",
    });
    let isDeviceDashboard = matchPath(this.props.location.pathname, {
      path: "/watchlist",
    });
    doNotThisComponentIfHardwareScreen = isDeviceScreen || isDeviceDashboard;

    let AddIdleComp = (
      <IdleLogoutDialog user={this.props.user}></IdleLogoutDialog>
    );
    if (process.env.NODE_ENV !== "production") {
      AddIdleComp = (
        <IdleLogoutDialog user={this.props.user}></IdleLogoutDialog>
      );
    }

    return (
      <React.Fragment>
        {/* {doNotThisComponentIfHardwareScreen ? null : (
          <Sidebar user={this.props.user} logOut={this.props.logOut} />
        )} */}

        <Sidebar
          socket={socket}
          updateAlarmListStates={this.updateAlarmListStates}
          MAX_VENTS={this.state.MAX_VENTS}
          ventilators={this.state.ventilators}
          addVentToWatchList={this.addOrReplaceVentilator}
          doNotShowSidebar={doNotThisComponentIfHardwareScreen}
          user={this.props.user}
          isDeviceDashboard={isDeviceDashboard}
          logOut={this.props.logOut}
          updateCurveMonitoringVisiblity={this.updateCurveMonitoringVisiblity}
          currentGrid={this.currentGrid}
        />

        <div
          className={
            doNotThisComponentIfHardwareScreen ? "hardware-panel" : "main-panel"
          }
        >
          {/*<!-- Navbar -->*/}
          {doNotThisComponentIfHardwareScreen ? null : (
            <TopNavbar user={this.props.user} />
          )}

          {/* <div className="main-panel">
          <TopNavbar user={this.props.user} /> */}

          {/*<!-- End Navbar -->*/}
          <div
            className={
              doNotThisComponentIfHardwareScreen
                ? "hardware-content"
                : "content"
            }
            style={{ paddingTop: "0px" }}
          >
            {/* <div className="content" style={{ paddingTop: "0px" }}> */}
            <div className="container-fluid" style={{ padding: "0px" }}>
              {/* <Route
                path="/device-screen"
                render={() => <DeviceScreen draw={true} socket={socket} />}
              /> */}
              <Switch>
                <Route
                  path="/RegisterCompany"
                  render={() => <RegisterCompany />}
                />

                <Route
                  path="/device-screen"
                  render={() => {
                    if (
                      this.props.user.email &&
                      this.props.user.email === process.env.REACT_APP_GUEST_ID
                    ) {
                      return (
                        <SingleDeviceScreenGuest
                          user={this.props.user}
                          MAX_VENTS={this.state.MAX_VENTS}
                          addNewVentToWatchList={this.addNewVentToWatchList}
                          removeVentCompletelyById={
                            this.removeVentCompletelyById
                          }
                          ventilators={this.state.ventilators}
                          socket={socket}
                        />
                      );
                    } else {
                      return (
                        <SingleDeviceScreen
                          MAX_VENTS={this.state.MAX_VENTS}
                          addNewVentToWatchList={this.addNewVentToWatchList}
                          removeVentCompletelyById={
                            this.removeVentCompletelyById
                          }
                          ventilators={this.state.ventilators}
                          socket={socket}
                        />
                      );
                    }
                  }}
                />

                <Route
                  path="/admin-console"
                  render={() =>
                    this.props.user.role == "admin" ? (
                      <AdminConsole socket={socket} />
                    ) : (
                      <NotFound />
                    )
                  }
                />

                <Route
                  path="/profile-page"
                  render={() => <ProfilePage socket={socket} />}
                />
                <Route
                  path="/watchlist"
                  render={() => (
                    <Dashboard
                      socket={socket}
                      setVentilators={this.addOrReplaceVentilator}
                      removeVent={this.removeVent}
                      ventilators={this.state.ventilators}
                      isCurveMonitoringOpen={this.state.isCurveMonitoringOpen}
                      handleToggleingOfCurveMonitoring={this.handleToggleingOfCurveMonitoring}
                      currentGrid={this.state.currentGrid}
                    />
                  )}
                />
                <Route
                  path="/alpha-vents"
                  render={() => (
                    <IonVents
                      location={this.props.location}
                      AlarmListStates={this.state.AlarmListStates}
                      MAX_VENTS={this.state.MAX_VENTS}
                      addNewVentToWatchList={this.addNewVentToWatchList}
                      removeVentCompletelyById={this.removeVentCompletelyById}
                      ventilators={this.state.ventilators}
                      user={this.props.user}
                      setVentilators={this.setVentilators}
                      socket={socket}
                    />
                  )}
                />
                <Route
                  islog={this.props.islog}
                  exact={true}
                  path=""
                  render={() => (this.props.islogg ? <NotFound /> : null)}
                />
              </Switch>

              {/* <!-- your content here -->*/}
            </div>
          </div>
          {doNotThisComponentIfHardwareScreen ? null : <Footer />}
        </div>

        {AddIdleComp}
      </React.Fragment>
    );
  }

  componentDidMount() {
    //console.log("[mainpanel]----------did mont");
    this.initRxjx();
  }

  async componentWillUnmount() {
    await this.clearRxjs();
    this.setState({ islog: false });
  }

  initRxjx = async () => {
    //listen sails socket for any change
    let topicName = null; //
    if (this.props.user.role == "user") {
      topicName = "alarm" + this.props.user.plant_id;
    }
    if (this.props.user.role == "admin") {
      topicName = "alarm" + this.props.user.company_id;
    }
    //console.log("[mainpanel]topicname", topicName);
    this.rxjs_alarm = fromEvent(SailsSocket, topicName);
    this.rxjs_alarm_subscription = this.rxjs_alarm.subscribe((alarms) => {
      alarmService.send(alarms);
    });
  };
  clearRxjs = async () => {
    this.rxjs_alarm_subscription.unsubscribe();
    this.rxjs_alarm = null;
    return;
  };
}

export default withRouter(MainPanel);
