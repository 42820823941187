import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { DEVICEALRAM_ID } from "../../../api-route";
import QrReader from "react-qr-reader";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import "../App.css";
import QueryString from "query-string";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import Draggable from "react-draggable";
import Paper from "@material-ui/core/Paper";
//import QrCodeDialog from "../Screens/dialog/QrCodeDialog";
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export default function QrCodeDialog(props) {
  const { open, setClose, currentDevice, onClose, deviceList } = props;
  // console.log("deviceList:", deviceList);
  const history = useHistory();
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });
  const [result, setResult] = useState("No result");
  const handleClose = () => {
    setClose();
  };

  const handleScan = async (data) => {
    //data.preventDefault();
    if (data) {
      // console.log("data", data);
      let token = localStorage.getItem("abmUsertoken");
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          data: data,
          deviceList: deviceList
        })
      };
      await fetch(`${DEVICEALRAM_ID}`, requestOptions)
        .then((res) => res.json())
        .then((result) => {
          //let final = [];
          // final.push(data1);
          // console.log("data after get", result);
          if (result.success) {
            let query = QueryString.stringify(result.data);
            //setResult(data);
            setClose(false);
            onClose();
            history.push(`/device-screen?${query}`);
          } else {
            setSnackMsg("Resource not available");
            setSnackSeverity("error");
            setOpenSnack(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setSnackMsg(`Something went wrong`);
          setSnackSeverity("error");
          setOpenSnack(true);
        });
    }
  };
  const handleError = (err) => {
    console.error(err);
  };
  const handleSnackbar = () => {
    setOpenSnack(false);
  };
  return (
    <React.Fragment>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="xs"
        // width={100}
        style={{ maxWidth: "400px", margin: "auto" }}
        PaperComponent={PaperComponent}
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="draggable-dialog-title"
          style={{
            background: "#808080",
            color: "white",
            fontSize: "1rem",
            cursor: "move",
          }}
        >
          Device QR
          <IconButton
            style={{ color: "white", float: "right" }}
            aria-label="upload picture"
            component="button"
            onTouchTap={handleClose}
            onClick={handleClose}
          ></IconButton>
        </DialogTitle>
        <div
          style={{
            position: "absolute",
            width: "50px",
            right: "0px",
            top: "18px",
            color: "white",
          }}
          onClick={handleClose}
        >
          <CloseIcon style={{ cursor: "pointer" }} />
        </div>
        <DialogContent>
          <div className={classes.root}>
            <div className="HpQrcode">
              <QrReader delay={300} onError={handleError} onScan={handleScan} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
