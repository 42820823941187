import React, { Component, useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import { PCREATE } from "../../../api-route";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "../App.css";
// import GMap from "../GoogleMapContainer";
import InputField from "./validation/editfrom";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";


import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function AddNewVentDialog(props) {
  const { open, setClose, onChange, companyList } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  //Form Validating
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);

  const [values, setValues] = React.useState({});
  const [selected2, setselected2] = useState("");
  const [hasError2, sethasError2] = useState(false);
  // const [company, setCompany] = React.useState([]);
  let company = companyList;
  const [sessionExpired, setSessionExpired] = React.useState(false);

  const handleInputChange = (name, value) => {
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChangedrop2 = (e) => {
    if (e.target.value) {
      // console.log("Selected plant",e.target.value)
      setselected2(e.target.value);
      // values["plantName"]=e.target.value;

      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }

    sethasError2(false);
  };


  const submitForm = () => {
    // e.preventDefault();

    let isValid = true;

    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();

      if (!valid) {
        isValid = false;
      }
    }
    sethasError2(false);
    if (!selected2) {
      sethasError2(true);
      isValid = false;
    }
    if (!isValid) {
      return;
    }
    // e.preventDefault();
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({ name: values.plant, company_id: values.company }),
    };

    let data = [];
    fetch(`${PCREATE}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json()
      })
      .then((result) => {
        // setResultData(result);
        //  console.log("result",result)
        if (result.success) {
          setSnackMsg("Branch added succcesfully");
          setSnackSeverity("success");
          setOpenSnack(true);
          onChange('reset-filter');
          handleClose();
        } else {
          //    alert("Plant Added Unsuccesfully due to "+result.message )
          setSnackMsg(`Branch added unsuccesfully due to ${result.message}`);
          setSnackSeverity("error");
          setOpenSnack(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    //Carry on as normal
  };

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const handleClose = () => {
    setClose(false);
  };
  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />

      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "1rem" }}
        >
          ADD BRANCH
          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                TabIndicatorProps={{ style: { background: "gray" } }}
                value={value}
                onChange={handleChange1}
                aria-label="simple tabs example"
              >
                <Tab label="Details" {...a11yProps(0)} />
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="add-new-vent-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form noValidate>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Branch Name
                                  </label>
                                  <InputField
                                    ref={inputRefs.current[0]}
                                    label="Branch"
                                    name="plant"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control"
                                  />
                                </div>
                              </div>

                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Hospital Name{" "}
                                  </label>
                                  <FormControl
                                    style={{ width: "100%" }}
                                    error={hasError2}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="company"
                                      value={selected2}
                                      onChange={handleChangedrop2}
                                      style={{ backgroundColor: "white",textTransform:'capitalize' }}
                                    >
                                      {company.map((item, value) => (
                                        <MenuItem key={value} value={item.id} style={{textTransform:'capitalize' }}>
                                          {item.name}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {hasError2 && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Hospital must be selected!
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                            {/* <button type="submit" className="btn">
                              ADD BRANCH
                            </button> */}
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </DialogContent>

        <DialogActions style={{ paddingRight: '20px' }}>
          <button type="button" className="btn" onClick={submitForm} >
           Submit
                            </button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
