//export const WS_URL = "ws://159.65.159.52:15675/ws";
export const WS_URL = process.env.REACT_APP_RABBITMQ;
export const RABBIT_USERNAME = process.env.REACT_APP_RABBITMQ_USER;
export const RABBIT_PASSWORD = process.env.REACT_APP_RABBITMQ_PWD;
//-------------------------------------------------------
export const HPALARM_SUB = "1";
export const MPALARM_SUB = "2";
export const LPALARM_SUB = "3";
export const NOALARM_SUB = "4";

// rabbitmqctl add_user abm  11012019mqtt
// rabbitmqctl set_permissions -p / abm ".*" ".*" ".*"
// rabbitmqctl set_user_tags abm management
