import React, { Component, useState } from "react";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ReplayIcon from "@material-ui/icons/Replay";
import Done from "@material-ui/icons/Done";

export default function IncrementModel(props) {
  let initialValue = props.value;
  let [range, setRange] = useState(props.range);
  let [step, setStep] = useState(props.step);
  let [inspValue, setinspValue] = useState(props.value);

  return (
    <div>
      <div className="row">
        <div className="col-4 p-4">
          <button
            className="btn"
            style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              backgroundColor: "gray",
              borderRadius: "50px",
              width: "65px",
              height: "65px",
            }}
            onClick={() => {
              // console.log("Props", props.float);
              let temp = parseFloat(inspValue).toFixed(1);
              temp = Number(temp) + step;
              if ("max" in range) {
                if (temp <= range.max) {
                  if (props.float) {
                    // console.log("props true", props.float);
                    setinspValue(parseFloat(temp).toFixed(1));
                  } else {
                    // console.log("props false", props.float);
                    setinspValue(parseInt(temp));
                  }
                }
              } else {
                setinspValue(temp);
              }
            }}
          >
            {/* <i
              className="material-icons"
              style={{
                color: "white",
                fontSize: "4.5rem",
                marginLeft: "-33px",
              }}
            >
              keyboard_arrow_up
            </i> */}
            <KeyboardArrowUpIcon
              style={{
                color: "white",
                fontSize: "4.5rem",
                // marginLeft: "-33px",
                // marginTop: "-14px",
              }}
            />
          </button>
          <button
            className="btn"
            style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              backgroundColor: "gray",
              borderRadius: "50px",
              width: "65px",
              height: "65px",
            }}
            onClick={() => {
              let temp = parseFloat(inspValue).toFixed(1);
              temp = Number(temp) - step;
              if ("min" in range) {
                if (temp >= range.min) {
                  if (props.float) setinspValue(parseFloat(temp).toFixed(1));
                  else {
                    setinspValue(parseInt(temp));
                  }
                }
              } else {
                setinspValue(temp);
              }
            }}
          >
            <KeyboardArrowDownIcon
              style={{
                color: "white",
                fontSize: "4.5rem",
                // marginLeft: "-33px",
                // marginTop: "-13px",
              }}
            />
            {/* <i
              className="material-icons"
              style={{
                color: "white",
                fontSize: "4.5rem",
                marginLeft: "-33px",
              }}
              onClick={() => {
                let temp = parseFloat(inspValue).toFixed(1);
                temp = Number(temp) - step;
                if ("min" in range) {
                  if (temp >= range.min) {
                    if (props.float) setinspValue(parseFloat(temp).toFixed(1));
                    else {
                      setinspValue(parseInt(temp));
                    }
                  }
                } else {
                  setinspValue(temp);
                }
              }}
            >
              keyboard_arrow_down
            </i> */}
          </button>
          <button
            className="btn"
            style={{
              display:"flex",
              justifyContent:"center",
              alignItems:"center",
              backgroundColor: "gray",
              borderRadius: "50px",
              width: "65px",
              height: "65px",
              // paddingLeft: "30px",
              // paddingTop: "5px",
            }}
            onClick={() => {
              setinspValue(initialValue);
              console.log("reply value", initialValue);
            }}
          >
            <ReplayIcon
              style={{
                color: "white",
                fontSize: "3rem",
                // marginLeft: "-20px",
              }}
            />
            {/* <i
              className="material-icons"
              style={{
                color: "white",
                fontSize: "3rem",
                marginLeft: "-20px",
              }}
            >
              replay
            </i> */}
          </button>
        </div>
        <div className="col-8 pt-4">
          <button
            className="btn"
            style={{
              backgroundColor: "#008080",
              opacity: "0.9",
              width: "180px",
              height: "150px",
              padding: "0px",
            }}
          >
            <span
              style={{
                fontSize: "80px",
                color: "white",
                marginLeft: "0px",
              }}
            >
              {" "}
              {inspValue}{" "}
            </span>
          </button>
          <button
            className="btn"
            style={{
              backgroundColor: "green",
              width: "180px",
              height: "50px",
              marginLeft: "0px",
              marginTop: "10px",
            }}
            onClick={() => {
              props.toggleChildPopup();
              props.setValue(props.title, inspValue);
            }}
          >
            {/* <i
              className="material-icons"
              style={{ color: "white", fontSize: "3rem" }}
            >
              done
            </i> */}
            <Done
              style={{ color: "white", fontSize: "3rem", marginTop: "-11px" }}
            />
          </button>
        </div>
      </div>
    </div>
  );
}
