import React, { Component } from "react";
import VentilatorScreen from "./VentilatorScreen";
import Vetpick from "../../img/watchlist.png";

//import VentDialog from "./dialog/ventDialog";
// import { any } from "prop-types";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  addNewVentilator = (NewVentilator) => {
    console.log("NewVentilator", NewVentilator);
    console.log("currentGrid(Dashboard):", this.props.currentGrid);

    let _data = NewVentilator[0];
    if (this.props.ventilators.length == 4) {
      // get position
      // and replace that one form here
      this.props.setVentilators(_data, this.sourceDevice);
    } else {
      this.props.setVentilators(_data);
    }
    // }
  };

  /* -----------------------------------

   handleClickOnVentId --> this is to open vent dialog' 
  ------------------------------------*/

  handleClickOnVentId = (sourceDevice) => {
    this.sourceDevice = sourceDevice;
    // let sourceDevice={
    //   BedID: ""
    //   RoomID: ""
    //   Severity: "1"
    //   c_alarm: "No Alarm"
    //   company_id: 100
    //   deviceId: "Vent003"
    //   plant_id: "P5@100"
    // }
    this.props.removeVent(sourceDevice);
  };

  SingleScreen = (length) => (
    <div
      className="row removeMargin "
      style={{ height: "100vh", background: "black" }}
    >
      <div className="col-md-12 col-lg-12 col-xs-12 col-xl-12 removePadding addRigtPadding single">
        <VentilatorScreen
          key={"2"}
          handleClickOnVentId={this.handleClickOnVentId}
          socket={this.props.socket}
          ventilator={this.props.ventilators[0]}
          isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(this.props.ventilators[0])}
          handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
        />
      </div>
    </div>
  );
  handleIsCurveMonitoringOpen = (vent) => {
    // console.log("handleIsCurveMonitoringOpen",vent," Id:",vent.deviceId," ISopen",this.props.isCurveMonitoringOpen)
    if (vent.deviceId in this.props.isCurveMonitoringOpen) {
      // console.log("this.props.isCurveMonitoringOpen.vent.deviceId",this.props.isCurveMonitoringOpen[vent.deviceId])
      return this.props.isCurveMonitoringOpen[vent.deviceId];
    } else {
      return false;
    }
  }

  render() {
    const { ventilators, currentGrid } = this.props;
    let length = 0;
    if (ventilators && Array.isArray(ventilators)) {
      length = ventilators.length;
      console.log("Dashboard:Render", ventilators, currentGrid);
      switch (length) {
        case 1:
          return this.SingleScreen(length);
      }
    }
    let _currentGrid = currentGrid ? currentGrid : '2:2'
    let _currentGrid_total_row = _currentGrid.split(':')[1];
    let _currentGrid_total_column = _currentGrid.split(':')[0];
    // console.log("_currentGrid_total_row:", _currentGrid_total_row, " _currentGrid_total_column:", _currentGrid_total_column)

    let bootstrap_grid_class = "col-md-12 col-lg-6 col-xs-12 col-xl-6 removePadding";
    if (_currentGrid_total_column == "2" || ((_currentGrid_total_column == "3"
      || _currentGrid_total_column == "4") && length == 2)) {
      bootstrap_grid_class = "col-md-12 col-lg-6 col-xs-12 col-xl-6 removePadding";
    } else if (_currentGrid_total_column == "3" || (_currentGrid_total_column == "4" && length == 3)) {
      bootstrap_grid_class = "col-md-12 col-lg-4 col-xs-12 col-xl-4 removePadding";
    } else if (_currentGrid_total_column == "4") {
      bootstrap_grid_class = "col-md-12 col-lg-3 col-xs-12 col-xl-3 removePadding";
    }


    return (
      <React.Fragment>
        {length == 0 ? (
          <div className="d-flex justify-content-center align-items-center" style={{ height: '90vh' }}>
            <img
              src={Vetpick}
              style={{
                height: "200px",
                width: "200px",
              }}
              alt="Add vents"
            />
          </div>
        ) : null}

        <div className="row removeMargin vent-dashboard">
          {ventilators.map((vent, index) => {
            // if ((_currentGrid_total_column == '2' && index > 3) ||
            //   (_currentGrid_total_column == '3' && index > 8)) {
            //   return null;
            // }
            return (
              <div className={bootstrap_grid_class}>
                <VentilatorScreen
                  key={index}
                  handleClickOnVentId={this.handleClickOnVentId}
                  socket={this.props.socket}
                  ventilator={vent}
                  isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(vent)}
                  handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
                /></div>
            )
          })}
          {/* <div className="col-md-12 col-lg-4 col-xs-12 col-xl-4 removePadding addRigtPadding">
            {length > 0 ? (
              <VentilatorScreen
                key={"0"}
                handleClickOnVentId={this.handleClickOnVentId}
                socket={this.props.socket}
                ventilator={this.props.ventilators[0]}
                isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(this.props.ventilators[0])}
                handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
              />
            ) : null}
          </div>

          <div className="col-md-12 col-lg-4 col-xs-12 col-xl-4 removePadding">
            {length > 1 ? (
              <VentilatorScreen
                key={"1"}
                handleClickOnVentId={this.handleClickOnVentId}
                socket={this.props.socket}
                ventilator={this.props.ventilators[1]}
                isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(this.props.ventilators[1])}
                handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
              />
            ) : null}
          </div>
          <div className="col-md-12 col-lg-4 col-xs-12 col-xl-4 removePadding">
            {length > 3 ? (
              <VentilatorScreen
                key={"3"}
                handleClickOnVentId={this.handleClickOnVentId}
                socket={this.props.socket}
                ventilator={this.props.ventilators[3]}
                isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(this.props.ventilators[3])}
                handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
              />
            ) : null}
          </div>
        */}
        </div>
        {/* <div className="row removeMargin">
          <div className="col-md-12 col-lg-6 col-xs-12 col-xl-6 removePadding addRigtPadding">
            {length > 2 ? (
              <VentilatorScreen
                key={"2"}
                handleClickOnVentId={this.handleClickOnVentId}
                socket={this.props.socket}
                ventilator={this.props.ventilators[2]}
                isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(this.props.ventilators[2])}
                handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
              />
            ) : null}
          </div>
          <div className="col-md-12 col-lg-6 col-xs-12 col-xl-6 removePadding">
            {length > 3 ? (
              <VentilatorScreen
                key={"3"}
                handleClickOnVentId={this.handleClickOnVentId}
                socket={this.props.socket}
                ventilator={this.props.ventilators[3]}
                isCurveMonitoringOpen={this.handleIsCurveMonitoringOpen(this.props.ventilators[3])}
                handleToggleingOfCurveMonitoring={this.props.handleToggleingOfCurveMonitoring}
              />
            ) : null}
          </div>
        </div> */}
      </React.Fragment>
    );
  }
  componentDidMount() {
    // this.sbs1 = RBTMQDisconnect.subscribe((data) => {
    //   console.log("Rabit MQ disconnected");
    // });
    // this.sbs2 = RBTMQConnect.subscribe((data) => {
    //   console.log("Rabit MQ connected");
    // });
    // this.sbs3 = RBTMQError.subscribe((data) => {
    //   console.log("Rabit MQ error", data);
    // });
    // this.sbs4 = RBTMQReconnecting.subscribe((data) => {
    //   console.log("RBTMQReconnecting-->", data);
    // });
  }
  componentWillUnmount() {
    // this.sbs1.unsubscribe();
    // this.sbs2.unsubscribe();
    // this.sbs3.unsubscribe();
    // this.sbs4.unsubscribe();
  }
}

export default Dashboard;
