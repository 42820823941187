import React, { useEffect, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import InputField from './registerations/company/inputField';
import { RESETPASSWORD, RESETPASSWORD_DECRYPT } from "../../src/api-route";
import ReactLoading from "react-loading";
import { Redirect } from 'react-router-dom';
import { CustomsSnackbar } from "./snakbar/snackbar";
import logo from "../img/abm-new-logo.png";

import './login/login.css';

export default function SignUp(props) {
    const history = useHistory();
    // function useQuery() {
    //     return new URLSearchParams(useLocation().search);
    // }
    let { id, token } = useParams();
    console.log("query id id", id, " Token:", token);
    const [data, setData] = React.useState({});
    let password = data.password;
    // let query = useQuery();
    const inputRefs = React.useRef([
        React.createRef(), React.createRef()
    ]);
    const handleChange = (name, value) => {
        setData(prev => ({ ...prev, [name]: value }))

    }
    const [linkData, setLinkData] = useState({});
    const [loading, setLoading] = useState(false);
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackSeverity, setSnackSeverity] = React.useState("");
    const [snackMsg, setSnackMsg] = React.useState("");
    const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({ vertical: "top", horizontal: "center" });
    const handleSnackbar = () => {
        setOpenSnack(false);
    };
    useEffect(() => {
        if (token && id) {
            const requestOptions = {
                method: "GET",
            };
            fetch(
                `${RESETPASSWORD_DECRYPT}?id=${id}&token=${token}`,
                requestOptions
            ).then((res) => res.json()).then((response) => {
                console.log("Response:", response)
                setLinkData(response);
                if (response && response.message) { //if there is 'message' in response that means there is a problem 
                    if (response.message === 'jwt expired') {
                        setOpenSnack(true);
                        setSnackMsg("Your password reset link has expired");
                        setSnackSeverity("error")
                    } else if (response.message === 'invalid signature') {
                        setOpenSnack(true);
                        setSnackMsg("This link has already been used or expired");
                        setSnackSeverity("error")
                    } else if (response.message === 'Not Found') {
                        history.replace("/");
                        // setOpenSnack(true);
                        // setSnackMsg("This link has already been used or expired");
                        // setSnackSeverity("error")
                    } else {
                        setOpenSnack(true);
                        setSnackMsg("Something went wrong. Try again later");
                        setSnackSeverity("error")
                    }
                    setTimeout(() => history.replace("/"), 5000)
                }
            })

        } else {
            // console.log("Token or id is missing");
            history.replace("/");
        }

    }, []);

    const handleSubmit = (e) => {
        // console.log("INSIDE SUBMIT", data)
        // console.log('Linkdata emal:', linkData, " is true?:", 'email' in linkData);
        e.preventDefault();
        let isValid = true;
        for (let i = 0; i < inputRefs.current.length; i++) {
            if (inputRefs.current[i].current === null) {
                continue;
            }
            const valid = inputRefs.current[i].current.validate();
            if (!valid) {
                isValid = false;
            }
        }
        if (isValid && linkData.email) {
            setLoading(true);
            setTimeout(() => {
                fetch(RESETPASSWORD, {
                    method: "POST",
                    body: JSON.stringify({
                        email: linkData.email,
                        password: data.password
                    }),
                })
                    .then((res) => res.json())
                    .then((result) => {
                        if (result.status) {
                            setLoading(false);
                            if (result.status === 200) {
                                setOpenSnack(true);
                                setSnackMsg("Password updated succesfully");
                                setSnackSeverity("success")
                                // setRedirectToLogin(true)
                                setTimeout(() => history.replace("/"), 3000)
                                    ;
                            } else {
                                setOpenSnack(true);
                                setSnackMsg(result.message);
                                setSnackSeverity("error")
                                setTimeout(() => history.replace("/"), 5000)
                            }
                            // console.log("SENT STATUS:", result.status);
                        }

                    });
            }, 600);
        }
    }

    return (
        <React.Fragment>
            <CustomsSnackbar
                msg={snackMsg}
                flag={openSnack}
                onClose={handleSnackbar}
                key={"cusSnackbar"}
                anchorOrigin={customeSnackbarAnchor}
                severity={snackSeverity}
            />
            {/* {console.log('Linkdata emal', linkData.email)} */}

            {redirectToLogin ? <Redirect to="/" /> : null}
            <div className="login">
                <section className="own-code-animation own-code-login" style={{ flexDirection: 'column', justifyContent: 'flex-start' }}>
                    {/* <div className="own-content"> */}
                    <div className="own-header mb-0">
                        <span className="own-logo">
                            <img src={logo} alt="Logo" className="img-fluid logo-resize" style={{ width: '150px' }} />
                        </span>
                    </div>
                    <div className="container">
                        <div
                            style={{
                                margin: "auto",
                                display: "flex",
                                alignSelf: "center",
                                maxWidth: "600px",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                            <form onSubmit={handleSubmit} noValidate className="well form-horizontal register" style={{ width: "100%" }}
                            >
                                <fieldset style={{
                                    backgroundColor: "rgb(255 255 255 / 95%)", padding: '15px', borderRadius: '6px'
                                }}>
                                    <div style={{ marginTop: "10px" }}><center><h3><b className="reset-pass-text">Reset Password</b></h3></center></div><br />

                                    <div className="row form-group">
                                        <label className="col-sm-4 labelstyle ">New Password</label>
                                        <div className="col-sm-8 ">
                                            <InputField
                                                ref={inputRefs.current[0]}
                                                label="Password"
                                                name="password"
                                                type="password"
                                                placeholder="Enter password"
                                                validation="required|min:6"
                                                onChange={handleChange}
                                                className="form-control cust-input1"
                                                placeholder="Enter password"
                                            />
                                        </div>
                                    </div>


                                    <div className="row form-group">
                                        <label className="col-sm-4 labelstyle">Confirm Password</label>
                                        <div className="col-sm-8">
                                            <InputField
                                                ref={inputRefs.current[1]}
                                                label="Confirm password"
                                                name="cpwd"
                                                type="password"
                                                validation="passwordmatch"
                                                onChange={handleChange}
                                                className="form-control cust-input1"
                                                placeholder="Confirm password"
                                                password={password}
                                            />
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", padding: "20px", paddingBottom: "5px" }}>
                                        <button
                                            className="own-btn-black" style={{ width: '150px' }}
                                        >Submit</button>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        {loading ? (
                                            <ReactLoading type={"cylon"} color="#000
                                " />
                                        ) : null
                                            // <span style={{ color: "#ff00009e", fontSize: "15px", fontWeight: "550" }}>{error}</span>
                                        }
                                    </div>
                                </fieldset>

                            </form>
                        </div>
                    </div>
                    {/* </div> */}
                </section>
            </div>
        </React.Fragment>
    );
}
