import React from "react";
import { client, RBTMQConnect, RBTMQReconnect } from "../../mqttConnections";
import { Link } from "react-router-dom";
import ReplyIcon from "@material-ui/icons/Undo";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";

class WarningMsg extends React.PureComponent {
  constructor(props) {
    super(props);
    //-----------------------------------------Mqtt
    this.connectSubscription = null;
    this.reConnectSubscription = null;
    //---------------------------------------------localInterval
    this.makeActiveIntrRef = null;
    this.makeInactiveIntrRef = null;
    this.watcherIntrRef = null;
    this.state = {
      isOnline: true,
    };
    this.livesignal = new Date();
    setTimeout(() => {
      client.subscribe(`ION/${this.props.deviceId}/CURRENTALARM`, () => {
        // console.log(`Constructore cliet.status  excuted`, client.connected);
      });
    }, 2000);
  }

  watchSingnal = () => {
    // console.clear();
    // console.log("watchSingnal-", this.livesignal.getSeconds());
    var seconds = (new Date().getTime() - this.livesignal.getTime()) / 1000;
    // console.log("Diffrence between in Seconds ", seconds);
    if (seconds > 20) {
          // console.log("20 seconds passed");
      this.setState({ isOnline: false });
    } else {
      this.setState({ isOnline: true });
    }
  };

  cleanResources = () => {
    client.unsubscribe(`ION/${this.props.deviceId}/CURRENTALARM`);
  };

  runTestCode = () => {
    this.makeActiveIntrRef = setInterval(() => {
      this.livesignal = new Date();
    }, 2000);
  };
  runProductionCode = () => {
    this.connectSubscription = RBTMQConnect.subscribe((obserer) => {
      //console.log("WarningMsg-Connected Rabbit:");
      //console.log(`cliet.status excuted`, client.connected);
      client.subscribe(`ION/${this.props.deviceId}/CURRENTALARM`, () => {
        //console.log(`ION/${this.props.deviceId}/CURRENTALARM subcribed`);
      });
    });
    this.reConnectSubscription = RBTMQReconnect.subscribe((obserer) => {
      //client.unsubscribe(`ION/${this.props.deviceId}/CURRENTALARM`);
    });
    client.on("message", (topic, msg) => {
      if (topic == `ION/${this.props.deviceId}/CURRENTALARM`) {
        // console.log(`ION/${this.props.deviceId}/CURRENTALARM ---msg`);
        this.livesignal = new Date();
      }
    });
  };

  componentDidMount() {
    if (process.env.NODE_ENV !== "production") {
      //testing environment
      // this.runTestCode();
      this.runProductionCode();
    } else {
      //this block will excute only
      // in production mood
      // do not make any change here....
      this.runProductionCode();
      // this.runTestCode();
    }
    // run watcher ryt after every 5 seconds
    this.watcherIntrRef = setInterval(() => {
      this.watchSingnal();
    }, 5000);
  }
  componentWillUnmount() {
    //console.log("UnMountWarning");
    clearInterval(this.watcherIntrRef);
    clearInterval(this.makeActiveIntrRef);
    this.cleanResources();
  }

  render() {
    // console.log("Warning component", this.state.isOnline);
    if (!this.state.isOnline) {
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            background: "#00000085",
            position: "absolute",
            top: "0",
            left: "0",
            justifyContent: "center",
            display: "flex",
            cursor: "not-allowed",
            zIndex:'99'
          }}
        >
          <div
            style={{
              width: "80%",
              height: "12%",
              background: "yellow",
              display: "flex",
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "12px",
              cursor: "auto",
            }}
          >
            <span style={{ fontSize: "20px", display: "flex" }}>
              This Ventilator({this.props.deviceId}) is no more online{" "}
              {this.props.single ? (
                <Link to="/alpha-vents" style={{ paddingLeft: "16px" }}>
                  {<ReplyIcon titleAccess="Go To Alpha Vents" />}
                </Link>
              ) : (
                <Button
                  onClick={() => {
                    console.log("keepalive-Props", this.props);
                    this.props.handleClickOnVentId(this.props.ventilator);
                  }}
                  variant="contained"
                  color="default"
                  style={{ paddingLeft: "16px" }}
                >
                  Remove
                </Button>
              )}
            </span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default WarningMsg;
