// import img2 from '../images/scroll_right.png';
// import img1 from "../images/scroll_left.png";
import ReactDOM from "react-dom";
import React, { Component, Fragment } from "react";
//import styles from "./styles";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Popup from "reactjs-popup";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import ReplayIcon from "@material-ui/icons/Replay";
import Done from "@material-ui/icons/Done";
import Slider from "../ui-components/slider/slider";
import RSlider from "../ui-components/slider/rSlider";
import SingleHandelSlider from "../ui-components/singleHandelSlider/rSlider";
import SHSlider from "../ui-components/singleHandelSlider/slider";
import { CustomsSnackbar } from "../../components/snakbar/snackbar";
import { checkLock } from "../../services/http";

// function ReadOnlySlider({ caption, values, domain }) {
//   return (
//     <div
//       className="d-flex justify-content-center align-items-center"
//       style={{ flexDirection: "column" }}
//       id="pkSlider"
//     >
//       <label className="heading">{caption}</label>
//       <RSlider keyValues={values} domain={domain} />
//     </div>
//   );
// }
function ReadOnlySlider({ caption, values, domain }) {
  if (caption === "Apnea") {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <label className="heading">{caption}</label>
        <div className="d-flex justify-content-center align-items-center">
          <SingleHandelSlider
            keyValues={[values[0], 0]}
            domain={[0, 60]}
            high
          />
          <SingleHandelSlider keyValues={[values[1], 0]} domain={[0, 10]} />
        </div>
      </div>
    );
  } else if (caption === "Circuit Disc") {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <label className="heading">{caption}</label>
        <SingleHandelSlider keyValues={values} domain={domain} high noCaption />
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <label className="heading">{caption}</label>
        <RSlider keyValues={values} domain={domain} />
      </div>
    );
  }
}

class Setting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ventilatorChildPopup: false,
      title: "",
      units: "",
      contentStyle: {},
      value: null,
      localAlarmSettingsData: { ...this.props.alarmSettings },
      page1: true,
      rightimage: true,
      isEnabled: 1,
      // message: 'hello'
      openSnack: false,
    };

    this.token = localStorage.getItem("abmUsertoken");
    this.sliderDomain = {
      pkSlider: {
        min: 4,
        max: 60,
        step: 1,
      },
      minutevalue: {
        min: 1,
        max: 99,
        step: 1,
      },
      breathvalue: {
        min: 4,
        max: 80,
        step: 1,
      },
      tidolvalue: {
        min: 50,
        max: 2000,
        step: 50,
      },
      peepvalue: {
        min: 0,
        max: 30,
        step: 1,
      },
      apneavalue: {
        min: 0,
        max: 120,
        step: 1,
      },
      spo2value: {
        min: 70,
        max: 99,
        step: 1,
      },
      circuitvalue: {
        min: 0,
        max: 60,
        step: 1,
      },
      fio2_hvalue: {
        min: 21,
        max: 100,
        step: 1,
      },
    };

    this.alaramcheckigvalue = {
      APN: 0,
      BR_H: 0,
      BR_L: 0,
      CKTD: 0,
      FIO2_H: 0,
      FIO2_L: 0,
      MV_H: 0,
      MV_L: 0,
      PEEP_H: 0,
      PEEP_L: 0,
      P_H: 0,
      P_L: 0,
      SPO2_H: 0,
      SPO2_L: 0,
      TV_H: 0,
      TV_L: 0,
    };
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.y_offsetWhenScrollDisabled = 0;

    this.MinuteVRef = React.createRef();
    this.PeakPRef = React.createRef();
    this.BreathRef = React.createRef();
    this.TidalVolRef = React.createRef();
    this.PeepRef = React.createRef();
    this.ApneaRef = React.createRef();
    this.Spo2Ref = React.createRef();
    this.CircuitDiscRef = React.createRef();
    this.FIO2Ref = React.createRef();
  }


  componentWillMount() {
    // this.disableScrollOnBody();
  }
  componentWillReceiveProps(comingProps) {
    if ("alarmSettings" in comingProps) {
      this.setState(
        { localAlarmSettingsData: comingProps.alarmSettings },
        () => {
          //this.alaramcheckigvalue = comingProps.alarmSettings;
          //console.log(" setState setting applied", this.alaramcheckigvalue);
        }
      );
    }
  }


  componentWillUnmount() {
    // this.enableScrollOnBody();
  }

  componentDidMount() {
    this.alaramcheckigvalue = this.props.alarmSettings;
  }

  onCancelClick() {
    // console.log("cancel clicked");
    this.props.closePopup();
  }
  onConfirmClick() {
    this.props.closePopup();
  }

  hanndleonclick = () => {
    this.setState({
      page1: false,
      rightimage: false,
    });
  };
  hanndleonclick1 = () => {
    this.setState({
      page1: true,
      rightimage: true,
    });
  };
  toggleChildPopup = () => {
    this.setState({ ventilatorChildPopup: !this.state.ventilatorChildPopup });
  };

  onPressVentilatorSettings = (ref) => {
    checkLock({ token: this.token }).then(res => {
      if (res.status === 200) {
        let title = ref.current.title;
        let content = ref.current.textContent;

        if ("PeakP" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "2%",
            padding: "15px 10px 40px 40px",
          };
          // if (content) {
          this.setState({
            title: "Peak P",
            units: "cmH2O",
            value: [
              parseInt(this.state.localAlarmSettingsData.P_H),
              parseInt(this.state.localAlarmSettingsData.P_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.P_EN) ? 1 : 0,
          });
          // }
        }
        if ("MinuteV" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "16%",
            padding: "15px 10px 40px 40px",
          };

          // if (content) {
          this.setState({
            title: "Minute V",
            units: "L",
            value: [
              parseInt(this.state.localAlarmSettingsData.MV_H),
              parseInt(this.state.localAlarmSettingsData.MV_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.MV_EN) ? 1 : 0,
          });
          // }

        }

        if ("BreathR" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "35%",
            padding: "15px 10px 40px 40px",
          };

          // if (content) {
          this.setState({
            title: "Breath R",
            units: "BPM",
            value: [
              parseInt(this.state.localAlarmSettingsData.BR_H),
              parseInt(this.state.localAlarmSettingsData.BR_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.BR_EN) ? 1 : 0,
          });
          // }
        }

        if ("TidalVol" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "53%",
            padding: "15px 10px 40px 40px",
          };

          // if (content) {
          this.setState({
            title: "Tidal Vol",
            units: "mL",
            value: [
              parseInt(this.state.localAlarmSettingsData.TV_H),
              parseInt(this.state.localAlarmSettingsData.TV_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.TV_EN) ? 1 : 0,
          });
          // }
        }

        if ("Peep" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "2%",
            padding: "15px 10px 40px 40px",
          };

          // if (content) {
          this.setState({
            title: "PEEP",
            units: "cmH2O",
            value: [
              parseInt(this.state.localAlarmSettingsData.PEEP_H),
              parseInt(this.props.alarmSettings.PEEP_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.PEEP_EN)
              ? 1
              : 0,
          });
          // }
        }

        if ("Apnea" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "16%",
            padding: "15px 10px 40px 40px",
          };

          // if (content) {
          this.setState({
            title: "Apnea",
            units: "",
            value: [parseInt(this.state.localAlarmSettingsData.APN), 0],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.APN_EN) ? 1 : 0,
          });
          // }
        }

        if ("Spo2" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "35%",
            padding: "15px 10px 40px 40px",
          };

          // if (content) {
          this.setState({
            title: "SPO2",
            units: "%",
            value: [
              parseInt(this.state.localAlarmSettingsData.SPO2_H),
              parseInt(this.state.localAlarmSettingsData.SPO2_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.SPO2_EN)
              ? 1
              : 0,
          });
          // }
        }

        if ("CircuitDisc" == title) {
          let contentStyle = {
            backgroundColor: "black",
            // opacity: "0.9",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "2%",
            padding: "15px 10px 40px 40px",
          };
          this.setState({
            title: "Circuit Disc",
            units: "sec",
            value: [parseInt(this.state.localAlarmSettingsData.CKTD), 0],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.CKTD_EN)
              ? 1
              : 0,
          });
        }
        if ("FIO2" == title) {
          let contentStyle = {
            backgroundColor: "black",
            height: "430px",
            width: "500px",
            minWidth: "330px",
            marginLeft: "53%",
            padding: "15px 10px 40px 40px",
          };
          // if (content) {
          this.setState({
            title: "FIO2",
            units: "%",
            value: [
              parseInt(this.state.localAlarmSettingsData.FIO2_H),
              parseInt(this.state.localAlarmSettingsData.FIO2_L),
            ],
            contentStyle: contentStyle,
            isEnabled: parseInt(this.state.localAlarmSettingsData.FIO2_EN)
              ? 1
              : 0,
          });
          // }
        }


        this.toggleChildPopup();
      } else {
        this.cusSnackbar.open()
      }
    }).catch(res => {
      console.log("LOCK-catch")
      console.error(res)
    })

  };
  cusSnackbar = {
    title: "ventmood-lock-snackbar",
    message: (<span>Can't allow in monitor mode.<br/>Unlock to access control mode</span>),
    anchor: { vertical: 'bottom', horizontal: 'right' },
    severity: "warning",
    close: () => {
      this.setState({ openSnack: false });
    },
    open: () => {
      // console.log("---OPen called, this",this)
      this.setState({ openSnack: true });
    },

  }


  onPressTick = ({ value, key, isEnabled }) => {
    let newlocalState = { ...this.state.localAlarmSettingsData };
    // console.log("[Suddhir],{ value, key, isEnabled }", {
    //   value,
    //   key,
    //   isEnabled,
    // });
    if (key === "Peak P") {
      newlocalState.P_H = value[0];
      newlocalState.P_L = value[1];
      if ("P_EN" in newlocalState) {
        newlocalState.P_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "Minute V") {
      newlocalState.MV_H = value[0];
      newlocalState.MV_L = value[1];
      if ("MV_EN" in newlocalState) {
        newlocalState.MV_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "Breath R") {
      newlocalState.BR_H = value[0];
      newlocalState.BR_L = value[1];
      if ("BR_EN" in newlocalState) {
        newlocalState.BR_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "Tidal Vol") {
      newlocalState.TV_H = value[0];
      newlocalState.TV_L = value[1];
      if ("TV_EN" in newlocalState) {
        newlocalState.TV_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "PEEP") {
      newlocalState.PEEP_H = value[0];
      newlocalState.PEEP_L = value[1];
      if ("PEEP_EN" in newlocalState) {
        newlocalState.PEEP_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "SPO2") {
      newlocalState.SPO2_H = value[0];
      newlocalState.SPO2_L = value[1];
      if ("SPO2_EN" in newlocalState) {
        newlocalState.SPO2_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "Circuit Disc") {
      newlocalState.CKTD = value[0];
      // newlocalState.P_L=value[1];
      if ("CKTD_EN" in newlocalState) {
        newlocalState.CKTD_EN = isEnabled ? 1 : 0;
      }

      // this.setState({ localAlarmSettingsData: newlocalState });
    } else if (key === "FIO2") {
      newlocalState.FIO2_H = value[0];
      newlocalState.FIO2_L = value[1];
      if ("FIO2_EN" in newlocalState) {
        newlocalState.FIO2_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    }

    this.props.peakhighlowvalue(newlocalState);
    // console.log("Output for publish", newlocalState);
  };

  doubleSliderPopupOutput = ({ higVal, lowVal, key, isEnabled }) => {
    //alert(`[Setting] doubleSliderPopupOutput key${key} H:${higVal}L:${lowVal}`);
    let newlocalState = { ...this.state.localAlarmSettingsData };

    if (key === "Apnea") {
      // console.log("Output for publish -keyenabled", isEnabled, newlocalState);
      newlocalState.APN = higVal[0];
      newlocalState.APNBR = lowVal[0];
      // newlocalState.P_L=value[1];
      if ("APN_EN" in newlocalState) {
        newlocalState.APN_EN = isEnabled ? 1 : 0;
      }
      // this.setState({ localAlarmSettingsData: newlocalState });
    }

    this.props.peakhighlowvalue(newlocalState);
    // console.log("Double Slider NEw LOcal State",newlocalState);
  };
  handleSnackbar = () => {
    this.setState({ openSnack: false });
  };
  render() {
    let leftrenctanglestyle = {
      position: "absolute",
      // top: "128.894px",
      backgroundColor: "white",
      height: "26px",
      margin: "0rem",
      textAlign: "center",
      right: "-45px",
      width: "2rem",
      paddingBottom: "2px",
      paddingTop: "2.5px",
      // marginTop: "20px",
    };

    let rightrenctanglestyle = {
      position: "absolute",
      // top: "128.894px",
      backgroundColor: "white",
      height: "26px",
      margin: "0rem",
      textAlign: "center",
      // right: "29px",
      width: "2rem",
      paddingBottom: "2px",
      paddingTop: "2.5px",
      marginTop: "170px",
    };

    return (
      <div>
        {<style>{"body { background-color: black; }"}</style>}
        <CustomsSnackbar
          msg={this.cusSnackbar.message}
          flag={this.state.openSnack}
          onClose={this.cusSnackbar.close}
          key={this.cusSnackbar.title}
          anchorOrigin={this.cusSnackbar.anchor}
          severity={this.cusSnackbar.severity}
        />
        <Carousel showThumbs={false} cancelable={false}>
          <div className="maindiv">
            <div className="row">
              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.P_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"PeakP"}
                ref={this.PeakPRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.PeakPRef);
                }}
              >
                <ReadOnlySlider
                  caption={"Peak P"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.P_H),
                    parseInt(this.state.localAlarmSettingsData.P_L),
                  ]}
                  domain={[
                    this.sliderDomain.pkSlider.min,
                    this.sliderDomain.pkSlider.max,
                  ]}
                />
              </div>

              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.MV_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"MinuteV"}
                ref={this.MinuteVRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.MinuteVRef);
                }}
              >
                <ReadOnlySlider
                  caption={"Minute V"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.MV_H),
                    parseInt(this.state.localAlarmSettingsData.MV_L),
                  ]}
                  domain={[
                    this.sliderDomain.minutevalue.min,
                    this.sliderDomain.minutevalue.max,
                  ]}
                />
              </div>

              <div
                className="col-lg-3 col-md-6  col-sm-6 col-6 pt-3 pt-lg-0"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.BR_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"BreathR"}
                ref={this.BreathRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.BreathRef);
                }}
              >
                <ReadOnlySlider
                  caption={"Breath R"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.BR_H),
                    parseInt(this.state.localAlarmSettingsData.BR_L),
                  ]}
                  domain={[
                    this.sliderDomain.breathvalue.min,
                    this.sliderDomain.breathvalue.max,
                  ]}
                />
              </div>

              <div
                className="col-lg-3 col-md-6 col-sm-6 col-6 pt-3 pt-lg-0"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.TV_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"TidalVol"}
                ref={this.TidalVolRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.TidalVolRef);
                }}
              >
                <ReadOnlySlider
                  caption={"Tidal Vol"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.TV_H),
                    parseInt(this.state.localAlarmSettingsData.TV_L),
                  ]}
                  domain={[
                    this.sliderDomain.tidolvalue.min,
                    this.sliderDomain.tidolvalue.max,
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="maindiv">
            <div className="row">
              <div
                className="col-lg col-md-6 col-sm-6 col-6"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.PEEP_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"Peep"}
                ref={this.PeepRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.PeepRef);
                }}
              >
                <ReadOnlySlider
                  caption={"PEEP"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.PEEP_H),
                    parseInt(this.state.localAlarmSettingsData.PEEP_L),
                  ]}
                  domain={[
                    this.sliderDomain.peepvalue.min,
                    this.sliderDomain.peepvalue.max,
                  ]}
                />
              </div>

              <div
                className="col-lg col-md-6 col-sm-6 col-6"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.APN_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"Apnea"}
                ref={this.ApneaRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.ApneaRef);
                }}
              >
                <ReadOnlySlider
                  caption={"Apnea"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.APN),
                    parseInt(this.state.localAlarmSettingsData.APNBR),
                  ]}
                  domain={[
                    this.sliderDomain.apneavalue.min,
                    this.sliderDomain.apneavalue.max,
                  ]}
                />
              </div>
              <div
                className="col-lg col-md-6  col-sm-6 col-6 pt-3 pt-lg-0"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.SPO2_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"Spo2"}
                ref={this.Spo2Ref}
                onClick={() => {
                  this.onPressVentilatorSettings(this.Spo2Ref);
                }}
              >
                <ReadOnlySlider
                  caption={"SPO2"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.SPO2_H),
                    parseInt(this.state.localAlarmSettingsData.SPO2_L),
                  ]}
                  domain={[
                    this.sliderDomain.spo2value.min,
                    this.sliderDomain.spo2value.max,
                  ]}
                />
              </div>
              <div
                className="col-lg col-md-6  col-sm-6 col-6 pt-3 pt-lg-0"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.FIO2_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"FIO2"}
                ref={this.FIO2Ref}
                onClick={() => {
                  this.onPressVentilatorSettings(this.FIO2Ref);
                }}
              >
                <ReadOnlySlider
                  caption={"FIO2"}
                  values={[
                    parseInt(this.state.localAlarmSettingsData.FIO2_H),
                    parseInt(this.state.localAlarmSettingsData.FIO2_L),
                  ]}
                  domain={[
                    parseInt(this.sliderDomain.fio2_hvalue.min),
                    parseInt(this.sliderDomain.fio2_hvalue.max),
                  ]}
                />
              </div>
            </div>
          </div>

          <div className="maindiv">
            <div className="row">
              <div
                className="col-lg-4 col-md-4  col-sm-6 col-6"
                style={{
                  opacity: parseInt(this.state.localAlarmSettingsData.CKTD_EN)
                    ? "1"
                    : "0.5",
                }}
                title={"CircuitDisc"}
                ref={this.CircuitDiscRef}
                onClick={() => {
                  this.onPressVentilatorSettings(this.CircuitDiscRef);
                }}
              >
                <ReadOnlySlider
                  caption={"Circuit Disc"}
                  values={[parseInt(this.state.localAlarmSettingsData.CKTD), 0]}
                  domain={[
                    parseInt(this.sliderDomain.circuitvalue.min),
                    parseInt(this.sliderDomain.circuitvalue.max),
                  ]}
                />
              </div>
              <div className="col-lg-4 col-md-4  col-sm-6 col-6" />
              <div className="col-lg-4 col-md-4  col-sm-6 col-6" />
            </div>
          </div>
        </Carousel>
        <div className="row">
          {/* <div className="col-12"></div> */}
          {/* <div className="col-6 col-sm-3 d-flex justify-content-center">
            <button
              // className="btn"
              // style={{
              //   backgroundColor: "gray",
              //   width: "150px",
              // }}
              // onClick={(event) => {
              //   // this.props.peakhighlowvalue(this.alaramcheckigvalue);
              //   this.onConfirmClick();
              // }}
            >
              <span style={{ fontSize: "20px", color: "white" }}>
                {" "}
                Confirm{" "}
              </span>
            </button> */}

          <div className="col-12 d-flex justify-content-center">
            <button
              className="btn"
              style={{
                backgroundColor: "gray",
                width: "150px",
                borderLeft: "1px solid black",
                // marginLeft: "120px",
              }}
              onClick={this.onCancelClick}
            >
              <span style={{ fontSize: "20px", color: "White" }}>Close</span>
            </button>
          </div>
          <div className="col-6 col-sm-3 d-none d-sm-block"></div>
        </div>
        <Popup
          id="5th-popup"
          contentStyle={this.state.contentStyle}
          open={this.state.ventilatorChildPopup}
        // closeOnDocumentClick={true}
        >
          {this.state.title !== "Apnea" ? (
            <SliderPopup
              caption={this.state.title}
              unit={this.state.units}
              constant={this.sliderDomain}
              values={this.state.value}
              onPressTick={this.onPressTick}
              toggleChildPopup={this.toggleChildPopup}
              isEnabled={this.state.isEnabled}
            />
          ) : (
              <DoubleSliderPopup
                selectedValues={this.doubleSliderPopupOutput}
                higVal={[parseInt(this.state.localAlarmSettingsData.APN), 0]}
                lowVal={[
                  parseInt(this.state.localAlarmSettingsData.APNBR)
                    ? parseInt(this.state.localAlarmSettingsData.APNBR)
                    : 0,
                  0,
                ]}
                higDomain={{
                  min: 0,
                  max: 60,
                  step: 1,
                }}
                lowDomain={{
                  min: 0,
                  max: 10,
                  step: 1,
                }}
                caption={this.state.title}
                unit={["sec", "BPM"]}
                constant={this.sliderDomain}
                values={this.state.value}
                onPressTick={this.onPressTick}
                toggleChildPopup={this.toggleChildPopup}
                isEnabled={this.state.isEnabled}
              />
            )}
        </Popup>
      </div>
    );
  }
}
export default Setting;

const sliderStyle = {
  position: "relative",
  height: "300px",
  marginLeft: "50%",
  // marginTop: "6%",
  touchAction: "none",
};

class SliderPopup extends Component {
  state = {
    values: this.props.values,
    propsFlag: true,
    disabled: !this.props.isEnabled,
  };

  onchange = (data) => {
    this.setState({ propsFlag: data });
  };

  onUpArrow = (domain) => {
    if (this.state.propsFlag) {
      if (
        this.state.values[0] + domain.step <= domain.max &&
        this.state.values[1] >= domain.min
      ) {
        this.setState({
          values: [this.state.values[0] + domain.step, this.state.values[1]],
        });
      }
    } else {
      if (
        this.state.values[1] + domain.step <= domain.max &&
        this.state.values[0] > this.state.values[1] + domain.step
      ) {
        this.setState({
          values: [this.state.values[0], this.state.values[1] + domain.step],
        });
      }
    }
  };

  onDownArrow = (domain) => {
    if (this.state.propsFlag) {
      if (
        this.state.values[0] - domain.step >= domain.min &&
        this.state.values[0] - domain.step > this.state.values[1]
      ) {
        this.setState({
          values: [this.state.values[0] - domain.step, this.state.values[1]],
        });
      }
    } else {
      if (this.state.values[1] - domain.step >= domain.min) {
        this.setState({
          values: [this.state.values[0], this.state.values[1] - domain.step],
        });
      }
    }
  };

  onReset = () => {
    this.setState({ values: this.props.values });
  };
  render() {
    let domain = null;
    if (this.props.caption === "Peak P") {
      domain = this.props.constant.pkSlider;
    } else if (this.props.caption === "Minute V") {
      domain = this.props.constant.minutevalue;
    } else if (this.props.caption === "Breath R") {
      domain = this.props.constant.breathvalue;
    } else if (this.props.caption === "Tidal Vol") {
      domain = this.props.constant.tidolvalue;
    } else if (this.props.caption === "PEEP") {
      domain = this.props.constant.peepvalue;
    } else if (this.props.caption === "Apnea") {
      domain = this.props.constant.apneavalue;
    } else if (this.props.caption === "SPO2") {
      domain = this.props.constant.spo2value;
    } else if (this.props.caption === "Circuit Disc") {
      domain = this.props.constant.circuitvalue;
    } else if (this.props.caption === "FIO2") {
      domain = this.props.constant.fio2_hvalue;
    }

    if (domain) {
      // console.log("Domain:", domain);
    } else {
      console.log("Domain Not Found:", domain);
      return (
        <div>
          <p
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "40%",
              fontSize: "1rem",
            }}
          >
            Domain not found
          </p>
        </div>
      );
    }
    let renderCheckbox = (
      <React.Fragment>
        <input
          type="checkbox"
          style={{ width: "35px", height: "20px" }}
          onChange={(e) => {
            this.setState({ disabled: !this.state.disabled });
          }}
          defaultChecked={this.state.disabled}
        />
        Disable{" "}
      </React.Fragment>
    );
    if (this.props.caption === "Peak P" || this.props.caption === "Tidal Vol") {
      renderCheckbox = null;
    }
    return (
      <div style={{ height: 520, width: "100%" }}>
        <div className="row m-0">
          <div className="col-8 p-0">
            <div className="row m-0">
              <div
                className="col-8 p-0"
                style={{
                  fontSize: "1rem",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                Units: {this.props.unit}
              </div>
              <div
                className="col-4 p-0"
                style={{
                  fontSize: "1rem",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                {this.props.caption}
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row m-0">
          <div
            className="col-8"
            style={{ padding: "10px", border: "1px solid #16395d" }}
          >
            <div
              className="contatiner d-flex justify-content-center align-items-center"
              style={{
                background: "#16395d7d",
                boxShadow: "inset 0 0 15px 10px #16395d",
                padding: "10px 0px 5px 0px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
            >
              {this.props.caption === "Circuit Disc" ? (
                <SHSlider
                  activate={true}
                  keyValues={this.state.values}
                  onchange={(e) => {
                    this.onchange(e);
                  }}
                  domain={domain}
                  high
                  disableOnclick
                  noCaption
                ></SHSlider>
              ) : (
                  <Slider
                    keyValues={this.state.values}
                    onchange={(e) => {
                      this.onchange(e);
                    }}
                    domain={domain}
                  ></Slider>
                )}
            </div>
          </div>
          <div
            className="col-4"
            style={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#8080806b",
                borderRadius: "50px",
                width: "75px",
                height: "75px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
              onClick={() => {
                this.onUpArrow(domain);
              }}
            >
              <KeyboardArrowUpIcon
                style={{
                  color: "white",
                  fontSize: "4.5rem",
                }}
              />
            </button>
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#8080806b",
                borderRadius: "50px",
                width: "75px",
                height: "75px",
                marginTop: "40px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
              onClick={() => {
                this.onDownArrow(domain);
              }}
            >
              <KeyboardArrowDownIcon
                style={{
                  color: "white",
                  fontSize: "4.5rem",
                }}
              />
            </button>
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#8080806b",
                borderRadius: "50px",
                width: "75px",
                height: "75px",
                marginTop: "40px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
              onClick={() => {
                this.onReset();
              }}
            >
              <ReplayIcon
                style={{
                  color: "white",
                  fontSize: "3rem",
                }}
              />
            </button>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-8 p-0">
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "green",
                width: "100%",
                height: "35px",
              }}
              onClick={() => {
                this.props.onPressTick({
                  value: this.state.values,
                  key: this.props.caption,
                  isEnabled: !this.state.disabled,
                });
                this.props.toggleChildPopup();
              }}
            >
              <Done style={{ color: "white", fontSize: "2.5rem" }} />
            </button>
          </div>
          <div
            className="col-4"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              letterSpacing: "1px",
              color: "white",
              fontSize: "1rem",
            }}
          >
            {renderCheckbox}
          </div>
        </div>
      </div>
    );
  }
}
class DoubleSliderPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: this.props.values,
      propsFlag: true,
      h1: true,
      h2: false,
      higDomain: this.props.higDomain,
      lowDomain: this.props.lowDomain,
      higVal: this.props.higVal,
      lowVal: this.props.lowVal,
      disabled: !this.props.isEnabled,
    };
  }

  onchange1 = (data) => {
    if (!this.state.h1) {
      this.setState({ h1: !this.state.h1, h2: this.state.h1 });
    }
  };

  onchange2 = (data) => {
    if (!this.state.h2) {
      this.setState({ h2: !this.state.h2, h1: this.state.h2 });
    }
  };

  onUpArrow = () => {
    if (this.state.h1) {
      if (
        this.state.higVal[0] + this.state.higDomain.step <=
        this.state.higDomain.max &&
        this.state.higVal[1] >= this.state.higDomain.min
      ) {
        this.setState({
          higVal: [
            this.state.higVal[0] + this.state.higDomain.step,
            this.state.higVal[1],
          ],
        });
      }
    } else {
      if (
        this.state.lowVal[0] + this.state.lowDomain.step <=
        this.state.lowDomain.max
      ) {
        this.setState({
          lowVal: [
            this.state.lowVal[0] + this.state.lowDomain.step,
            this.state.lowVal[1],
          ],
        });
      }
    }
  };

  onDownArrow = () => {
    if (this.state.h1) {
      if (
        this.state.higVal[0] - this.state.higDomain.step >=
        this.state.higDomain.min
      ) {
        this.setState({
          higVal: [
            this.state.higVal[0] - this.state.higDomain.step,
            this.state.higVal[1],
          ],
        });
      }
    } else {
      if (
        this.state.lowVal[0] - this.state.lowDomain.step >=
        this.state.lowDomain.min
      ) {
        this.setState({
          lowVal: [
            this.state.lowVal[0] - this.state.lowDomain.step,
            this.state.lowVal[1],
          ],
        });
      }
    }
  };

  onReset = () => {
    if (this.state.h1) {
      this.setState({ higVal: this.props.higVal }); // reset highSlider Value
    } else {
      this.setState({ lowVal: this.props.lowVal }); // reset lowSlider Value
    }
    //this.setState({ values: this.props.values });
  };
  render() {
    // console.log("Double slider", this.props.values);
    // let domain = null;
    // if (this.props.caption === "Apnea") {
    //   domain = this.props.constant.apneavalue;
    // }

    return (
      <div style={{ width: "100%" }}>
        <div className="row m-0">
          <div className="col-8 p-0">
            <div className="row m-0">
              <div
                className="col-4 p-0"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "0.95rem",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                Units: {this.props.unit[0]}
              </div>
              <div
                className="col-4 p-0"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "0.95rem",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                {this.props.caption}
              </div>
              <div
                className="col-4 p-0"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "0.95rem",
                  letterSpacing: "1px",
                  color: "white",
                }}
              >
                Units: {this.props.unit[1]}
              </div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="row m-0">
          <div
            className="col-8"
            style={{ padding: "10px", border: "1px solid #16395d" }}
          >
            <div
              className="contatiner d-flex justify-content-center align-items-center"
              style={{
                background: "#16395d7d",
                boxShadow: "inset 0 0 15px 10px #16395d",
                padding: "10px 0px 5px 0px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
            >
              <SHSlider
                activate={this.state.h1}
                keyValues={this.state.higVal}
                onchange={(e) => {
                  this.onchange1(e);
                }}
                high
                /*  domain={this.state.higDomain} */
                domain={this.state.higDomain}
              ></SHSlider>
              <SHSlider
                activate={this.state.h2}
                keyValues={this.state.lowVal}
                onchange={(e) => {
                  this.onchange2(e);
                }}
                domain={this.state.lowDomain}
              /*  domain={domain} */
              ></SHSlider>
            </div>
          </div>
          <div
            className="col-4"
            style={{
              display: "inline-flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#8080806b",
                borderRadius: "50px",
                width: "75px",
                height: "75px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
              onClick={() => {
                this.onUpArrow();
              }}
            >
              <KeyboardArrowUpIcon
                style={{
                  color: "white",
                  fontSize: "4.5rem",
                }}
              />
            </button>
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#8080806b",
                borderRadius: "50px",
                width: "75px",
                height: "75px",
                marginTop: "40px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
              onClick={() => {
                this.onDownArrow();
              }}
            >
              <KeyboardArrowDownIcon
                style={{
                  color: "white",
                  fontSize: "4.5rem",
                }}
              />
            </button>
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#8080806b",
                borderRadius: "50px",
                width: "75px",
                height: "75px",
                marginTop: "40px",
                opacity: this.state.disabled ? "0.6" : "1",
                pointerEvents: this.state.disabled ? "none" : "auto",
              }}
              onClick={() => {
                this.onReset();
              }}
            >
              <ReplayIcon
                style={{
                  color: "white",
                  fontSize: "3rem",
                }}
              />
            </button>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-8 p-0">
            <button
              className="btn d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "green",
                width: "100%",
                height: "35px",
              }}
              onClick={() => {
                // this.props.onPressTick({
                //   value: this.state.values,
                //   key: this.props.caption,
                // });
                this.props.selectedValues({
                  key: this.props.caption,
                  higVal: this.state.higVal,
                  lowVal: this.state.lowVal,
                  isEnabled: !this.state.disabled,
                });
                //alert(`H:${this.state.higVal},L:${this.state.lowVal}`);
                this.props.toggleChildPopup();
              }}
            >
              <Done style={{ color: "white", fontSize: "2.5rem" }} />
            </button>
          </div>
          <div
            className="col-4"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              letterSpacing: "1px",
              color: "white",
              fontSize: "1rem",
            }}
          >
            <input
              type="checkbox"
              style={{ width: "35px", height: "20px" }}
              onChange={(e) => {
                this.setState({ disabled: !this.state.disabled });
              }}
              defaultChecked={this.state.disabled}
            />
            Disable
          </div>
        </div>
      </div>
    );
  }
}
