import React, { Component, createRef } from "react";
import Select from "react-select";
import styles from "./Ventmode.module.css";
import { CustomsSnackbar } from "../../components/snakbar/snackbar";
import { checkLock } from "../../services/http";
const customStyles = {
  valueContainer: () => ({
    backgroundColor: "gray",
    display: "flex",
    minHeight: "2.5rem",
    justifyContent: "center",
  }),
  control: () => ({}),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      ...provided,
      opacity,
      transition,
      color: "white",
      fontWeight: "500",
    };
  },
};
const patientTypeOptions = [
  { label: "Adult", value: 0 },
  { label: "Pediatric", value: 1 },
];

const circuitTypeOptions = [
  {
    label: "Dual Limb",
    value: 0,
  },
  { label: "Single Limb with Leak", value: 1 },
];
const ventilatorModeOptions = [
  {
    label: "Pressure",
    value: 3,
  },
  { label: "Volume", value: 4 },
];
const breathOptionsFrPresure = [
  { label: "Assist/Control", value: 1 },
  { label: "Intermittent", value: 3 },
  { label: "Support", value: 2 },
];
const breathOptionsForVolume = [
  { label: "Assist/Control", value: 1 },
  { label: "Intermittent", value: 3 },
  { label: "PRVC", value: 4 },
];

//vol mode-> 1,3,4

// presure-->1,2,3

class Ventmode extends Component {
  constructor(props) {
    // this.setting = {
    //   VBrthOpt: 0,
    //   VMode: 3,
    //   CktType: "0",
    //   PType: "0",
    // };
    super(props);
    this.allTopicsToBeSubscribed = [];
    this.state = {
      // confirmBGColor:"#33b238",
      confirmBGColor: "#999999",

      mode: "",
      _selectedVentilatorMode: null,
      _selectedBreathOption: null,
      dropDownDefaultOptions: {
        PatientType: parseInt(this.props.settings.PType),
        CircuitType: parseInt(this.props.settings.CktType),
        VentilatorMode: this.props.settings.VMode == "3" ? 0 : 1,
        BreathOptions: this.props.settings.VBrthOpt,
      },
      openSnack: false,
      dropdownChange: false,
    };
    this.token=localStorage.getItem("abmUsertoken");
    this.ventilatorModeRef = createRef();
    this.PatientTypeRef = createRef();
    this.CircuitTypeRef = createRef();
    this.BreathOptionsRef = createRef();
    // setTimeout(()=>{
    //   this.subscribeTopic("/VENTSETTINGS");
    // })
  }

  customDidMount = () => {
    let mode = this.ventilatorModeRef.current.state;
    let brthOptions = this.props.settings.VBrthOpt;
    // console.log("[DID_MOUNT] brthOptions", brthOptions);
    // console.log("[DID_MOUNT] mode", mode.value);
    // console.log("[DID_MOUNT] this.props.settings", this.props.settings);
    let modeVal = "";
    if (mode) {
      if (mode.value.label === "Pressure" && brthOptions === "I") {
        modeVal = "PC-SIMV(PS)";
      } else if (brthOptions === "I" && mode.value.label === "Volume") {
        modeVal = "VC-SIMV(PS)";
      } else if (brthOptions === "AC" && mode.value.label === "Pressure") {
        modeVal = "PCV";
      } else if (brthOptions === "AC" && mode.value.label === "Volume") {
        modeVal = "VCV";
      } else if (brthOptions == "SU") {
        modeVal = "CPAP";
        if (this.props.settings.InspTrig > 0) {
          // console.log("VCV-------------settings.InspTrig>0",settings.InspTrig)
          modeVal = "PSV";
        }
      } else if (brthOptions === "PR") {
        modeVal = "PRVC";
      }

      this.setState({
        _selectedVentilatorMode: this.ventilatorModeRef.current.state.value,
        mode: modeVal,
      });
    }
  };

  componentDidMount() {
    // console.log("Sudheeer--------ventilatorMode", this.ventilatorModeRef);
    // console.log("[DID_Mount] this.ventilatorModeRef", this.ventilatorModeRef);
    // console.log(
    //   "[DID_Mount]  this.state.VentilatorMode",
    //   this.state.VentilatorMode
    // );
    //console.log("[DID_Mount] this.props.settings", this.props.settings);
    this.customDidMount();
  }

  publishCircuitType = (e) => {
    // console.log("CircuitType", e);
    this.setState({ confirmBGColor: "#33b238" });
  };
  publishVentilatorMode = (e) => {
    // if u change ventilator mode from web
    // due tro on change given on this drrpdown
    // it will reset breathOption to access/control so all time
    // when ever u change ventMode breathn option has to hold only AccessControll
    // we are doing comprasion on ventMode with accessControl by default
    // but in breath option we arwe doing as such...
    this.setState({ dropdownChange: true });
    setTimeout(() => {
      let Vmode = e.label;
      let BreathRef = "Assist/Control"; //this.BreathOptionsRef.current.state.value.label;
      // console.info("CurentVmode", Vmode);
      // console.info(
      //   "CurentBreatOption",
      //   this.BreathOptionsRef.current.state.value.label
      // );

      this.setState({ confirmBGColor: "#33b238" });
      if (Vmode === "Pressure" && BreathRef == "Assist/Control") {
        // if (this.props.settings.InspTrig > 0) {
        //   Vmode = "PCV(A)";
        // }
        this.setState({ mode: "PCV", _selectedVentilatorMode: e });
      } else if (Vmode === "Volume" && BreathRef == "Assist/Control") {
        this.setState({ mode: "VCV", _selectedVentilatorMode: e });
      } else if (Vmode === "Pressure" && BreathRef == "Intermittent") {
        this.setState({ mode: "PC-SIMV(PS)", _selectedVentilatorMode: e });
      } else if (Vmode === "Volume" && BreathRef == "Intermittent") {
        this.setState({ mode: "VC-SIMV(PS)", _selectedVentilatorMode: e });
      } else if (Vmode === "Volume") {
        this.setState({ mode: "VCV", _selectedVentilatorMode: e });
      } else if (BreathRef == "Support") {
        this.setState({ mode: "CPAP", _selectedVentilatorMode: e });
      } else if (BreathRef == "PRVC") {
        this.setState({ mode: "PVRC", _selectedVentilatorMode: e });
      }
    }, 100);

    // console.log("Ventilator mode", e);
  };

  publishBreathOptions = (e) => {
    // e={
    //   label: "Pressure", value: 1
    // }
    this.setState({ dropdownChange: true });
    let ventilatorModeVal = this.ventilatorModeRef.current.state.value;
    this.setState({ confirmBGColor: "#33b238" });
    if (e.label === "Support") {
      this.setState({ mode: "CPAP" });
    } else if (
      e.label === "Intermittent" &&
      ventilatorModeVal.label === "Pressure"
    ) {
      this.setState({ mode: "PC-SIMV(PS)" });
    } else if (
      e.label === "Intermittent" &&
      ventilatorModeVal.label === "Volume"
    ) {
      this.setState({ mode: "VC-SIMV(PS)" });
    } else if (
      e.label === "Assist/Control" &&
      ventilatorModeVal.label === "Pressure"
    ) {
      this.setState({ mode: "PCV" });
    } else if (
      e.label === "Assist/Control" &&
      ventilatorModeVal.label === "Volume"
    ) {
      this.setState({ mode: "VCV" });
    } else if (e.label === "PRVC") {
      this.setState({ mode: "PRVC" });
    }
    // //console.log("Ventilator mode", e);
  };

  onCancelClick = () => {
    this.props.closePopup();
    //console.log("sudhir_------", client);
  };

  cusSnackbar={
    title:"ventmood-lock-snackbar",
    message:(<span>Can't allow in monitor mode.<br/>Unlock to access control mode</span>),
   anchor:{ vertical: 'bottom', horizontal: 'right' },
   severity:"warning",
    close:()=>{
      this.setState({ openSnack: false });
    },
    open:()=>{
      console.log("---OPen called, this",this)
      this.setState({ openSnack: true });
    },
    
  }

  onConfirm = () => {
    checkLock({token:this.token}).then(res=>{
      if(res.status===200){
   //-------------------------------------
      let ventilatorModeVal = this.ventilatorModeRef.current.state.value;
      // let PatientTypeVal = this.PatientTypeRef.current.state.value;
      // let CircuitTypeVal = this.CircuitTypeRef.current.state.value;
      let BreathOptionsVal = this.BreathOptionsRef.current.state.value;
      let brethOpt = null;

      if (BreathOptionsVal.label == "Assist/Control") {
        brethOpt = "AC";
      } else if (BreathOptionsVal.label === "Support") {
        brethOpt = "SU";
      } else if (BreathOptionsVal.label === "Intermittent") {
        brethOpt = "I";
      } else if (BreathOptionsVal.label === "PRVC") {
        brethOpt = "PR";
      }

      let mode_set = {
        VBrthOpt: brethOpt,
        VMode: parseInt(ventilatorModeVal.value),
        CktType: null,//parseInt(CircuitTypeVal.value)
        PType: null//parseInt(PatientTypeVal.value),
      };

      let newSetting = { ...this.props.settings, ...mode_set };
      console.info("ModSettngOLd------------->", this.props.settings);
      console.info("ModSettngNew------------>", newSetting);
      this.props.setValue("MODE_PVC", newSetting);
      this.setState({ confirmBGColor: "#999999", dropdownChange: false });
      this.props.closePopup();
    //----------------------------------------
  }else{
    //console.log("LOCK-Else")
    this.cusSnackbar.open()
  }}).catch(res=>{
    console.error(res)
  })

  };

  upadteSetting = (data) => {
    let Mode = this.state.mode;
    if (data.BreathOptions == "AC" && data.VentilatorMode == 0) {
      Mode = "PCV";
    }
    if (data.BreathOptions == "AC" && data.VentilatorMode == 1) {
      Mode = "VCV";
    } else if (data.BreathOptions == "SU") {
      Mode = "CPAP";
      if (data.InspTrig > 0) {
        Mode = "PSV";
      }
    } else if (data.BreathOptions == "I" && data.VentilatorMode == 0) {
      Mode = "PC-SIMV(PS)";
    } else if (data.BreathOptions == "I" && data.VentilatorMode == 1) {
      Mode = "VC-SIMV(PS)";
    } else if (data.BreathOptions == "PR") {
      Mode = "PVRC";
    }
    if (!this.state.dropdownChange) {
      this.setState(
        {
          dropDownDefaultOptions: data,
          _selectedVentilatorMode: ventilatorModeOptions[data.VentilatorMode],
          mode: Mode,
        },
        () => {
          // console.log("newVent mode", this.state.dropDownDefaultOptions);
        }
      );
    }
  };
  componentWillReceiveProps(comingProps) {
    // console.log("Props recieved", comingProps);
    if ("settings" in comingProps) {
      //console.log("NEW SEttings props found", comingProps.settings);
      let oldSettings = { ...this.state.dropDownDefaultOptions };
      if (comingProps.settings.VMode == 3) {
        oldSettings.VentilatorMode = 0;
      }
      if (comingProps.settings.VMode == 4) {
        oldSettings.VentilatorMode = 1;
      }
      if (comingProps.settings.PType == 0) {
        oldSettings.PatientType = 0;
      }
      if (comingProps.settings.PType == 1) {
        oldSettings.PatientType = 1;
      }
      if (comingProps.settings.CktType == 0) {
        oldSettings.CircuitType = 0;
      }
      if (comingProps.settings.CktType == 1) {
        oldSettings.CircuitType = 1;
      }

      oldSettings.BreathOptions = comingProps.settings.VBrthOpt;
      oldSettings.InspTrig = comingProps.settings.InspTrig;
      this.upadteSetting(oldSettings);
    }
  }
  render() {
    // console.log(
    //   " this.state.dropDownDefaultOptions",
    //   this.state.dropDownDefaultOptions
    // );
    // console.log("ventmode------>settings", this.props.settings);

    let renderPatientType = null;
    let renderCircuitType = null;
    let renderVentilatorMode = null;
    let breathOptionDropDown = null;
    let breathOptionIndexVolume = 0;
    let breathOptionIndexPressure = 0;
    let VentMode = this.state.mode;

    if (this.state.dropDownDefaultOptions.VentilatorMode === 0) {
      if (this.state.dropDownDefaultOptions.BreathOptions == "AC") {
        breathOptionIndexPressure = 0;
      } else if (this.state.dropDownDefaultOptions.BreathOptions == "SU") {
        breathOptionIndexPressure = 2;
      } else if (this.state.dropDownDefaultOptions.BreathOptions == "I") {
        breathOptionIndexPressure = 1;
      }
    } else {
      if (this.state.dropDownDefaultOptions.BreathOptions == "AC") {
        breathOptionIndexVolume = 0;
      } else if (this.state.dropDownDefaultOptions.BreathOptions == "I") {
        breathOptionIndexVolume = 1;
      } else if (this.state.dropDownDefaultOptions.BreathOptions == "PR") {
        breathOptionIndexVolume = 2;
      }
    }

    renderPatientType = (
      <Select
        key={this.state.dropDownDefaultOptions.PatientType}
        // ref={this.PatientTypeRef}
        isSearchable={false}
        options={patientTypeOptions}
        styles={customStyles}
        isDisabled={true}
        // onChange={() => {
        //   this.setState({ confirmBGColor: "#33b238" });
        // }}
        components={{
          DropdownIndicator: () => null,
          IndicatorsContainer: () => null,
        }}
        defaultValue={
          patientTypeOptions[this.state.dropDownDefaultOptions.PatientType]
        }
      ></Select>
    );

    renderCircuitType = (
      <Select
        key={this.state.dropDownDefaultOptions.CircuitType}
        // ref={this.CircuitTypeRef}
        // onChange={this.publishCircuitType}
        isSearchable={false}
        isDisabled={true}
        options={circuitTypeOptions}
        defaultValue={
          circuitTypeOptions[this.state.dropDownDefaultOptions.CircuitType]
        }
        styles={customStyles}
        components={{
          DropdownIndicator: () => null,
          IndicatorsContainer: () => null,
        }}
      />
    );

    // if (this.state.dropDownDefaultOptions.VentilatorMode) {
    //console.log("[render]Volume");
    renderVentilatorMode = (
      <Select
        key={this.state.dropDownDefaultOptions.VentilatorMode}
        ref={this.ventilatorModeRef}
        isSearchable={false}
        options={ventilatorModeOptions}
        defaultValue={
          ventilatorModeOptions[
            this.state.dropDownDefaultOptions.VentilatorMode
          ]
        }
        styles={customStyles}
        components={{
          DropdownIndicator: () => null,
          IndicatorsContainer: () => null,
        }}
        onChange={this.publishVentilatorMode}
      ></Select>
    );
    if (
      this.state._selectedVentilatorMode &&
      this.state._selectedVentilatorMode.label === "Volume" //  Volume
    ) {
      breathOptionDropDown = (
        <Select
          ref={this.BreathOptionsRef}
          isSearchable={false}
          key={
            breathOptionIndexVolume + this.state._selectedVentilatorMode.label
          }
          options={breathOptionsForVolume}
          defaultValue={breathOptionsForVolume[breathOptionIndexVolume]}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorsContainer: () => null,
          }}
          onChange={this.publishBreathOptions}
        />
      );
    } else if (
      this.state._selectedVentilatorMode &&
      this.state._selectedVentilatorMode.label === "Pressure" //  Pressure
    ) {
      breathOptionDropDown = (
        <Select
          ref={this.BreathOptionsRef}
          key={
            breathOptionIndexPressure + this.state._selectedVentilatorMode.label
          }
          isSearchable={false}
          options={breathOptionsFrPresure}
          defaultValue={breathOptionsFrPresure[breathOptionIndexPressure]}
          styles={customStyles}
          components={{
            DropdownIndicator: () => null,
            IndicatorsContainer: () => null,
          }}
          onChange={this.publishBreathOptions}
        />
      );
    }

    //****************  setting PopupMode *****************//
    if (VentMode === "PCV" && this.props.settings.InspTrig > 0) {
      VentMode = "PCV(A)";
    }
    if (VentMode === "VCV" && this.props.settings.InspTrig > 0) {
      VentMode = "VCV(A)";
    }
    if (VentMode === "PC-SIMV(PS)" && this.props.settings.PSUP == 0) {
      VentMode = "PC-SIMV";
    }
    if (VentMode === "VC-SIMV(PS)" && this.props.settings.PSUP == 0) {
      VentMode = "VC-SIMV";
    }
    return (
      <>
        <div
          className="row"
          style={{
            paddingTop: "30px",
            margin: "auto",
            justifyContent: "center",
          }}
        >
          <CustomsSnackbar
           msg={this.cusSnackbar.message}
            flag={this.state.openSnack}
            onClose={this.cusSnackbar.close}
            key={this.cusSnackbar.title}
            anchorOrigin={this.cusSnackbar.anchor}
            severity={this.cusSnackbar.severity}
          />
          <p key={VentMode} className={styles["venttext"]}>
            {VentMode}
          </p>
        </div>
        {/* <button
          onClick={() => {
            this.props.switch();
          }}
        >
          Send data
        </button> */}
        <div className="container">
          <div
            className="row"
            style={{ border: "1px solid none", marginTop: "5%" }}
          >
            <div className={`col-md-6`} style={{ border: "1px solid none" }}>
              <div
                className="container"
                style={{ textAlign: "center", width: "84%" }}
              >
                <label className={`text-info ${styles["label-text-size"]}`}>
                  Patient Type
                </label>
                {renderPatientType}
              </div>
            </div>

            <div className="col-md-6" style={{ border: "1px solid none" }}>
              <div
                className="container"
                style={{ textAlign: "center", width: " 84%" }}
              >
                <label className={`text-info ${styles["label-text-size"]}`}>
                  Circuit Type
                </label>
                {renderCircuitType}
              </div>
            </div>
          </div>
          {/*  second row */}

          <div
            className="row"
            style={{ border: "1px solid none", marginTop: "5%" }}
          >
            <div className="col-md-6">
              <div
                className="container"
                style={{ textAlign: "center", width: "84%" }}
              >
                <label className={`text-info ${styles["label-text-size"]}`}>
                  Ventilator Mode
                </label>
                {renderVentilatorMode}
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="container"
                style={{ textAlign: "center", width: "84%" }}
              >
                <label className={`text-info ${styles["label-text-size"]}`}>
                  Breath Options
                </label>
                {breathOptionDropDown}
              </div>
            </div>
          </div>

          {/*  third  row  contains confirm  button*/}
          <div
            className="row"
            style={{ border: "1px solid none", marginTop: "5%" }}
          >
            <div className="col-md-3 col-sm-12 col-xs-12"></div>
            <div className="col-md-3 col-sm-12 col-xs-12">
              <div
                className="container"
                style={{ border: "1px solid none", width: "100%" }}
              >
                <button
                  className={`btn`}
                  onClick={this.onConfirm}
                  style={{
                    borderRadius: "0",
                    width: "100%",
                    backgroundColor: this.state.confirmBGColor,
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12">
              <div
                className="container"
                style={{ border: "1px solid none", width: "100%" }}
              >
                <button
                  className={`btn`}
                  style={{ borderRadius: "0", width: "100%" }}
                  onClick={this.onCancelClick}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="col-md-3 col-sm-12 col-xs-12 "></div>
          </div>
        </div>
      </>
    );
  }
}

export default Ventmode;
