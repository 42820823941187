import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import { PLANT_DEL } from "../../../api-route";
import Button from "@material-ui/core/Button";

export default class BranchDeleteDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      error: null,
    };
    this.headerStyle = {
      fontSize: "30px",
      color: "#000000a6",
      letterSpacing: "-1px",
      fontWeight: "550",
    };
    this.subHeaderStyle = {
      fontSize: "25px",
      color: "#000000bd",
      marginTop: "25px",
      letterSpacing: "-1px",
      fontWeight: "550",
    };
    this.subTextStyle = {
      fontSize: "19px",
      color: "#000000b0",
    };
  }

  handleClose = () => {
    this.setState({
      checked: false,
      error: null,
    });
    this.props.setClose();
  };
  handleOnDestroy = () => {
    if (this.state.checked) {
      console.log("ID IS", this.props.associatedData.branch._id);
      let token = localStorage.getItem("abmUsertoken");
      let user = localStorage.getItem("abmUser");
      let _user = JSON.parse(user);
      const requestOptions = {
        method: "DELETE",
        headers: { "Content-Type": "application/json", Authorization: token },
        body: JSON.stringify({
          userId: _user.email,
          delPlant: this.props.associatedData.branch._id,
        }),
      };
      fetch(
        `${PLANT_DEL}/${this.props.associatedData.branch._id}/force`,
        requestOptions
      )
        .then((res) => {
          if (res.status == 200) {
            this.props.onChangedUser(true);
            this.props.onChangePlant(true);
            this.props.onChangeVent(true);
            console.log("Destroyed");
          }
          return res.json();
        }).then((result) => {
          if (result.status == 200) {
            this.props.setSnackSeverity("success");
          } else {
            this.props.setSnackSeverity("error");
          }
          if (result.message) {
            this.props.setSnackMsg(result.message)
            this.props.setOpenSnack(true);
          }
          this.handleClose();
        })
        .catch((error) => {
          this.handleClose();
        })
        .finally(() => {
          // this.handleClose();
        });
    }
  };
  render() {
    let associatedData = this.props.associatedData;
    let textMessage = "";
    if (associatedData && associatedData.totalUsers != 0) {
      if (associatedData.totalUsers > 1) {
        textMessage = textMessage.concat(
          "'",
          associatedData.totalUsers,
          "'",
          " Users"
        );
      } else {
        textMessage = textMessage.concat(
          "'",
          associatedData.totalUsers,
          "'",
          " User "
        );
      }
    }
    if (associatedData && associatedData.totalVents != 0) {
      if (associatedData.totalUsers > 0) {
        textMessage = textMessage.concat(" and ");
      }
      if (associatedData.totalVents > 1) {
        textMessage = textMessage.concat(
          "'",
          associatedData.totalVents,
          "'",
          " Ventilators"
        );
      } else {
        textMessage = textMessage.concat(
          "'",
          associatedData.totalVents,
          "'",
          " Ventilator"
        );
      }
    }
    return (
      <React.Fragment>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.props.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div style={{ margin: "20px", border: "1px solid lightgray" }}>
            <div style={{ margin: "35px" }}>
              <p style={this.headerStyle}>
                Destroy Branch '{associatedData.branch.name}'
              </p>
              <p style={this.subTextStyle}>
                This is irreversible. All Branch data will be scrubbed and
                irretrievable.
              </p>
              <p style={this.subHeaderStyle}>Associated Resources</p>
              <p style={this.subTextStyle}>
                {textMessage} would be deleted along with the branch.
              </p>

              <p style={{ display: "flex", ...this.subTextStyle }}>
                <input
                  type="checkbox"
                  style={{ width: "35px", height: "20px" }}
                  onChange={(e) => {
                    this.setState({ checked: !this.state.checked });
                  }}
                  defaultChecked={this.state.checked}
                />
                <span>Destroy all associated resources</span>
              </p>
              <button
                type="button"
                className="btn"
                style={{
                  backgroundColor: "#ff2100c9",
                  fontSize: "1.2rem",
                  textTransform: "none",
                  opacity: this.state.checked ? "1" : "0.6",
                  pointerEvents: this.state.checked ? "auto" : "none",
                }}
                onClick={this.handleOnDestroy}
              >
                Destroy this Branch
              </button>
              {this.state.error ? (
                <p
                  className="mt-1"
                  style={{ fontSize: "1rem", color: "#e01717f0" }}
                >
                  {this.state.error}
                </p>
              ) : null}
            </div>
            <div style={{ display: "flex", justifyContent: 'flex-end', paddingRight: '10px', paddingBottom: '3px' }}>
              <Button onClick={this.handleClose} color="primary" autoFocus>
                Cancel
          </Button>
            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}
