export function formatAlmData(data, apnea, noApena) {
  //---------------------------------------------------
  // Input
  // ["High Min Vol", "1", "01:40 pm", "", "2"]
  // Value at index
  // 0-> alram;
  // 1-> active(1) or inactive(0)
  // 2-> activated time
  // 3-> resolved time
  // 4-> priority ; defines background color
  // Output
  // activatedTime <space> alarm
  //----------------------------------------------------
  let result = [];
  let callBackinvoked = false;
  data.forEach((line, index) => {
    let _words = line.split(",");
    let _record = {
      className: "grayColr",
      alarm: _words[0] ? _words[0].trim() : "",
      activatedTime: _words[2],
    };
    if (_words[1] === "1") {
      if (!callBackinvoked && _record.alarm.toUpperCase() === "APNEA") {
        apnea();
        callBackinvoked = true;
      }
      switch (_words[4]) {
        case "1":
          _record["className"] = "high-alarm";
          break;
        case "2":
          _record["className"] = "medium-alarm";
          break;
        case "3":
          _record["className"] = "low-alarm";
          break;
        case "4":
          _record["className"] = "no-alarm";
          break;
      }
    }
    result.push(_record);
  });
  if (!callBackinvoked) {
    noApena();
    callBackinvoked = true;
  }
  return result;
}
