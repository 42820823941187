import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputField from "./validation/editfrom";
import { fetchplantByC_id, } from "../../../services/http";
import "../App.css";
import { ADMINEDIT } from "../../../api-route";
import { CustomsSnackbar } from "../../snakbar/snackbar";
import SessionExpiryDialog from "./sessionExpiryDialog";

import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

export default function UserDetailsDialog(props) {
  let isAdmin = false;
  const { open, setClose, currentUser, onChange } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const Role = [{ displyname: "User", name: "user", id: "0" }];
  useEffect(() => {
    renderPlantTableData();
  }, []);


  //DropdownValidation
  const [selected, setselected] = useState("");
  const [hasError, sethasError] = useState(false);
  const [selected1, setselected1] = useState("");
  const [hasError1, sethasError1] = useState(false);
  const [items, setItems] = React.useState([]);
  const [values, setValues] = React.useState({});
  const [openSnack, setOpenSnack] = React.useState(false);
  const [snackSeverity, setSnackSeverity] = React.useState("");
  const [snackMsg, setSnackMsg] = React.useState("");
  const [customeSnackbarAnchor, setCustomeSnackbarAnchor] = useState({
    vertical: "top",
    horizontal: "center",
  });

  const handleChangedrop1 = (name, value) => {
    setselected1(value);
    setValues({ ...values, [name]: value });
    sethasError1(false);
  };
  const handleChangedrop = (name, value) => {
    setselected(value);
    setValues({ ...values, [name]: value });
    sethasError(false);
  };

  //Form Validation
  const inputRefs = React.useRef([
    React.createRef(),
    React.createRef(),
    React.createRef(),
    React.createRef(),
  ]);
  const [data, setData] = React.useState("");
  const [sessionExpired, setSessionExpired] = React.useState(false);
  const handleInputChange = (name, value) => {
    setData({ ...data, [name]: value });
  };

  const renderPlantTableData = async () => {
    let token = localStorage.getItem("abmUsertoken");
    let user = localStorage.getItem("abmUser");
    let _user = JSON.parse(user);
    const data = await fetchplantByC_id(token, _user.company_id);
    if (data && data.data && data.data.length > 0) {
      setItems(data.data);
    }
  };


  const submitForm = (e) => {
    e.preventDefault();
    let isValid = true;
    for (let i = 0; i < inputRefs.current.length; i++) {
      if (inputRefs.current[i].current === null) {
        continue;
      }
      const valid = inputRefs.current[i].current.validate();
      if (!valid) {
        isValid = false;
      }
    }
    sethasError(false);
    if (!selected && !currentUser.role) {
      sethasError(true);
      isValid = false;
    }
    sethasError1(false);
    if (!selected1 && !currentUser.plant_id) {
      sethasError1(true);
      isValid = false;
    }

    if (!isValid) {
      return;
    }
    // data["userName"]=currentUser.userName;
    // data["email"]=currentUser.email;
    // data["fName"]=currentUser.fName;
    // data["lName"]=currentUser.lName;

    let plant = "";
    let role = "";

    if (values.Plant) {
      plant = values.Plant;
    } else {
      plant = currentUser.plantId;
    }
    if (!values.Role) {
      role = currentUser.role;
    } else {
      role = values.Role;
    }
    let token = localStorage.getItem("abmUsertoken");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json", Authorization: token },
      body: JSON.stringify({
        userId: currentUser.id,
        userName: currentUser.userName,
        email: currentUser.email,
        fName: currentUser.fName,
        lName: currentUser.lName,
        Plant: plant,
        role: role,
      }),
    };

    let data = [];
    fetch(`${ADMINEDIT}`, requestOptions)
      .then((res) => {
        if (res.status === 401) {
          setSessionExpired(true);
        }
        return res.json()
      })
      .then((result) => {
        // setResultData(result);
        // console.log("result", result);
        if (result.success) {
          setSnackMsg("User edited successfully");
          setSnackSeverity("success");
          setOpenSnack(true);
          onChange(true);
          handleClose();
        } else {
          setSnackMsg(`User editing failed because ${result.message}`);
          setSnackSeverity("error");
          setOpenSnack(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    //Carry on as normal
    // editUser();

    // console.log("submit successfully");
    // alert("submit successfully")
    // console.log(data)
    //Carry on as normal
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose = () => {
    setValues({});
    setselected("");
    setselected1("");
    setValue(0);
    setClose(false);
  };

  const handleSnackbar = () => {
    setOpenSnack(false);
  };

  return (
    <React.Fragment>
      <SessionExpiryDialog open={sessionExpired}>
      </SessionExpiryDialog>
      <CustomsSnackbar
        msg={snackMsg}
        flag={openSnack}
        onClose={handleSnackbar}
        key={"cusSnackbar"}
        anchorOrigin={customeSnackbarAnchor}
        severity={snackSeverity}
      />
      <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "#808080", color: "white", fontSize: "18px" }}
        >
          USER
          <IconButton aria-label="close" onClick={handleClose} style={{
            position: "absolute", right: "20px",
            top: "6px", color: "white"
          }} >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div className={classes.root}>
            <AppBar position="static">
              <Tabs
                TabIndicatorProps={{ style: { background: "gray" } }}
                value={value}
                onChange={handleChange}
              >
                <Tab label="Details" {...a11yProps(0)} />
                {(currentUser.role !== ("superadmin") && currentUser.role !== ("admin")) ? <Tab
                  label="Edit"
                  {...a11yProps(1)}
                /> : null}
              </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form>
                            <div className="row">
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    User Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={currentUser.userName}
                                    disabled
                                  />
                                </div>
                              </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Email / UserID
                                  </label>
                                  <input
                                    type="email"
                                    className="form-control"
                                    value={currentUser.email}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Role</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      currentUser.role === "superadmin"
                                        ? "Super Admin"
                                        : currentUser.role === "user"
                                          ? "User "
                                          : "Admin"
                                    }
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    First Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentUser.fName}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Last Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentUser.lName}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Hospital Name{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentUser.companyName}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Branch Name{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control capitalize"
                                    value={currentUser.plantName}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Created At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentUser.createdAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Updated At
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={new Date(currentUser.updatedAt)}
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="vent-details-dialog card card-plain">
                        <div className="card-body genrl-tab">
                          <form onSubmit={submitForm} noValidate>
                            <div className="row">
                              {/* <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    User Name
                                  </label>
                                  <InputField
                                    disabled={true}
                                    ref={inputRefs.current[0]}
                                    label="User name"
                                    name="username"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control"
                                    defaultValue={currentUser.userName}
                                  />
                                </div>
                              </div> */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    Email / UserID
                                  </label>
                                  <InputField
                                    disabled={true}
                                    ref={inputRefs.current[1]}
                                    label="Email"
                                    name="email"
                                    type="email"
                                    validation="required|emailpattern"
                                    onChange={handleInputChange}
                                    className="form-control"
                                    defaultValue={currentUser.email}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">Role</label>
                                  <FormControl
                                    style={{
                                      width: "100%",
                                    }}
                                  >
                                    <Select
                                      // value={Device}
                                      // onChange={}
                                      name="Role"
                                      //value={selected}
                                      defaultValue={"user"}
                                      //value={selected}
                                      disabled={true}
                                      onChange={(event) =>
                                        handleChangedrop(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      style={{ backgroundColor: "white", textTransform: 'capitalize' }}
                                    >
                                      {Role.map((item, index) => (
                                        <MenuItem key={index} value={item.name} className="capitalize">
                                          {item.displyname}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                    {hasError && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Role must be selected!
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>

                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    First Name
                                  </label>
                                  <InputField
                                    disabled={true}
                                    ref={inputRefs.current[2]}
                                    name="firstname"
                                    onChange={handleInputChange}
                                    validation={"required|userpattern"}
                                    className="form-control capitalize"
                                    defaultValue={currentUser.fName}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Last Name
                                  </label>
                                  <InputField
                                    disabled={true}
                                    ref={inputRefs.current[3]}
                                    name="lastname"
                                    onChange={handleInputChange}
                                    // validation={"required|userpattern"}
                                    className="form-control capitalize"
                                    defaultValue={currentUser.lName}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Hospital Name
                                  </label>
                                  <InputField
                                    disabled={true}
                                    ref={inputRefs.current[0]}
                                    name="comapny_name"
                                    onChange={handleInputChange}
                                    // validation={"required|userpattern"}
                                    className="form-control capitalize"
                                    defaultValue={currentUser.companyName}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label className="bmd-label-floating">
                                    {" "}
                                    Branch Name{" "}
                                  </label>
                                  <FormControl style={{ width: "100%" }}>
                                    <Select
                                      // value={Device}
                                      // onChange={}

                                      name="Plant"
                                      defaultValue={currentUser.plantId}
                                      onChange={(event) =>
                                        handleChangedrop1(
                                          event.target.name,
                                          event.target.value
                                        )
                                      }
                                      style={{ backgroundColor: "white", textTransform: 'capitalize' }}
                                    >
                                      {(items.length === 0) ? <MenuItem disabled={true}>
                                        Branch Not Available
                                      </MenuItem> :
                                        items.map((item, value) => (
                                          <MenuItem key={value} value={item.id} className="capitalize">
                                            {item.name}
                                          </MenuItem>
                                        ))}
                                    </Select>
                                    {hasError1 && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {" "}
                                        Branch must be selected!
                                      </p>
                                    )}
                                  </FormControl>
                                </div>
                              </div>
                            </div>

                            <button
                              type="submit"
                              className="btn"
                              style={{ float: 'right', margin: '30px 0px -15px 0px' }}
                            >
                              save{" "}
                            </button>

                            <div className="clearfix"></div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
