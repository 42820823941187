import React from "react";
import Styles from "./alarm-list.module.css";
export default function AlarmList({ showAlarmHistory, alarmList }) {
  if (showAlarmHistory) {
    return (
      <div className="alarm-list-container" style={{ position: "relative" }}>
        <div
          className={`card ${Styles["alarm-list"]} ${Styles["contrl-colr"]} h`}
        >
          <ul className="list-group list-group-flush">
            {alarmList.map((ele, index) => {
              // console.log("Mapped element of Alarm history:", ele)
              return (
                <li
                  key={`alarm-list-${index}`}
                  className={`list-group-item text-center ${
                    Styles[ele.className]
                  }`}
                  style={{ color: "white" }}
                >
                  {ele.activatedTime} {ele.alarm}
                </li>
              );
            })}
          </ul>
          <div className={Styles["cotrol-container"]}>
            {/*  <div
              onClick={() => {
                //setList([]);
                alert("Temprary off");
              }}
            >
              Clear
            </div> */}
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
