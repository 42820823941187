import React, { useEffect, useState } from "react";
import Lock from "@material-ui/icons/Lock";
import UnLock from "@material-ui/icons/LockOpen";
import IconButton from "@material-ui/core/IconButton";
import { lockScreenEvent } from "../rxjssignals/lock-signal";
import Tooltip from "@material-ui/core/Tooltip";
import { lock,checkLock } from '../services/http';
export default () => {
  let [action, setAction] = useState(true);
  useEffect(() => {
    lockScreenEvent.receive((data) => {
      if (
        data &&
        typeof data === "object" &&
        "verbs" in data &&
        data.verbs === "unlockscreen"
      ) {
        //console.log("[Lockbutton]->Recieve",data)
        setAction(false);
      }
    });
  },[]);
  useEffect(() => {
    const token = localStorage.getItem("abmUsertoken");
    checkLock({ token: token }).then((res) => {
        if (res.status === 200) {
          setAction(false);
        }else{
            setAction(true);
        }})
  },[]);

  return (
    <>
      {action ? (<Tooltip title="Unlock to access control mode">
          <IconButton
            color="inherit"
            aria-label="lock screen"
            onClick={() => {
              lockScreenEvent.send({ verbs: "lockscreen", action: true });
              // alert("Lock screen");
            }}
            edge="start"
          >
            <Lock />
          </IconButton>
        </Tooltip>): (
        <Tooltip title="Lock to access control mode">
          <IconButton
            color="inherit"
            aria-label="lock screen"
            onClick={async () => {
                const token = await localStorage.getItem("abmUsertoken");
                lock({ token: token }).then((res) => {
                    if (res.status === 200) {
                      setAction(true);
                    }
                  }).catch(console.log);
              
             //lockScreenEvent.send({ verbs: "unlockscreen", action: false });
             // alert("Lock screen lockScreenEvent.send({ verbs: unlockscreen, action: false });");
            }}
            edge="start"
          >
            <UnLock />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};
